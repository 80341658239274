/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
// import { default as documentMasterData } from '../dataSet/documentMaster.json'
import { GetState } from "../reducers"

export enum DocumentMasterActionList {
    ADD_DOCUMENT_MASTER = 'ADD_DOCUMENT_MASTER',
    FETCH_DOCUMENT_MASTER = 'FETCH_DOCUMENT_MASTER',
    UPDATE_DOCUMENT_MASTER = 'UPDATE_DOCUMENT_MASTER',
    DELETE_DOCUMENT_MASTER = 'DELETE_DOCUMENT_MASTER',
}

// export enum DocumentTypeList {
//     RC = 'RC',
//     FC = 'FC',
//     INSURANCE = 'Insurance',
//     DL = 'DL',
// }

// export type DocumentType = DocumentTypeList.DL | DocumentTypeList.FC | DocumentTypeList.INSURANCE | DocumentTypeList.RC


export interface DocumentMasterType {
    id?: number
    code: string
    name: string
    // type: DocumentType // to be changed after api implemented
    createdBy?: string
    createTimestamp?: string
}

export interface AddDocumentMasterAction {
    type: DocumentMasterActionList.ADD_DOCUMENT_MASTER
    data: DocumentMasterType
}

export interface FetchDocumentMasterAction {
    type: DocumentMasterActionList.FETCH_DOCUMENT_MASTER
    data: Array<DocumentMasterType>
}

export interface UpdateDocumentMasterAction {
    type: DocumentMasterActionList.UPDATE_DOCUMENT_MASTER
    data: DocumentMasterType
}

export interface DeleteDocumentMasterAction {
    type: DocumentMasterActionList.DELETE_DOCUMENT_MASTER
    data: number
}

export type DocumentMasterActions = AddDocumentMasterAction | FetchDocumentMasterAction | UpdateDocumentMasterAction | DeleteDocumentMasterAction

export const addDocumentMaster = (data: DocumentMasterType) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Document Master Added")
        return api.post<APIResponse<DocumentMasterType>>('document_master/', data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<AddDocumentMasterAction>({
                    type: DocumentMasterActionList.ADD_DOCUMENT_MASTER,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Document Master Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const updateDocumentMaster = (data: DocumentMasterType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Document Master Updated")
        return api.put<APIResponse<DocumentMasterType>>(`document_master/?id=${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<UpdateDocumentMasterAction>({
                    type: DocumentMasterActionList.UPDATE_DOCUMENT_MASTER,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Document Master Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchDocumentMaster = () => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<DocumentMasterType[]>>('document_master/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchDocumentMasterAction>({
                    type: DocumentMasterActionList.FETCH_DOCUMENT_MASTER,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteDocumentMaster = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Document Master Deleted")
        return api.delete<APIResponse<DocumentMasterType>>(`document_master/?id=${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteDocumentMasterAction>({
                    type: DocumentMasterActionList.DELETE_DOCUMENT_MASTER,
                    data: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Document Master Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}

