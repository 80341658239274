/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import { default as collectionDetailsData } from '../dataSet/collectionDetails.json'
import { GetState } from "../reducers"

export enum CollectionDetailsActionList {
    ADD_COLLECTION_DETAILS = 'ADD_COLLECTION_DETAILS',
    FETCH_COLLECTION_DETAILS = 'FETCH_COLLECTION_DETAILS',
    UPDATE_COLLECTION_DETAILS = 'UPDATE_COLLECTION_DETAILS',
}

export enum CollectionTypeList {
    INCOME = 'Income',
    EXPENSE = 'Expense'
}
export type CollectionsType = CollectionTypeList.EXPENSE | CollectionTypeList.INCOME

export interface CollectionDetailsType {
    // "id": 1,
    // "collectionId": 1,
    // "type": " Income",
    // "details": "by ticket wise",
    // "amount": "1000"

    id?: number
    collectionId: number
    conductorId?: number
    type: CollectionsType | String
    details: string
    amount: string
}

export interface AddCollectionDetailsAction {
    type: CollectionDetailsActionList.ADD_COLLECTION_DETAILS
    data: CollectionDetailsType
}

export interface FetchCollectionDetailsAction {
    type: CollectionDetailsActionList.FETCH_COLLECTION_DETAILS
    data: Array<CollectionDetailsType>
}

export interface UpdateCollectionDetailsAction {
    type: CollectionDetailsActionList.UPDATE_COLLECTION_DETAILS
    data: CollectionDetailsType
}

export type CollectionDetailsActions = AddCollectionDetailsAction | FetchCollectionDetailsAction | UpdateCollectionDetailsAction

export const addCollectionDetails = (data: CollectionDetailsType) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const { collectionDetails } = getState()
        dispatch<AddCollectionDetailsAction>({
            type: CollectionDetailsActionList.ADD_COLLECTION_DETAILS,
            data: {
                ...data,
                id: collectionDetails.length + 1
            }
        })
        return Promise.resolve("Collection details Added")
        // return api.post<APIResponse<CollectionDetailsType>>('CollectionDetails/', data).then(response => {
        //    if (response.status === 200 && response.data.data) {

        //         return Promise.resolve(response.data.message
        //             ? response.data.message
        //             : 'Successfully logged in')
        //     } else {
        //         throw { response }
        //     }
        // }).catch(error => {
        //     return Promise.reject(error.response
        //         ? error.response.data.message
        //             ? error.response.data.message
        //             : 'Unable to login'
        //         : 'Unable to login')
        // })
    }
}

export const updateCollectionDetails = (data: CollectionDetailsType, id: number) => {
    return async (dispatch: Dispatch) => {
        dispatch<UpdateCollectionDetailsAction>({
            type: CollectionDetailsActionList.UPDATE_COLLECTION_DETAILS,
            data: {
                ...data,
                id: id
            }
        })
        return Promise.resolve("Collection details Updated")
        // return api.post<APIResponse<CollectionDetailsType>>('CollectionDetails/', data).then(response => {
        //    if (response.status === 200 && response.data.data) {

        //         return Promise.resolve(response.data.message
        //             ? response.data.message
        //             : 'Successfully logged in')
        //     } else {
        //         throw { response }
        //     }
        // }).catch(error => {
        //     return Promise.reject(error.response
        //         ? error.response.data.message
        //             ? error.response.data.message
        //             : 'Unable to login'
        //         : 'Unable to login')
        // })
    }
}

export const fetchCollectionDetails = () => {
    return async (dispatch: Dispatch) => {
        dispatch<FetchCollectionDetailsAction>({
            type: CollectionDetailsActionList.FETCH_COLLECTION_DETAILS,
            data: collectionDetailsData
        })
        // return api.post<APIResponse<CollectionDetailsType>>('CollectionDetails/', data).then(response => {
        //    if (response.status === 200 && response.data.data) {

        //         return Promise.resolve(response.data.message
        //             ? response.data.message
        //             : 'Successfully logged in')
        //     } else {
        //         throw { response }
        //     }
        // }).catch(error => {
        //     return Promise.reject(error.response
        //         ? error.response.data.message
        //             ? error.response.data.message
        //             : 'Unable to login'
        //         : 'Unable to login')
        // })
    }
}



