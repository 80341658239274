import React, { ChangeEventHandler, useState } from "react"
import { useDispatch } from "react-redux"
import { AppDispatch } from "../redux/store"
import { ToastTypes, useToast } from "./toast/ToastProvider"
import { EmployeeType, fetchEmployee, updateEmployeeAttachments } from "../redux/actions/employeeActions"
import { apiUrl } from "../config"
import { FormInput } from "./custom/components"

interface Props {
    editData?: EmployeeType
    onSave: () => void

}

const EditAttachmentsForm = ({ editData, onSave }: Props) => {
    const toast = useToast()

    const dispatch = useDispatch<AppDispatch>()

    const [attachments, setAttachments] = useState<File[]>([])
    const [attachmentsError, setAttachmentsError] = useState<string>('')
    const [showEdit, setShowEdit] = useState<boolean>(false)
    const [hide, setHide] = useState<boolean>(true)

    const handleEdit = () => {
        setShowEdit(true)
        setHide(false)
    }


    const handleAttachments: ChangeEventHandler<HTMLInputElement> = (e) => {
        if (e.target.files !== null) {
            const fileArray: File[] = []
            let fileError = false
            const fileList: FileList = e.target.files
            for (let i = 0; i < fileList.length; i++) {
                const file = fileList.item(i)
                if (file === null) {
                    fileError = true
                    continue
                } else if (!(file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf')) {
                    fileError = true
                    continue
                } else if (file.size / 1024 / 1024 > 2) {
                    fileError = true
                    continue
                } else {
                    fileArray.push(file)
                }
            }

            if (fileError) {
                toast('Some files does not meet requirements', ToastTypes.ERROR)
            }

            setAttachments(fileArray)
        } else {
            setAttachmentsError('You\'ve selected nothing')
        }
    }

    const handleSave = () => {
        // let error = false

        if (editData !== undefined && editData.id !== undefined) {
            const formData = new FormData()
            attachments.forEach(file => formData.append('attachments[]', file))

            dispatch(updateEmployeeAttachments(formData, editData.id)).then(text => {
                toast(text, ToastTypes.SUCCESS)
                onSave()
                dispatch(fetchEmployee())
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
        }
    }

    return <div className='container'>
        <div className='row'>

            {hide && editData?.attachments?.map(a => {
                if (a.url != null) {
                    return <div className='col-sm-6 col-md-4 col-lg-3'>
                        <div style={{ width: '150px', height: '150px' }}>
                            <img src={apiUrl + '/' + a.url} alt='attachment' className="fit-contain w-100 h-100"></img>
                        </div>
                        <div className="d-flex justify-content-end hstack gap-1">
                            <button className="btn btn-primary" onClick={handleEdit}>Edit All</button>
                        </div>
                    </div>
                } else { return <label className='col-sm-6 col-md-4 col-lg-3 text-center'>'No data'</label> }
            })
            }
            {showEdit && <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Attachments'
                    onChange={handleAttachments}
                    errorText={attachmentsError}
                    containerClass="mb-2"
                    type="file"
                    multiple
                />
            </div>}


        </div>
        <div className="d-flex justify-content-end hstack gap-1">
            {/* <button className="btn btn-primary" onClick={handleEdit}>Edit</button> */}
            {showEdit && <button className="btn btn-success" onClick={handleSave}>Save</button>}
        </div>

    </div>
}

export default EditAttachmentsForm