import { ChangeEventHandler, Fragment, MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormInput } from '../components/custom/components'
import PageTitle from '../components/custom/components/PageTitle'
import VerticalLayout from '../components/custom/layouts/Vertical'
import Modal from '../components/Modal'
import { ToastTypes, useToast } from '../components/toast/ToastProvider'
import { FormType, FormTypeList } from '../components/types'
import VehicleHiringForm from '../components/VehicleHiringForm'
import api, { APIResponse, getAxiosRequestConfig } from '../config/api'
import { rupeeFormat, today } from '../config/functions'
import { AuthUserType } from '../redux/actions/authUserActions'
import { deleteVehicleHiring, VehicleHiringReason, VehicleHiringStatus, VehicleHiringType } from '../redux/actions/vehicleHiringActions'
import { StoreState } from '../redux/reducers'
import { AppDispatch } from '../redux/store'
import { addKey, isInToday } from '../config/functions'

interface TableBody {
    sno: number
    vehicleId?: number
    vehicleName: string
    startPoint: string
    endPoint: string
    hiringReason: VehicleHiringReason // to be changed after api implemented
    fromDate: string
    toDate: string
    totalDays: string
    totalKM: string
    chargePerKM: string
    driverAllowance: string
    cleanerAllowance: string
    haltingAmount: string
    tpAmount: string
    totalAmount: string
    advanceAmount: string
    cname: string
    caddress: string
    cmobile: string
    status: VehicleHiringStatus// to be changed after api implemented
    driverAssigned?: number
    driverName: string
    cleanerAssigned?: number
    cleanerName: string
    bookingDate: string
    onEdit: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
    timeStamp: string
    authUser: AuthUserType
}

type HandleEdit = (value: VehicleHiringType) => void
type HandleDelete = (value: VehicleHiringType) => void

const VehicleHiring = () => {

    const toast = useToast()
    const dispatch = useDispatch<AppDispatch>()

    const authUser = useSelector<StoreState, AuthUserType>(state => state.authUser)
    const token = authUser.token!
    const config = getAxiosRequestConfig(token)

    // const vehicleHiringList = useSelector<StoreState, Array<VehicleHiringType & Key>>(state => addKey(state.vehicleHiring))
    const [showForm, setShowForm] = useState<boolean>(false)
    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    const [editData, setEditData] = useState<VehicleHiringType>()


    const [fromDate, setFromDate] = useState<string>(today())
    const [toDate, setToDate] = useState<string>(today())
    const [vehicleHiring, setVehicleHiring] = useState<VehicleHiringType[]>([])

    const totalIncome = useMemo<number>(() => {
        return vehicleHiring.reduce((previous, current) => {
            return previous + parseFloat(current.totalAmount)
        }, 0)

    }, [vehicleHiring])

    const totalDriverAllowance = useMemo<number>(() => {
        return vehicleHiring.reduce((previous, current) => {
            return previous + parseFloat(current.driverAllowance)
        }, 0)

    }, [vehicleHiring])

    const totalCleanerAllowance = useMemo<number>(() => {
        return vehicleHiring.reduce((previous, current) => {
            return previous + parseFloat(current.cleanerAllowance)
        }, 0)

    }, [vehicleHiring])

    const totalTpAmount = useMemo<number>(() => {
        return vehicleHiring.reduce((previous, current) => {
            return previous + (current.tpAmount ? parseFloat(current.tpAmount) : 0)
        }, 0)

    }, [vehicleHiring])

    const totalExpense = useMemo<number>(() => {
        return totalDriverAllowance + totalCleanerAllowance + totalTpAmount

    }, [totalDriverAllowance, totalCleanerAllowance, totalTpAmount])

    const totalBalance = useMemo<number>(() => {
        return totalIncome - totalExpense

    }, [totalIncome, totalExpense])

    const VehicleHiringFilter = useCallback(() => {
        api.get<APIResponse<VehicleHiringType[]>>(`vehicle_hiring/filter/?from_date=${fromDate}&to_date=${toDate}`, config).then(response => {

            if (response.status === 200 && response.data.data) {
                const result = response.data.data
                setVehicleHiring(result)

            }
        }).catch(error => {

        })

    }, [fromDate, toDate])

    const handleFromDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setFromDate(e.target.value)
    }

    const handleToDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setToDate(e.target.value)
    }

    const handleAdd = () => {
        setShowForm(true)
    }

    const handleEdit: HandleEdit = (value) => {
        setFormType(FormTypeList.UPDATE)
        setEditData(value)
        setShowForm(true)
    }

    const handleDelete: HandleDelete = (value) => {
        if (value.id !== undefined) {
            dispatch(deleteVehicleHiring(value.id)).then(text => {
                toast(text)
                VehicleHiringFilter()
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
        }
    }


    const handleFormClose = () => {
        setShowForm(false)
        setFormType(FormTypeList.ADD)
        setEditData(undefined)
    }

    useEffect(() => {
        VehicleHiringFilter()
    }, [VehicleHiringFilter, fromDate, toDate])


    return <Fragment>
        <Modal
            headerText={formType === FormTypeList.ADD ? 'Add Vehicle Hiring' : 'Edit Vehicle Hiring'}
            visible={showForm}
            onClose={handleFormClose}
            centered
            size='xl'
        >
            <VehicleHiringForm
                formType={formType}
                editData={editData}
                onSave={handleFormClose}
                vehicleHiringFilter={VehicleHiringFilter}
            />

        </Modal>
        <VerticalLayout>
            <PageTitle
                title='Vehicle Hiring'
                buttonText='ADD'
                onButtonClick={handleAdd}
            />

            <div className='card'>
                <div className='card-body'>
                    <div className='container-fluid'>
                        <div className="row">
                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <FormInput
                                    label='From Date'
                                    value={fromDate}
                                    onChange={handleFromDate}
                                    placeholder="From Date"
                                    containerClass="mb-2"
                                    type="date"
                                />
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <FormInput
                                    label='To Date'
                                    value={toDate}
                                    onChange={handleToDate}
                                    placeholder="To Date"
                                    containerClass="mb-2"
                                    type="date"
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className='card'>
                <div className='card-body'>
                    <div className='container-fluid'>
                        <div className="row">
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <h4>Income: {totalIncome ? rupeeFormat(totalIncome) : 0}</h4>
                            </div>

                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <h4>Expense: {totalExpense ? rupeeFormat(totalExpense) : 0}</h4>
                            </div>

                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <h4>Balance: {totalBalance ? rupeeFormat(totalBalance) : 0}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="card">
                <div className="card-body">
                    <div className='table-wrapper'>
                        <table className='table colored'>
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    <th className='text-truncate align-middle'>Vehicle</th>
                                    <th className='text-truncate align-middle'>From</th>
                                    <th className='text-truncate align-middle'>To</th>
                                    <th className='text-truncate align-middle'>Hired For</th>
                                    <th className='text-truncate align-middle'>From date</th>
                                    <th className='text-truncate align-middle'>To date</th>
                                    <th className='text-truncate align-middle'>Total Days</th>
                                    {/* <th className='text-truncate align-middle'>Total KM</th> */}
                                    {/* <th className='text-truncate align-middle'>Charge/Km</th> */}
                                    <th className='text-truncate align-middle'>Driver Allowance</th>
                                    <th className='text-truncate align-middle'>Cleaner Allowance</th>
                                    <th className='text-truncate align-middle'>Halting Amount</th>
                                    <th className='text-truncate align-middle'>Temporary Permit Amount</th>
                                    <th className='text-truncate align-middle'>Total Amount</th>
                                    <th className='text-truncate align-middle'>Advance Amount</th>
                                    <th className='text-truncate align-middle'>Customer</th>
                                    {/* <th className='text-truncate align-middle'>Customer Address</th> */}
                                    {/* <th className='text-truncate align-middle'>Customer Mobile</th> */}
                                    <th className='text-truncate align-middle'>Status</th>
                                    <th className='text-truncate align-middle'>Driver</th>
                                    <th className='text-truncate align-middle'>Cleaner</th>
                                    <th className='text-truncate align-middle'>Booking Date</th>
                                    <th className='text-truncate align-middle text-center'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {vehicleHiring.map((vh, i) => {
                                    return <VehicleHiringBody
                                        sno={i + 1}
                                        vehicleName={vh.vehicleName}
                                        startPoint={vh.startPoint}
                                        endPoint={vh.endPoint}
                                        hiringReason={vh.hiringReason}
                                        fromDate={vh.fromDate}
                                        toDate={vh.toDate}
                                        totalDays={vh.totalDays}
                                        totalKM={vh.totalKM}
                                        chargePerKM={vh.chargePerKM ? vh.chargePerKM : ''}
                                        driverAllowance={vh.driverAllowance}
                                        cleanerAllowance={vh.cleanerAllowance}
                                        haltingAmount={vh.haltingAmount ? vh.haltingAmount : ''}
                                        tpAmount={vh.tpAmount ? vh.tpAmount : ''}
                                        totalAmount={vh.totalAmount}
                                        advanceAmount={vh.advanceAmount ? vh.advanceAmount : ''}
                                        cname={vh.customer ? vh.customer.name : ''}
                                        caddress={vh.customer ? vh.customer.address : ''}
                                        cmobile={vh.customer ? vh.customer.contactNumber : ''}
                                        status={vh.status}
                                        driverName={vh.driverName}
                                        cleanerName={vh.cleanerName}
                                        bookingDate={vh.bookingDate}
                                        authUser={authUser}
                                        timeStamp={vh.createTimestamp !== undefined ? vh.createTimestamp : ''}
                                        // key={vh.key}
                                        onEdit={() => handleEdit(vh)}
                                        onDelete={() => handleDelete(vh)}
                                    />
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </VerticalLayout>
    </Fragment>
}

const VehicleHiringBody = ({ sno, endPoint, totalDays, hiringReason, fromDate, toDate, vehicleName, chargePerKM, startPoint, totalKM, driverAllowance, cleanerAllowance, haltingAmount, tpAmount, totalAmount, advanceAmount, cname, caddress, cmobile, status, driverName, cleanerName, bookingDate, onEdit, onDelete,authUser,timeStamp }: TableBody) => {
    const ShowEditForManager = isInToday(timeStamp)

    return <tr>
        <td>{sno}</td>
        <td className='text-capitalize'>{vehicleName}</td>
        <td className='text-capitalize'>{startPoint}</td>
        <td className='text-capitalize'>{endPoint}</td>
        <td className='text-capitalize'>{hiringReason}</td>
        <td>{fromDate}</td>
        <td>{toDate}</td>
        <td>{totalDays}</td>
        {/* <td>{totalKM}</td> */}
        {/* <td>{chargePerKM}</td> */}
        <td>{driverAllowance}</td>
        <td>{cleanerAllowance}</td>
        <td>{haltingAmount}</td>
        <td>{tpAmount}</td>
        <td>{totalAmount}</td>
        <td>{advanceAmount}</td>
        <td className='text-capitalize'>{cname}<br />{cmobile}</td>
        {/* <td className='text-capitalize'>{caddress}</td> */}
        {/* <td className='text-capitalize'>{cmobile}</td> */}
        <td className='text-capitalize'>{status}</td>
        <td className='text-capitalize'>{driverName}</td>
        <td className='text-capitalize'>{cleanerName}</td>
        <td>{bookingDate}</td>
        <td>
            <div className='hstack gap-1'>
                {(authUser.role === 'admin' || authUser.role === 'super' || authUser.role === 'Admin' || authUser.role === 'Super') && <button className='btn btn-xs btn-primary' onClick={onEdit}>Edit</button>}
                {(authUser.role === 'Manager' || authUser.role === 'manager') && ShowEditForManager && <button className='btn btn-xs btn-primary' onClick={onEdit}>Edit</button>}
                <button className='btn btn-xs btn-danger' onClick={onDelete}>Delete</button>
            </div>
        </td>
    </tr >

}

export default VehicleHiring
