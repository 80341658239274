import { Action } from "../actions/types"
import { TicketPricingType, TicketPricingActionList } from "../actions/ticketPricingActions"

const initialValue: Array<TicketPricingType> = []

const ticketPricingReducer = (state: Array<TicketPricingType> = initialValue, action: Action) => {
    switch (action.type) {

        case TicketPricingActionList.ADD_TICKET_PRICING:
            return [
                ...state,
                action.data,
            ]

        case TicketPricingActionList.UPDATE_TICKET_PRICING:
            const updated = [...state].slice()
            const index = updated.findIndex(u => u.id === action.data.id)

            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }

            return updated

        case TicketPricingActionList.UPDATE_TICKET_RATE:
            const rateUpdated = [...state]
            action.data.forEach(d => {
                const index = rateUpdated.findIndex(u => u.id === d.id)

                if (index > -1) {
                    rateUpdated[index] = {
                        ...d
                    }
                } else {
                    rateUpdated.push(d)
                }
            })

            return rateUpdated


        case TicketPricingActionList.FETCH_TICKET_PRICING:
            return action.data

        case TicketPricingActionList.DELETE_TICKET_PRICING:
            return state.filter(s => s.id !== action.data)

        default:
            return state
    }
}

export default ticketPricingReducer