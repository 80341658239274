import React, { ChangeEventHandler, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { addKey } from "../config/functions"
import { addDocumentMaster, DocumentMasterType, updateDocumentMaster } from "../redux/actions/documentMasterActions"
import { Key } from "../redux/actions/types"
import { StoreState } from "../redux/reducers"
import { AppDispatch } from "../redux/store"
import { FormInput } from "./custom/components"
// import Option from "./Option"
import { ToastTypes, useToast } from "./toast/ToastProvider"
import { FormType, FormTypeList } from "./types"

interface Props {
    formType: FormType
    editData?: DocumentMasterType
    onSave: () => void
}
//{
// id:PK,
// documentCode:FT,
// DocumentName:FT,
// DocumentType: RC/Insurance/FC/DL
// }
// const DOCUMENT_TYPES = addKey([
//     {
//         text: 'RC',
//         value: 'RC'
//     },
//     {
//         text: 'INSURANCE',
//         value: 'Insurance'
//     },
//     {
//         text: 'FC',
//         value: 'FC'
//     },
//     {
//         text: 'DL',
//         value: 'DL'
//     }
// ])
const DocumentMasterForm = ({ formType, onSave, editData }: Props) => {
    const toast = useToast()

    const dispatch = useDispatch<AppDispatch>()
    const documentMaster = useSelector<StoreState, Array<DocumentMasterType & Key>>(state => addKey(state.documentMaster))

    const [documentCode, setDocumentCode] = useState<string>('')
    const [documentCodeError, setDocumentCodeError] = useState<string>('')

    const [documentName, setDocumentName] = useState<string>('')
    const [documentNameError, setDocumentNameError] = useState<string>('')
    // const [documentType, setDocumentType] = useState<DocumentType>(DocumentTypeList.DL)
    // const [documentTypeError, setDocumentTypeError] = useState<string>('')

    const resetStates = () => {
        // setDocumentType(DocumentTypeList.DL)
        // setDocumentTypeError('')
        setDocumentName('')
        setDocumentNameError('')
        setDocumentCode('')
        setDocumentCodeError('')
    }

    const handleDocumentCode: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDocumentCode(e.target.value)
        setDocumentCodeError('')
    }

    const handleDocumentName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDocumentName(e.target.value)
        setDocumentNameError('')
    }

    // const handleDocumentType: ChangeEventHandler<HTMLInputElement> = (e) => {
    //     setDocumentType(e.target.value as DocumentType)
    //     setDocumentTypeError('')
    // }

    const handleClear = () => {
        resetStates()
    }

    const handleSave = () => {
        let error = false
        const data: DocumentMasterType = {
            name: documentName,
            code: documentCode,
            // type: documentType,
        }

        if (formType === FormTypeList.ADD) {
            if (data.code === "") {
                setDocumentCodeError('Code required')
                error = true
            } else if ((data.code).length > 15) {
                setDocumentCodeError('Document Code must be within 15 characters')
                error = true
            } else if (data.code !== "") {
                const uniqueIndex = documentMaster.findIndex(dm => {
                    return dm.code.toLowerCase() === data.code.toLowerCase()
                })
                if (uniqueIndex > -1) {
                    setDocumentCodeError('Code already exists')
                    error = true
                }
            }
        }

        if (formType === FormTypeList.UPDATE) {
            if (data.code === "") {
                setDocumentCodeError('Document Code required')
                error = true
            } else if ((data.code).length > 15) {
                setDocumentCodeError('Document Code must be within 15 characters')
                error = true
            }
        }

        if (data.name === "") {
            setDocumentNameError('Document Name required')
            error = true
        } else if ((data.name).length > 30) {
            setDocumentNameError('Document Name should be within 30 characters')
            error = true
        }

        // if (data.type === "") {
        //     setDocumentTypeError('Document Type required')
        //     error = true
        // } else 
        // if ([DocumentTypeList.DL, DocumentTypeList.FC, DocumentTypeList.INSURANCE, DocumentTypeList.RC].indexOf(data.type as DocumentType) === -1) {
        //     setDocumentTypeError('Document type must be DL/FC/Insurance/RC')
        //     error = true
        // }

        if (!error) {
            if (formType === FormTypeList.ADD) {

                dispatch(addDocumentMaster(data)).then(text => {
                    toast(text)
                    onSave()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            } else if (formType === FormTypeList.UPDATE && editData !== undefined && editData.id !== undefined) {
                dispatch(updateDocumentMaster(data, editData.id)).then(text => {
                    toast(text)
                    onSave()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
        }
    }

    useEffect(() => {
        if (formType === FormTypeList.UPDATE && editData !== undefined) {

            setDocumentCode(editData.code)
            setDocumentName(editData.name)
            // setDocumentType(editData.type)

        } else {
            resetStates()
        }
    }, [formType, editData])


    return <div className='container'>
        <div className="row">

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Document Code'
                    labelClassName="required"
                    value={documentCode}
                    onChange={handleDocumentCode}
                    placeholder='Document Code'
                    errorText={documentCodeError}
                >
                </FormInput>

            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Document Name'
                    labelClassName="required"
                    value={documentName}
                    onChange={handleDocumentName}
                    placeholder='Document Name'
                    errorText={documentNameError}
                // containerClass="mb-2"
                />
            </div>

            {/* <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Document Type'
                    labelClassName="required"
                    value={documentType}
                    onChange={handleDocumentType}
                    type='select'
                    errorText={documentTypeError}
                >
                    <Option value=''>Select</Option>
                    {DOCUMENT_TYPES.map(dt => {
                        return <Option value={dt.value} key={dt.key}>{dt.text}</Option>
                    })}
                </FormInput>
            </div> */}


        </div>
        <div className="d-flex justify-content-end hstack gap-1">
            <button className="btn btn-primary" onClick={handleClear}>Clear</button>
            <button className="btn btn-success" onClick={handleSave}>Save</button>
        </div>

    </div>
}
export default DocumentMasterForm