import { configureStore } from '@reduxjs/toolkit'
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk, { ThunkMiddleware } from 'redux-thunk'
import { appName } from '../config'
import { Action } from './actions/types'
import reducers, { StoreState } from './reducers'

const persistConfig = {
    key: appName,
    storage: storage,
    whitelist: ['authUser'],
}

const persistedReducer = persistReducer(persistConfig, reducers)

const thunkMiddleware: ThunkMiddleware<StoreState, Action> = thunk

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware => {
        const middlewares = getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
                warnAfter: 500,
            },
            immutableCheck: {
                warnAfter: 500,
            }
        })

        middlewares.push(thunkMiddleware)

        return middlewares
    },
    devTools: process.env.NODE_ENV !== 'production',
})

export type AppDispatch = typeof store.dispatch

export const persistor = persistStore(store)