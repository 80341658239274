import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import AdminDashboard from '../components/AdminDashboard'
import PageTitle from '../components/custom/components/PageTitle'
import VerticalLayout from '../components/custom/layouts/Vertical'
import api, { APIResponse, getAxiosRequestConfig } from '../config/api'
import { AuthUserType } from '../redux/actions/authUserActions'
import { EmployeeType, EmployeeTypeList } from '../redux/actions/employeeActions'
import { VehicleDocumentType } from '../redux/actions/vehicleDocumentActions'
import { StoreState } from '../redux/reducers'

const Home = () => {
    const authUser = useSelector<StoreState, AuthUserType>(state => state.authUser)
    const token = authUser.token!

    const config = getAxiosRequestConfig(token)


    const [bus, setBus] = useState<Array<VehicleDocumentType>>([])
    const [employee, setEmployee] = useState<Array<EmployeeType>>([])

    useEffect(() => {
        api.get<APIResponse<VehicleDocumentType[]>>(`vehicle_document/selectForExpiryDate/`, config).then(response => {
            if (response.status === 200 && response.data.data) {
                const result = response.data.data
                // console.log(result);
                setBus(result)
            }
        }).catch(error => {

        })
    }, [])

    useEffect(() => {
        api.get<APIResponse<EmployeeType[]>>(`employee/selectForExpiryDate/`, config).then(response => {

            if (response.status === 200 && response.data.data) {
                const result = response.data.data
                // console.log(result);

                setEmployee(result)
            }
        }).catch(error => {

        })
    }, [])



    return <VerticalLayout>
        <PageTitle
        // title='Page title'
        />

        {
            (authUser.role === 'admin' || authUser.role === 'super' || authUser.role === 'Admin' || authUser.role === 'Super') &&
            <AdminDashboard />
        }

        <div className='mb-4'>
            <div className='row mt-1'>
                {bus.length > 0 && <h4>Bus</h4>}
                {bus !== undefined && bus.map((b, i) => {
                    // console.log(b.documentName);
                    return <div className="card rounded mx-2 col-sm-6 col-md-6 col-lg-4" style={{ 'maxWidth': '25rem' }}>
                        <div className="card-body vstack gap-1 ">
                            <div className='d-flex justify-content-between align-items-center'>
                                {/* <label className={`badge ${b.documentCode === 'FC' ? 'bg-danger' : (`${b.documentCode === 'RC' ? 'bg-warning' : (`${b.documentCode === 'INSURANCE' ? 'bg-info' : 'bg-secondary'}`)}`)} align-middle fs-4`}>{b.documentName}</label> */}
                                <label className='badge bg-info fs-4'> {b.documentName} </label><br /><br />
                                <span className={`badge rounded-pill ${b.expiredOrNot === 'Expired' ? 'bg-danger' : 'bg-warning text-dark'} fs-6`}>{b.expiredOrNot}</span>
                            </div>
                            <label className='text-black fs-3'> {b.expiryDate} </label>
                            <label className='text-end mb-1'>{b.vehicleRegNo}</label>
                            <label className='text-black text-capitalize text-capitalize'>{b.vehicleName}</label>
                        </div>
                    </div>
                })}

            </div>

            <div className='row'>
                {employee.filter(f => {
                    return f.type === EmployeeTypeList.DRIVER
                }).length > 0 && <h4>Driver</h4>}
                {employee !== undefined && employee.filter(f => {
                    return f.type === EmployeeTypeList.DRIVER
                }).map((e, i) => {
                    return <div className="card rounded mx-2 col-sm-6 col-md-6 col-lg-4" style={{ 'maxWidth': '25rem' }}>
                        <div className="card-body vstack gap-1">
                            <div className='d-flex justify-content-between align-items-center'>
                                <label className='badge bg-info align-middle fs-4'>LICENSE</label>
                                <span className={`badge rounded-pill ${e.expiredOrNot === 'Expired' ? 'bg-danger' : 'bg-warning text-dark'} fs-6`}>{e.expiredOrNot}</span>
                            </div>
                            <label className='text-black fs-3'> {e.drivingLicenseExpiryDate} </label>
                            <label className='text-end mb-1'>{e.drivingLicenseNumber} </label>
                            <label className='text-black text-capitalize'>{e.name}</label>
                        </div>
                    </div>
                })}

            </div>

            <div className='row'>
                {employee.filter(f => {
                    return f.type === EmployeeTypeList.CONDUCTOR
                }).length > 0 && <h4>Conductor</h4>}
                {employee !== undefined && employee.filter(f => {
                    return f.type === EmployeeTypeList.CONDUCTOR
                }).map((e, i) => {
                    return <div className="card rounded mx-2 col-sm-6 col-md-6 col-lg-4" style={{ 'maxWidth': '23rem' }}>
                        <div className="card-body vstack gap-1">
                            <div className='d-flex justify-content-between align-items-center'>
                                <label className='badge bg-info align-middle fs-4'>LICENSE</label>
                                <span className={`badge rounded-pill ${e.expiredOrNot === 'Expired' ? 'bg-danger' : 'bg-warning text-dark'} fs-6`}>{e.expiredOrNot}</span>
                            </div>
                            <label className='text-black fs-3'> {e.drivingLicenseExpiryDate} </label>
                            <label className='text-end mb-1'>{e.drivingLicenseNumber} </label>
                            <label className='text-black text-capitalize'>{e.name}</label>
                        </div>
                    </div>
                })}

            </div>
        </div>

    </VerticalLayout >
}

export default Home
// return <div className="card rounded mx-2 col-sm-6 col-md-6 col-lg-4" style={{ 'maxWidth': '23rem' }}>
//                     <div className="card-body vstack gap-1">
//                         <div className='d-flex justify-content-between align-items-center'>
//                             <label className='badge bg-info align-middle fs-4'>LICENSE</label>
//                             <label className='text-black fs-3'> {e.drivingLicenseExpiryDate} </label>
//                         </div>
//                         <label className='text-end mb-1'>{e.drivingLicenseNumber} </label>
//                         <label className='text-black text-capitalize'>{e.name}</label>
//                     </div>
//                 </div>