import React, { ChangeEventHandler, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { addKey } from "../config/functions"
import { RouteMasterType } from "../redux/actions/routeMasterActions"
import { addTripMaster, fetchTripMaster, TripMasterType, updateTripMaster } from "../redux/actions/tripMasterActions"
import { Key } from "../redux/actions/types"
import { StoreState } from "../redux/reducers"
import { AppDispatch } from "../redux/store"
import { FormInput } from "./custom/components"
import Option from "./Option"
import { useToast } from "./toast/ToastProvider"
import { FormType, FormTypeList } from "./types"

interface Props {
    formType: FormType
    editData?: TripMasterType
    onSave: () => void
}

const TripMasterForm = ({ formType, onSave, editData }: Props) => {
    const toast = useToast()

    const dispatch = useDispatch<AppDispatch>()
    const routeMaster = useSelector<StoreState, Array<RouteMasterType & Key>>(state => addKey(state.routeMaster))

    const [tripName, setTripName] = useState<string>('')
    const [tripNameError, setTripNameError] = useState<string>('')
    const [number, setNumber] = useState<string>('')
    const [numberError, setNumberError] = useState<string>('')
    const [startTime, setStartTime] = useState<string>('')
    const [startTimeError, setStartTimeError] = useState<string>('')
    const [endTime, setEndTime] = useState<string>('')
    const [endTimeError, setEndTimeError] = useState<string>('')
    const [routeName, setRouteName] = useState<string>('')
    const [routeId, setRouteId] = useState<string>('')
    const [routeIdError, setRouteIdError] = useState<string>('')

    const resetStates = () => {
        setNumber('')
        setNumberError('')
        setStartTime('')
        setStartTimeError('')
        setEndTime('')
        setEndTimeError('')
        setRouteName('')
        setRouteId('')
        setRouteIdError('')
    }

    const handleNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
        setNumber(e.target.value)
        setNumberError('')
    }

    const handleTripName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setTripName(e.target.value)
        setTripNameError('')
    }

    const handleStartTime: ChangeEventHandler<HTMLInputElement> = (e) => {
        setStartTime(e.target.value)
        setStartTimeError('')
    }

    const handleEndTime: ChangeEventHandler<HTMLInputElement> = (e) => {
        setEndTime(e.target.value)
        setEndTimeError('')
    }

    const handleRouteId: ChangeEventHandler<HTMLInputElement> = (e) => {
        setRouteId(e.target.value)
        const index = routeMaster.findIndex(rm => rm.id !== undefined && rm.id.toString() === e.target.value)

        if (index > -1) {
            setRouteName(routeMaster[index].name)
        }

        setRouteIdError('')
    }

    const handleClear = () => {
        resetStates()
    }

    const handleSave = () => {
        let error = false
        const data: TripMasterType = {
            routeId: parseInt(routeId),
            routeName: routeName,
            tripName: tripName,
            startTime: startTime,
            endTime: endTime,
            number: number
        }

        if (data.number === "") {
            setNumberError('Trip Number required')
            error = true
        } else if (parseInt(data.number) <= 0) {
            setNumberError('Invalid Trip Number')
            error = true
        }

        if (data.startTime === "") {
            setStartTimeError('Start Time required')
            error = true
        }

        if (data.endTime === "") {
            setEndTimeError('End Time required')
            error = true
        }

        if (isNaN(data.routeId)) {
            setRouteIdError('Route required')
            error = true
        } else if (data.routeId <= 0) {
            setRouteIdError('Invalid Route')
            error = true
        }

        if (data.tripName === "") {
            setTripNameError('Trip Name required')
            error = true
        } else if ((data.tripName).length > 30) {
            setTripNameError('Trip Name must be within 30 characters')
            error = true
        }

        if (!error) {
            if (formType === FormTypeList.ADD) {

                dispatch(addTripMaster(data)).then(text => {
                    toast(text)
                    onSave()
                    dispatch(fetchTripMaster())
                }).catch(text => {
                    toast(text)
                })
            } else if (formType === FormTypeList.UPDATE && editData !== undefined && editData.id !== undefined) {
                dispatch(updateTripMaster(data, editData.id)).then(text => {
                    toast(text)
                    onSave()
                    dispatch(fetchTripMaster())
                }).catch(text => {
                    toast(text)
                })
            }
        }
    }


    useEffect(() => {
        if (formType === FormTypeList.UPDATE && editData !== undefined) {

            setNumber(editData.number)
            setStartTime(editData.startTime)
            setEndTime(editData.endTime)
            setRouteName(editData.routeName)
            setTripName(editData.tripName)
            setRouteId(editData.routeId !== undefined ? editData.routeId.toString() : '')

        } else {
            resetStates()
        }
    }, [formType, editData])


    return <div className='container'>
        <div className="row">

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Route'
                    labelClassName="required"
                    value={routeId}
                    onChange={handleRouteId}
                    type='select'
                    errorText={routeIdError}
                >
                    <option value="">Select</option>
                    {routeMaster.filter(rm => {
                        return rm.code !== "HIRE"
                    }).map(rm => {
                        return <Option value={rm.id} key={rm.key}>{rm.name}</Option>
                    })}
                </FormInput>

            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Trip Name'
                    labelClassName="required"
                    value={tripName}
                    onChange={handleTripName}
                    placeholder='Trip Name'
                    errorText={tripNameError}
                    containerClass="mb-2"
                />
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Trip Number'
                    labelClassName="required"
                    value={number}
                    onChange={handleNumber}
                    placeholder='Number'
                    errorText={numberError}
                    containerClass="mb-2"
                    type="number"
                />
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Start Time'
                    labelClassName="required"
                    value={startTime}
                    onChange={handleStartTime}
                    placeholder='Start Time'
                    errorText={startTimeError}
                    containerClass="mb-2"
                />
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='End Time'
                    labelClassName="required"
                    value={endTime}
                    onChange={handleEndTime}
                    placeholder='EndTime'
                    errorText={endTimeError}
                    containerClass="mb-2"
                />
            </div>

        </div>
        <div className="d-flex justify-content-end hstack gap-1">
            <button className="btn btn-primary" onClick={handleClear}>Clear</button>
            <button className="btn btn-success" onClick={handleSave}>Save</button>
        </div>

    </div>
}
export default TripMasterForm