import { useContext } from 'react'
import { rupeeFormat } from '../config/functions'
import { CollectionContext, CollectionContextType } from './CollectionForm'
// import { CollectionContext, CollectionContextType } from '../pages/Collection'
import { FormInput } from './custom/components'


const ExpenseC1 = () => {

    const { expense1Total,
        dieselAmount1,
        dieselAmount1Error,
        driverAllowance1,
        driverAllowance1Error,
        conductorAllowance1,
        conductorAllowance1Error,
        driverSalary1,
        driverSalary1Error,
        conductorSalary1,
        conductorSalary1Error,
        cleanerSalary1,
        cleanerSalary1Error,
        incentive1,
        incentive1Error,
        bookingCharge1,
        bookingCharge1Error,
        standRent1,
        standRent1Error,
        others1,
        others1Error,
        handleDieselAmount1,
        handleDriverAllowance1,
        handleConductorAllowance1,
        handleDriverSalary1,
        handleConductorSalary1,
        handleCleanerSalary1,
        handleIncentive1,
        handleBookingCharge1,
        handleStandRent1,
        handleOthers1, } = useContext<CollectionContextType | null>(CollectionContext)!


    return <>
        <div className='container'>
            <div className="row">

                <div className='col-sm-6 col-md-4 col-lg-3'>
                    <FormInput
                        label='Diesel'
                        value={dieselAmount1}
                        onChange={handleDieselAmount1}
                        placeholder='Diesel'
                        errorText={dieselAmount1Error}
                        type='number'
                        containerClass="mb-2"
                    />
                </div>

                <div className='col-sm-6 col-md-4 col-lg-3'>
                    <FormInput
                        label='Driver Salary'
                        value={driverSalary1}
                        onChange={handleDriverSalary1}
                        placeholder='Driver Salary'
                        errorText={driverSalary1Error}
                        type='number'
                        containerClass="mb-2"
                    />
                </div>

                <div className='col-sm-6 col-md-4 col-lg-3'>
                    <FormInput
                        label='Conductor Salary'
                        value={conductorSalary1}
                        onChange={handleConductorSalary1}
                        placeholder='Conductor Salary'
                        errorText={conductorSalary1Error}
                        type='number'
                        containerClass="mb-2"
                    />
                </div>

                <div className='col-sm-6 col-md-4 col-lg-3'>
                    <FormInput
                        label='Cleaner Salary'
                        value={cleanerSalary1}
                        onChange={handleCleanerSalary1}
                        placeholder='Cleaner Salary'
                        errorText={cleanerSalary1Error}
                        type='number'
                        containerClass="mb-2"
                    />
                </div>

                <div className='col-sm-6 col-md-4 col-lg-3'>
                    <FormInput
                        label='Driver Allowance'
                        value={driverAllowance1}
                        onChange={handleDriverAllowance1}
                        placeholder='Driver Allowance'
                        errorText={driverAllowance1Error}
                        type='number'
                        containerClass="mb-2"
                    />
                </div>
                <div className='col-sm-6 col-md-4 col-lg-3'>
                    <FormInput
                        label='Conductor Allowance'
                        value={conductorAllowance1}
                        onChange={handleConductorAllowance1}
                        placeholder='Conductor Allowance'
                        errorText={conductorAllowance1Error}
                        type='number'
                        containerClass="mb-2"
                    />
                </div>


                <div className='col-sm-6 col-md-4 col-lg-3'>
                    <FormInput
                        label='Incentive'
                        value={incentive1}
                        onChange={handleIncentive1}
                        placeholder='Incentive'
                        errorText={incentive1Error}
                        type='number'
                        containerClass="mb-2"
                    />
                </div>
                <div className='col-sm-6 col-md-4 col-lg-3'>
                    <FormInput
                        label='Booking Charge'
                        value={bookingCharge1}
                        onChange={handleBookingCharge1}
                        placeholder='Booking Charge'
                        errorText={bookingCharge1Error}
                        type='number'
                        containerClass="mb-2"
                    />
                </div>
                <div className='col-sm-6 col-md-4 col-lg-3'>
                    <FormInput
                        label='Stand Rent'
                        value={standRent1}
                        onChange={handleStandRent1}
                        placeholder='Stand Rent'
                        errorText={standRent1Error}
                        type='number'
                        containerClass="mb-2"
                    />
                </div>
                <div className='col-sm-6 col-md-4 col-lg-3'>
                    <FormInput
                        label='Others'
                        value={others1}
                        onChange={handleOthers1}
                        placeholder='Others'
                        errorText={others1Error}
                        type='number'
                        containerClass="mb-2"
                    />
                </div>
            </div>
            <div className='text-end'>
                <h4>Total Amount : {rupeeFormat(expense1Total ? expense1Total : 0)}</h4>
            </div>
        </div>
    </>
}
export default ExpenseC1