import { nanoid } from 'nanoid'
import React from 'react'
import * as Bootstrap from 'react-bootstrap'

interface Props {
    children?: React.ReactNode
    headerText?: string
    onClose?: () => void
    visible?: boolean
    size?: 'lg' | 'sm' | 'xl'
    centered?: boolean
    style?: React.CSSProperties
}

const Modal = ({ children, headerText, onClose, visible, size, centered, style }: Props) => {

    const id = nanoid()

    // useEffect(() => {
    //     const bodyElement = document.querySelector('body')
    //     if (visible && bodyElement) {
    //         bodyElement.classList.add('overflow-hidden')
    //     } else if (!visible && bodyElement) {
    //         bodyElement.classList.remove('overflow-hidden')
    //     }
    // }, [visible])

    return <Bootstrap.Modal
        size={size}
        show={visible}
        onHide={onClose}
        aria-labelledby={id}
        centered={centered}
        style={style}
    >
        <Bootstrap.Modal.Header closeButton>
            <Bootstrap.Modal.Title id={id}>
                {headerText}
            </Bootstrap.Modal.Title>
        </Bootstrap.Modal.Header>
        <Bootstrap.Modal.Body style={{ maxHeight: '70vh', overflow: 'auto' }} className='modal-body-scrollbar' >
            {children}
        </Bootstrap.Modal.Body>
    </Bootstrap.Modal>

    // return <>
    //     {visible && <div className='modal-wrapper p-2 position-fixed top-0 start-0 vw-100 vh-100 d-flex align-items-center justify-content-center overflow-hidden'>
    //         <div className='card'>
    //             {headerText !== undefined && <div className='card-header d-flex align-items-center justify-content-between bg-light-green-fade'>
    //                 <h5 className='m-0'>{headerText}</h5>
    //                 <X className='cursor-pointer' onClick={onClose} />
    //             </div>}
    //             <div className='card-body overflow-auto scrollbar' style={{ maxHeight: '60vh' }}>
    //                 {children}
    //             </div>

    //         </div>
    //     </div>}
    // </>
}

export default Modal
