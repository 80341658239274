import { ChangeEventHandler, useContext } from 'react'
import { useSelector } from 'react-redux'
import { addKey, rupeeFormat } from '../config/functions'
// import { CollectionContext, CollectionContextType } from '../pages/Collection'
import { TripMasterType } from '../redux/actions/tripMasterActions'
import { Key } from '../redux/actions/types'
import { StoreState } from '../redux/reducers'
import { CollectionContext, CollectionContextType } from './CollectionForm'
import { FormInput } from './custom/components'

const TripWiseCollectionC2 = () => {
    const { routeId, setTripWiseAmount2, tripWiseAmount2, tripWiseTotalForC2 } = useContext<CollectionContextType | null>(CollectionContext)!

    const tripMaster = useSelector<StoreState, Array<TripMasterType & Key>>(state => addKey(state.tripMaster.filter(tp => {
        return tp.routeId.toString() === routeId
    })))

    return <>
        <div className='container'>
            {tripMaster.map((t, i) => {
                const index = tripWiseAmount2.findIndex(ta => {
                    return ta.tripId === t.id
                })
                const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
                    setTripWiseAmount2(prev => {
                        if (index > -1) {
                            const updated = [...prev]
                            updated[index].amount = e.target.value
                            return updated
                        }

                        return t.id !== undefined ? [
                            ...prev,
                            {
                                amount: e.target.value,
                                refund: '',
                                remarks: '',
                                tripId: t.id
                            }
                        ] : prev
                    })
                }
                const onRefundChange: ChangeEventHandler<HTMLInputElement> = (e) => {
                    setTripWiseAmount2(prev => {
                        if (index > -1) {
                            const updated = [...prev]
                            updated[index].refund = e.target.value
                            return updated
                        }

                        return t.id !== undefined ? [
                            ...prev,
                            {
                                amount: '',
                                refund: e.target.value,
                                remarks: '',
                                tripId: t.id
                            }
                        ] : prev
                    })
                }
                const onRemarksChange: ChangeEventHandler<HTMLInputElement> = (e) => {
                    setTripWiseAmount2(prev => {
                        if (index > -1) {
                            const updated = [...prev]
                            updated[index].remarks = e.target.value
                            return updated
                        }

                        return t.id !== undefined ? [
                            ...prev,
                            {
                                amount: '',
                                refund: '',
                                remarks: e.target.value,
                                tripId: t.id
                            }
                        ] : prev
                    })
                }

                return <TripAmount
                    amount={index > -1 ? tripWiseAmount2[index].amount : ""}
                    refund={index > -1 ? tripWiseAmount2[index].refund : ""}
                    remarks={index > -1 ? tripWiseAmount2[index].remarks : ""}
                    label={t.startTime}
                    onChange={onChange}
                    onRefundChange={onRefundChange}
                    onRemarksChange={onRemarksChange}
                    key={i}
                />
            })}

            <div className='text-end'>
                <h4>Total Amount : {rupeeFormat(tripWiseTotalForC2)}</h4>
            </div>
        </div>
    </>
}

interface TripAmountProps {
    label: string
    amount: string
    refund?: string
    remarks?: string
    onChange: ChangeEventHandler<HTMLInputElement>
    onRefundChange: ChangeEventHandler<HTMLInputElement>
    onRemarksChange: ChangeEventHandler<HTMLInputElement>
}

const TripAmount = ({ amount, label, refund, remarks, onChange, onRefundChange, onRemarksChange }: TripAmountProps) => {

    return <div className="row">
        <div className='col-sm-6 col-lg-3'>
            <label>{label}</label>
        </div>
        <div className='col-sm-6 col-lg-3'>
            <FormInput
                value={amount}
                onChange={onChange}
                containerClass="mb-2"
            />
        </div>
        <div className='col-sm-6 col-lg-3'>
            <FormInput
                value={refund}
                placeholder='Refund'
                onChange={onRefundChange}
                containerClass="mb-2"
            />
        </div>
        <div className='col-sm-6 col-lg-3'>
            <FormInput
                value={remarks}
                placeholder='Remarks'
                onChange={onRemarksChange}
                containerClass="mb-2"
            />
        </div>
    </div>
}
export default TripWiseCollectionC2