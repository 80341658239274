import VerticalLayout from '../components/custom/layouts/Vertical'
import PageTitle from '../components/custom/components/PageTitle'
import { deleteRole, RoleType } from '../redux/actions/roleActions'
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from '../redux/reducers'
import { Key } from '../redux/actions/types'
import { addKey, isInToday } from '../config/functions'
import Modal from '../components/Modal'
import { MouseEventHandler, useState } from 'react'
import { FormType, FormTypeList } from '../components/types'
import { ToastTypes, useToast } from '../components/toast/ToastProvider'
import { AppDispatch } from '../redux/store'
import RoleForm from '../components/RoleForm'
import { AuthUserType } from '../redux/actions/authUserActions'

interface TableBody {
    sno: number
    title: string
    module: string
    permission: string
    onEdit: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
    timeStamp: string
    authUser: AuthUserType
}

type HandleEdit = (value: RoleType) => void
type HandleDelete = (value: RoleType) => void

const Role = () => {
    const toast = useToast()

    const dispatch = useDispatch<AppDispatch>()
    const authUser = useSelector<StoreState, AuthUserType>(state => state.authUser)


    const role = useSelector<StoreState, Array<RoleType & Key>>(state => addKey(state.role))
    const [showForm, setShowForm] = useState<boolean>(false)
    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    const [editData, setEditData] = useState<RoleType>()

    const handleAdd = () => {
        setShowForm(true)
    }

    const handleEdit: HandleEdit = (value) => {
        setFormType(FormTypeList.UPDATE)
        setEditData(value)
        setShowForm(true)
    }

    const handleDelete: HandleDelete = (value) => {
        if (value.id !== undefined) {
            dispatch(deleteRole(value.id)).then(text => {
                toast(text)
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
        }
    }

    const handleFormClose = () => {
        setShowForm(false)
        setFormType(FormTypeList.ADD)
        setEditData(undefined)
    }

    return <>
        <Modal
            headerText={formType === FormTypeList.ADD ? 'Add Role' : 'Edit Role'}
            visible={showForm}
            onClose={handleFormClose}
            size='xl'
            centered
        >
            <RoleForm
                formType={formType}
                editData={editData}
                onSave={handleFormClose}
            />

        </Modal>
        <VerticalLayout>
            <PageTitle
                title='Role'
                buttonText='ADD'
                onButtonClick={handleAdd}
            />
            <div className="card">
                <div className="card-body">
                    <div className='table-wrapper'>
                        <table className='table colored'>
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    <th className='text-truncate align-middle'>Title</th>
                                    <th className='text-truncate align-middle'>Module</th>
                                    <th className='text-truncate align-middle'>Permission</th>
                                    <th className='text-truncate align-middle'>Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                {role.map((r, i) => {
                                    return <RoleBody
                                        sno={i + 1}
                                        title={r.role}
                                        module={r.module}
                                        permission={r.access}
                                        key={r.key}
                                        authUser={authUser}
                                        timeStamp={r.createTimestamp !== undefined ? r.createTimestamp : ''}
                                        onEdit={() => handleEdit(r)}
                                        onDelete={() => handleDelete(r)}
                                    />
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </VerticalLayout>
    </>
}

const RoleBody = ({ sno, title, permission, module, onEdit, onDelete, timeStamp, authUser }: TableBody) => {
    const ShowEditForManager = isInToday(timeStamp)

    return <tr>
        <td className='align-middle'>{sno}</td>
        <td className='text-capitalize align-middle'>{title}</td>
        <td className='text-capitalize align-middle'>{module}</td>
        <td className='text-capitalize align-middle'>{permission}</td>
        <td className='hstack gap-1'>
            {(authUser.role === 'admin' || authUser.role === 'super' || authUser.role === 'Admin' || authUser.role === 'Super') && <button className='btn btn-xs btn-primary' onClick={onEdit}>Edit</button>}
            {(authUser.role === 'Manager' || authUser.role === 'manager') && ShowEditForManager && <button className='btn btn-xs btn-primary' onClick={onEdit}>Edit</button>}
            <button className='btn btn-xs btn-danger' onClick={onDelete}>Delete</button>
        </td>
    </tr>
}

export default Role
