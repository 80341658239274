import { useContext } from 'react'
import { rupeeFormat } from '../config/functions'
import { CollectionContext, CollectionContextType } from './CollectionForm'
// import { CollectionContext, CollectionContextType } from '../pages/Collection'
import { FormInput } from './custom/components'


const ExpenseC2 = () => {

    const { expense2Total,
        dieselAmount2,
        dieselAmount2Error,
        driverAllowance2,
        driverAllowance2Error,
        conductorAllowance2,
        conductorAllowance2Error,
        driverSalary2,
        driverSalary2Error,
        conductorSalary2,
        conductorSalary2Error,
        cleanerSalary2,
        cleanerSalary2Error,
        incentive2,
        incentive2Error,
        bookingCharge2,
        bookingCharge2Error,
        standRent2,
        standRent2Error,
        others2,
        others2Error,
        handleDieselAmount2,
        handleDriverAllowance2,
        handleConductorAllowance2,
        handleDriverSalary2,
        handleConductorSalary2,
        handleCleanerSalary2,
        handleIncentive2,
        handleBookingCharge2,
        handleStandRent2,
        handleOthers2, } = useContext<CollectionContextType | null>(CollectionContext)!


    return <>
        <div className='container'>
            <div className="row">

                <div className='col-sm-6 col-md-4 col-lg-3'>
                    <FormInput
                        label='Diesel'
                        value={dieselAmount2}
                        onChange={handleDieselAmount2}
                        placeholder='Diesel'
                        errorText={dieselAmount2Error}
                        type='number'
                        containerClass="mb-2"
                    />
                </div>

                <div className='col-sm-6 col-md-4 col-lg-3'>
                    <FormInput
                        label='Driver Salary'
                        value={driverSalary2}
                        onChange={handleDriverSalary2}
                        placeholder='Driver Salary'
                        errorText={driverSalary2Error}
                        type='number'
                        containerClass="mb-2"
                    />
                </div>
                <div className='col-sm-6 col-md-4 col-lg-3'>
                    <FormInput
                        label='Conductor Salary'
                        value={conductorSalary2}
                        onChange={handleConductorSalary2}
                        placeholder='Conductor Salary'
                        errorText={conductorSalary2Error}
                        type='number'
                        containerClass="mb-2"
                    />
                </div>

                <div className='col-sm-6 col-md-4 col-lg-3'>
                    <FormInput
                        label='Cleaner Salary'
                        value={cleanerSalary2}
                        onChange={handleCleanerSalary2}
                        placeholder='Cleaner Salary'
                        errorText={cleanerSalary2Error}
                        type='number'
                        containerClass="mb-2"
                    />
                </div>

                <div className='col-sm-6 col-md-4 col-lg-3'>
                    <FormInput
                        label='Driver Allowance'
                        value={driverAllowance2}
                        onChange={handleDriverAllowance2}
                        placeholder='Driver Allowance'
                        errorText={driverAllowance2Error}
                        type='number'
                        containerClass="mb-2"
                    />
                </div>
                <div className='col-sm-6 col-md-4 col-lg-3'>
                    <FormInput
                        label='Conductor Allowance'
                        value={conductorAllowance2}
                        onChange={handleConductorAllowance2}
                        placeholder='Conductor Allowance'
                        errorText={conductorAllowance2Error}
                        type='number'
                        containerClass="mb-2"
                    />
                </div>

                <div className='col-sm-6 col-md-4 col-lg-3'>
                    <FormInput
                        label='Incentive'
                        value={incentive2}
                        onChange={handleIncentive2}
                        placeholder='Incentive'
                        errorText={incentive2Error}
                        type='number'
                        containerClass="mb-2"
                    />
                </div>
                <div className='col-sm-6 col-md-4 col-lg-3'>
                    <FormInput
                        label='Booking Charge'
                        value={bookingCharge2}
                        onChange={handleBookingCharge2}
                        placeholder='Booking Charge'
                        errorText={bookingCharge2Error}
                        type='number'
                        containerClass="mb-2"
                    />
                </div>
                <div className='col-sm-6 col-md-4 col-lg-3'>
                    <FormInput
                        label='Stand Rent'
                        value={standRent2}
                        onChange={handleStandRent2}
                        placeholder='Stand Rent'
                        errorText={standRent2Error}
                        type='number'
                        containerClass="mb-2"
                    />
                </div>
                <div className='col-sm-6 col-md-4 col-lg-3'>
                    <FormInput
                        label='Others'
                        value={others2}
                        onChange={handleOthers2}
                        placeholder='Others'
                        errorText={others2Error}
                        type='number'
                        containerClass="mb-2"
                    />
                </div>
            </div>
            <div className='text-end'>
                <h4>Total Amount : {rupeeFormat(expense2Total ? expense2Total : 0)}</h4>
            </div>
        </div>
    </>
}
export default ExpenseC2