import React from 'react'
import CollectionForm from '../components/CollectionForm'
import { FormTypeList } from '../components/types'

const AddCollection = () => {

    return <CollectionForm
        formType={FormTypeList.ADD}
    />
}

export default AddCollection