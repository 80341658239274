import { RtoMasterActionList, RtoMasterType } from "../actions/rtoMasterActions"
import { Action } from "../actions/types"

const initialValue: Array<RtoMasterType> = []

const rtoMasterReducer = (state: Array<RtoMasterType> = initialValue, action: Action) => {
    switch (action.type) {

        case RtoMasterActionList.ADD_RTO_MASTER:
            return [
                ...state,
                action.data,
            ]

        case RtoMasterActionList.UPDATE_RTO_MASTER:
            const updated = [...state].slice()
            const index = updated.findIndex(u => u.id === action.data.id)

            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }

            return updated

        case RtoMasterActionList.FETCH_RTO_MASTER:
            return action.data

        case RtoMasterActionList.DELETE_RTO_MASTER:

            return state.filter(s => s.id !== action.data)

        default:
            return state
    }
}

export default rtoMasterReducer