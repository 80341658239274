/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
// import { default as vehicleMasterData } from '../dataSet/vehicleMaster.json'
import { GetState } from "../reducers"

export enum VehicleMasterActionList {
    ADD_VEHICLE_MASTER = 'ADD_VEHICLE_MASTER',
    FETCH_VEHICLE_MASTER = 'FETCH_VEHICLE_MASTER',
    UPDATE_VEHICLE_MASTER = 'UPDATE_VEHICLE_MASTER',
    DELETE_VEHICLE_MASTER = 'DELETE_VEHICLE_MASTER',
}

export enum VehicleTypeList {
    MOFUSSIL = 'Mofussil',
    SPARE = 'Spare',
    TOWN = 'Town',
}

export enum VehicleCodeList {
    SUP = 'SUP',
    AMT = 'AMT'
}

export enum VehicleCategoryList {
    BUS = 'Bus',
    LORRY = 'Lorry',
    VAN = 'Van',
    CAR = 'Car',
}
export type VehicleCode = VehicleCodeList.AMT | VehicleCodeList.SUP

export type VehicleCategory = VehicleCategoryList.BUS | VehicleCategoryList.LORRY | VehicleCategoryList.CAR | VehicleCategoryList.VAN

export type VehicleType = VehicleTypeList.MOFUSSIL | VehicleTypeList.SPARE | VehicleTypeList.TOWN

export interface VehicleMasterType {
    id?: number
    type: VehicleType // to be changed after api implemented
    code: VehicleCode
    name: string
    registrationNumber: string
    brand: string
    category: VehicleCategory // to be changed after api implemented
    yearMFG: string
    chassisNumber: string
    engineNumber: string
    mileage: string
    rtoId?: number
    rtoName?: string
    rtoArea?: string
    purchaseDate?: string
    createdBy?: string
    createTimestamp?: string
}

export interface AddVehicleMasterAction {
    type: VehicleMasterActionList.ADD_VEHICLE_MASTER
    data: VehicleMasterType
}

export interface FetchVehicleMasterAction {
    type: VehicleMasterActionList.FETCH_VEHICLE_MASTER
    data: Array<VehicleMasterType>
}

export interface UpdateVehicleMasterAction {
    type: VehicleMasterActionList.UPDATE_VEHICLE_MASTER
    data: VehicleMasterType
}

export interface DeleteVehicleMasterAction {
    type: VehicleMasterActionList.DELETE_VEHICLE_MASTER
    data: number
}

export type VehicleMasterActions = AddVehicleMasterAction | FetchVehicleMasterAction | UpdateVehicleMasterAction | DeleteVehicleMasterAction

export const addVehicleMaster = (data: VehicleMasterType) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Vehicle Master Added")

        return api.post<APIResponse<VehicleMasterType>>('vehicle_master/', data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<AddVehicleMasterAction>({
                    type: VehicleMasterActionList.ADD_VEHICLE_MASTER,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message

                    ? response.data.message
                    : 'Vehicle Master Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const updateVehicleMaster = (data: VehicleMasterType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)
        // return Promise.resolve("Vehicle Master Updated")
        return api.put<APIResponse<VehicleMasterType>>(`vehicle_master/?id=${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<UpdateVehicleMasterAction>({
                    type: VehicleMasterActionList.UPDATE_VEHICLE_MASTER,
                    data: {
                        ...data,
                        id: id
                    }
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Vehicle Master Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchVehicleMaster = () => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<VehicleMasterType[]>>('vehicle_master/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchVehicleMasterAction>({
                    type: VehicleMasterActionList.FETCH_VEHICLE_MASTER,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteVehicleMaster = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)
        // return Promise.resolve("Vehicle Master Deleted")
        return api.delete<APIResponse>(`vehicle_master/?id=${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteVehicleMasterAction>({
                    type: VehicleMasterActionList.DELETE_VEHICLE_MASTER,
                    data: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Vehicle Master Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}

