/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { default as vehicleRouteMappingMasterData } from '../dataSet/vehicleRouteMappingMaster.json'
import { GetState } from "../reducers"

export enum VehicleRouteMappingMasterActionList {
    ADD_VEHICLE_ROUTEMAPPING_MASTER = 'ADD_VEHICLE_ROUTEMAPPING_MASTER',
    FETCH_VEHICLE_ROUTEMAPPING_MASTER = 'FETCH_VEHICLE_ROUTEMAPPING_MASTER',
    UPDATE_VEHICLE_ROUTEMAPPING_MASTER = 'UPDATE_VEHICLE_ROUTEMAPPING_MASTER',
    DELETE_VEHICLE_ROUTEMAPPING_MASTER = 'DELETE_VEHICLE_ROUTEMAPPING_MASTER',
}

export interface VehicleRouteMappingMasterType {
    id?: number
    vehicleId: number
    vehicleName: string
    routeId: number
    routeName: string
    createdBy?: string
    createTimestamp?: string
}

export interface AddVehicleRouteMappingMasterAction {
    type: VehicleRouteMappingMasterActionList.ADD_VEHICLE_ROUTEMAPPING_MASTER
    data: VehicleRouteMappingMasterType
}

export interface FetchVehicleRouteMappingMasterAction {
    type: VehicleRouteMappingMasterActionList.FETCH_VEHICLE_ROUTEMAPPING_MASTER
    data: Array<VehicleRouteMappingMasterType>
}

export interface UpdateVehicleRouteMappingMasterAction {
    type: VehicleRouteMappingMasterActionList.UPDATE_VEHICLE_ROUTEMAPPING_MASTER
    data: VehicleRouteMappingMasterType
}

export interface DeleteVehicleRouteMappingMasterAction {
    type: VehicleRouteMappingMasterActionList.DELETE_VEHICLE_ROUTEMAPPING_MASTER
    data: number
}

export type VehicleRouteMappingMasterActions = AddVehicleRouteMappingMasterAction | FetchVehicleRouteMappingMasterAction | UpdateVehicleRouteMappingMasterAction | DeleteVehicleRouteMappingMasterAction

export const addVehicleRouteMappingMaster = (data: VehicleRouteMappingMasterType) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Vehicle RouteMapping Master Added")
        return api.post<APIResponse<VehicleRouteMappingMasterType>>('vehicle_route_mapping/', data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<AddVehicleRouteMappingMasterAction>({
                    type: VehicleRouteMappingMasterActionList.ADD_VEHICLE_ROUTEMAPPING_MASTER,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Vehicle RouteMapping Master Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const updateVehicleRouteMappingMaster = (data: VehicleRouteMappingMasterType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Vehicle RouteMapping Master Updated")
        return api.put<APIResponse<VehicleRouteMappingMasterType>>(`vehicle_route_mapping/?id=${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<UpdateVehicleRouteMappingMasterAction>({
                    type: VehicleRouteMappingMasterActionList.UPDATE_VEHICLE_ROUTEMAPPING_MASTER,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Vehicle RouteMapping Master Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchVehicleRouteMappingMaster = () => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<VehicleRouteMappingMasterType[]>>('vehicle_route_mapping/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchVehicleRouteMappingMasterAction>({
                    type: VehicleRouteMappingMasterActionList.FETCH_VEHICLE_ROUTEMAPPING_MASTER,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteVehicleRouteMappingMaster = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Vehicle RouteMapping Master Deleted")
        return api.delete<APIResponse<VehicleRouteMappingMasterType>>(`vehicle_route_mapping/?id=${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteVehicleRouteMappingMasterAction>({
                    type: VehicleRouteMappingMasterActionList.DELETE_VEHICLE_ROUTEMAPPING_MASTER,
                    data: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Vehicle RouteMapping Master Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}

