import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { ChangeEventHandler, Fragment, MouseEventHandler, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import CollectionViewDetailsForm from '../components/CollectionViewDetailsForm'
import { FormInput } from '../components/custom/components'
import PageTitle from '../components/custom/components/PageTitle'
import VerticalLayout from '../components/custom/layouts/Vertical'
import Modal from '../components/Modal'
import Option from '../components/Option'
import api, { APIResponse, getAxiosRequestConfig } from '../config/api'
import { addKey, currencyFormat, isInToday, LocalFormatDate, rupeeFormat, today } from '../config/functions'
import { CollectionType } from '../redux/actions/collectionActions'
import { RouteMasterType } from '../redux/actions/routeMasterActions'
import { Key } from '../redux/actions/types'
import { VehicleMasterType } from '../redux/actions/vehicleMasterActions'
import { StoreState } from '../redux/reducers'
// import { CollectionSaveDataType } from '../components/CollectionForm'
import * as Feather from 'react-feather'
import { ToastTypes, useToast } from '../components/toast/ToastProvider'
import { AuthUserType } from '../redux/actions/authUserActions'
import { EmployeeType, EmployeeTypeList } from '../redux/actions/employeeActions'

interface TableBody {

    sno: number
    id: number
    routeName: string
    vehicleName: string
    driver: string
    diesel: number
    totalKm: number
    mileage: number
    date: string
    invoiceNumber?: string
    totalIncome: string
    totalExpense: string
    shortage?: string
    excess?: string
    balance: string
    machineName?: string
    startingNumber?: string
    endingNumber?: string
    createdBy?: string
    createTimestamp?: string
    onView: MouseEventHandler<HTMLButtonElement>
    authUser: AuthUserType
    timeStamp: string
}

type HandleViewInDetail = (value: CollectionType) => void

const Collection = () => {
    const toast = useToast()

    const authUser = useSelector<StoreState, AuthUserType>(state => state.authUser)
    const token = authUser.token!
    const config = getAxiosRequestConfig(token)

    // const collection = useSelector<StoreState, Array<CollectionType & Key>>(state => addKey(state.collection))
    const routeMaster = useSelector<StoreState, Array<RouteMasterType & Key>>(state => addKey(state.routeMaster))
    const vehiclemaster = useSelector<StoreState, Array<VehicleMasterType & Key>>(state => addKey(state.vehicleMaster))
    const drivers = useSelector<StoreState, Array<EmployeeType & Key>>(state => addKey(state.employee.filter(e => e.type === EmployeeTypeList.DRIVER)))

    // const [showForm, setShowForm] = useState<boolean>(false)
    const [viewForm, setViewForm] = useState<boolean>(false)
    const [editData, setEditData] = useState<CollectionType>()

    const [fromDate, setFromDate] = useState<string>(today())
    const [toDate, setToDate] = useState<string>(today())
    const [routeId, setRouteId] = useState<string>('')
    const [vehicleId, setVehicleId] = useState<string>('')
    const [collection, setCollection] = useState<CollectionType[]>([])


    const handleFromDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setFromDate(e.target.value)
    }

    const handleToDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setToDate(e.target.value)
    }

    const handleRouteId: ChangeEventHandler<HTMLInputElement> = (e) => {
        setRouteId(e.target.value)
    }

    const handleVehicleId: ChangeEventHandler<HTMLInputElement> = (e) => {
        setVehicleId(e.target.value)
    }

    const totalIncome = useMemo<number>(() => {
        return collection.reduce((previous, current) => {
            return previous + parseFloat(current.totalIncome)
        }, 0)

    }, [collection])

    const totalExpense = useMemo<number>(() => {
        return collection.reduce((previous, current) => {
            return previous + parseFloat(current.totalExpense)
        }, 0)

    }, [collection])

    const totalShortage = useMemo<number>(() => {
        return collection.reduce((previous, current) => {
            return previous + (current.shortage !== undefined ? parseFloat(current.shortage) : 0)
        }, 0)

    }, [collection])

    const totalExcess = useMemo<number>(() => {
        return collection.reduce((previous, current) => {
            return previous + (current.excess !== undefined ? parseFloat(current.excess) : 0)
        }, 0)

    }, [collection])

    const totalBalance = useMemo<number>(() => {
        return collection.reduce((previous, current) => {
            return previous + Number(current.balance)
        }, 0)

    }, [collection])

    const handleDownloadPDF = () => {
        const doc = new jsPDF('l', 'mm', 'a4')

        doc.addFont('Times-Bold', 'Times', 'bold')
        doc.setFont('Times')
        doc.setFontSize(18)
        doc.text('SUPREME  BUS  SERVICE', 100, 13)

        const head = [['S.No', 'Date', 'Route', 'Collection', 'Expense', 'Income', 'Diesel', 'Km', 'Avg.Mileage']]

        const body = collection.map((c, i) => {
            let driverName = ''
            let diesel = 0
            let km = 0
            let mileage = 0

            if (c.vehicleReading) {
                mileage = c.vehicleReading?.averageMilage
                diesel = c.vehicleReading?.diesel
                km = c.vehicleReading?.totalKm
                const index = drivers.findIndex(d => d.id === (c.vehicleReading ? c.vehicleReading?.driverId : undefined))
                if (index > -1) {
                    driverName = drivers[index].name
                }
            }

            return [
                i + 1,
                LocalFormatDate(c.date),
                // String(c.invoiceNumber),
                String(c.routeName),
                // String(c.vehicleName),
                String(c.totalIncome),
                String(c.totalExpense),
                // String(c.shortage),
                // String(c.excess),
                String(c.balance),
                String(diesel.toFixed(2)),
                String(km),
                String(mileage),
                // String(c.machineName),
                // String(c.startingNumber),
                // String(c.endingNumber),
                // String(c.createdBy),
                // String(c.createTimestamp)
            ]
        })

        autoTable(doc, {
            head: head,
            body: body,
            startY: 20,
            didDrawPage: function () {

                // // Header
                var str = doc.getNumberOfPages();
                doc.setFontSize(10);
                // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                // var pageSize = doc.internal.pageSize;
                // var pageHeight = pageSize.height
                //     ? pageSize.height
                //     : pageSize.getHeight();
                doc.text(str.toString(), 255, 10);
            }
        })

        // doc.addPage()
        doc.addFont('Times-Bold', 'Times', 'bold')
        doc.setFont('Times')
        // doc.setFontType('bold')
        doc.setFontSize(18)
        doc.text('Total Shortage :', 25, (doc as any).lastAutoTable.finalY + 20)
        doc.text('Rs.' + String(totalShortage), 70, (doc as any).lastAutoTable.finalY + 20)

        doc.text('Total Excess :', 25, (doc as any).lastAutoTable.finalY + 35)
        doc.text('Rs.' + String(totalExcess), 70, (doc as any).lastAutoTable.finalY + 35)

        doc.text('Total Balance :', 25, (doc as any).lastAutoTable.finalY + 50)
        doc.text('Rs.' + String(totalBalance), 70, (doc as any).lastAutoTable.finalY + 50)

        doc.text('Total Income :', 155, (doc as any).lastAutoTable.finalY + 20)
        doc.text('Rs.' + String(totalIncome), 200, (doc as any).lastAutoTable.finalY + 20)

        doc.text('Total Expense :', 155, (doc as any).lastAutoTable.finalY + 35)
        doc.text('Rs.' + String(totalExpense), 200, (doc as any).lastAutoTable.finalY + 35)

        doc.save("collection.pdf")
    }

    const handleViewInDetail: HandleViewInDetail = (value) => {
        setEditData(value)
        setViewForm(true)
    }

    const handleFormClose = () => {
        setViewForm(false)
        setEditData(undefined)
    }

    useEffect(() => {
        // if (editData !== undefined && editData.id !== undefined) {
        api.get<APIResponse<CollectionType[]>>(`collection/filter/?from_date=${fromDate}&to_date=${toDate}&vehicle_id=${vehicleId}&route_id=${routeId}`, config).then(response => {

            if (response.status === 200 && response.data.data) {
                const result = response.data.data
                // console.log(result);
                setCollection(result)


            } else {
                // eslint-disable-next-line no-throw-literal
                throw { response }
            }

        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? toast(error.response.data.message, ToastTypes.ERROR)
                    : 'Unable to fetch'
                : 'Unable to fetch')

        })
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fromDate, toDate, vehicleId, routeId])

    return <Fragment>
        {/* <Modal
            headerText={formType === FormTypeList.ADD ? 'Add Collection' : 'Edit Collection'}
            visible={showForm}
            onClose={handleFormClose}
            centered
            size='xl'
        >
            <CollectionForm
                formType={formType}
                editData={editData}
                // onSave={handleFormClose}
            />

        </Modal> */}

        <Modal
            headerText={'Details'}
            visible={viewForm}
            onClose={handleFormClose}
            centered
            size='xl'
        >
            <CollectionViewDetailsForm
                editData={editData}
            />

        </Modal>

        <VerticalLayout>
            <PageTitle
                title='Collection'
                buttonText='ADD'
                isLink
                to='/collection/add'
            />
            <div className='card shadow-box-2 px-0 mx-0 mb-1 pt-1'>
                <div className='card-body ps-3 py-0'>
                    <div className='container-fluid '>
                        <div className="row">
                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <FormInput
                                    label='From Date'
                                    value={fromDate}
                                    onChange={handleFromDate}
                                    placeholder="From Date"
                                    containerClass=""
                                    type="date"
                                />
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <FormInput
                                    label='To Date'
                                    value={toDate}
                                    onChange={handleToDate}
                                    placeholder="To Date"
                                    containerClass="mb-2"
                                    type="date"
                                />
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <FormInput
                                    value={routeId}
                                    onChange={handleRouteId}
                                    type='select'
                                    label='Route'
                                >
                                    <option value="">All</option>
                                    {routeMaster.filter(rm => {
                                        return rm.code !== "HIRE"
                                    }).map(rm => {
                                        return <Option value={rm.id} key={rm.key}>{rm.name}</Option>
                                    })}
                                </FormInput>
                            </div>
                            {/* <div className="col-sm-6 col-md-4 col-lg-3">
                                <FormInput
                                    value={vehicleId}
                                    onChange={handleVehicleId}
                                    type='select'
                                    label='Vehicle'
                                >
                                    <option value="">All</option>
                                    {vehiclemaster.map(vm => {
                                        return <Option value={vm.id} key={vm.key}>{vm.name}</Option>
                                    })}
                                </FormInput>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className='card shadow-box-2 p-0 mx-0 mb-1'>
                <div className='card-body px-0 pt-1 pb-0 m-0'>
                    <div className='container-fluid'>
                        <div className="row">
                            <div className="col-sm-6 col-md-4 col-lg-4 ">
                                <div className='rounded  p-1 m-1 blue-box row'>
                                    <div className="col-sm-3 text-white p-1">
                                        <Feather.PlusSquare
                                            color='white'
                                            size={40}
                                        />
                                    </div>
                                    <div className="col-sm-9" >
                                        <h4 className='text-white'>Collection: <br />{currencyFormat(totalIncome)}</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className='rounded  p-1 m-1 pink-box row'>
                                    <div className="col-sm-3 text-white p-1">
                                        <Feather.MinusSquare
                                            color='white'
                                            size={40}
                                        />
                                    </div>
                                    <div className="col-sm-9" >
                                        <h4 className='text-white'>Expense:<br /> {currencyFormat(totalExpense)}</h4>
                                    </div>
                                </div>

                            </div>
                            {/* <div className="col-sm-6 col-md-4 col-lg-4">
                                <h4> Excess / Shortage: {currencyFormat(totalExcess)}/{currencyFormat(totalShortage)}</h4>
                            </div> */}
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className='rounded  p-1 m-1 green-box row'>
                                    <div className="col-sm-3 text-white p-1">
                                        <Feather.Pocket
                                            color='white'
                                            size={40}
                                        />
                                    </div>
                                    <div className="col-sm-9" >
                                        <h4 className='text-white'>Income:<br /> {currencyFormat(totalBalance)}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {collection.length > 0 && <div className="card">
                <div className="card-body">
                    <div className='table-wrapper'>
                        <table className='table colored' id='collection-table'>
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    <th className='text-truncate align-middle'>Date</th>
                                    <th className='text-truncate align-middle'>Route</th>
                                    <th className='text-truncate align-middle text-end'>Collection</th>
                                    <th className='text-truncate align-middle text-end'>Expense</th>
                                    <th className='text-truncate align-middle text-end'>Income</th>
                                    <th className='text-truncate align-middle text-end'>Diesel</th>
                                    <th className='text-truncate align-middle text-end'>Km</th>
                                    <th className='text-truncate align-middle text-end'>Average Mileage</th>
                                    <th className='text-truncate align-middle text-center'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {collection !== undefined && collection.map((c, i) => {
                                    let driverName = ''
                                    let diesel = 0
                                    let km = 0
                                    let mileage = 0

                                    if (c.vehicleReading) {
                                        mileage = c.vehicleReading?.averageMilage
                                        diesel = c.vehicleReading?.diesel
                                        km = c.vehicleReading?.totalKm
                                        const index = drivers.findIndex(d => d.id === (c.vehicleReading ? c.vehicleReading?.driverId : undefined))
                                        if (index > -1) {
                                            driverName = drivers[index].name
                                        }
                                    }

                                    return <CollectionBody
                                        sno={i + 1}
                                        id={c.id !== undefined ? c.id : 0}
                                        date={LocalFormatDate(c.date)}
                                        // invoiceNumber={c.invoiceNumber}
                                        routeName={c.routeName}
                                        vehicleName={c.vehicleName}
                                        totalIncome={rupeeFormat(c.totalIncome)}
                                        totalExpense={rupeeFormat(c.totalExpense)}
                                        shortage={rupeeFormat(c.shortage)}
                                        excess={rupeeFormat(c.excess)}
                                        balance={rupeeFormat(c.balance)}
                                        // machineName={c.machineName}
                                        // startingNumber={c.startingNumber}
                                        // endingNumber={c.endingNumber}
                                        createdBy={c.createdBy}
                                        createTimestamp={c.createTimestamp}
                                        driver={driverName}
                                        diesel={diesel}
                                        totalKm={km}
                                        mileage={mileage}
                                        // key={c.key}
                                        // onEdit={() => handleEdit(c)}
                                        onView={() => handleViewInDetail(c)}
                                        authUser={authUser}
                                        timeStamp={c.createTimestamp !== undefined ? c.createTimestamp : ''}
                                        key={c.id}
                                    />
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className='my-2 text-end'>
                        <button className='btn btn-primary' onClick={handleDownloadPDF}>Download PDF
                            <i className='fe-download'></i>
                        </button>
                    </div>
                </div>
            </div>}
        </VerticalLayout>
    </Fragment>
}

const CollectionBody = ({ sno, date, invoiceNumber = '', routeName, vehicleName, totalIncome, totalExpense, shortage, excess, balance, createdBy = '', createTimestamp = '', machineName, startingNumber, endingNumber, id, onView, driver, mileage, diesel, totalKm, authUser, timeStamp }: TableBody) => {

    const ShowEditForManager = isInToday(timeStamp)

    return <tr>
        <td>{sno}</td>
        <td>{date}</td>
        {/* <td>{invoiceNumber}</td> */}
        <td>{routeName}</td>
        {/* <td>{vehicleName}</td> */}
        {/* <td>{driver}</td> */}
        <td className='text-truncate text-end pe-2'>{totalIncome}</td>
        <td className='text-truncate text-end pe-2'>{totalExpense}</td>
        {/* <td>{shortage}</td>
        <td>{excess}</td> */}
        <td className='text-truncate text-end pe-2'>{balance}</td>
        <td className='text-end'>{diesel.toFixed(2)}</td>
        <td className='text-end'>{totalKm}</td>
        <td className='text-end pe-4'>{mileage}</td>

        <td className=''>
            <div className='ms-2 hstack gap-1'>
                {(authUser.role === 'admin' || authUser.role === 'super' || authUser.role === 'Admin' || authUser.role === 'Super') && < Link to={`/collection/edit/${id}`} className='btn btn-primary btn-xs text-end'>Edit</Link>}
                {(authUser.role === 'Manager' || authUser.role === 'manager') && ShowEditForManager && < Link to={`/collection/edit/${id}`} className='btn btn-primary btn-xs text-end'>Edit</Link>}
                <button className='btn btn-danger btn-xs text-truncate text-end' onClick={onView}>View Details</button>
            </div>
        </td>
    </tr >

}

export default Collection
