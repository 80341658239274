import { Action } from "../actions/types"
import { VehicleMasterType, VehicleMasterActionList } from "../actions/vehicleMasterActions"

const initialValue: Array<VehicleMasterType> = []

const vehicleMasterReducer = (state: Array<VehicleMasterType> = initialValue, action: Action) => {
    switch (action.type) {

        case VehicleMasterActionList.ADD_VEHICLE_MASTER:
            return [
                ...state,
                action.data,
            ]

        case VehicleMasterActionList.UPDATE_VEHICLE_MASTER:
            const updated = [...state].slice()
            const index = updated.findIndex(u => u.id === action.data.id)

            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }

            return updated

        case VehicleMasterActionList.FETCH_VEHICLE_MASTER:
            return action.data

        case VehicleMasterActionList.DELETE_VEHICLE_MASTER:
            return state.filter(s => s.id !== action.data)

        default:
            return state
    }
}

export default vehicleMasterReducer