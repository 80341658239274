import { useDispatch } from "react-redux"
import { ToastTypes, useToast } from "../components/toast/ToastProvider"
import { deleteTransaction, TransactionType } from "../redux/actions/transactionActions"
import { AppDispatch } from "../redux/store"


interface Props {
    editData?: TransactionType
    onClose: () => void
    // onDelete: () => void
}

// type HandleDelete = (value: TransactionType) => void

const DeleteTransactionForm = ({ editData, onClose }: Props) => {

    const toast = useToast()
    const dispatch = useDispatch<AppDispatch>()

    const handleDeleteYes = () => {
        if (editData !== undefined && editData.id !== undefined) {
            console.log(editData);

            dispatch(deleteTransaction(editData.id)).then(text => {
                toast(text, ToastTypes.SUCCESS)
                // dispatch(fetchTransaction())
                // resetStates()
                // CurrentDateTransactions()
                // CurrentCashBalance()
                // AllBankBalances()
                onClose()
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
        }
    }

    return <>
        <div className="container">
            <div className="row">
                <div className="col-lg-12 col-md-8 col-sm-8 my-1">
                    <label className="fs-4 text-black">Do You Really Want To Delete this Transaction ?</label>
                </div>
                <div className="hstack gap-1 d-flex justify-content-end ">
                    <button className="btn btn-lg btn-danger" onClick={handleDeleteYes}>Delete</button>
                    <button className="btn btn-lg btn-primary" onClick={onClose}>Cancel</button>
                </div>
            </div>
        </div>
    </>
}
export default DeleteTransactionForm