import { ChangeEventHandler, MouseEventHandler, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { fetchAccountHolder } from "../redux/actions/accountHolderActions"
// import { addKey } from "../config/functions"
import { addSupplier, fetchSupplier, SupplierType, updateSupplier } from "../redux/actions/supplierActions"
// import { Key } from "../redux/actions/types"
// import { StoreState } from "../redux/reducers"
import { AppDispatch } from "../redux/store"
import { FormInput } from "./custom/components"
import { ToastTypes, useToast } from "./toast/ToastProvider"
import { FormType, FormTypeList } from "./types"

interface Props {
    formType: FormType
    editData?: SupplierType
    onSave: () => void
}
// "id": 1,
// "code": "sup1",
// "name": "Mahindra",
// "contactPerson": "Ash",
// "mobileNumber": "8604858905",
// "GSTNumber": "3485HGJ348937",
// "aadharNumber": "6756 4088 7883"

const SupplierForm = ({ formType, onSave, editData }: Props) => {
    const toast = useToast()

    const dispatch = useDispatch<AppDispatch>()
    // const supplier = useSelector<StoreState, Array<SupplierType & Key>>(state => addKey(state.supplier))

    const [code, setCode] = useState<string>('')
    const [codeError, setCodeError] = useState<string>('')
    const [name, setName] = useState<string>('')
    const [nameError, setNameError] = useState<string>('')
    const [address, setAddress] = useState<string>('')
    const [addressError, setAddressError] = useState<string>('')
    const [contactPerson, setContactPerson] = useState<string>('')
    const [contactPersonError, setContactPersonError] = useState<string>('')
    const [mobile, setMobile] = useState<string>('')
    const [mobileError, setMobileError] = useState<string>('')
    const [gstNo, setGstNo] = useState<string>('')
    const [gstNoError, setGstNoError] = useState<string>('')
    const [aadharNo, setAadharNo] = useState<string>('')
    const [aadharNoError, setAadharNoError] = useState<string>('')
    const [panNo, setPanNo] = useState<string>('')
    const [panNoError, setPanNoError] = useState<string>('')
    const [oldBalance, setOldBalance] = useState<string>('')
    const [oldBalanceError, setOldBalanceError] = useState<string>('')
    const [oldBalanceDate, setOldBalanceDate] = useState<string>('')
    const [oldBalanceDateError, setOldBalanceDateError] = useState<string>('')

    const resetStates = () => {
        setCode('')
        setCodeError('')
        setName('')
        setNameError('')
        setAddress('')
        setAddressError('')
        setContactPerson('')
        setContactPersonError('')
        setMobile('')
        setMobileError('')
        setGstNo('')
        setGstNoError('')
        setAadharNo('')
        setAadharNoError('')
        setPanNo('')
        setPanNoError('')
        setOldBalance('')
        setOldBalanceError('')
        setOldBalanceDate('')
        setOldBalanceDateError('')
    }

    const handleOldBalance: ChangeEventHandler<HTMLInputElement> = (e) => {
        setOldBalance(e.target.value)
        setOldBalanceError('')
    }

    const handleOldBalanceDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setOldBalanceDate(e.target.value)
        setOldBalanceDateError('')
    }

    const handleCode: ChangeEventHandler<HTMLInputElement> = (e) => {
        setCode(e.target.value)
        setCodeError('')
    }

    const handleName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setName(e.target.value)
        setNameError('')
    }

    const handleAddress: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAddress(e.target.value)
        setAddressError('')
    }


    const handleContactPerson: ChangeEventHandler<HTMLInputElement> = (e) => {
        setContactPerson(e.target.value)
        setContactPersonError('')
    }

    const handleMobile: ChangeEventHandler<HTMLInputElement> = (e) => {
        setMobile(e.target.value)
        setMobileError('')
    }

    const handleGstNo: ChangeEventHandler<HTMLInputElement> = (e) => {
        setGstNo(e.target.value)
        setGstNoError('')
    }
    const handleAadharNo: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAadharNo(e.target.value)
        setAadharNoError('')
    }
    const handlePanNo: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPanNo(e.target.value)
        setPanNoError('')
    }

    const handleClear: MouseEventHandler<HTMLButtonElement> = (e) => {
        resetStates()
    }

    const handleSave = () => {
        let error = false
        const data: SupplierType = {
            code: code,
            name: name,
            address: address,
            contactPerson: contactPerson,
            mobileNumber: mobile,
            gstNumber: gstNo,
            aadharNumber: aadharNo,
            panNumber: panNo,
            oldBalance: oldBalance,
            oldBalanceDate: oldBalanceDate,
        }

        // if (formType === FormTypeList.ADD) {
        //     if (data.code === "") {
        //         setCodeError('Code required')
        //         error = true
        //     } else if ((data.code).length > 15) {
        //         setCodeError('Code must be within 15 characters')
        //         error = true
        //     } else if (data.code !== "") {
        //         const uniqueIndex = supplier.findIndex(s => {
        //             return s.code.toLowerCase() === data.code.toLowerCase()
        //         })
        //         if (uniqueIndex > -1) {
        //             setCodeError('Code already exists')
        //             error = true
        //         }
        //     }
        // }

        // if (formType === FormTypeList.UPDATE) {
        //     if (data.code === "") {
        //         setCodeError('Code required')
        //         error = true
        //     } else if ((data.code).length > 15) {
        //         setCodeError('Code must be within 15 characters')
        //         error = true
        //     }
        // }

        if (data.code !== "" && data.code !== undefined) {
            if ((data.code).length > 15) {
                setCodeError('Code must be within 15 characters')
                error = true
            }
        }

        if (data.name === "") {
            setNameError('Name required')
            error = true
        } else if ((data.name).length > 30) {
            setNameError('Name must be within 30 characters')
            error = true
        }

        if (data.address !== "" && data.address !== undefined) {
            if ((data.address)?.length > 100) {
                setAddressError('Address should be within 100 characters')
                error = true
            }
        }

        if (data.contactPerson === "") {
            setContactPersonError('Contact Person Name required')
            error = true
        } else if ((data.contactPerson).length > 30) {
            setContactPersonError('Contact Person must be within 30 characters')
            error = true
        }

        if (data.mobileNumber === "") {
            setMobileError('Mobile Number required')
            error = true
        }

        if (data.gstNumber !== "") {
            if ((data.gstNumber)?.length !== 15) {
                setGstNoError('GST Number should be 15 characters in length')
                error = true
            }
        }

        if (data.aadharNumber !== "") {
            if ((data.aadharNumber)?.length !== 12) {
                setAadharNoError('Aadhar Number should be 12 characters in length')
                error = true
            }
        }

        if (data.panNumber !== "") {
            if ((data.panNumber)?.length !== 10) {
                setPanNoError('Pan Number should be 10 characters in length')
                error = true
            }
        }

        if (!error) {
            if (formType === FormTypeList.ADD) {
                dispatch(addSupplier(data)).then(text => {
                    toast(text, ToastTypes.SUCCESS)
                    onSave()
                    dispatch(fetchSupplier())
                    dispatch(fetchAccountHolder())
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            } else if (formType === FormTypeList.UPDATE && editData !== undefined && editData.id !== undefined) {
                dispatch(updateSupplier(data, editData.id)).then(text => {
                    toast(text, ToastTypes.SUCCESS)
                    onSave()
                    dispatch(fetchSupplier())
                    dispatch(fetchAccountHolder())
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
        }
    }

    useEffect(() => {
        if (formType === FormTypeList.UPDATE && editData !== undefined) {

            setCode(editData.code !== undefined ? editData.code : '')
            setName(editData.name)
            setAddress(editData.address !== undefined ? editData.address : '')
            setContactPerson(editData.contactPerson)
            setMobile(editData.mobileNumber)
            setGstNo(editData.gstNumber !== undefined ? editData.gstNumber : '')
            setAadharNo(editData.aadharNumber !== undefined ? editData.aadharNumber : '')
            setPanNo(editData.panNumber !== undefined ? editData.panNumber : '')
            setOldBalance(editData.oldBalance !== undefined ? editData.oldBalance : '')
            setOldBalanceDate(editData.oldBalanceDate !== undefined ? editData.oldBalanceDate : '')

        } else {
            resetStates()
        }
    }, [formType, editData])


    return <div className='container'>
        <div className="row">

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Code'
                    // labelClassName="required"
                    value={code}
                    onChange={handleCode}
                    placeholder='Code'
                    errorText={codeError}
                    containerClass="mb-2"
                />
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Name'
                    labelClassName="required"
                    value={name}
                    onChange={handleName}
                    placeholder='Name'
                    errorText={nameError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Address'
                    // labelClassName="required"
                    value={address}
                    onChange={handleAddress}
                    placeholder='Address'
                    errorText={addressError}
                    containerClass="mb-2"
                    type="textarea"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Contact Person'
                    labelClassName="required"
                    value={contactPerson}
                    onChange={handleContactPerson}
                    placeholder='ContactPerson'
                    errorText={contactPersonError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Mobile'
                    labelClassName="required"
                    value={mobile}
                    onChange={handleMobile}
                    placeholder='Mobile'
                    errorText={mobileError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Gst.No'
                    value={gstNo}
                    onChange={handleGstNo}
                    placeholder='Gst.No'
                    errorText={gstNoError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Aadhar.No'
                    value={aadharNo}
                    onChange={handleAadharNo}
                    placeholder='Aadhar.No'
                    errorText={aadharNoError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Pan.No'
                    value={panNo}
                    onChange={handlePanNo}
                    placeholder='Pan.No'
                    errorText={panNoError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Old Balance'
                    value={oldBalance}
                    onChange={handleOldBalance}
                    placeholder='Old Balance'
                    errorText={oldBalanceError}
                    containerClass="mb-2"
                    type="number"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Old Balance Date'
                    value={oldBalanceDate}
                    onChange={handleOldBalanceDate}
                    placeholder='Old Balance Date'
                    errorText={oldBalanceDateError}
                    containerClass="mb-2"
                    type="date"
                />
            </div>
        </div>
        <div className="d-flex justify-content-end hstack gap-1">
            <button className="btn btn-primary" onClick={handleClear}>Clear</button>
            <button className="btn btn-success" onClick={handleSave}>Save</button>
        </div>

    </div>
}
export default SupplierForm