/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../reducers"

export enum TicketPricingActionList {
    ADD_TICKET_PRICING = 'ADD_TICKET_PRICING',
    FETCH_TICKET_PRICING = 'FETCH_TICKET_PRICING',
    UPDATE_TICKET_PRICING = 'UPDATE_TICKET_PRICING',
    UPDATE_TICKET_RATE = 'UPDATE_TICKET_RATE',
    DELETE_TICKET_PRICING = 'DELETE_TICKET_PRICING',
}

export interface TicketPricingType {
    id?: number
    fromStop: number
    from?: string
    toStop: number
    to?: string
    routeId: number
    routeName?: string
    ticketCode?: string
    ticketRate: string
    createdBy?: string
    createTimestamp?: string
}

export interface EditTicketRate {
    id: number
    ticketRate: string
}

export interface AddTicketPricingAction {
    type: TicketPricingActionList.ADD_TICKET_PRICING
    data: TicketPricingType
}

export interface FetchTicketPricingAction {
    type: TicketPricingActionList.FETCH_TICKET_PRICING
    data: Array<TicketPricingType>
}

export interface UpdateTicketPricingAction {
    type: TicketPricingActionList.UPDATE_TICKET_PRICING
    data: TicketPricingType
}

export interface UpdateTicketRateAction {
    type: TicketPricingActionList.UPDATE_TICKET_RATE
    data: Array<TicketPricingType>
}

export interface DeleteTicketPricingAction {
    type: TicketPricingActionList.DELETE_TICKET_PRICING
    data: number
}

export type TicketPricingActions = AddTicketPricingAction | FetchTicketPricingAction | UpdateTicketPricingAction | DeleteTicketPricingAction | UpdateTicketRateAction

export const addTicketPricing = (data: TicketPricingType) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Ticket Pricing Added")
        return api.post<APIResponse<TicketPricingType>>('ticket_pricing/', data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<AddTicketPricingAction>({
                    type: TicketPricingActionList.ADD_TICKET_PRICING,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Ticket Pricing Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const updateTicketPricing = (data: TicketPricingType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Ticket Pricing Updated")
        return api.put<APIResponse<TicketPricingType>>(`ticket_pricing/?id=${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<UpdateTicketPricingAction>({
                    type: TicketPricingActionList.UPDATE_TICKET_PRICING,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Ticket Pricing Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const updateTicketRate = (data: Array<TicketPricingType>) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.put<APIResponse<TicketPricingType[]>>(`ticket_pricing/`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<UpdateTicketRateAction>({
                    type: TicketPricingActionList.UPDATE_TICKET_RATE,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Ticket Rate Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchTicketPricing = () => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<TicketPricingType[]>>('ticket_pricing/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchTicketPricingAction>({
                    type: TicketPricingActionList.FETCH_TICKET_PRICING,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteTicketPricing = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Ticket Pricing Deleted")
        return api.delete<APIResponse<TicketPricingType>>('ticket_pricing/', config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteTicketPricingAction>({
                    type: TicketPricingActionList.DELETE_TICKET_PRICING,
                    data: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Ticket Pricing Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}

