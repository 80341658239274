import { MouseEventHandler, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PageTitle from '../components/custom/components/PageTitle'
import VerticalLayout from '../components/custom/layouts/Vertical'
import Modal from '../components/Modal'
import SupplierForm from '../components/SupplierForm'
import { ToastTypes, useToast } from '../components/toast/ToastProvider'
import { FormType, FormTypeList } from '../components/types'
import { addKey, isInToday, LocalFormatDate } from '../config/functions'
import { AuthUserType } from '../redux/actions/authUserActions'
import { deleteSupplier, SupplierType } from '../redux/actions/supplierActions'
import { Key } from '../redux/actions/types'
import { StoreState } from '../redux/reducers'
import { AppDispatch } from '../redux/store'

interface TableBody {

    // "id": 1,
    // "code": "sup1",
    // "name": "Mahindra",
    // "contactPerson": "Ash",
    // "mobileNumber": "8604858905",
    // "GSTNumber": "3485HGJ348937",
    // "aadharNumber": "6756 4088 7883"
    sno: number
    code: string
    name: string
    address: string
    contactPerson: string
    mobileNumber: string
    GSTNumber?: string
    aadharNumber?: string
    panNumber?: string
    oldBalance?: string
    oldBalanceDate?: string
    authUser: AuthUserType
    onEdit: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
    timeStamp: string
}

type HandleEdit = (value: SupplierType) => void
type HandleDelete = (value: SupplierType) => void

const Supplier = () => {
    const toast = useToast()

    const dispatch = useDispatch<AppDispatch>()

    const authUser = useSelector<StoreState, AuthUserType>(state => state.authUser)
    const supplier = useSelector<StoreState, Array<SupplierType & Key>>(state => addKey(state.supplier))

    const [showForm, setShowForm] = useState<boolean>(false)
    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    const [editData, setEditData] = useState<SupplierType>()

    const handleAdd = () => {
        setShowForm(true)
    }

    const handleEdit: HandleEdit = (value) => {
        setFormType(FormTypeList.UPDATE)
        setEditData(value)
        setShowForm(true)
    }

    const handleDelete: HandleDelete = (value) => {
        if (value.id !== undefined) {
            dispatch(deleteSupplier(value.id)).then(text => {
                toast(text, ToastTypes.SUCCESS)
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
        }
    }

    const handleFormClose = () => {
        setShowForm(false)
        setFormType(FormTypeList.ADD)
        setEditData(undefined)
    }

    return <>
        <Modal
            headerText={formType === FormTypeList.ADD ? 'Add Supplier' : 'Edit Supplier'}
            visible={showForm}
            onClose={handleFormClose}
            size='xl'
            centered
        >
            <SupplierForm
                formType={formType}
                editData={editData}
                onSave={handleFormClose}
            />

        </Modal>
        <VerticalLayout>
            <PageTitle
                title='Supplier'
                buttonText='ADD'
                onButtonClick={handleAdd}
            />
            <div className="card">
                <div className="card-body">
                    <div className='table-wrapper'>
                        <table className='table colored'>
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    <th className='text-truncate align-middle'>Code</th>
                                    <th className='text-truncate align-middle'>Name</th>
                                    <th className='text-truncate align-middle'>Address</th>
                                    <th className='text-truncate align-middle'>Contact Person</th>
                                    <th className='text-truncate align-middle'>Mobile</th>
                                    <th className='text-truncate align-middle'>GST.No</th>
                                    <th className='text-truncate align-middle'>Aadhar.No</th>
                                    <th className='text-truncate align-middle'>Pan.No</th>
                                    <th className='text-truncate align-middle'>Old Balance</th>
                                    <th className='text-truncate align-middle'>Old Balance Date</th>
                                    <th className='text-truncate align-middle text-center'>Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                {supplier.map((s, i) => {
                                    return <SupplierBody
                                        sno={i + 1}
                                        code={s.code !== undefined ? s.code : ''}
                                        name={s.name}
                                        address={s.address !== undefined ? s.address : ''}
                                        contactPerson={s.contactPerson}
                                        mobileNumber={s.mobileNumber}
                                        GSTNumber={s.gstNumber}
                                        aadharNumber={s.aadharNumber}
                                        panNumber={s.panNumber}
                                        oldBalance={s.oldBalance}
                                        oldBalanceDate={(s.oldBalanceDate !== undefined && s.oldBalanceDate !== '') ? LocalFormatDate(s.oldBalanceDate) : ''}
                                        key={s.key}
                                        authUser={authUser}
                                        timeStamp={s.createTimestamp !== undefined ? s.createTimestamp : ''}
                                        onEdit={() => handleEdit(s)}
                                        onDelete={() => handleDelete(s)}
                                    />
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </VerticalLayout>
    </>
}

const SupplierBody = ({ sno, code, aadharNumber, panNumber, name, address, contactPerson, mobileNumber, GSTNumber, oldBalance, oldBalanceDate, onEdit, onDelete, authUser,timeStamp }: TableBody) => {
    const ShowEditForManager = isInToday(timeStamp)
    

    return <tr>
        <td>{sno}</td>
        <td className='text-uppercase'>{code}</td>
        <td className='text-capitalize'>{name}</td>
        <td className='text-capitalize'>{address}</td>
        <td className='text-capitalize'>{contactPerson}</td>
        <td className='text-capitalize'>{mobileNumber}</td>
        <td className='text-capitalize text-truncate'>{GSTNumber}</td>
        <td className='text-capitalize text-truncate'>{aadharNumber}</td>
        <td className='text-capitalize text-truncate'>{panNumber}</td>
        <td className='text-capitalize text-truncate'>{oldBalance}</td>
        <td className='text-capitalize text-truncate'>{oldBalanceDate}</td>
        <td>
            <div className='hstack gap-1'>
            {(authUser.role === 'admin' || authUser.role === 'super' || authUser.role === 'Admin' || authUser.role === 'Super') && <button className='btn btn-xs btn-primary' onClick={onEdit}>Edit</button>}
                {(authUser.role === 'Manager' || authUser.role === 'manager') && ShowEditForManager && <button className='btn btn-xs btn-primary' onClick={onEdit}>Edit</button>}
                <button className='btn btn-xs btn-danger' onClick={onDelete}>Delete</button>
            </div>
        </td>
    </tr>
}

export default Supplier
