import { Action } from "../actions/types"
import { CollectionDetailsType, CollectionDetailsActionList } from "../actions/collectionDetailsActions"

const initialValue: Array<CollectionDetailsType> = []

const collectionDetailsReducer = (state: Array<CollectionDetailsType> = initialValue, action: Action) => {
    switch (action.type) {

        case CollectionDetailsActionList.ADD_COLLECTION_DETAILS:
            return [
                ...state,
                action.data,
            ]

        case CollectionDetailsActionList.UPDATE_COLLECTION_DETAILS:
            const updated = [...state].slice()
            const index = updated.findIndex(u => u.id === action.data.id)

            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }

            return updated

        case CollectionDetailsActionList.FETCH_COLLECTION_DETAILS:
            return action.data

        default:
            return state
    }
}

export default collectionDetailsReducer