import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { ChangeEventHandler, Fragment, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { FormInput } from '../components/custom/components'
import PageTitle from '../components/custom/components/PageTitle'
import VerticalLayout from '../components/custom/layouts/Vertical'
import { useToast } from '../components/toast/ToastProvider'
import api, { APIResponse, getAxiosRequestConfig } from '../config/api'
import { rupeeFormat, today } from '../config/functions'
import { AuthUserType } from '../redux/actions/authUserActions'
import { StoreState } from '../redux/reducers'

export interface CumulativeReportType {

    accountName: string
    openingBalance: string
    totalDebit: string
    totalCredit: string
}

interface TableBody {

    sno: number
    accountName: string
    openingBalance: string
    totalDebit: string
    totalCredit: string
    closingBalance: string
}


const CumulativeReport = () => {
    const toast = useToast()

    const authUser = useSelector<StoreState, AuthUserType>(state => state.authUser)
    const token = authUser.token!
    const config = getAxiosRequestConfig(token)

    const [fromDate, setFromDate] = useState<string>(today())
    const [toDate, setToDate] = useState<string>(today())
    const [data, setData] = useState<CumulativeReportType[]>([])


    const handleFromDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setFromDate(e.target.value)
    }

    const handleToDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setToDate(e.target.value)
    }

    const totalIncome = useMemo<number>(() => {
        return data.reduce((previous, current) => {
            return previous + parseFloat(current.totalDebit)
        }, 0)

    }, [data])

    const totalExpense = useMemo<number>(() => {
        return data.reduce((previous, current) => {
            return previous + parseFloat(current.totalCredit)
        }, 0)

    }, [data])

    const totalOpeningBalance = useMemo<number>(() => {
        return data.reduce((previous, current) => {
            return previous + parseFloat(current.openingBalance)
        }, 0)

    }, [data])

    const totalClosingBalance = useMemo<number>(() => {
        return totalOpeningBalance + totalIncome - totalExpense
    }, [totalOpeningBalance, totalIncome, totalExpense])


    const handleDownloadPDF = () => {
        const doc = new jsPDF('l', 'mm', 'a4')

        doc.addFont('Times-Bold', 'Times', 'bold')
        doc.setFont('Times')
        doc.setFontSize(18)
        doc.text('SUPREME  BUS  SERVICE', 100, 13)

        doc.setFontSize(14)
        doc.text('Total', 15, 28)

        const TotalHead = [['Total Opening Balance', 'Total Income', 'Total Expense', 'Total Closing Balance']]

        const TotalBody = [[String(totalOpeningBalance), String(totalIncome), String(totalExpense), String(totalClosingBalance)]]

        autoTable(doc, {
            head: TotalHead,
            body: TotalBody,
            startY: 30,
            didDrawPage: function () {

                // // Header
                var str = doc.getNumberOfPages();
                doc.setFontSize(10);
                // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                // var pageSize = doc.internal.pageSize;
                // var pageHeight = pageSize.height
                //     ? pageSize.height
                //     : pageSize.getHeight();
                doc.text(str.toString(), 260, 10);
            }
        })

        const head = [['S.No', 'Account Name', 'Opening Balance', 'Total Income', 'Total Expense', 'Closing Balance']]

        // const foot = [['Total', '', String(totalOpeningBalance), String(totalIncome), String(totalExpense), String(totalClosingBalance)]]

        const body = data.map((d, i) => {
            let closingBalance = Number(d.openingBalance) + Number(d.totalDebit) - Number(d.totalCredit)
            return [
                i + 1,
                String(d.accountName),
                String(d.openingBalance),
                String(d.totalDebit),
                String(d.totalCredit),
                String(closingBalance)

            ]
        })

        autoTable(doc, {
            head: head,
            body: body,
            // foot: foot,
            startY: (doc as any).lastAutoTable.finalY + 10,
            didDrawPage: function () {

                // // Header
                var str = doc.getNumberOfPages();
                doc.setFontSize(10);
                // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                // var pageSize = doc.internal.pageSize;
                // var pageHeight = pageSize.height
                //     ? pageSize.height
                //     : pageSize.getHeight();
                doc.text(str.toString(), 260, 10);
            }
        })
        // doc.setFontSize(14)
        // doc.text('Total', 15, (doc as any).lastAutoTable.finalY + 10)
        // doc.text(String(totalOpeningBalance), 98, (doc as any).lastAutoTable.finalY + 10)
        // doc.text(String(totalIncome), 150, (doc as any).lastAutoTable.finalY + 10)
        // doc.text(String(totalExpense), 190, (doc as any).lastAutoTable.finalY + 10)
        // doc.text(String(totalClosingBalance), 240, (doc as any).lastAutoTable.finalY + 10)

        doc.save("Cumulative Report.pdf")
    }

    useEffect(() => {
        if (fromDate !== undefined && toDate !== undefined) {
            api.get<APIResponse<CumulativeReportType[]>>(`transaction/cumulative_report/?from_date=${fromDate}&to_date=${toDate}`, config).then(response => {

                if (response.status === 200 && response.data.data) {
                    const result = response.data.data
                    // console.log(result);
                    setData(result)

                } else {
                    // eslint-disable-next-line no-throw-literal
                    throw { response }
                }

            }).catch(error => {

            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fromDate, toDate])

    return <Fragment>

        <VerticalLayout>
            <PageTitle
                title='Cumulative Report'
            />

            <div className='card'>
                <div className='card-body'>
                    <div className='container-fluid'>
                        <div className="row">
                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <FormInput
                                    label='From Date'
                                    value={fromDate}
                                    onChange={handleFromDate}
                                    placeholder="From Date"
                                    containerClass="mb-2"
                                    type="date"
                                />
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <FormInput
                                    label='To Date'
                                    value={toDate}
                                    onChange={handleToDate}
                                    placeholder="To Date"
                                    containerClass="mb-2"
                                    type="date"
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {data.length > 0 && <div className='card'>
                <div className='card-body'>
                    <div className='container-fluid'>
                        <div className="row">
                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <h4>Opening Balance: {rupeeFormat(totalOpeningBalance)}</h4>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <h4>Income: {rupeeFormat(totalIncome)}</h4>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <h4>Expense: {rupeeFormat(totalExpense)}</h4>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <h4>Closing Balance: {rupeeFormat(totalClosingBalance)}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}

            {data.length > 0 ? <div className="card">
                <div className="card-body">
                    <div className='table-wrapper'>
                        <table className='table colored'>
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    <th className='text-truncate align-middle'>Account Name</th>
                                    <th className='text-truncate align-middle'>Opening Balance</th>
                                    <th className='text-truncate align-middle'>Total Debit</th>
                                    <th className='text-truncate align-middle'>Total Credit</th>
                                    <th className='text-truncate align-middle'>Closing Balance</th>

                                </tr>
                            </thead>
                            <tbody>
                                {data !== undefined && data.map((d, i) => {
                                    let closingBalance = Number(d.openingBalance) +
                                        Number(d.totalDebit) - Number(d.totalCredit)
                                    return <CumulativeBody
                                        sno={i + 1}
                                        accountName={d.accountName}
                                        openingBalance={rupeeFormat(d.openingBalance)}
                                        totalDebit={rupeeFormat(d.totalDebit)}
                                        totalCredit={rupeeFormat(d.totalCredit)}
                                        closingBalance={rupeeFormat(closingBalance)}
                                        key={i}
                                    />
                                })}
                            </tbody>
                            <tfoot>
                                <tr>

                                    <td colSpan={2} style={{ textAlign: 'start' }}>
                                        <span className="text-dark fw-bold fs-5">Total</span>
                                    </td>
                                    <td style={{ textAlign: 'start' }}>
                                        <span className="text-dark fw-bold fs-5">{rupeeFormat(totalOpeningBalance)}</span>
                                    </td>
                                    <td style={{ textAlign: 'start' }}>
                                        <span className="text-dark fw-bold fs-5">{rupeeFormat(totalIncome)}</span>
                                    </td>
                                    <td style={{ textAlign: 'start' }}>
                                        <span className="text-dark fw-bold fs-5">{rupeeFormat(totalExpense)}</span>
                                    </td>
                                    <td style={{ textAlign: 'start' }}>
                                        <span className="text-dark fw-bold fs-5">{rupeeFormat(totalClosingBalance)}</span>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <div className='my-2 text-end'>
                        <button className='btn btn-primary' onClick={handleDownloadPDF}>Download PDF
                            <i className='fe-download'></i>
                        </button>
                    </div>
                </div>
            </div> :
                <div className='text-center'>
                    <label >No records found</label>
                </div>}
        </VerticalLayout>
    </Fragment>
}

const CumulativeBody = ({ sno, accountName, totalDebit, totalCredit, closingBalance, openingBalance }: TableBody) => {

    return <tr>
        <td>{sno}</td>
        <td className='text-truncate'>{accountName}</td>
        <td className='text-truncate' >{openingBalance}</td>
        <td className='text-truncate' >{totalDebit}</td>
        <td className='text-truncate' >{totalCredit}</td>
        <td className='text-truncate' >{closingBalance}</td>

    </tr>

}

export default CumulativeReport
