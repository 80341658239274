import { Action } from "../actions/types"
import { RoleType, RoleActionList } from "../actions/roleActions"

const initialValue: Array<RoleType> = []

const roleReducer = (state: Array<RoleType> = initialValue, action: Action) => {
    switch (action.type) {

        case RoleActionList.ADD_ROLE:
            return [
                ...state,
                action.data,
            ]

        case RoleActionList.UPDATE_ROLE:
            const updated = [...state].slice()
            const index = updated.findIndex(u => u.id === action.data.id)

            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }

            return updated

        case RoleActionList.FETCH_ROLE:
            return action.data

        case RoleActionList.DELETE_ROLE:
            return state.filter(s => s.id !== action.data)

        default:
            return state
    }
}

export default roleReducer