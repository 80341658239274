/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../reducers"

export enum UserActionList {
    ADD_USER = 'ADD_USER',
    FETCH_USER = 'FETCH_USER',
    UPDATE_USER = 'UPDATE_USER',
    DELETE_USER = 'DELETE_USER',
}

export enum UserStatusList {
    ACTIVE = 'Active',
    INACTIVE = 'Inactive',
}

export enum UserRoleList {
    SUPER = 'super',
    ADMIN = 'admin',
    MANAGER = 'manager',
}

export type UserRole = UserRoleList.SUPER | UserRoleList.ADMIN | UserRoleList.MANAGER

export type UserStatus = UserStatusList.ACTIVE | UserStatusList.INACTIVE

export interface UserType {

    userId?: string
    password?: string
    name: string
    designation: string
    role: string // to be changed after api implemented
    status: UserStatus // to be changed after api implemented
    emailId?: string
    mobileNumber?: string
    createTimestamp?: string
}

export interface AddUserAction {
    type: UserActionList.ADD_USER
    data: UserType
}

export interface FetchUserAction {
    type: UserActionList.FETCH_USER
    data: Array<UserType>
}

export interface UpdateUserAction {
    type: UserActionList.UPDATE_USER
    data: UserType
}

export interface DeleteUserAction {
    type: UserActionList.DELETE_USER
    data: string
}

export type UserActions = AddUserAction | FetchUserAction | UpdateUserAction | DeleteUserAction

export const addUser = (data: UserType) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("User Added")
        return api.post<APIResponse<UserType>>('user/', data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<AddUserAction>({
                    type: UserActionList.ADD_USER,
                    data: response.data.data

                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'User Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const updateUser = (data: UserType, userId: string) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("User Updated")
        return api.put<APIResponse<UserType>>(`user/?user_id=${userId}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<UpdateUserAction>({
                    type: UserActionList.UPDATE_USER,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'User Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchUser = () => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<UserType[]>>('user/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchUserAction>({
                    type: UserActionList.FETCH_USER,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteUser = (userId: string) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)
        // return Promise.resolve("User Deleted")

        return api.delete<APIResponse<UserType>>(`user/?user_id=${userId}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteUserAction>({
                    type: UserActionList.DELETE_USER,
                    data: userId
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'User Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}

