import { ChangeEventHandler, createContext, MouseEventHandler, useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormInput } from '../components/custom/components'
import PageTitle from '../components/custom/components/PageTitle'
import VerticalLayout from '../components/custom/layouts/Vertical'
import Option from '../components/Option'
import { useToast } from '../components/toast/ToastProvider'
import { FormType, FormTypeList } from '../components/types'
import { addKey } from '../config/functions'
import { RouteMasterType } from '../redux/actions/routeMasterActions'
import { TicketPricingType, updateTicketRate } from '../redux/actions/ticketPricingActions'
import { Key } from '../redux/actions/types'
import { StoreState } from '../redux/reducers'
import { AppDispatch } from '../redux/store'
// import TicketPricingForm from '../components/TicketPricingForm'
import Modal from '../components/Modal'
import { StopMasterType } from '../redux/actions/stopMasterActions'

interface TableBody {
    sno: number
    from?: string
    to?: string
    routeId?: number
    routeName?: string
    ticketCode?: string
    ticketRate: string
    // onEdit: MouseEventHandler<HTMLButtonElement>
    // onDelete: MouseEventHandler<HTMLButtonElement>
}

interface TicketPricingTableProps {
    setShowForm: React.Dispatch<React.SetStateAction<boolean>>
    setFormType: React.Dispatch<React.SetStateAction<FormType>>
    setEditData: React.Dispatch<React.SetStateAction<TicketPricingType | undefined>>
}

// type HandleEdit = (value: TicketPricingType) => void
// type HandleDelete = (value: TicketPricingType) => void

interface TicketPricingContextType {
    stopMaster: Array<StopMasterType & Key>
    ticketPricing: Array<TicketPricingType & Key>
    hideEdit: boolean
    setHideEdit: React.Dispatch<React.SetStateAction<boolean>>
    setChangedTicketPricing: React.Dispatch<React.SetStateAction<Array<TicketPricingType>>>
    routeId: string
}

const TicketPricingContext = createContext<TicketPricingContextType | null>(null)

const TicketPricing = () => {
    const toast = useToast()

    const dispatch = useDispatch<AppDispatch>()

    const ticketPricing = useSelector<StoreState, Array<TicketPricingType & Key>>(state => addKey(state.ticketPricing))
    const stopMasterList = useSelector<StoreState, Array<StopMasterType & Key>>(state => addKey(state.stopMaster))
    const routeMaster = useSelector<StoreState, Array<RouteMasterType & Key>>(state => addKey(state.routeMaster))

    const [hideEdit, setHideEdit] = useState<boolean>(true)
    const [changedTicketPricing, setChangedTicketPricing] = useState<Array<TicketPricingType>>([])
    const [routeId, setRouteId] = useState<string>('')

    const [showForm, setShowForm] = useState<boolean>(false)
    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    const [editData, setEditData] = useState<TicketPricingType>()

    const stopMaster = useMemo<Array<StopMasterType & Key>>(() => {
        return stopMasterList.filter(sm => {
            return sm.routeId === parseInt(routeId)
        })

    }, [stopMasterList, routeId])

    const handleCancel: MouseEventHandler<HTMLButtonElement> = (e) => {
        setHideEdit(true)
        setChangedTicketPricing([])
    }

    const handleSave: MouseEventHandler<HTMLButtonElement> = (e) => {
        dispatch(updateTicketRate(changedTicketPricing)).then(text => {
            setChangedTicketPricing([])
            setHideEdit(true)
            toast(text)
        })
    }

    const handleRouteId: ChangeEventHandler<HTMLInputElement> = (e) => {
        setRouteId(e.target.value)

    }

    // const handleAdd = () => {
    //     setShowForm(true)
    // }

    const handleFormClose = () => {
        setShowForm(false)
        setFormType(FormTypeList.ADD)
        setEditData(undefined)
    }

    return <TicketPricingContext.Provider value={{ stopMaster, ticketPricing, hideEdit, setHideEdit, setChangedTicketPricing, routeId }}>
        <Modal
            headerText={formType === FormTypeList.ADD ? 'Add Ticket Pricing' : 'Edit Ticket Pricing'}
            visible={showForm}
            onClose={handleFormClose}
            size='lg'
            centered
        >
            {/* <TicketPricingForm
                formType={formType}
                editData={editData}
                onSave={handleFormClose}
            /> */}

        </Modal>

        <VerticalLayout>
            <PageTitle
                title='Ticket Pricing'
            // buttonText='ADD'
            // onButtonClick={handleAdd}
            />

            <div className='card'>
                <div className='card-body'>
                    <div className='container-fluid'>
                        <div className="row">
                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <FormInput
                                    value={routeId}
                                    onChange={handleRouteId}
                                    type='select'
                                    label='Route'
                                >
                                    <option value="">All</option>
                                    {routeMaster.filter(rm => {
                                        return rm.code !== "HIRE"
                                    }).map(rm => {
                                        return <Option value={rm.id} key={rm.key}>{rm.name}</Option>
                                    })}
                                </FormInput>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {routeId !== '' && !hideEdit && <div className='my-2 text-end'>
                <button className='btn btn-primary me-2' onClick={handleCancel} >Cancel</button>
                <button className='btn btn-success me-2' onClick={handleSave}>Save</button>
            </div>}

            <div className="card">
                <div className="card-body">
                    <div className='table-wrapper'>
                        {routeId === ''
                            ?
                            <TicketPricingTable
                                setEditData={setEditData}
                                setFormType={setFormType}
                                setShowForm={setShowForm}
                            />
                            :
                            <TicketPricingByRoute />
                        }
                    </div>
                </div>
            </div>
        </VerticalLayout>

    </TicketPricingContext.Provider>
}
const TicketPricingTable = ({ setFormType, setEditData, setShowForm }: TicketPricingTableProps) => {
    // const toast = useToast()
    // const dispatch = useDispatch<AppDispatch>()
    const ticketPricing = useContext<TicketPricingContextType | null>(TicketPricingContext)!.ticketPricing


    // const handleEdit: HandleEdit = (value) => {
    //     setFormType(FormTypeList.UPDATE)
    //     setEditData(value)
    //     setShowForm(true)
    // }

    // const handleDelete: HandleDelete = (value) => {
    //     if (value.id !== undefined) {
    //         dispatch(deleteTicketPricing(value.id)).then(text => {
    //             toast(text)
    //         }).catch(text => {
    //             toast(text, ToastTypes.ERROR)
    //         })
    //     }
    // }
    // const ticketPricing = useMemo<Array<TicketPricingType & Key>>(() => {
    //     return routeId !== "" ? ticketPricingList.filter(tp => tp.id === parseInt(routeId)) : ticketPricingList
    // }, [routeId, ticketPricingList])

    return <table className='table colored'>
        <thead>
            <tr>
                <th className='text-truncate align-middle'>S.No</th>
                <th className='text-truncate align-middle'>Route Name</th>
                <th className='text-truncate align-middle'>From</th>
                <th className='text-truncate align-middle'>To</th>
                <th className='text-truncate align-middle'>Ticket Code</th>
                <th className='text-truncate align-middle'>Ticket Rate</th>
                {/* <th className='text-truncate align-middle'>Action</th> */}
            </tr>
        </thead>
        <tbody>
            {ticketPricing.map((tp, i) => {
                return <TicketPricingBody
                    sno={i + 1}
                    routeName={tp.routeName}
                    from={tp.from}
                    to={tp.to}
                    ticketCode={tp.ticketCode}
                    ticketRate={tp.ticketRate}
                    key={tp.key}
                // onEdit={() => handleEdit(tp)}
                // onDelete={() => handleDelete(tp)}
                />
            })}
        </tbody>
    </table>
}

const TicketPricingBody = ({ sno, from, to, ticketRate, ticketCode, routeName }: TableBody) => {
    return <tr>
        <td>{sno}</td>
        <td>{routeName}</td>
        <td className='text-capitalize'>{from}</td>
        <td className='text-capitalize'>{to}</td>
        <td>{ticketCode}</td>
        <td>{ticketRate}</td>
        {/* <td className='hstack gap-1'>
            <button className='btn btn-primary' onClick={onEdit}>Edit</button>
            <button className='btn btn-danger' onClick={onDelete}>Delete</button>
        </td> */}
    </tr>
}

const TicketPricingByRoute = () => {
    const stopMaster = useContext<TicketPricingContextType | null>(TicketPricingContext)!.stopMaster

    return <table className='table border table-bordered'>
        <tbody>
            {[undefined, ...stopMaster].map((from, index) => {
                return <TicketPricingByRouteRow
                    fromId={from && from.id !== undefined ? from.id : 0}
                    fromIndex={index}
                    key={index}

                />
            })}
        </tbody>
    </table>
}

interface TicketPricingByRouteRowProps {
    fromId: number
    fromIndex: number
}

const TicketPricingByRouteRow = ({ fromIndex, fromId }: TicketPricingByRouteRowProps) => {
    const { stopMaster, ticketPricing } = useContext<TicketPricingContextType | null>(TicketPricingContext)!

    return <tr>
        <th className='text-capitalize text-truncate' style={{ width: "100px" }}>{fromIndex > 0 && stopMaster[fromIndex - 1].name}</th>
        {stopMaster.map((to, toIndex) => {
            const i = ticketPricing.findIndex(tp => {
                return to.id === tp.toStop && fromId === tp.fromStop
            })
            return <TicketPricingByRouteData
                id={i > -1 ? ticketPricing[i].id : undefined}
                text={to.name}
                key={toIndex}
                price={i > -1 ? ticketPricing[i].ticketRate : fromId === to.id ? '' : "0"}
                isHeading={fromIndex === 0}
                fromId={fromId}
                toId={to.id ? to.id : 0}
            />
        })}
    </tr>
}
interface TicketPricingByRouteDataProps {
    id?: number
    text: string
    price: string
    isHeading: boolean
    fromId: number
    toId: number
}

const TicketPricingByRouteData = ({ text, isHeading, price, id, fromId, toId }: TicketPricingByRouteDataProps) => {
    const { hideEdit, setHideEdit, setChangedTicketPricing, routeId } = useContext<TicketPricingContextType | null>(TicketPricingContext)!

    const [ticketPrice, setTicketPrice] = useState<string>('')
    const [showEdit, setShowEdit] = useState<boolean>(false)

    const handleTicketPriceClick = () => {
        if (fromId !== toId) {
            setShowEdit(true)
            setHideEdit(false)
        }
    }

    const handleTicketPriceChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        setTicketPrice(e.target.value)
        setChangedTicketPricing(prev => {
            const updated = [...prev]
            const index = prev.findIndex(p => {
                return p.fromStop === fromId && p.toStop === toId
            })
            if (index > -1) {
                updated[index].ticketRate = e.target.value
                return updated
            }
            return [
                ...prev, {
                    ticketRate: e.target.value,
                    fromStop: fromId,
                    toStop: toId,
                    routeId: parseInt(routeId),
                }
            ]
        })
    }

    useEffect(() => {
        if (hideEdit) {
            setShowEdit(false)
            setTicketPrice(price)
        }
    }, [hideEdit])

    useEffect(() => {
        setTicketPrice(price || '')
    }, [price])

    return isHeading ? <th className='text-capitalize align-middle text-truncate' style={{ width: "100px" }}>{text}</th> : <td className={`align-middle`} onClick={handleTicketPriceClick}>{showEdit && fromId !== toId ? <input className='form-control' type="number" style={{ width: '100px' }} value={ticketPrice} onChange={handleTicketPriceChange} /> : price}</td>
}

export default TicketPricing
