import { ChangeEventHandler, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import FormInput from '../components/custom/components/FormInput'
import { addKey } from "../config/functions"
import { RouteMasterType } from "../redux/actions/routeMasterActions"
import { SupplierType } from "../redux/actions/supplierActions"
import { Key } from "../redux/actions/types"
import { addVehicleService, fetchVehicleService, PaymentModesForVs, updateVehicleService, VehicleServicePaymentModes, VehicleServiceType } from "../redux/actions/vehicleServiceActions"
import { StoreState } from "../redux/reducers"
import { AppDispatch } from "../redux/store"
import Option from "./Option"
import { ToastTypes, useToast } from "./toast/ToastProvider"
import { FormType, FormTypeList } from "./types"
// import { enumToArrayOfObj } from '../config/functions'

interface Props {
    formType: FormType
    editData?: VehicleServiceType
    onSave: () => void

}

// const VEHICLE_SERVICE_STATUS = addKey([
//     {
//         text: 'Pending',
//         value: 'Pending'
//     },
//     {
//         text: 'Settled',
//         value: 'Settled'
//     }
// ])


const VehicleServiceForm = ({ formType, editData, onSave }: Props) => {
    const routeMaster = useSelector<StoreState, Array<RouteMasterType & Key>>(state => addKey(state.routeMaster))
    // const vehicleMaster = useSelector<StoreState, Array<VehicleMasterType & Key>>(state => addKey(state.vehicleMaster))
    const supplier = useSelector<StoreState, Array<SupplierType & Key>>(state => addKey(state.supplier))

    const toast = useToast()

    const dispatch = useDispatch<AppDispatch>()

    const [date, setDate] = useState<string>('')
    const [dateError, setDateError] = useState<string>('')
    const [routeId, setRouteId] = useState<string>('')
    const [routeIdError, setRouteIdError] = useState<string>('')
    const [routeName, setRouteName] = useState<string>('')
    const [supplierId, setSupplierId] = useState<string>('')
    const [supplierIdError, setSupplierIdError] = useState<string>('')
    const [supplierName, setSupplierName] = useState<string>('')
    const [billNumber, setBillNumber] = useState<string>('')
    const [billNumberError, setBillNumberError] = useState<string>('')
    const [details, setDetails] = useState<string>('')
    const [detailsError, setDetailsError] = useState<string>('')
    const [amount, setAmount] = useState<string>('')
    const [amountError, setAmountError] = useState<string>('')
    // const [status, setStatus] = useState<VehicleServiceStatus>(VehicleServiceStatusList.PENDING)
    // const [statusError, setStatusError] = useState<string>('')
    // const [settledDate, setSettledDate] = useState<string>('')
    // const [settledDateError, setSettledDateError] = useState<string>('')
    const [paymentMode, setPaymentMode] = useState<VehicleServicePaymentModes>(PaymentModesForVs.CREDIT)
    const [paymentModeError, setPaymentModeError] = useState<string>('')

    // const vehicleServiceStatusList = useMemo<(OptionType & Key)[]>(() => {
    //     return addKey(enumToArrayOfObj(VehicleServiceStatusList))
    // }, [])

    const resetStates = () => {
        setDate('')
        setDateError('')
        setRouteId('')
        setRouteIdError('')
        setSupplierId('')
        setSupplierIdError('')
        setBillNumber('')
        setBillNumberError('')
        setDetails('')
        setDetailsError('')
        setAmount('')
        setAmountError('')
        // setStatus(VehicleServiceStatusList.PENDING)
        // setStatusError('')
        // setSettledDate('')
        // setSettledDateError('')
        setPaymentMode(PaymentModesForVs.CREDIT)
        setPaymentModeError('')
    }

    const handleDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDate(e.target.value)
        setDateError('')
    }

    const handleBillNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBillNumber(e.target.value)
        setBillNumberError('')
    }

    const handleDetails: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDetails(e.target.value)
        setDetailsError('')
    }

    const handleAmount: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAmount(e.target.value)
        setAmountError('')
    }

    // const handleSettledDate: ChangeEventHandler<HTMLInputElement> = (e) => {
    //     setSettledDate(e.target.value)
    //     setSettledDateError('')
    // }

    // const handleStatus: ChangeEventHandler<HTMLInputElement> = (e) => {
    //     setStatus(e.target.value as VehicleServiceStatus)
    //     if (e.target.value === VehicleServiceStatusList.PENDING) {
    //         setSettledDate('')
    //         setSettledDateError('')
    //     }
    //     setStatusError('')
    // }

    const handleRouteId: ChangeEventHandler<HTMLInputElement> = (e) => {
        setRouteId(e.target.value)
        const index = routeMaster.findIndex(rm => rm.id !== undefined && rm.id.toString() === e.target.value)

        if (index > -1) {
            setRouteName(routeMaster[index].name)
        }

        setRouteIdError('')
    }

    const handleSupplierId: ChangeEventHandler<HTMLInputElement> = (e) => {
        setSupplierId(e.target.value)
        const index = supplier.findIndex(vm => vm.id !== undefined && vm.id.toString() === e.target.value)

        if (index > -1) {
            setSupplierName(supplier[index].name)
        }

        setSupplierIdError('')
    }

    const handleCash: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPaymentMode(PaymentModesForVs.CASH)
        setPaymentModeError('')
    }

    const handleCredit: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPaymentMode(PaymentModesForVs.CREDIT)
        setPaymentModeError('')
    }

    const handleClear = () => {
        resetStates()
    }

    const handleSave = () => {
        let error = false
        const data: VehicleServiceType = {
            date: date,
            routeId: parseInt(routeId),
            routeName: routeName,
            supplierId: parseInt(supplierId),
            supplierName: supplierName,
            billNumber: billNumber,
            details: details,
            amount: amount,
            // status: status,
            // settledDate: settledDate,
            paymentMode: paymentMode
        }
        if (data.date === "") {
            setDateError('Date required')
            error = true
        } else if ((data.date).length > 10) {
            setDateError('Invalid Date')
            error = true
        }

        if (isNaN(data.routeId)) {
            setRouteIdError(' Route required')
            error = true
        } else if (data.routeId <= 0) {
            setRouteIdError(' Route must be a valid one')
            error = true
        }

        if (isNaN(data.supplierId)) {
            setSupplierIdError(' Supplier required')
            error = true
        } else if (data.supplierId <= 0) {
            setSupplierIdError(' Supplier must be a valid one')
            error = true
        }

        if (data.amount === "") {
            setAmountError('Amount required')
            error = true
        } else if (parseFloat(data.amount) < 0) {
            setAmountError('Invalid Amount')
            error = true
        }

        if (data.billNumber === "") {
            setBillNumberError('Bill No required')
            error = true
        } else if ((data.billNumber).length > 10) {
            setBillNumberError('Bill number must be within 10 characters')
            error = true
        }

        // if (data.details !== "" && data.details !== undefined) {
        //     if ((data.details).length > 100) {
        //         setDetailsError('Details must be within 100 characters')
        //         error = true
        //     }
        // }

        if (data.details === "") {
            setDetailsError('Details required')
            error = true
        } else if (data.details !== undefined && (data.details).length > 100) {
            setDetailsError('Details must be within 100 characters')
            error = true
        }


        if ([PaymentModesForVs.CASH, PaymentModesForVs.CREDIT].indexOf(data.paymentMode as VehicleServicePaymentModes) === -1) {
            setPaymentModeError('Payment Mode must be Credit/Cash.')
            error = true
        }


        // if (data.status === "") {
        //     setStatusError('Status required')
        //     error = true
        // } else
        // if ([VehicleServiceStatusList.SETTLED, VehicleServiceStatusList.PENDING].indexOf(data.status as VehicleServiceStatus) === -1) {
        //     setStatusError('Status must be SETTLED/PENDING')
        //     error = true
        // }

        // if (data.status === VehicleServiceStatusList.SETTLED && data.settledDate === "") {
        //     setSettledDateError('Settled Date required')
        //     error = true
        // } else if ((data.date).length > 10) {
        //     setDateError('Invalid Date')
        //     error = true
        // }

        // } else if (data.status === VehicleServiceStatusList.PENDING) {
        //     // setSettledDate('')
        //     data.settledDate = ''
        // }
        if (!error) {
            if (formType === FormTypeList.ADD) {

                dispatch(addVehicleService(data)).then(text => {
                    toast(text)
                    onSave()
                    dispatch(fetchVehicleService())
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            } else if (formType === FormTypeList.UPDATE && editData !== undefined && editData.id !== undefined) {
                dispatch(updateVehicleService(data, editData.id)).then(text => {
                    toast(text)
                    onSave()
                    dispatch(fetchVehicleService())
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
        }
    }

    useEffect(() => {
        if (formType === FormTypeList.UPDATE && editData !== undefined) {

            setDate(editData.date)
            setRouteId(editData.routeId.toString())
            setRouteName(editData.routeName)
            setSupplierId(editData.supplierId.toString())
            setSupplierName(editData.supplierName)
            setPaymentMode(editData.paymentMode)
            setBillNumber(editData.billNumber)
            setDetails(editData.details !== undefined ? editData.details : '')
            setAmount(editData.amount)
            // setStatus(editData.status)
            // setSettledDate(editData.settledDate !== undefined && editData.settledDate !== null ? editData.settledDate : '')

        } else {
            resetStates()
        }
    }, [formType, editData])

    return <div className='container'>
        <div className='row'>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Date'
                    labelClassName="required"
                    value={date}
                    onChange={handleDate}
                    placeholder='Date'
                    errorText={dateError}
                    containerClass="mb-2"
                    type="date"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Route'
                    labelClassName="required"
                    type='select'
                    value={routeId}
                    onChange={handleRouteId}
                    errorText={routeIdError}
                    containerClass="mb-2"
                >
                    <Option value=''>Select</Option>
                    {routeMaster.filter(rm => {
                        return rm.code !== "HIRE"
                    }).map(rm => {
                        return <Option value={rm.id} key={rm.key}>{rm.name}</Option>
                    })}
                </FormInput>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Supplier'
                    labelClassName="required"
                    type='select'
                    value={supplierId}
                    onChange={handleSupplierId}
                    errorText={supplierIdError}
                    containerClass="mb-2"
                >
                    <Option value=''>Select</Option>
                    {supplier.map(s => {
                        return <Option value={s.id} key={s.key}>{s.name}</Option>
                    })}
                </FormInput>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Bill.No'
                    labelClassName="required"
                    value={billNumber}
                    onChange={handleBillNumber}
                    placeholder='Bill No'
                    errorText={billNumberError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-6 col-lg-12 my-2'>
                <FormInput
                    label='Details'
                    labelClassName="required"
                    value={details}
                    onChange={handleDetails}
                    placeholder='Details'
                    errorText={detailsError}
                    containerClass="mb-2"
                    type="textarea"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3 my-2'>
                <label htmlFor="paymentMode" className="required">Payment Mode</label>
                <div className="hstack gap-1">
                    <FormInput
                        label='Cash'
                        onChange={handleCash}
                        errorText={paymentModeError}
                        containerClass="my-2"
                        type="radio"
                        name="paymentMode"
                        checked={paymentMode === PaymentModesForVs.CASH}
                    />

                    <FormInput
                        label='Credit'
                        onChange={handleCredit}
                        errorText={paymentModeError}
                        containerClass="my-2"
                        type="radio"
                        name="paymentMode"
                        checked={paymentMode === PaymentModesForVs.CREDIT}
                    />
                </div>
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Bill Amount'
                    labelClassName="required"
                    value={amount}
                    onChange={handleAmount}
                    placeholder='Amount'
                    errorText={amountError}
                    containerClass="my-2"
                />
            </div>

            {/* <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Status'
                    labelClassName="required"
                    type='select'
                    value={status}
                    onChange={handleStatus}
                    errorText={statusError}
                    containerClass="mb-2"
                >
                    <Option value=''>Select</Option>
                    {vehicleServiceStatusList.map(ct => {
                        return <Option value={ct.value} key={ct.key}>{ct.text}</Option>
                    })}
                </FormInput>
            </div>

            {status === VehicleServiceStatusList.SETTLED && <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='SettledDate'
                    labelClassName="required"
                    value={settledDate}
                    onChange={handleSettledDate}
                    placeholder='SettledDate'
                    errorText={settledDateError}
                    containerClass="mb-2"
                    type="date"
                />
            </div>} */}

        </div>
        <div className="d-flex justify-content-end hstack gap-1">
            <button className="btn btn-primary" onClick={handleClear}>Clear</button>
            <button className="btn btn-success" onClick={handleSave}>Save</button>
        </div>

    </div>
}

export default VehicleServiceForm