/* eslint-disable no-throw-literal */
import jsPDF from "jspdf"
import autoTable from 'jspdf-autotable'
import { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import api, { APIResponse, getAxiosRequestConfig } from "../config/api"
import { addKey, currencyFormat, rupeeFormat } from "../config/functions"
import { AuthUserType } from "../redux/actions/authUserActions"
import { CollectionType } from "../redux/actions/collectionActions"
import { CollectionTypeList } from "../redux/actions/collectionDetailsActions"
import { TripMasterType } from "../redux/actions/tripMasterActions"
import { Key } from "../redux/actions/types"
import { VehicleMasterType } from "../redux/actions/vehicleMasterActions"
import { StoreState } from "../redux/reducers"
import { CollectionSaveDataType } from "./CollectionForm"
import { ToastTypes, useToast } from "./toast/ToastProvider"

interface Props {
    editData?: CollectionType
}
// id?: number
// routeId: number
// vehicleId: number
// date: string
// //c
// invoiceNumber: string
// shortage: number
// excess: number
// balance: number
// //vr
// startingKm: number
// endingKm: number
// diesel: number
// driver: number
// cleaner: number
// conductor: number
// spareVehicleId: number | null
// spareStartingKM: number | null
// spareEndingKM: number | null
// spareDiesel: number | null
// //cd
// collectionDetails: Array<CollectionDetailsType>
// //trip det
// tripDetails: Array<TripDetailsType>
// //tic det
// ticketDetails: Array<TicketDetailsType>


const CollectionViewDetailsForm = ({ editData }: Props) => {

    const authUser = useSelector<StoreState, AuthUserType>(state => state.authUser)

    const token = authUser.token!
    const config = getAxiosRequestConfig(token)
    const TripMaster = useSelector<StoreState, Array<TripMasterType & Key>>(state => addKey(state.tripMaster))
    const VehicleMaster = useSelector<StoreState, Array<VehicleMasterType & Key>>(state => addKey(state.vehicleMaster))

    const toast = useToast()

    const [data, setData] = useState<CollectionSaveDataType>()
    const [spareVehicleName, setSpareVehicleName] = useState<string>('')
    const [vehicleName, setVehicleName] = useState<string>('')

    // const ticketIncome1 = useMemo<number | undefined>(() => {
    //     if (data?.ticketDetails !== undefined) {
    //         return data?.ticketDetails.reduce((previous, current) => {
    //             return previous + parseFloat(current.)
    //         }, 0)

    //     }
    // }, [data])

    // const ticketIncome2 = useMemo<number | undefined>(() => {
    //     if (data?.ticketDetails2 !== undefined) {
    //         return data?.ticketDetails2.reduce((previous, current) => {
    //             return previous + parseFloat(current.amount)
    //         }, 0)

    //     }
    // }, [data])

    const income1 = useMemo<number | undefined>(() => {
        if (data?.tripDetails !== undefined) {
            return data?.tripDetails.reduce((previous, current) => {
                return previous + parseFloat(current.amount)
            }, 0)

        }
    }, [data])

    const income2 = useMemo<number | undefined>(() => {
        if (data?.tripDetails2 !== undefined) {
            return data?.tripDetails2.reduce((previous, current) => {
                return previous + parseFloat(current.amount)
            }, 0)

        }
    }, [data])

    const TotalIncome = useMemo<number>(() => {

        return (income1 !== undefined ? income1 : 0) + (income2 !== undefined ? income2 : 0)

    }, [income1, income2])

    const expense1 = useMemo<number | undefined>(() => {
        if (data?.collectionDetails !== undefined) {
            return data?.collectionDetails.filter(f => {
                return f.type === CollectionTypeList.EXPENSE
            }).reduce((previous, current) => {
                return previous + parseFloat(current.amount)
            }, 0)

        }
    }, [data])

    const expense2 = useMemo<number | undefined>(() => {
        if (data?.collectionDetails2 !== undefined) {
            return data?.collectionDetails2.filter(f => {
                return f.type === CollectionTypeList.EXPENSE
            }).reduce((previous, current) => {
                return previous + parseFloat(current.amount)
            }, 0)

        }
    }, [data])

    const TotalExpense = useMemo<number>(() => {

        return (expense1 !== undefined ? expense1 : 0) + (expense2 !== undefined ? expense2 : 0)

    }, [expense1, expense2])

    const totalKm = useMemo<number | undefined>(() => {
        if (data?.startingKm !== undefined && data?.endingKm !== undefined && data?.diesel !== undefined) {
            return data?.endingKm - data?.startingKm
        }
    }, [data])

    const mileage = useMemo<number | undefined>(() => {
        if (data?.diesel !== undefined && totalKm !== undefined) {
            return totalKm / data?.diesel
        }
    }, [data, totalKm])

    const spareTotalKm = useMemo<number | undefined>(() => {
        if (data?.spareStartingKM !== undefined && data?.spareEndingKM !== undefined && data?.spareStartingKM !== null && data?.spareEndingKM !== null) {
            return data?.spareEndingKM - data?.spareStartingKM
        }
    }, [data])

    const spareMileage = useMemo<number | undefined>(() => {
        if (data?.spareDiesel !== undefined && data?.spareDiesel !== null && spareTotalKm !== undefined) {
            return spareTotalKm / data?.spareDiesel
        }
    }, [data, spareTotalKm])

    const handleDownloadPDF = () => {
        if (data !== undefined) {
            const doc = new jsPDF('l', 'mm', 'a4')

            const head1 = [['S.No', 'Ticket Price', 'Starting No', 'Ending No', 'Count', 'Amount']]
            const tfoot1 = [['Total', '', '', '', '', currencyFormat(income1)]]
            const body1 = data?.ticketDetails.map((td, i) => {

                const count = td.startingNumber !== '' && td.endingNumber !== '' ? Math.abs(parseFloat(td.endingNumber) - parseFloat(td.startingNumber)) : ''

                const amount = count !== '' ? count * parseFloat(td.ticketPrice) : ''

                return [
                    String(i + 1),
                    String(td.ticketPrice),
                    String(td.startingNumber),
                    String(td.endingNumber),
                    String(count),
                    String(currencyFormat(amount))
                ]
            })


            const head2 = [['S.No', 'Trip Start Time', 'Trip End Time', 'Amount']]
            const tfoot2 = [['Total', '', '', currencyFormat(income1)]]
            const body2 = data?.tripDetails.map((td, i) => {
                const index = TripMaster.findIndex(t => t.id === td.tripId)

                return [
                    String(i + 1),
                    String(TripMaster[index] ? TripMaster[index].startTime : ''),
                    String(TripMaster[index] ? TripMaster[index].endTime : ''),
                    String(currencyFormat(td.amount))
                ]
            })

            const head3 = [['S.No', 'Expense Details', 'Amount']]
            const tfoot3 = [['Total', '', currencyFormat(expense1)]]

            const body3 = data?.collectionDetails.filter((f) => {
                return f.type === CollectionTypeList.EXPENSE
            }).map((cd, i) => {
                return [
                    i + 1,
                    String(cd.details),
                    String(currencyFormat(cd.amount))
                ]
            })

            doc.setFontSize(20)
            doc.text('SUPREME BUS SERVICE', 100, 12)


            doc.setFontSize(13)
            // doc.setFontStyle('bold')
            doc.text('Invoice. No :', 15, 30)
            doc.text('Route :', 125, 30)
            doc.text('Date :', 205, 30)
            // doc.setFontStyle('normal')
            doc.text(data?.invoiceNumber, 45, 30)
            doc.text(data.routeName !== undefined ? data.routeName : '', 140, 30)
            doc.text(data.date, 220, 30)

            doc.setFontSize(20)
            doc.text('Vehicle Details', 115, 55)
            doc.setFontSize(13)
            // doc.setFontStyle('bold')
            doc.text('Vehicle :', 15, 70)
            doc.text('Starting Km :', 80, 70)
            doc.text('Ending Km :', 155, 70)
            doc.text('Diesel :', 235, 70)
            // doc.setFontStyle('normal')
            doc.text(vehicleName, 35, 70)
            doc.text(data.startingKm.toString(), 108, 70)
            doc.text(data.endingKm.toString(), 182, 70)
            doc.text(data.diesel.toString(), 252, 70)

            doc.text('Total Km :', 80, 80)
            doc.text(totalKm !== undefined ? totalKm.toString() : '', 108, 80)
            doc.text('Mileage :', 155, 80)
            doc.text(mileage !== undefined ? (mileage.toFixed(2)).toString() : '', 182, 80)
            // doc.setFontStyle('bold')
            // doc.addFont('ComicSansMS', 'Comic Sans', 'bold');
            // doc.setFont('Comic Sans');
            // doc.text('Hello World', 50, 50);
            // doc.addFont('HELVETICA', 'Helvetica', 'bold');
            // doc.setFont('Helvetica');
            // doc.text('Hello World', 50, 50);
            doc.text('Spare Vehicle :', 15, 90)
            doc.text('Starting Km:', 80, 90)
            doc.text('Ending Km:', 155, 90)
            doc.text('Diesel:', 235, 90)
            // doc.setFontStyle('normal')
            doc.text(spareVehicleName, 48, 90)
            doc.text(data.spareStartingKM !== null ? data.spareStartingKM.toString() : '', 120, 90)
            doc.text(data.spareEndingKM !== null ? data.spareEndingKM.toString() : '', 193, 90)
            doc.text(data.spareDiesel !== null ? data.spareDiesel.toString() : '', 263, 90)

            doc.text('Total Km :', 80, 100)
            doc.text(spareTotalKm !== undefined ? spareTotalKm.toString() : '', 108, 100)
            doc.text('Mileage :', 155, 100)
            doc.text(spareMileage !== undefined ? (spareMileage.toFixed(2)).toString() : '', 182, 100)

            // doc.setFontStyle('bold')
            doc.text('Driver :', 15, 110)
            doc.text('Cleaner :', 80, 110)
            // doc.setFontStyle('normal')
            doc.text(data.driverName !== undefined ? data.driverName : '', 33, 110)
            doc.text(data.cleanerName !== undefined ? data.cleanerName : '', 105, 110)

            doc.text('Conductor :', 15, 120)
            doc.text('Machine :', 80, 120)
            doc.text('Ticket Starting No :', 155, 120)
            doc.text('Ticket Ending No :', 230, 120)
            // doc.setFontStyle('normal')
            doc.text(data.conductorName1 !== undefined ? data.conductorName1 : '', 40, 120)
            doc.text(data.machineName1 !== null ? (data.machineName1 !== undefined ? data.machineName1 : '') : '', 100, 120)
            doc.text(data.startingNumber1 !== undefined ? data.startingNumber1.toString() : '', 195, 120)
            doc.text(data.endingNumber1 !== undefined ? data.endingNumber1.toString() : '', 270, 120)

            if (data.conductorName2 !== undefined && data.conductor2 !== undefined && data.startingNumber2 !== undefined) {
                doc.text('Conductor 2:', 15, 130)
                doc.text('Machine 2:', 80, 130)
                doc.text('Ticket Starting No 2:', 155, 130)
                doc.text('Ticket Ending No 2:', 230, 130)
                // doc.setFontStyle('normal')
                doc.text(data.conductorName2 !== undefined ? data.conductorName2 : '', 41, 130)
                doc.text(data.machineName2 !== null ? (data.machineName2 !== undefined ? data.machineName2 : '') : '', 102, 130)
                doc.text(data.startingNumber2 !== undefined ? data.startingNumber2.toString() : '', 198, 130)
                doc.text(data.endingNumber2 !== undefined ? data.endingNumber2.toString() : '', 271, 130)

            }
            doc.addPage()
            doc.setFontSize(14)
            doc.text('Ticket Details', 10, 15)
            autoTable(doc, {
                head: head1,
                body: body1,
                foot: tfoot1,
                startY: 25

            })

            if (data?.ticketDetails2 !== undefined) {
                // doc.text('Ticket Details', 10, 15)
                const head1b = [['S.No', 'Ticket Price', 'Starting No', 'Ending No', 'Count', 'Amount']]
                const tfoot1b = [['Total', '', '', currencyFormat(income2)]]

                const body1b = data?.ticketDetails2.map((td, i) => {

                    const count = td.startingNumber !== '' && td.endingNumber !== '' ? Math.abs(parseFloat(td.endingNumber) - parseFloat(td.startingNumber)) : ''

                    const amount = count !== '' ? count * parseFloat(td.ticketPrice) : ''

                    return [
                        String(i + 1),
                        String(td.ticketPrice),
                        String(td.startingNumber),
                        String(td.endingNumber),
                        String(count),
                        String(currencyFormat(amount))
                    ]
                })
                autoTable(doc, {
                    head: head1b,
                    body: body1b,
                    foot: tfoot1b,
                    startY: (doc as any).lastAutoTable.finalY + 10
                })
            }

            // doc.addPage()
            doc.setFontSize(14)
            doc.text('Trip Details', 10, (doc as any).lastAutoTable.finalY + 10)
            autoTable(doc, {
                head: head2,
                body: body2,
                foot: tfoot2,
                startY: (doc as any).lastAutoTable.finalY + 20
            })
            if (data?.tripDetails2 !== undefined) {
                const head2b = [['S.No', 'Trip Start Time', 'Trip End Time', 'Amount']]
                const tfoot2b = [['Total', '', '', currencyFormat(income2)]]

                const body2b = data?.tripDetails2.map((td, i) => {
                    const index = TripMaster.findIndex(t => t.id === td.tripId)

                    return [
                        String(i + 1),
                        String(TripMaster[index] ? TripMaster[index].startTime : ''),
                        String(TripMaster[index] ? TripMaster[index].endTime : ''),
                        String(currencyFormat(td.amount))
                    ]
                })
                autoTable(doc, {
                    head: head2b,
                    body: body2b,
                    foot: tfoot2b,
                    startY: (doc as any).lastAutoTable.finalY + 10
                })
            }

            // doc.addPage()
            doc.setFontSize(14)
            doc.text('Expense', 10, (doc as any).lastAutoTable.finalY + 10)
            autoTable(doc, {
                head: head3,
                body: body3,
                foot: tfoot3,
                startY: (doc as any).lastAutoTable.finalY + 20
            })
            if (data?.collectionDetails2 !== undefined) {
                const head3b = [['S.No', 'Expense Details', 'Amount']]
                const tfoot3b = [['Total', '', currencyFormat(expense2)]]

                const body3b = data?.collectionDetails2.filter((f) => {
                    return f.type === CollectionTypeList.EXPENSE
                }).map((cd, i) => {
                    return [
                        i + 1,
                        String(cd.details),
                        String(currencyFormat(cd.amount))
                    ]
                })
                autoTable(doc, {
                    head: head3b,
                    body: body3b,
                    foot: tfoot3b,
                    startY: (doc as any).lastAutoTable.finalY + 10
                })
            }
            // autoTable(doc, { html: '#expense' })

            doc.addPage()
            doc.setFontSize(18)
            doc.text('Total Income :', 30, 20)
            doc.text('Rs.' + String(TotalIncome), 72, 20)

            doc.text('Total Expense:', 30, 35)
            doc.text('Rs.' + String(TotalExpense), 73, 35)

            doc.text('Balance :', 30, 50)
            doc.text('Rs.' + String(data.balance), 68, 50)

            doc.text('Shortage :', 155, 20)
            doc.text('Rs.' + String(data.shortage), 203, 20)

            doc.text('Excess :', 155, 35)
            doc.text('Rs.' + String(data.excess), 203, 35)

            doc.save("collection details.pdf")
        }
    }

    useEffect(() => {
        if (data !== undefined && data.VRvehicleId !== undefined) {
            VehicleMaster.forEach(vm => {
                if (vm.id === data.VRvehicleId) {
                    setVehicleName(vm.name)
                }
            })
        }
    }, [data, VehicleMaster])

    useEffect(() => {
        if (data !== undefined && data.spareVehicleId !== undefined) {
            VehicleMaster.forEach(vm => {
                if (vm.id === data.spareVehicleId) {
                    setSpareVehicleName(vm.name)
                }
            })
        }
    }, [data, VehicleMaster])

    useEffect(() => {
        if (editData !== undefined && editData.id !== undefined) {
            api.get<APIResponse<CollectionSaveDataType>>(`collection/?id=${editData.id}`, config).then(response => {

                if (response.status === 200 && response.data.data) {
                    const result = response.data.data
                    setData(result)

                } else {
                    throw { response }
                }

            }).catch(error => {
                toast(error.response
                    ? error.response.data.message
                        ? error.response.data.message
                        : 'Unable to fetch'
                    : 'Unable to fetch', ToastTypes.ERROR)

            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editData])

    return <div className="card m-0">
        <div className="card-body ps-2 pe-0">
            <div className='container-fluid overflow-auto'>
                {/* <div id="collectionDetailView"> */}
                <div className='row'>
                    <div className='col-sm-6 col-md-4 col-lg-4'>
                        <label className="fs-5 text-black" htmlFor="invoice">Invoice No :</label>
                        <span id="invoice" className="fs-5 text-black"> {data?.invoiceNumber}</span>
                    </div>

                    <div className='col-sm-6 col-md-4 col-lg-4 text-center'>
                        <label className="fs-5 text-black" htmlFor="route">Route :</label>
                        <span id="route" className="fs-5 text-black"> {data?.routeName}</span>
                    </div>

                    <div className='col-sm-6 col-md-4 col-lg-4 text-center'>
                        <label className="fs-5 text-black" htmlFor="date">Date :</label>
                        <span id="date" className="fs-5 text-black"> {data?.date}</span>
                    </div>
                </div>

                <div className='row my-3'>
                    <h4 className="text-center fs-4 my-3">Vehicle Details</h4>

                    <div className='col-sm-6 col-md-4 col-lg-3'>
                        <label className="fs-5 text-black" htmlFor="vehicle">Vehicle :</label>
                        <span id="vehicle" className="fs-5 text-black">{vehicleName}</span>
                    </div>

                    <div className='col-sm-6 col-md-4 col-lg-3'>
                        <label className="fs-5 text-black" htmlFor="startingKm">Starting Km :</label>
                        <span id="startingKm" className="fs-5 text-black"> {data?.startingKm}</span>
                    </div>

                    <div className='col-sm-6 col-md-4 col-lg-3'>
                        <label className="fs-5 text-black" htmlFor="endingKm">Ending Km :</label>
                        <span id="endingKm" className="fs-5 text-black"> {data?.endingKm}</span>
                    </div>

                    <div className='col-sm-6 col-md-4 col-lg-3'>
                        <label className="fs-5 text-black" htmlFor="diesel">Diesel :</label>
                        <span id="diesel" className="fs-5 text-black"> {data?.diesel}</span>
                    </div>

                </div>

                <div className='row my-3'>

                    <div className='col-sm-6 col-md-4 col-lg-3'>
                    </div>

                    <div className='col-sm-6 col-md-4 col-lg-3'>
                        <label className="fs-5 text-black" htmlFor="startingKm">Total Km :</label>
                        <span id="startingKm" className="fs-5 text-black">{totalKm}</span>
                    </div>

                    <div className='col-sm-6 col-md-4 col-lg-3'>
                        <label className="fs-5 text-black" htmlFor="endingKm">Mileage :</label>
                        <span id="endingKm" className="fs-5 text-black">{mileage !== undefined ? (mileage.toFixed(2)).toString() : ''}</span>
                    </div>

                    <div className='col-sm-6 col-md-4 col-lg-3'>
                    </div>

                </div>

                <div className='row my-3'>

                    <div className='col-sm-6 col-md-4 col-lg-3'>
                        <label className="fs-5 text-black" htmlFor="spare vehicle">Spare Vehicle :</label>
                        <span id="spare vehicle" className="fs-5 text-black">{spareVehicleName}</span>
                    </div>

                    <div className='col-sm-6 col-md-4 col-lg-3'>
                        <label className="fs-5 text-black" htmlFor="spare startingKm">Starting Km :</label>
                        <span id="spare startingKm" className="fs-5 text-black"> {data?.spareStartingKM}</span>
                    </div>

                    <div className='col-sm-6 col-md-4 col-lg-3'>
                        <label className="fs-5 text-black" htmlFor="spare endingKm">Ending Km :</label>
                        <span id="spare endingKm" className="fs-5 text-black"> {data?.spareEndingKM}</span>
                    </div>

                    <div className='col-sm-6 col-md-4 col-lg-3'>
                        <label className="fs-5 text-black" htmlFor="spare diesel">Diesel :</label>
                        <span id="spare diesel" className="fs-5 text-black"> {data?.spareDiesel}</span>
                    </div>

                </div>

                <div className='row my-3'>

                    <div className='col-sm-6 col-md-4 col-lg-3'>
                    </div>

                    <div className='col-sm-6 col-md-4 col-lg-3'>
                        <label className="fs-5 text-black" htmlFor="startingKm">Total Km :</label>
                        <span id="startingKm" className="fs-5 text-black">{spareTotalKm}</span>
                    </div>

                    <div className='col-sm-6 col-md-4 col-lg-3'>
                        <label className="fs-5 text-black" htmlFor="endingKm">Mileage :</label>
                        <span id="endingKm" className="fs-5 text-black">{spareMileage !== undefined ? (spareMileage.toFixed(2)).toString() : ''}</span>
                    </div>

                    <div className='col-sm-6 col-md-4 col-lg-3'>
                    </div>

                </div>

                <div className='row my-3'>

                    <div className='col-sm-6 col-md-4 col-lg-3'>
                        <label className="fs-5 text-black" htmlFor="driver">Driver :</label>
                        <span id="driver" className="text-capitalize fs-5 text-black">  {data?.driverName}</span>
                    </div>


                    <div className='col-sm-6 col-md-4 col-lg-3'>
                        <label className="fs-5 text-black" htmlFor="cleaner">Cleaner :</label>
                        <span id="cleaner" className="text-capitalize fs-5 text-black">  {data?.cleanerName}</span>
                    </div>

                </div>

                <div className='row my-3'>
                    <div className='col-sm-6 col-md-4 col-lg-3'>
                        <label className="fs-5 text-black" htmlFor="conductor">Conductor :</label>
                        <span id="conductor" className="text-capitalize fs-5 text-black"> {data?.conductorName1}</span>
                    </div>

                    <div className='col-sm-6 col-md-4 col-lg-3'>
                        <label className="fs-5 text-black" htmlFor="driver">Machine :</label>
                        <span id="driver" className="text-capitalize fs-5 text-black"> {data?.machineName1}</span>
                    </div>

                    <div className='col-sm-6 col-md-4 col-lg-3'>
                        <label className="fs-5 text-black" htmlFor="conductor">Ticket starting No :</label>
                        <span id="conductor" className="text-capitalize fs-5 text-black"> {data?.startingNumber1}</span>
                    </div>

                    <div className='col-sm-6 col-md-4 col-lg-3'>
                        <label className="fs-5 text-black" htmlFor="cleaner">Ticket Ending No :</label>
                        <span id="cleaner" className="text-capitalize fs-5 text-black"> {data?.endingNumber1}</span>
                    </div>


                    {data?.conductor2 !== undefined && <div className='row my-3'>
                        < div className='col-sm-6 col-md-4 col-lg-3'>
                            <label className="fs-5 text-black" htmlFor="conductor">Conductor 2:</label>
                            <span id="conductor" className="text-capitalize fs-5 text-black"> {data?.conductorName2}</span>
                        </div>

                        <div className='col-sm-6 col-md-4 col-lg-3'>
                            <label className="fs-5 text-black" htmlFor="driver">Machine 2:</label>
                            <span id="driver" className="text-capitalize fs-5 text-black"> {data?.machineName2}</span>
                        </div>

                        <div className='col-sm-6 col-md-4 col-lg-3'>
                            <label className="fs-5 text-black" htmlFor="conductor">Ticket starting No 2: </label>
                            <span id="conductor" className="text-capitalize fs-5 text-black"> {data?.startingNumber2}</span>
                        </div>

                        <div className='col-sm-6 col-md-4 col-lg-3'>
                            <label className="fs-5 text-black" htmlFor="cleaner">Ticket Ending No 2: </label>
                            <span id="cleaner" className="text-capitalize fs-5 text-black"> {data?.endingNumber2}</span>
                        </div>
                    </div>
                    }
                </div>

                <div className="my-5">
                    <h4 className="text-center fs-4 mb-2">Ticket Details</h4>

                    <table className="table colored" id="ticketDetails">

                        <thead>
                            <tr>
                                <th scope="col">S.No</th>
                                <th scope="col">Ticket Price</th>
                                <th scope="col">Starting No</th>
                                <th scope="col">Ending No</th>
                                <th scope="col">Total Count</th>
                                <th scope="col">Amount</th>
                            </tr>
                        </thead>
                        {data?.ticketDetails.map((td, i) => {

                            return <TicketBody
                                sno={i + 1}
                                ticketRate={td.ticketPrice.toString()}
                                startingNo={td.startingNumber.toString()}
                                endingNo={td.endingNumber.toString()}
                            />
                        })
                        }
                        <tfoot>
                            <tr>
                                <td colSpan={5} style={{ textAlign: 'left' }}>
                                    <span className="text-dark fw-bold fs-5">Total</span>
                                </td>
                                <td style={{ textAlign: 'left' }}>
                                    <span className="text-dark fw-bold fs-5">{rupeeFormat(income1)}</span>
                                </td>
                            </tr>
                        </tfoot>
                    </table>

                    {data?.ticketDetails2 && <table className="table colored" id="ticketDetails2">

                        <thead>
                            <tr>
                                <th scope="col">S.No</th>
                                <th scope="col">Ticket Price</th>
                                <th scope="col">Starting No</th>
                                <th scope="col">Ending No</th>
                                <th scope="col">Total Count</th>
                                <th scope="col">Amount</th>
                            </tr>
                        </thead>
                        {data?.ticketDetails2.map((td, i) => {

                            return <TicketBody
                                sno={i + 1}
                                ticketRate={td.ticketPrice.toString()}
                                startingNo={td.startingNumber.toString()}
                                endingNo={td.endingNumber.toString()}
                            />
                        })
                        }
                        <tfoot>
                            <tr>
                                <td colSpan={5} style={{ textAlign: 'left' }}>
                                    <span className="text-dark fw-bold fs-5">Total</span>
                                </td>
                                <td style={{ textAlign: 'left' }}>
                                    <span className="text-dark fw-bold fs-5">{rupeeFormat(income2)}</span>
                                </td>
                            </tr>
                        </tfoot>
                    </table>}
                </div>

                <div className="my-5">
                    <h4 className="text-center fs-4 mb-2">Trip Details</h4>

                    <table className="table colored" id="tripDetails">

                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Start Time</th>
                                <th>End Time</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        {data?.tripDetails.map((td, i) => {
                            return TripMaster.filter(f => {
                                return td.tripId === f.id
                            }).map(tm => {
                                return <TripBody
                                    sno={i + 1}
                                    startTime={tm.startTime}
                                    endtime={tm.endTime.toString()}
                                    amount={td.amount.toString()}
                                />
                            })
                        })
                        }
                        <tfoot>
                            <tr>
                                <td colSpan={3} style={{ textAlign: 'left' }}>
                                    <span className="text-dark fw-bold fs-5">Total</span>
                                </td>
                                <td style={{ textAlign: 'left' }}>
                                    <span className="text-dark fw-bold fs-5">{rupeeFormat(income1)}</span>
                                </td>
                            </tr>
                        </tfoot>
                    </table>

                    {data?.tripDetails2 && <table className="table colored" id="tripDetails2">

                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Start Time</th>
                                <th>End Time</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        {data?.tripDetails2.map((td, i) => {
                            return TripMaster.filter(f => {
                                return td.tripId === f.id
                            }).map(tm => {
                                return <TripBody
                                    sno={i + 1}
                                    startTime={tm.startTime}
                                    endtime={tm.endTime.toString()}
                                    amount={td.amount.toString()}
                                />
                            })
                        })
                        }
                        <tfoot>
                            <tr>
                                <td colSpan={3} style={{ textAlign: 'left' }}>
                                    <span className="text-dark fw-bold fs-5">Total</span>
                                </td>
                                <td style={{ textAlign: 'left' }}>
                                    <span className="text-dark fw-bold fs-5">{rupeeFormat(income2)}</span>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                    }
                </div>

                <div className="my-5">
                    <h4 className="text-center fs-4 mb-2">Expense</h4>

                    <table className="table colored" id="expense">

                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Expense Details</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        {data?.collectionDetails.filter((f) => {
                            return f.type === CollectionTypeList.EXPENSE
                        }).map((cd, i) => {
                            return <tbody>
                                <tr>
                                    <td>{i + 1}</td>
                                    <td>{cd.details}</td>
                                    <td>{rupeeFormat(cd.amount)}</td>
                                </tr>
                            </tbody>
                        })
                        }
                        <tfoot>
                            <tr>
                                <td colSpan={2} style={{ textAlign: 'left' }}>
                                    <span className="text-dark fw-bold fs-5">Total</span>
                                </td>
                                <td style={{ textAlign: 'left' }}>
                                    <span className="text-dark fw-bold fs-5">{rupeeFormat(expense1)}</span>
                                </td>
                            </tr>
                        </tfoot>
                    </table>

                    {data?.collectionDetails2 && <table className="table colored" id="expense2">

                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Expense Details</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        {data?.collectionDetails2.filter((f) => {
                            return f.type === CollectionTypeList.EXPENSE
                        }).map((cd, i) => {
                            return <tbody>
                                <tr>
                                    <td>{i + 1}</td>
                                    <td>{cd.details}</td>
                                    <td>{rupeeFormat(cd.amount)}</td>
                                </tr>
                            </tbody>
                        })
                        }
                        <tfoot>
                            <tr>
                                <td colSpan={2} style={{ textAlign: 'left' }}>
                                    <span className="text-dark fw-bold fs-5">Total</span>
                                </td>
                                <td style={{ textAlign: 'left' }}>
                                    <span className="text-dark fw-bold fs-5">{rupeeFormat(expense2)}</span>
                                </td>
                            </tr>
                        </tfoot>
                    </table>}
                </div>
                <div className="my-1 d-flex justify-content-between">
                    <label htmlFor="totalIncome" className="fs-3 fw-bold">Total Income :</label>
                    <span id="totalIncome" className="fs-3 fw-bold">{rupeeFormat(TotalIncome)}</span>
                </div>
                <div className="my-1 d-flex justify-content-between">
                    <label htmlFor="totalExpense" className="fs-3 fw-bold">Total Expense :</label>
                    <span id='totalExpense' className="fs-3 fw-bold">{rupeeFormat(TotalExpense)}</span>
                </div>
                <div className="my-1 d-flex justify-content-between">
                    <label htmlFor="totalShortage" className="fs-3 fw-bold">Shortage :</label>
                    <span id="totalShortage" className="fs-3 fw-bold">{rupeeFormat(data?.shortage)}</span>
                </div>
                <div className="my-1 d-flex justify-content-between">
                    <label htmlFor="totalExcess" className="fs-3 fw-bold">Excess :</label>
                    <span id="totalExcess" className="fs-3 fw-bold">{rupeeFormat(data?.excess)}</span>
                </div>

                <div className="my-1 d-flex justify-content-between">
                    <label htmlFor="totalBalance" className="fs-3 fw-bold">Balance :</label>
                    <span id="totalBalance" className="fs-3 fw-bold">{rupeeFormat(data?.balance)}</span>
                </div>

                {/* </div> */}
                <div className='my-3 text-end'>
                    <button className='btn btn-primary' onClick={handleDownloadPDF}>Download PDF
                        <i className='fe-download'></i>
                    </button>
                </div>
            </div>

        </div>
    </div >
}

interface TicketBodyProps {
    sno: number
    ticketRate: string
    startingNo: string
    endingNo: string
}

const TicketBody = ({ ticketRate, endingNo, startingNo, sno }: TicketBodyProps) => {

    const count = startingNo !== '' && endingNo !== '' ? Math.abs(parseFloat(endingNo) - parseFloat(startingNo)) : ''

    const amount = count !== '' ? count * parseFloat(ticketRate) : ''

    return <tbody>
        <tr>
            <td className=''>{sno} </td>
            <td className=''>{rupeeFormat(parseFloat(ticketRate))}</td>
            <td className=''>{startingNo} </td>
            <td className=''>{endingNo} </td>
            <td className=''>{count}</td>
            <td className=''>{rupeeFormat(amount)}</td>
        </tr>
    </tbody>
}

interface TripBodyProps {
    sno: number
    amount: string
    startTime: string
    endtime: string
    // total?: number
}

const TripBody = ({ startTime, endtime, amount, sno }: TripBodyProps) => {


    return <tbody>
        <tr>
            <td className=''>{sno} </td>
            <td className=''>{startTime} </td>
            <td className=''>{endtime} </td>
            <td className=''>{rupeeFormat(parseFloat(amount))}</td>
        </tr>
        {/* <tr>
            <td colSpan={3} className="fs-4">Total</td>
            <td className="fs-4">{total}</td>
        </tr> */}
    </tbody>
}

export default CollectionViewDetailsForm