import React, { ChangeEventHandler, MouseEventHandler, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { fetchAccountHolder } from "../redux/actions/accountHolderActions"
// import { addKey } from "../config/functions"
import { addRouteMaster, RouteMasterType, updateRouteMaster } from "../redux/actions/routeMasterActions"
// import { Key } from "../redux/actions/types"
// import { StoreState } from "../redux/reducers"
import { AppDispatch } from "../redux/store"
import { FormInput } from "./custom/components"
import { ToastTypes, useToast } from "./toast/ToastProvider"
import { FormType, FormTypeList } from "./types"

interface Props {
    formType: FormType
    editData?: RouteMasterType
    onSave: () => void
}

const RouteMasterForm = ({ formType, onSave, editData }: Props) => {
    // const routeMaster = useSelector<StoreState, Array<RouteMasterType & Key>>(state => addKey(state.routeMaster))

    const toast = useToast()

    const dispatch = useDispatch<AppDispatch>()

    const [code, setCode] = useState<string>('')
    const [codeError, setCodeError] = useState<string>('')
    const [name, setName] = useState<string>('')
    const [nameError, setNameError] = useState<string>('')
    const [startPoint, setStartPoint] = useState<string>('')
    const [startPointError, setStartPointError] = useState<string>('')
    const [endPoint, setEndPoint] = useState<string>('')
    const [endPointError, setEndPointError] = useState<string>('')
    const [km, setKm] = useState<string>('')
    const [kmError, setKmError] = useState<string>('')
    const [numberOfStops, setNumberOfStops] = useState<string>('')
    const [numberOfStopsError, setNumberOfStopsError] = useState<string>('')
    const [numberOfStages, setNumberOfStages] = useState<string>('')
    const [numberOfStagesError, setNumberOfStagesError] = useState<string>('')
    const [tripCount, setTripCount] = useState<string>('')
    const [tripCountError, setTripCountError] = useState<string>('')

    const resetStates = () => {
        setCode('')
        setCodeError('')
        setName('')
        setNameError('')
        setStartPoint('')
        setStartPointError('')
        setEndPoint('')
        setEndPointError('')
        setKm('')
        setKmError('')
        setNumberOfStops('')
        setNumberOfStopsError('')
        setNumberOfStages('')
        setNumberOfStagesError('')
        setTripCount('')
        setTripCountError('')
    }

    const handleCode: ChangeEventHandler<HTMLInputElement> = (e) => {
        setCode(e.target.value)
        setCodeError('')
    }

    const handleName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setName(e.target.value)
        setNameError('')
    }

    const handleStartPoint: ChangeEventHandler<HTMLInputElement> = (e) => {
        setStartPoint(e.target.value)
        setStartPointError('')
    }

    const handleEndPoint: ChangeEventHandler<HTMLInputElement> = (e) => {
        setEndPoint(e.target.value)
        setEndPointError('')
    }

    const handleKm: ChangeEventHandler<HTMLInputElement> = (e) => {
        setKm(e.target.value)
        setKmError('')
    }

    const handleNumberOfStops: ChangeEventHandler<HTMLInputElement> = (e) => {
        setNumberOfStops(e.target.value)
        setNumberOfStopsError('')
    }

    const handleNumberOfStages: ChangeEventHandler<HTMLInputElement> = (e) => {
        setNumberOfStages(e.target.value)
        setNumberOfStagesError('')
    }

    const handleTripCount: ChangeEventHandler<HTMLInputElement> = (e) => {
        setTripCount(e.target.value)
        setTripCountError('')
    }

    const handleClear: MouseEventHandler<HTMLButtonElement> = (e) => {
        resetStates()
    }

    const handleSave = () => {
        let error = false
        const data: RouteMasterType = {
            code: code,
            name: name,
            startPoint: startPoint,
            endPoint: endPoint,
            totalKm: km,
            stopsNumber: numberOfStops,
            stageNumber: numberOfStages,
            tripsPerDay: tripCount
        }

        // if (formType === FormTypeList.UPDATE) {
        //     if (data.code === "") {
        //         setCodeError('Code required')
        //         error = true
        //     }
        // }

        // if (formType === FormTypeList.ADD) {
        //     if (data.code === "") {
        //         setCodeError('Code required')
        //         error = true
        //     } else if (data.code !== "") {
        //         const uniqueIndex = routeMaster.findIndex(rm => {
        //             return rm.code.toLowerCase() === data.code.toLowerCase()
        //         })
        //         if (uniqueIndex > -1) {
        //             setCodeError('Code already exists')
        //             error = true
        //         }
        //     }
        // }
        if (data.code !== "" && data.code !== undefined) {
            if ((data.code).length > 15) {
                setCodeError('Code must be within 15 characters')
                error = true
            }
        }

        if (data.name === "") {
            setNameError('Name required')
            error = true
        } else if ((data.name).length > 30) {
            setNameError('Name should be within 30 characters')
            error = true
        }

        if (data.startPoint === "") {
            setStartPointError('Start Point required')
            error = true
        } else if ((data.startPoint).length > 30) {
            setStartPointError('Start Point should be within 30 characters')
            error = true
        }

        if (data.endPoint === "") {
            setEndPointError('End Point required')
            error = true
        } else if ((data.endPoint).length > 30) {
            setEndPointError('End Point should be within 30 characters')
            error = true
        }

        if (data.totalKm === "") {
            setKmError("Total No.of.Km's required")
            error = true
        } else if (parseFloat(data.totalKm) < 0) {
            setKmError("Invalid Total Km")
            error = true
        }

        if (data.stopsNumber === "") {
            setNumberOfStopsError('No.Of.Stops required')
            error = true
        } else if (parseInt(data.stopsNumber) < 0) {
            setNumberOfStopsError("Invalid Stop.No")
            error = true
        }

        if (data.stageNumber === "") {
            setNumberOfStagesError('No.Of.Stages required')
            error = true
        } else if (parseInt(data.stageNumber) < 0) {
            setNumberOfStagesError("Invalid Stage Number")
            error = true
        }

        if (data.tripsPerDay === "") {
            setTripCountError('Trip Count required')
            error = true
        } else if (parseInt(data.tripsPerDay) < 0) {
            setTripCountError("Invalid Trip count")
            error = true
        }

        if (!error) {
            if (formType === FormTypeList.ADD) {

                dispatch(addRouteMaster(data)).then(text => {
                    toast(text)
                    onSave()
                    dispatch(fetchAccountHolder())
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            } else if (formType === FormTypeList.UPDATE && editData !== undefined && editData.id !== undefined) {
                dispatch(updateRouteMaster(data, editData.id)).then(text => {
                    toast(text)
                    onSave()
                    dispatch(fetchAccountHolder())
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
        }
    }

    useEffect(() => {
        if (formType === FormTypeList.UPDATE && editData !== undefined) {

            setCode(editData.code !== undefined ? editData.code : '')
            setName(editData.name)
            setStartPoint(editData.startPoint)
            setEndPoint(editData.endPoint)
            setKm(editData.totalKm)
            setNumberOfStops(editData.stopsNumber)
            setNumberOfStages(editData.stageNumber)
            setTripCount(editData.tripsPerDay)
        } else {
            resetStates()
        }
    }, [formType, editData])


    return <div className='container'>
        <div className="row">

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Code'
                    // labelClassName="required"
                    value={code}
                    onChange={handleCode}
                    placeholder='Code'
                    errorText={codeError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Name'
                    labelClassName="required"
                    value={name}
                    onChange={handleName}
                    placeholder='Name'
                    errorText={nameError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='StartPoint'
                    labelClassName="required"
                    value={startPoint}
                    onChange={handleStartPoint}
                    placeholder='StartPoint'
                    errorText={startPointError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='EndPoint'
                    labelClassName="required"
                    value={endPoint}
                    onChange={handleEndPoint}
                    placeholder='EndPoint'
                    errorText={endPointError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='KM'
                    labelClassName="required"
                    value={km}
                    onChange={handleKm}
                    placeholder='KM'
                    errorText={kmError}
                    type="number"
                    containerClass="mb-2"
                />
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='NO.Of.Stops'
                    labelClassName="required"
                    value={numberOfStops}
                    onChange={handleNumberOfStops}
                    placeholder='NO.Of.Stops'
                    errorText={numberOfStopsError}
                    type="number"
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='NO.Of.Stages'
                    labelClassName="required"
                    value={numberOfStages}
                    onChange={handleNumberOfStages}
                    placeholder='NO.Of.Stages'
                    errorText={numberOfStagesError}
                    type="number"
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Trip Count'
                    labelClassName="required"
                    value={tripCount}
                    onChange={handleTripCount}
                    placeholder='Trip Count'
                    errorText={tripCountError}
                    containerClass="mb-2"
                    type="number"
                />
            </div>
        </div>
        <div className="d-flex justify-content-end hstack gap-1">
            <button className="btn btn-primary" onClick={handleClear}>Clear</button>
            <button className="btn btn-success" onClick={handleSave}>Save</button>
        </div>

    </div>
}
export default RouteMasterForm