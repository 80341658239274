import { Action } from "../actions/types"
import { CollectionType, CollectionActionList } from "../actions/collectionActions"

const initialValue: Array<CollectionType> = []

const collectionReducer = (state: Array<CollectionType> = initialValue, action: Action) => {
    switch (action.type) {

        case CollectionActionList.ADD_COLLECTION:
            return [
                ...state,
                action.data,
            ]

        case CollectionActionList.UPDATE_COLLECTION:
            const updated = [...state].slice()
            const index = updated.findIndex(u => u.id === action.data.id)

            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }

            return updated

        case CollectionActionList.FETCH_COLLECTION:
            return action.data

        default:
            return state
    }
}

export default collectionReducer