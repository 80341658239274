import { ChangeEventHandler, MouseEventHandler, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormInput } from '../components/custom/components'
import PageTitle from '../components/custom/components/PageTitle'
import VerticalLayout from '../components/custom/layouts/Vertical'
import Modal from '../components/Modal'
import Option from '../components/Option'
import { ToastTypes, useToast } from '../components/toast/ToastProvider'
import { FormType, FormTypeList } from '../components/types'
import VehicleDocumentForm from '../components/VehicleDocumentForm'
import { addKey, LocalFormatDate } from '../config/functions'
import { DocumentMasterType } from '../redux/actions/documentMasterActions'
import { Key } from '../redux/actions/types'
import { deleteVehicleDocument, VehicleDocumentType } from '../redux/actions/vehicleDocumentActions'
import { VehicleMasterType } from '../redux/actions/vehicleMasterActions'
import { StoreState } from '../redux/reducers'
import { AppDispatch } from '../redux/store'
import { isInToday } from '../config/functions'
import { AuthUserType } from '../redux/actions/authUserActions'

interface TableBody {
    sno: number
    vehicleName: string
    documentTypeCode: string
    registeredDate: string
    validityMonths: string
    expiryDate: string
    renewalAmount: string
    onEdit: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
    timeStamp: string
    authUser: AuthUserType
}

type HandleEdit = (value: VehicleDocumentType) => void
type HandleDelete = (value: VehicleDocumentType) => void


const VehicleDocument = () => {
    const toast = useToast()

    const authUser = useSelector<StoreState, AuthUserType>(state => state.authUser)

    const dispatch = useDispatch<AppDispatch>()

    const [showForm, setShowForm] = useState<boolean>(false)
    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    const [editData, setEditData] = useState<VehicleDocumentType>()

    const vehicleDocumentList = useSelector<StoreState, Array<VehicleDocumentType & Key>>(state => addKey(state.vehicleDocument))
    const vehiclemaster = useSelector<StoreState, Array<VehicleMasterType & Key>>(state => addKey(state.vehicleMaster))
    const documentMaster = useSelector<StoreState, Array<DocumentMasterType & Key>>(state => addKey(state.documentMaster))

    const [vehicleId, setVehicleId] = useState<string>('')
    const [documentCode, setDocumentCode] = useState<string>('')

    const vehicleDocument = useMemo<Array<VehicleDocumentType & Key>>(() => {
        return vehicleDocumentList.filter(vd => {
            return vehicleId !== '' ? vd.vehicleId === parseInt(vehicleId) : true
        }).filter(vd => {
            return documentCode !== '' ? vd.documentCode === documentCode : true
        })
    }, [vehicleId, documentCode, vehicleDocumentList])

    const handleVehicleId: ChangeEventHandler<HTMLInputElement> = (e) => {
        setVehicleId(e.target.value)
    }

    const handleDocumentCode: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDocumentCode(e.target.value)
    }

    const handleAdd = () => {
        setShowForm(true)
    }

    const handleEdit: HandleEdit = (value) => {
        setFormType(FormTypeList.UPDATE)
        setEditData(value)
        setShowForm(true)
    }

    const handleDelete: HandleDelete = (value) => {
        if (value.id !== undefined) {
            dispatch(deleteVehicleDocument(value.id)).then(text => {
                toast(text)
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
        }
    }

    const handleFormClose = () => {
        setShowForm(false)
        setFormType(FormTypeList.ADD)
        setEditData(undefined)
    }

    return <>
        <Modal
            headerText={formType === FormTypeList.ADD ? 'Add Vehicle Document' : 'Edit Vehicle Document'}
            visible={showForm}
            onClose={handleFormClose}
            size='xl'
            centered
        >
            <VehicleDocumentForm
                formType={formType}
                editData={editData}
                onSave={handleFormClose}
            />

        </Modal>
        <VerticalLayout>
            <PageTitle
                title='Vehicle Document'
                buttonText='ADD'
                onButtonClick={handleAdd}
            />

            <div className='card'>
                <div className='card-body'>
                    <div className='container-fluid'>
                        <div className="row">
                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <FormInput
                                    value={vehicleId}
                                    onChange={handleVehicleId}
                                    type='select'
                                    label='Vehicle'
                                >
                                    <option value="">All</option>
                                    {vehiclemaster.map(vm => {
                                        return <Option value={vm.id} key={vm.key}>{vm.name}</Option>
                                    })}
                                </FormInput>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <FormInput
                                    value={documentCode}
                                    onChange={handleDocumentCode}
                                    type='select'
                                    label='Document Code'
                                >
                                    <option value="">All</option>
                                    {documentMaster.map(dm => {
                                        return <Option value={dm.code} key={dm.key}>{dm.code}</Option>
                                    })}
                                </FormInput>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="card">
                <div className="card-body">
                    <div className='table-wrapper'>
                        <table className='table colored'>
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    <th className='text-truncate align-middle'>Vehicle Name</th>
                                    <th className='text-truncate align-middle'>Document Code</th>
                                    <th className='text-truncate align-middle'>Registered Date</th>
                                    <th className='text-truncate align-middle'>Validity Months</th>
                                    <th className='text-truncate align-middle'>Expiry Date</th>
                                    <th className='text-truncate align-middle'>Renewal Amount</th>
                                    <th className='text-truncate align-middle text-center'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {vehicleDocument.map((vd, i) => {
                                    return <VehicleDocumentBody
                                        sno={i + 1}
                                        vehicleName={vd.vehicleName}
                                        documentTypeCode={vd.documentCode}
                                        registeredDate={LocalFormatDate(vd.registeredDate)}
                                        validityMonths={vd.validityMonths}
                                        expiryDate={LocalFormatDate(vd.expiryDate)}
                                        renewalAmount={vd.renewalAmount !== undefined ? vd.renewalAmount : ''}
                                        authUser={authUser}
                                        timeStamp={vd.createTimestamp !== undefined ? vd.createTimestamp : ''}
                                        key={vd.key}
                                        onEdit={() => handleEdit(vd)}
                                        onDelete={() => handleDelete(vd)}
                                    />
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </VerticalLayout>
    </>
}

const VehicleDocumentBody = ({ sno, vehicleName, documentTypeCode, registeredDate, expiryDate, renewalAmount, validityMonths, onEdit, onDelete, timeStamp, authUser }: TableBody) => {

    const ShowEditForManager = isInToday(timeStamp)

    return <tr>
        <td>{sno}</td>
        <td>{vehicleName}</td>
        <td>{documentTypeCode}</td>
        <td>{registeredDate}</td>
        <td>{validityMonths}</td>
        <td>{expiryDate}</td>
        <td>&#8377;{renewalAmount}</td>
        <td>
            <div className='hstack gap-1'>
                {(authUser.role === 'admin' || authUser.role === 'super' || authUser.role === 'Admin' || authUser.role === 'Super') && <button className='btn btn-xs btn-primary' onClick={onEdit}>Edit</button>}
                {(authUser.role === 'Manager' || authUser.role === 'manager') && ShowEditForManager && <button className='btn btn-xs btn-primary' onClick={onEdit}>Edit</button>}
                <button className='btn btn-xs btn-danger' onClick={onDelete}>Delete</button>
            </div>
        </td>
    </tr>
}

export default VehicleDocument
