import React, { ChangeEventHandler, MouseEventHandler, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { addKey } from "../config/functions"
import { addSpareParts, SparePartsType, updateSpareParts } from "../redux/actions/sparePartsActions"
import { Key } from "../redux/actions/types"
import { StoreState } from "../redux/reducers"
import { AppDispatch } from "../redux/store"
import { FormInput } from "./custom/components"
import { ToastTypes, useToast } from "./toast/ToastProvider"
import { FormType, FormTypeList } from "./types"

interface Props {
    formType: FormType
    editData?: SparePartsType
    onSave: () => void
}
// {
//id: PK,
//code: FT,
//name: FT,
//brand: FT,
//usedFor: FT
// }
const SparePartsForm = ({ formType, onSave, editData }: Props) => {
    const toast = useToast()

    const dispatch = useDispatch<AppDispatch>()

    const spareParts = useSelector<StoreState, Array<SparePartsType & Key>>(state => addKey(state.spareParts))

    const [code, setCode] = useState<string>('')
    const [codeError, setCodeError] = useState<string>('')
    const [name, setName] = useState<string>('')
    const [nameError, setNameError] = useState<string>('')
    const [number, setNumber] = useState<string>('')
    const [numberError, setNumberError] = useState<string>('')
    const [brand, setBrand] = useState<string>('')
    const [brandError, setBrandError] = useState<string>('')
    const [usedFor, setUsedFor] = useState<string>('')
    const [usedForError, setUsedForError] = useState<string>('')

    const resetStates = () => {
        setCode('')
        setCodeError('')
        setName('')
        setNameError('')
        setNumber('')
        setNumberError('')
        setBrand('')
        setBrandError('')
        setUsedFor('')
        setUsedForError('')

    }

    const handleCode: ChangeEventHandler<HTMLInputElement> = (e) => {
        setCode(e.target.value)
        setCodeError('')
    }

    const handleName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setName(e.target.value)
        setNameError('')
    }

    const handleNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
        setNumber(e.target.value)
        setNumberError('')
    }

    const handleBrand: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBrand(e.target.value)
        setBrandError('')
    }

    const handleUsedFor: ChangeEventHandler<HTMLInputElement> = (e) => {
        setUsedFor(e.target.value)
        setUsedForError('')
    }

    const handleClear: MouseEventHandler<HTMLButtonElement> = (e) => {
        resetStates()
    }

    const handleSave = () => {
        let error = false
        const data: SparePartsType = {
            code: code,
            name: name,
            number: number,
            brand: brand,
            usedFor: usedFor,

        }
        if (formType === FormTypeList.ADD) {
            if (data.code === "") {
                setCodeError('Code required')
                error = true
            } else if (data.code !== "") {
                const uniqueIndex = spareParts.findIndex(sp => {
                    return sp.code.toLowerCase() === data.code.toLowerCase()
                })
                if (uniqueIndex > -1) {
                    setCodeError('Code already exists')
                    error = true
                }
            }
        }

        if (formType === FormTypeList.UPDATE) {
            if (data.code === "") {
                setCodeError('Code required')
                error = true
            }
        }
        if (data.name === "") {
            setNameError('Name required')
            error = true
        }

        if (data.number === "") {
            setNumberError('Number required')
            error = true
        }

        if (data.brand === "") {
            setBrandError('Brand required')
            error = true
        }

        if (data.usedFor === "") {
            setUsedForError('Usage required')
            error = true
        }


        if (!error) {
            if (formType === FormTypeList.ADD) {

                dispatch(addSpareParts(data)).then(text => {
                    toast(text)
                    onSave()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            } else if (formType === FormTypeList.UPDATE && editData !== undefined && editData.id !== undefined) {
                dispatch(updateSpareParts(data, editData.id)).then(text => {
                    toast(text)
                    onSave()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
        }
    }

    useEffect(() => {
        if (formType === FormTypeList.UPDATE && editData !== undefined) {

            setCode(editData.code)
            setName(editData.name)
            setNumber(editData.number !== undefined ? editData.number : '')
            setBrand(editData.brand)
            setUsedFor(editData.usedFor)

        } else {
            resetStates()
        }
    }, [formType, editData])


    return <div className='container'>
        <div className="row">

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Code'
                    value={code}
                    onChange={handleCode}
                    placeholder='Code'
                    errorText={codeError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Name'
                    value={name}
                    onChange={handleName}
                    placeholder='Name'
                    errorText={nameError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Number'
                    value={number}
                    onChange={handleNumber}
                    placeholder='Number'
                    errorText={numberError}
                    containerClass="mb-2"
                    type="number"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Brand'
                    value={brand}
                    onChange={handleBrand}
                    placeholder='Brand'
                    errorText={brandError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='UsedFor'
                    value={usedFor}
                    onChange={handleUsedFor}
                    placeholder='UsedFor'
                    errorText={usedForError}
                    containerClass="mb-2"
                />
            </div>

        </div>
        <div className="d-flex justify-content-end hstack gap-1">
            <button className="btn btn-primary" onClick={handleClear}>Clear</button>
            <button className="btn btn-success" onClick={handleSave}>Save</button>
        </div>

    </div>
}
export default SparePartsForm