import React, { ChangeEventHandler, MouseEventHandler, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import api, { APIResponse, getAxiosRequestConfig } from "../config/api"
import { addKey } from "../config/functions"
import { AuthUserType } from "../redux/actions/authUserActions"
import { EmployeeType, EmployeeTypeList } from "../redux/actions/employeeActions"
import { Key } from "../redux/actions/types"
import { addVehicleHiring, updateVehicleHiring, VehicleHiringReason, VehicleHiringReasonList, VehicleHiringStatus, VehicleHiringStatusList, VehicleHiringType } from "../redux/actions/vehicleHiringActions"
import { VehicleMasterType } from "../redux/actions/vehicleMasterActions"
import { StoreState } from "../redux/reducers"
import { AppDispatch } from "../redux/store"
import { FormInput } from "./custom/components"
import Option from "./Option"
import { ToastTypes, useToast } from "./toast/ToastProvider"
import { FormType, FormTypeList } from "./types"

interface Props {
    formType: FormType
    editData?: VehicleHiringType
    vehicleHiringFilter: () => void
    onSave: () => void
}
const HIRING_STATUS = addKey([
    {
        text: 'Requested',
        value: 'Requested'
    },
    {
        text: 'Confirmed',
        value: 'Confirmed'
    },
    {
        text: 'Started',
        value: 'Started'
    },
    {
        text: 'Closed',
        value: 'Closed'
    }
])

const HIRING_REASON = addKey([
    {
        text: 'Tour',
        value: 'Tour'
    },
    {
        text: 'Excursion',
        value: 'Excursion'
    },
    {
        text: 'Marriage',
        value: 'Marriage'
    },
    {
        text: 'Meetings',
        value: 'Meetings'
    }
])


// id:PK,
// vehicleId:FK,
// from:FT,
// to:FT,
// hiringReason: Excursion/Tour/Marriage/Meetings
// fromDate:FT,
// toDate:FT,
// totalDays:FT,
// totalKM:FT,
// chargePerKM:FT,
// driverAllowance:FT,
// cleanerAllowance:FT,
// haltingAmount:FT,
// TotalAmount:FT,
// AdvanceAmount:FT,
// customer:{
// name:FT,
// address:FT,
// contactNumber:FT
// }
// status: Requested/Confirmed/Started/Closed,
// DriverAssigned:FK - employee,
// cleanerAssigned:FK-employee
// bookingDate:FT

const VehicleHiringForm = ({ formType, onSave, editData, vehicleHiringFilter }: Props) => {

    const vehicleMaster = useSelector<StoreState, Array<VehicleMasterType & Key>>(state => addKey(state.vehicleMaster))
    const employee = useSelector<StoreState, Array<EmployeeType & Key>>(state => addKey(state.employee))

    const toast = useToast()
    const dispatch = useDispatch<AppDispatch>()
    const authUser = useSelector<StoreState, AuthUserType>(state => state.authUser)
    const token = authUser.token!
    const config = getAxiosRequestConfig(token)

    const [vehicleId, setVehicleId] = useState<string>('')
    const [vehicleIdError, setVehicleIdError] = useState<string>('')
    const [VehicleName, setVehicleName] = useState<string>('')
    const [from, setFrom] = useState<string>('')
    const [fromError, setFromError] = useState<string>('')
    const [to, setTo] = useState<string>('')
    const [toError, setToError] = useState<string>('')
    const [hiringReason, setHiringReason] = useState<VehicleHiringReason>(VehicleHiringReasonList.TOUR)
    const [hiringReasonError, setHiringReasonError] = useState<string>('')
    const [fromDate, setFromDate] = useState<string>('')
    const [fromDateError, setFromDateError] = useState<string>('')
    const [toDate, setToDate] = useState<string>('')
    const [toDateError, setToDateError] = useState<string>('')
    const [totalDays, setTotalDays] = useState<string>('')
    const [totalDaysError, setTotalDaysError] = useState<string>('')
    const [totalKM, setTotalKM] = useState<string>('')
    const [totalKMError, setTotalKMError] = useState<string>('')
    const [chargePerKM, setChargePerKM] = useState<string>('')
    const [chargePerKMError, setChargePerKMError] = useState<string>('')

    const [driverAllowance, setDriverAllowance] = useState<string>('')
    const [driverAllowanceError, setDriverAllowanceError] = useState<string>('')
    const [cleanerAllowance, setCleanerAllowance] = useState<string>('')
    const [cleanerAllowanceError, setCleanerAllowanceError] = useState<string>('')
    const [haltingAmount, setHaltingAmount] = useState<string>('')
    const [haltingAmountError, setHaltingAmountError] = useState<string>('')
    const [tpAmount, setTpAmount] = useState<string>('')
    const [tpAmountError, setTpAmountError] = useState<string>('')
    const [totalAmount, setTotalAmount] = useState<string>('')
    const [totalAmountError, setTotalAmountError] = useState<string>('')
    const [advanceAmount, setAdvanceAmount] = useState<string>('')
    const [advanceAmountError, setAdvanceAmountError] = useState<string>('')
    const [status, setStatus] = useState<VehicleHiringStatus>(VehicleHiringStatusList.CLOSED)
    const [statusError, setStatusError] = useState<string>('')
    const [driverName, setDriverName] = useState<string>('')
    const [driverId, setDriverId] = useState<string>('')
    const [driverIdError, setDriverIdError] = useState<string>('')
    const [cleanerName, setCleanerName] = useState<string>('')
    const [cleanerId, setCleanerId] = useState<string>('')
    const [cleanerIdError, setCleanerIdError] = useState<string>('')
    const [bookingDate, setBookingDate] = useState<string>('')
    const [bookingDateError, setBookingDateError] = useState<string>('')

    const [cname, setCname] = useState<string>('')
    const [cnameError, setCnameError] = useState<string>('')
    const [caddress, setCaddress] = useState<string>('')
    const [caddressError, setCaddressError] = useState<string>('')
    const [ccontactNumber, setCcontactNumber] = useState<string>('')
    const [ccontactNumberError, setCcontactNumberError] = useState<string>('')

    // const [date, setDate] = useState<string>(today)
    // const [dateError, setDateError] = useState<string>('')
    const [startingKm, setStartingKm] = useState<string>('')
    const [startingKmError, setStartingKmError] = useState<string>('')
    const [endingKm, setEndingKm] = useState<string>('')
    const [endingKmError, setEndingKmError] = useState<string>('')
    const [dieselInLitre, setDieselInLitre] = useState<string>('')
    const [dieselInLitreError, setDieselInLitreError] = useState<string>('')

    const resetStates = () => {
        setVehicleId('')
        setVehicleIdError('')
        setFrom('')
        setFromError('')
        setTo('')
        setToError('')
        setHiringReason(VehicleHiringReasonList.TOUR)
        setHiringReasonError('')
        setFromDate('')
        setFromDateError('')
        setToDate('')
        setToDateError('')
        setTotalDays('')
        setTotalDaysError('')
        setTotalKM('')
        setTotalKMError('')
        setChargePerKM('')
        setChargePerKMError('')
        setDriverAllowance('')
        setDriverAllowanceError('')
        setCleanerAllowance('')
        setCleanerAllowanceError('')
        setHaltingAmount('')
        setHaltingAmountError('')
        setTpAmount('')
        setTpAmountError('')
        setTotalAmount('')
        setTotalAmountError('')
        setAdvanceAmount('')
        setAdvanceAmountError('')
        setStatus(VehicleHiringStatusList.CLOSED)
        setStatusError('')
        setDriverId('')
        setDriverIdError('')
        setCleanerId('')
        setCleanerIdError('')
        setBookingDate('')
        setBookingDateError('')
        setCname('')
        setCnameError('')
        setCaddress('')
        setCaddressError('')
        setCcontactNumber('')
        setCcontactNumberError('')

        // setDate('')
        // setDateError('')
        setStartingKm('')
        setStartingKmError('')
        setEndingKm('')
        setEndingKmError('')
        setDieselInLitre('')
        setDieselInLitreError('')
    }

    const handleVehicleId: ChangeEventHandler<HTMLInputElement> = (e) => {
        setVehicleId(e.target.value)
        const index = vehicleMaster.findIndex(vm => vm.id !== undefined && vm.id.toString() === e.target.value)

        if (index > -1) {
            setVehicleName(vehicleMaster[index].name)
        }
        setVehicleIdError('')
    }

    const handleFrom: ChangeEventHandler<HTMLInputElement> = (e) => {
        setFrom(e.target.value)
        setFromError('')
    }

    const handleTo: ChangeEventHandler<HTMLInputElement> = (e) => {
        setTo(e.target.value)
        setToError('')
    }

    const handleHiringReason: ChangeEventHandler<HTMLInputElement> = (e) => {
        setHiringReason(e.target.value as VehicleHiringReason)
        setHiringReasonError('')
    }


    const handleFromDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setFromDate(e.target.value)
        setFromDateError('')
    }

    const handleToDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setToDate(e.target.value)
        setToDateError('')
    }

    const handleTotalDays: ChangeEventHandler<HTMLInputElement> = (e) => {
        setTotalDays(e.target.value)
        setTotalDaysError('')
    }

    const handleTotalKM: ChangeEventHandler<HTMLInputElement> = (e) => {
        setTotalKM(e.target.value)
        setTotalKMError('')
    }

    const handleChargePerKM: ChangeEventHandler<HTMLInputElement> = (e) => {
        setChargePerKM(e.target.value)
        setChargePerKMError('')
    }

    const handleDriverAllowance: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDriverAllowance(e.target.value)
        setDriverAllowanceError('')
    }

    const handleCleanerAllowance: ChangeEventHandler<HTMLInputElement> = (e) => {
        setCleanerAllowance(e.target.value)
        setCleanerAllowanceError('')
    }

    const handleHaltingAmount: ChangeEventHandler<HTMLInputElement> = (e) => {
        setHaltingAmount(e.target.value)
        setHaltingAmountError('')
    }

    const handleTpAmount: ChangeEventHandler<HTMLInputElement> = (e) => {
        setTpAmount(e.target.value)
        setTpAmountError('')
    }

    const handleTotalAmount: ChangeEventHandler<HTMLInputElement> = (e) => {
        setTotalAmount(e.target.value)
        setTotalAmountError('')
    }

    const handleAdvanceAmount: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAdvanceAmount(e.target.value)
        setAdvanceAmountError('')
    }

    const handleCname: ChangeEventHandler<HTMLInputElement> = (e) => {
        setCname(e.target.value)
        setCnameError('')
    }

    const handleCaddress: ChangeEventHandler<HTMLInputElement> = (e) => {
        setCaddress(e.target.value)
        setCaddressError('')
    }

    const handleCcontactNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
        setCcontactNumber(e.target.value)
        setCcontactNumberError('')
    }

    const handleDriverId: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDriverId(e.target.value)
        const index = employee.findIndex(em => em.id !== undefined && em.id.toString() === e.target.value)

        if (index > -1) {
            setDriverName(employee[index].name)
        }
        setVehicleIdError('')
    }


    const handleCleanerId: ChangeEventHandler<HTMLInputElement> = (e) => {
        setCleanerId(e.target.value)
        const index = employee.findIndex(em => em.id !== undefined && em.id.toString() === e.target.value)

        if (index > -1) {
            setCleanerName(employee[index].name)
        }
        setCleanerIdError('')
    }

    const handleBookingDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBookingDate(e.target.value)
        setBookingDateError('')
    }

    const handleStatus: ChangeEventHandler<HTMLInputElement> = (e) => {
        setStatus(e.target.value as VehicleHiringStatus)
        setStatusError('')
    }

    const handleStartingKm: ChangeEventHandler<HTMLInputElement> = (e) => {
        setStartingKm(e.target.value)
        setStartingKmError('')
    }
    const handleEndingKm: ChangeEventHandler<HTMLInputElement> = (e) => {
        setEndingKm(e.target.value)
        setEndingKmError('')
    }
    // const handleDate: ChangeEventHandler<HTMLInputElement> = (e) => {
    //     setDate(e.target.value)
    //     setDateError('')
    // }
    const handleDieselInLitre: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDieselInLitre(e.target.value)
        setDieselInLitreError('')
    }

    const handleClear: MouseEventHandler<HTMLButtonElement> = (e) => {
        resetStates()
    }

    const handleSave = () => {
        let error = false
        const data: VehicleHiringType = {
            vehicleId: parseInt(vehicleId),
            vehicleName: VehicleName,
            startPoint: from,
            endPoint: to,
            hiringReason: hiringReason,
            fromDate: fromDate,
            toDate: toDate,
            totalDays: totalDays,
            totalKM: totalKM,
            chargePerKM: chargePerKM,
            driverAllowance: driverAllowance,
            cleanerAllowance: cleanerAllowance,
            haltingAmount: haltingAmount,
            tpAmount: tpAmount,
            totalAmount: totalAmount,
            advanceAmount: advanceAmount,
            customer: {
                name: cname,
                contactNumber: ccontactNumber,
                address: caddress
            },
            status: status,
            driverName: driverName,
            driverAssigned: parseInt(driverId),
            cleanerName: cleanerName,
            cleanerAssigned: parseInt(cleanerId),
            bookingDate: bookingDate,
            //vr
            startingKm: startingKm,
            endingKm: endingKm,
            diesel: dieselInLitre,
        }

        if (data.vehicleId !== undefined && isNaN(data.vehicleId)) {
            setVehicleIdError('Vehicle Name required')
            error = true
        } else if (data.vehicleId !== undefined && data.vehicleId <= 0) {
            setVehicleIdError('Vehicle Name required')
            error = true
        }

        if (data.driverAssigned !== undefined && isNaN(data.driverAssigned)) {
            setDriverIdError('Driver Name required')
            error = true
        } else if (data.driverAssigned !== undefined && data.driverAssigned <= 0) {
            setDriverIdError('Invalid Driver Name')
            error = true
        }

        if (data.cleanerAssigned !== undefined && isNaN(data.cleanerAssigned)) {
            setCleanerIdError('Cleaner Name required')
            error = true
        } else if (data.cleanerAssigned !== undefined && data.cleanerAssigned <= 0) {
            setCleanerIdError('Invalid Cleaner Name')
            error = true
        }

        if (data.startPoint === "") {
            setFromError('From required')
            error = true
        } else if ((data.startPoint).length > 30) {
            setFromError("From must be within 30 characters")
            error = true
        }

        if (data.endPoint === "") {
            setToError('To required')
            error = true
        } else if ((data.endPoint).length > 30) {
            setToError("To must be within 30 characters")
            error = true
        }

        // if (data.hiringReason === "") {
        //     setHiringReasonError('Hiring Reason required')
        //     error = true
        // } else 
        if ([VehicleHiringReasonList.EXCURSION, VehicleHiringReasonList.MARRIAGE, VehicleHiringReasonList.MEETINGS, VehicleHiringReasonList.TOUR].indexOf(data.hiringReason as VehicleHiringReason) === -1) {
            setHiringReasonError('Hiring Reason must be EXCURSION/MARRIAGE/MEETINGS/TOUR')
            error = true
        }

        if (data.fromDate === "") {
            setFromDateError('From Date required')
            error = true
        } else if ((data.fromDate).length > 10) {
            setFromDateError('Invaild From Date')
            error = true
        }

        if (data.toDate === "") {
            setToDateError('To Date required')
            error = true
        } else if ((data.toDate).length > 10) {
            setToDateError('Invaild To Date')
            error = true
        }

        if (data.totalDays === "") {
            setTotalDaysError('Total Days required')
            error = true
        } else if (parseFloat(data.totalDays) < 0) {
            setTotalDaysError('Invalid Total Days')
            error = true
        }

        if (data.totalKM === "") {
            setTotalKMError('Total KM required')
            error = true
        } else if (parseFloat(data.totalKM) < 0) {
            setTotalKMError('Invalid Total KM')
            error = true
        }

        if (data.chargePerKM !== "" && data.chargePerKM !== undefined) {
            if (parseFloat(data.chargePerKM) < 0) {
                setChargePerKMError('Invalid Charge Per KM')
                error = true
            }
        }

        if (data.driverAllowance === "") {
            setDriverAllowanceError('Driver Allowance required')
            error = true
        } else if (parseFloat(data.driverAllowance) < 0) {
            setDriverAllowanceError('Invalid Driver Allowance')
            error = true
        }

        if (data.cleanerAllowance === "") {
            setCleanerAllowanceError('Cleaner Allowance required')
            error = true
        } else if (parseFloat(data.cleanerAllowance) < 0) {
            setCleanerAllowanceError('Invalid Cleaner Allowance')
            error = true
        }

        if (data.haltingAmount !== undefined && parseFloat(data.haltingAmount) < 0) {
            setHaltingAmountError('Invalid Halting Amount')
            error = true
        }

        if (data.tpAmount !== undefined && parseFloat(data.tpAmount) < 0) {
            setTpAmountError('Invalid Temporary Permit Amount')
            error = true
        }

        if (data.advanceAmount !== "" && data.advanceAmount !== undefined) {
            if (parseFloat(data.advanceAmount) < 0) {
                setAdvanceAmountError('Invalid Advance Amount')
                error = true
            }
        }

        if (data.totalAmount === "") {
            setTotalAmountError('Total Amount required')
            error = true
        } else if (parseFloat(data.totalAmount) < 0) {
            setTotalAmountError('Invalid Total Amount')
            error = true
        }

        if (data.customer !== undefined) {
            if ((data.customer.name).length > 30) {
                setCnameError('Customer Name must be within 30 characters')
                error = true
            }
        }

        // if (data.customer.contactNumber === "") {
        //     setCcontactNumberError('Customer contactNumber required')
        //     error = true
        // }

        if (data.customer !== undefined) {
            if ((data.customer.address).length > 100) {
                setCaddressError('Customer address must be within 100 characters')
                error = true
            }
        }

        // if (data.status === "") {
        //     setStatusError('Status required')
        //     error = true
        // } else
        if ([VehicleHiringStatusList.CLOSED, VehicleHiringStatusList.CONFIRMED, VehicleHiringStatusList.REQUESTED, VehicleHiringStatusList.STARTED].indexOf(data.status as VehicleHiringStatus) === -1) {
            setStatusError('Hiring Status must be REQUESTED/CONFIRMED/STARTED/CLOSED')
            error = true
        }

        if (data.bookingDate === "") {
            setBookingDateError('Booking Date required')
            error = true
        } else if ((data.bookingDate).length > 10) {
            setBookingDateError('Invaild Booking Date')
            error = true
        }

        if (data.startingKm !== undefined && parseInt(data.startingKm) < 0) {
            setStartingKmError('Invaild Starting Km')
            error = true
        }
        if (data.endingKm !== undefined && parseInt(data.endingKm) < 0) {
            setEndingKmError('Invaild Ending Km')
            error = true
        }
        if (data.diesel !== undefined && parseFloat(data.diesel) < 0) {
            setDieselInLitreError('Invaild Diesel')
            error = true
        }



        if (!error) {
            if (formType === FormTypeList.ADD) {

                dispatch(addVehicleHiring(data)).then(text => {
                    toast(text)
                    onSave()
                    vehicleHiringFilter()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            } else if (formType === FormTypeList.UPDATE && editData !== undefined && editData.id !== undefined) {
                dispatch(updateVehicleHiring(data, editData.id)).then(text => {
                    toast(text)
                    onSave()
                    vehicleHiringFilter()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
        }
    }

    useEffect(() => {
        if (formType === FormTypeList.UPDATE && editData !== undefined) {

            setVehicleName(editData.vehicleName)
            setVehicleId(editData.vehicleId ? editData.vehicleId.toString() : '')
            setFrom(editData.startPoint)
            setTo(editData.endPoint)
            setHiringReason(editData.hiringReason)
            setFromDate(editData.fromDate)
            setToDate(editData.toDate)
            setTotalDays(editData.totalDays)
            setTotalKM(editData.totalKM)
            setChargePerKM(editData.chargePerKM ? editData.chargePerKM : '')
            setDriverAllowance(editData.driverAllowance)
            setCleanerAllowance(editData.cleanerAllowance)
            setHaltingAmount(editData.haltingAmount ? editData.haltingAmount : '')
            setTpAmount(editData.tpAmount ? editData.tpAmount : '')
            setTotalAmount(editData.totalAmount)
            setAdvanceAmount(editData.advanceAmount ? editData.advanceAmount : '')
            setStatus(editData.status)
            setDriverId(editData.driverAssigned ? editData.driverAssigned.toString() : '')
            setDriverName(editData.driverName)
            setCleanerId(editData.cleanerAssigned ? editData.cleanerAssigned.toString() : '')
            setCleanerName(editData.cleanerName)
            setBookingDate(editData.bookingDate)
            setCname(editData.customer ? editData.customer.name : '')
            setCaddress(editData.customer ? editData.customer.address : '')
            setCcontactNumber(editData.customer ? editData.customer.contactNumber : '')

            setStartingKm(editData.startingKm ? editData.startingKm : '')
            setEndingKm(editData.endingKm ? editData.endingKm : '')
            setDieselInLitre(editData.diesel ? editData.diesel : '')
        } else {
            resetStates()
        }
    }, [formType, editData])

    useEffect(() => {
        if (formType === FormTypeList.UPDATE && editData !== undefined) {
            api.get<APIResponse<VehicleHiringType>>(`vehicle_hiring/?id=${editData.id}`, config).then(response => {

                if (response.status === 200 && response.data.data) {

                    const result = response.data.data
                    setStartingKm(result.startingKm ? result.startingKm.toString() : '')
                    setEndingKm(result.endingKm ? result.endingKm.toString() : '')
                    setDieselInLitre(result.diesel ? result.diesel.toString() : '')
                }
            }).catch(error => {

            })
        }
    }, [editData])

    return <div className='container'>
        <div className="row">

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Vehicle'
                    labelClassName="required"
                    value={vehicleId}
                    onChange={handleVehicleId}
                    type='select'
                    errorText={vehicleIdError}
                >
                    <Option value=''>Select</Option>
                    {vehicleMaster.map(vm => {
                        return <Option value={vm.id} key={vm.key}>{vm.name}</Option>
                    })}
                </FormInput>
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='From'
                    labelClassName="required"
                    value={from}
                    onChange={handleFrom}
                    placeholder='From'
                    errorText={fromError}
                    containerClass="mb-2"
                />
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='To'
                    labelClassName="required"
                    value={to}
                    onChange={handleTo}
                    placeholder='To'
                    errorText={toError}
                    containerClass="mb-2"
                />
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Hiring Reason'
                    labelClassName="required"
                    value={hiringReason}
                    onChange={handleHiringReason}
                    errorText={hiringReasonError}
                    type='select'
                    containerClass="mb-2"
                >
                    <Option value=''>Select</Option>
                    {HIRING_REASON.map(hr => {
                        return <Option value={hr.value} key={hr.key}>{hr.text}</Option>
                    })}
                </FormInput>
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='From Date'
                    labelClassName="required"
                    value={fromDate}
                    onChange={handleFromDate}
                    placeholder='From Date'
                    errorText={fromDateError}
                    type='date'
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='To Date'
                    labelClassName="required"
                    value={toDate}
                    onChange={handleToDate}
                    placeholder='To Date'
                    errorText={toDateError}
                    type='date'
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Total Days'
                    labelClassName="required"
                    value={totalDays}
                    onChange={handleTotalDays}
                    placeholder='Total No.Of.Days'
                    errorText={totalDaysError}
                    type='number'
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Total km'
                    labelClassName="required"
                    value={totalKM}
                    onChange={handleTotalKM}
                    placeholder='Total km'
                    errorText={totalKMError}
                    type='number'
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Charge Per KM'
                    // labelClassName="required"
                    value={chargePerKM}
                    onChange={handleChargePerKM}
                    placeholder='ChargePerKM'
                    errorText={chargePerKMError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Driver Allowance'
                    labelClassName="required"
                    value={driverAllowance}
                    onChange={handleDriverAllowance}
                    placeholder='Driver Allowance'
                    errorText={driverAllowanceError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Cleaner Allowance'
                    labelClassName="required"
                    value={cleanerAllowance}
                    onChange={handleCleanerAllowance}
                    placeholder='Cleaner Allowance'
                    errorText={cleanerAllowanceError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Halting Amount'
                    value={haltingAmount}
                    onChange={handleHaltingAmount}
                    placeholder='Halting Amount'
                    errorText={haltingAmountError}
                    containerClass="mb-2"
                />
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Temporary Permit Amount'
                    value={tpAmount}
                    onChange={handleTpAmount}
                    placeholder='Temporary Permit Amount'
                    errorText={tpAmountError}
                    containerClass="mb-2"
                />
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Total Amount'
                    labelClassName="required"
                    value={totalAmount}
                    onChange={handleTotalAmount}
                    placeholder='Total Amount'
                    errorText={totalAmountError}
                    containerClass="mb-2"
                />
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Advance Amount'
                    // labelClassName="required"
                    value={advanceAmount}
                    onChange={handleAdvanceAmount}
                    placeholder='Advance Amount'
                    errorText={advanceAmountError}
                    containerClass="mb-2"
                />
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Customer Name'
                    // labelClassName="required"
                    value={cname}
                    onChange={handleCname}
                    errorText={cnameError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Customer Address'
                    // labelClassName="required"
                    value={caddress}
                    onChange={handleCaddress}
                    errorText={caddressError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Customer Contact.No'
                    // labelClassName="required"
                    value={ccontactNumber}
                    onChange={handleCcontactNumber}
                    errorText={ccontactNumberError}
                    containerClass="mb-2"
                />
            </div>

            <div hidden className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Status'
                    labelClassName="required"
                    value={status}
                    onChange={handleStatus}
                    errorText={statusError}
                    type='select'
                    containerClass="mb-2"
                >
                    <Option value='' >Select</Option>
                    {HIRING_STATUS.map(hs => {
                        return <Option value={hs.value} key={hs.key}>{hs.text}</Option>
                    })}
                </FormInput>
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Driver'
                    labelClassName="required"
                    value={driverId}
                    onChange={handleDriverId}
                    errorText={driverIdError}
                    type='select'
                    containerClass="mb-2"
                >
                    <Option value='' >Select</Option>
                    {employee.filter(em => {
                        return em.type === EmployeeTypeList.DRIVER
                    }).map(e => {
                        return <Option value={e.id} key={e.key}>{e.name}</Option>
                    })
                    }
                </FormInput>
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Cleaner'
                    labelClassName="required"
                    value={cleanerId}
                    onChange={handleCleanerId}
                    errorText={cleanerIdError}
                    type='select'
                    containerClass="mb-2"
                >
                    <Option value='' >Select</Option>
                    {employee.filter(em => {
                        return em.type === EmployeeTypeList.CLEANER
                    }).map(e => {
                        return <Option value={e.id} key={e.key}>{e.name}</Option>
                    })}
                </FormInput>
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Booking Date'
                    labelClassName="required"
                    value={bookingDate}
                    onChange={handleBookingDate}
                    errorText={bookingDateError}
                    placeholder='Booking Date'
                    containerClass="mb-2"
                    type="date"
                />
            </div>

        </div>

        <div className="row">

            {/* <div className="col-lg-4 col-md-4 col-sm-6">
                <FormInput
                    label='Date'
                    value={date}
                    onChange={handleDate}
                    placeholder="Date"
                    errorText={dateError}
                    containerClass="mb-2"
                    type="date"
                />
            </div> */}
            <div className="col-lg-3 col-md-4 col-sm-6">
                <FormInput
                    label='Starting KM'
                    value={startingKm}
                    onChange={handleStartingKm}
                    placeholder="Starting KM"
                    errorText={startingKmError}
                    containerClass="mb-2"
                />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
                <FormInput
                    label='Ending Km'
                    value={endingKm}
                    onChange={handleEndingKm}
                    placeholder="Ending Km"
                    errorText={endingKmError}
                    containerClass="mb-2"
                />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
                <FormInput
                    label='Diesel(ltr)'
                    value={dieselInLitre}
                    onChange={handleDieselInLitre}
                    placeholder="Diesel In Litre"
                    errorText={dieselInLitreError}
                    containerClass="mb-2"
                />
            </div>
        </div>

        <div className="d-flex justify-content-end hstack gap-1">
            <button className="btn btn-primary" onClick={handleClear}>Clear</button>
            <button className="btn btn-success" onClick={handleSave}>Save</button>
        </div>

    </div >
}
export default VehicleHiringForm