import React, { ChangeEventHandler, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { addKey } from "../config/functions"
import { DocumentMasterType } from "../redux/actions/documentMasterActions"
import { Key } from "../redux/actions/types"
import { addVehicleDocument, updateVehicleDocument, VehicleDocumentType } from "../redux/actions/vehicleDocumentActions"
import { VehicleMasterType } from "../redux/actions/vehicleMasterActions"
import { StoreState } from "../redux/reducers"
import { AppDispatch } from "../redux/store"
import { FormInput } from "./custom/components"
import Option from "./Option"
import { ToastTypes, useToast } from "./toast/ToastProvider"
import { FormType, FormTypeList } from "./types"

interface Props {
    formType: FormType
    editData?: VehicleDocumentType
    onSave: () => void
}

// {
//     id:PK,
//     vehicleId:FK,
//     vehicleName:FK,
//     documentTypeCode:FK,
//     registeredDate:FT,
//     validityMonths:FT,
//     expiryDate:FT,
//     renewalAmount:FT
//     }

const VehicleDocumentForm = ({ formType, onSave, editData }: Props) => {
    const toast = useToast()

    const dispatch = useDispatch<AppDispatch>()
    const vehicleMaster = useSelector<StoreState, Array<VehicleMasterType & Key>>(state => addKey(state.vehicleMaster))
    const documentMaster = useSelector<StoreState, Array<DocumentMasterType & Key>>(state => addKey(state.documentMaster))

    const [documentCode, setDocumentCode] = useState<string>('')
    const [documentCodeError, setDocumentCodeError] = useState<string>('')
    const [registeredDate, setRegisteredDate] = useState<string>('')
    const [registeredDateError, setRegisteredDateError] = useState<string>('')
    const [expiryDate, setExpiryDate] = useState<string>('')
    const [expiryDateError, setExpiryDateError] = useState<string>('')
    const [vehicleName, setVehicleName] = useState<string>('')
    const [vehicleId, setVehicleId] = useState<string>('')
    const [vehicleIdError, setVehicleIdError] = useState<string>('')
    const [validityMonths, setValidityMonths] = useState<string>('')
    const [validityMonthsError, setValidityMonthsError] = useState<string>('')
    const [renewalAmount, setRenewalAmount] = useState<string>('')
    const [renewalAmountError, setRenewalAmountError] = useState<string>('')
    const [attachment, setAttachment] = useState<File>()
    const [attachmentError, setAttachmentError] = useState<string>('')

    const resetStates = () => {
        setDocumentCode('')
        setDocumentCodeError('')
        setRegisteredDate('')
        setRegisteredDateError('')
        setExpiryDate('')
        setExpiryDateError('')
        setVehicleName('')
        setVehicleId('')
        setVehicleIdError('')
        setValidityMonths('')
        setValidityMonthsError('')
        setRenewalAmount('')
        setRenewalAmountError('')
        // setAttachment('')
        setAttachmentError('')
    }

    const handleDocumentCode: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDocumentCode(e.target.value)
        setDocumentCodeError('')
    }

    const handleRegisteredDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setRegisteredDate(e.target.value)
        setRegisteredDateError('')
    }

    const handleExpiryDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setExpiryDate(e.target.value)
        setExpiryDateError('')
    }

    const handleValidityMonths: ChangeEventHandler<HTMLInputElement> = (e) => {
        setValidityMonths(e.target.value)
        setValidityMonthsError('')
    }

    const handleRenewalAmount: ChangeEventHandler<HTMLInputElement> = (e) => {
        setRenewalAmount(e.target.value)
        setRenewalAmountError('')
    }

    const handleVehicleId: ChangeEventHandler<HTMLInputElement> = (e) => {
        setVehicleId(e.target.value)
        const index = vehicleMaster.findIndex(vm => vm.id !== undefined && vm.id.toString() === e.target.value)

        if (index > -1) {
            setVehicleName(vehicleMaster[index].name)
        }
        setVehicleIdError('')
    }

    const handleAttachment: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAttachmentError('')
        if (e.target.files !== null) {
            const attach = e.target.files[0]
            if (!attach) {
                setAttachmentError("You've selected nothing");
            } else if (['application/pdf'].indexOf(attach.type) === -1) {
                setAttachmentError('File type must be PDF only');
            } else if (attach.size / 1024 / 1024 > 2) {
                setAttachmentError('File Size is too large');
            } else {
                setAttachment(attach)
            }
        } else {
            setAttachmentError("You've selected nothing")
        }
    }
    const handleClear = () => {
        resetStates()
    }

    const handleSave = () => {
        let error = false
        const data: VehicleDocumentType = {
            vehicleId: parseInt(vehicleId),
            vehicleName: vehicleName,
            registeredDate: registeredDate,
            expiryDate: expiryDate,
            documentCode: documentCode,
            renewalAmount: renewalAmount,
            attachmentUrl: attachment,
            validityMonths: validityMonths
        }

        if (data.documentCode === "") {
            setDocumentCodeError(' Document Code required')
            error = true
        }
        if (data.registeredDate === "") {
            setRegisteredDateError('Registered Date required')
            error = true
        }

        if (data.expiryDate === "") {
            setExpiryDateError('Expiry Date required')
            error = true
        }

        if (data.renewalAmount !== "" && data.renewalAmount !== undefined) {
            if (parseInt(data.renewalAmount) < 0) {
                setRenewalAmountError('Invalid Renewal Amount')
                error = true
            }
        }

        if (data.validityMonths === "") {
            setValidityMonthsError('Validity Years required')
            error = true
        } else if (parseInt(data.validityMonths) < 0) {
            setValidityMonthsError('Invalid Validity Years')
            error = true
        }

        if (isNaN(data.vehicleId)) {
            setVehicleIdError(' Invalid Vehicle ')
            error = true
        } else if (data.vehicleId <= 0) {
            setVehicleIdError('Invalid Vehicle')
            error = true
        }

        if (formType === FormTypeList.ADD && data.attachmentUrl !== undefined) {
            // if (data.attachmentUrl === '') {
            //     setAttachmentError('Select an image');
            //     error = true
            // } else
            if (['application/pdf'].indexOf(data.attachmentUrl.type) === -1) {
                setAttachmentError('File type must be PDF only');
                error = true
            } else if (data.attachmentUrl.size / 1024 / 1024 > 2) {
                setAttachmentError('File size is too large');
                error = true
            }
        }


        if (!error) {
            const formData = new FormData()
            formData.append('vehicleId', data.vehicleId.toString())
            formData.append('validityMonths', data.validityMonths)
            formData.append('documentCode', data.documentCode)
            formData.append('expiryDate', data.expiryDate)
            formData.append('registeredDate', data.registeredDate)
            formData.append('renewalAmount', data.renewalAmount !== undefined ? data.renewalAmount : '')
            formData.append('attachmentUrl', data.attachmentUrl as Blob)

            if (formType === FormTypeList.ADD) {
                dispatch(addVehicleDocument(formData)).then(text => {
                    toast(text)
                    onSave()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            } else if (formType === FormTypeList.UPDATE && editData !== undefined && editData.id !== undefined) {
                dispatch(updateVehicleDocument(formData, editData.id)).then(text => {
                    toast(text)
                    onSave()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
        }
    }


    useEffect(() => {
        if (formType === FormTypeList.UPDATE && editData !== undefined) {

            setVehicleName(editData.vehicleName)
            setVehicleId(editData.vehicleId !== undefined ? editData.vehicleId.toString() : '')
            setDocumentCode(editData.documentCode)
            setRegisteredDate(editData.registeredDate)
            setValidityMonths(editData.validityMonths)
            setExpiryDate(editData.expiryDate)
            setRenewalAmount(editData.renewalAmount !== undefined ? editData.renewalAmount : '')

        } else {
            resetStates()
        }
    }, [formType, editData])


    return <div className='container'>
        <div className="row">

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Vehicle'
                    labelClassName="required"
                    value={vehicleId}
                    onChange={handleVehicleId}
                    type='select'
                    errorText={vehicleIdError}
                >
                    <option value="">Select</option>
                    {vehicleMaster.map(vm => {
                        return <Option value={vm.id} key={vm.key}>{vm.name}</Option>
                    })}
                </FormInput>
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Document Code'
                    labelClassName="required"
                    value={documentCode}
                    onChange={handleDocumentCode}
                    errorText={documentCodeError}
                    type='select'
                // containerClass="mb-2"
                >
                    <option value="">Select</option>
                    {documentMaster.map(dm => {
                        return <Option value={dm.code} key={dm.key}>{dm.code}</Option>
                    })}
                </FormInput>
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Registered Date'
                    labelClassName="required"
                    value={registeredDate}
                    onChange={handleRegisteredDate}
                    placeholder='Registered Date'
                    errorText={registeredDateError}
                    type="Date"
                    containerClass="mb-2"

                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Validity Months'
                    labelClassName="required"
                    value={validityMonths}
                    onChange={handleValidityMonths}
                    placeholder='Validity Months'
                    errorText={validityMonthsError}
                    type="number"
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Expiry Date'
                    labelClassName="required"
                    value={expiryDate}
                    onChange={handleExpiryDate}
                    placeholder='Expiry Date'
                    errorText={expiryDateError}
                    type="Date"
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Renewal Amount'
                    value={renewalAmount}
                    onChange={handleRenewalAmount}
                    placeholder='Renewal Amount'
                    errorText={renewalAmountError}
                    type="number"
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Attachment'
                    onChange={handleAttachment}
                    placeholder='Attachment'
                    errorText={attachmentError}
                    type="file"
                    containerClass="mb-2"
                />
            </div>

        </div>
        <div className="d-flex justify-content-end hstack gap-1">
            <button className="btn btn-primary" onClick={handleClear}>Clear</button>
            <button className="btn btn-success" onClick={handleSave}>Save</button>
        </div>

    </div>
}
export default VehicleDocumentForm