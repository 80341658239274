export enum FormTypeList {
    ADD = "ADD",
    UPDATE = "UPDATE"
}

export type FormType = FormTypeList.ADD | FormTypeList.UPDATE

export enum FromList {
    REPORT = "REPORT",
    TRANSACTION = "TRANSACTION"
}

export type FromTypeForTransaction = FromList.REPORT | FromList.TRANSACTION