/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
// import { default as rtoMasterData } from '../dataSet/rtoMaster.json'
import { GetState } from "../reducers"

export enum RtoMasterActionList {
    ADD_RTO_MASTER = 'ADD_RTO_MASTER',
    FETCH_RTO_MASTER = 'FETCH_RTO_MASTER',
    UPDATE_RTO_MASTER = 'UPDATE_RTO_MASTER',
    DELETE_RTO_MASTER = 'DELETE_RTO_MASTER',
}

export interface RtoMasterType {

    id?: number
    code?: string
    name: string
    area: string
    createdBy?: string
    createTimestamp?: string
}

export interface AddRtoMasterAction {
    type: RtoMasterActionList.ADD_RTO_MASTER
    data: RtoMasterType
}

export interface FetchRtoMasterAction {
    type: RtoMasterActionList.FETCH_RTO_MASTER
    data: Array<RtoMasterType>
}

export interface UpdateRtoMasterAction {
    type: RtoMasterActionList.UPDATE_RTO_MASTER
    data: RtoMasterType
}

export interface DeleteRtoMasterAction {
    type: RtoMasterActionList.DELETE_RTO_MASTER
    data: number
}

export type RtoMasterActions = AddRtoMasterAction | FetchRtoMasterAction | UpdateRtoMasterAction | DeleteRtoMasterAction

export const addRtoMaster = (data: RtoMasterType) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("RTO Master Added")
        return api.post<APIResponse<RtoMasterType>>('rto_master/', data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<AddRtoMasterAction>({
                    type: RtoMasterActionList.ADD_RTO_MASTER,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'RTO Master Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const updateRtoMaster = (data: RtoMasterType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("RTO Master Updated")

        return api.put<APIResponse<RtoMasterType>>(`rto_master/?id=${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<UpdateRtoMasterAction>({
                    type: RtoMasterActionList.UPDATE_RTO_MASTER,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'RTO Master Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchRtoMaster = () => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<RtoMasterType[]>>('rto_master/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchRtoMasterAction>({
                    type: RtoMasterActionList.FETCH_RTO_MASTER,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteRtoMaster = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("RTO Master Deleted")
        return api.delete<APIResponse<RtoMasterType>>(`rto_master/?id=${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteRtoMasterAction>({
                    type: RtoMasterActionList.DELETE_RTO_MASTER,
                    data: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'RTO Master Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}

