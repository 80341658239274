import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { ChangeEventHandler, useEffect, useMemo, useState } from 'react'
import * as Feather from 'react-feather'
import { useSelector } from 'react-redux'
import { FormInput } from '../components/custom/components'
import PageTitle from '../components/custom/components/PageTitle'
import VerticalLayout from '../components/custom/layouts/Vertical'
import Option from '../components/Option'
import { useToast } from '../components/toast/ToastProvider'
import api, { APIResponse, getAxiosRequestConfig } from '../config/api'
import { addKey, currencyFormat, LocalFormatDate, rupeeFormat, today, toYMD } from '../config/functions'
import { AccountHolderType } from '../redux/actions/accountHolderActions'
import { AuthUserType } from '../redux/actions/authUserActions'
import { TransactionType } from '../redux/actions/transactionActions'
import { Key } from '../redux/actions/types'
import { StoreState } from '../redux/reducers'

interface openBalance {
    openingBalance: String
}

interface TableBody {
    sno: number
    // accountNumber: string
    // accountName: string
    // accountType: string
    date: string
    particulars: string
    accountName: string
    income: string
    expense: string
    balance?: string
    openingBalance?: string
}

const DayBookReport = () => {

    const toast = useToast()
    const authUser = useSelector<StoreState, AuthUserType>(state => state.authUser)
    const token = authUser.token!
    const config = getAxiosRequestConfig(token)

    const accountHolder = useSelector<StoreState, Array<AccountHolderType & Key>>(state => addKey(state.accountHolder))

    // const [date, setDate] = useState<string>(today())
    const [fromDate, setFromDate] = useState<string>(today())
    const [toDate, setToDate] = useState<string>(today())
    const [accountNumber, setAccountNumber] = useState<string>('')
    const [accountName, setAccountName] = useState<string>('')

    const [dayBookTransaction, setDayBookTransaction] = useState<TransactionType[]>([])
    const [data2, setData2] = useState<TransactionType[]>([])

    const [openingBalance, setOpeningBalance] = useState<string>('0')

    const BankList = useMemo<Array<AccountHolderType & Key>>(() => {
        return accountHolder.filter(ah => {
            return ah.accountName.substring(0, 6) === 'BANK -'
        })
    }, [accountHolder])

    const handleFromDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setFromDate(e.target.value)
    }

    const handleToDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setToDate(e.target.value)
    }

    // const handleDate: ChangeEventHandler<HTMLInputElement> = (e) => {
    //     setDate(e.target.value)
    // }

    const handleAccountNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAccountNumber(e.target.value)
    }

    const sortByDate = (ad: any, bd: any) => {
        let a1 = toYMD(ad.date);
        let b1 = toYMD(bd.date);

        let a = new Date(a1).getTime();
        let b = new Date(b1).getTime();

        return a - b;
    }

    const groupBy = (xs: any[], key: string) => {
        return xs.reduce((rv, x) => {
            (rv[x[key]] = rv[x[key]] || []).push(x)
            return rv
        }, {})
    }

    const dayBook2 = useMemo<TransactionType[]>(() => {
        const data = dayBookTransaction.filter(ah => {
            return ah.source === 'Collection'
        })
        return data
    }, [dayBookTransaction])

    const finalDaybookData = useMemo<TransactionType[]>(() => {
        const db = [...data2,
        ...dayBookTransaction.filter(ah => {
            return ah.source !== 'Collection'
        })]
        // console.log(db.map(m => ({ timstamp: new Date(m.date).getTime(), date: m.date })));

        db.sort(sortByDate);

        return db
    }, [dayBookTransaction, data2])

    const totalIncome = useMemo<number>(() => {
        return finalDaybookData.filter(bl => {
            return bl.accountGroupName === 'accounting'
        }).reduce((previous, current) => {
            return previous + parseFloat(current.debitAmount)
        }, 0)

    }, [finalDaybookData])

    const totalExpense = useMemo<number>(() => {
        return finalDaybookData.filter(bl => {
            return bl.accountGroupName === 'accounting'
        }).reduce((previous, current) => {
            return previous + parseFloat(current.creditAmount)
        }, 0)

    }, [finalDaybookData])

    const totalBalance = useMemo<number>(() => {
        return parseFloat(openingBalance) + totalIncome - totalExpense
    }, [totalIncome, totalExpense, openingBalance])

    const totalIncomeWithOpeningBalance = useMemo<number>(() => {
        return parseFloat(openingBalance) + totalIncome
    }, [totalIncome, openingBalance])

    const handleDownloadPDF = () => {
        const doc = new jsPDF('l', 'mm', 'a4')

        // doc.addFont('Times-Bold', 'Times', 'bold')
        // doc.setFont('Times')
        doc.setFontSize(18)
        doc.text('SUPREME  BUS  SERVICE', 100, 13)

        doc.setFontSize(14)
        // doc.text('Transaction Date :- ' + date, 15, 26)
        doc.text('Transaction Date :- ', 15, 25)
        doc.text('From Date: ' + LocalFormatDate(fromDate), 65, 25)
        doc.text('To Date: ' + LocalFormatDate(toDate), 155, 25)
        doc.text('Account Name : ' + accountName, 15, 33)

        // doc.text('Total', 15, 35)
        const TotalHead = [['Opening Balance', 'Total Income', 'Total Expense', 'Total Balance']]
        const TotalBody = [[rupeeFormat(openingBalance), rupeeFormat(totalIncome), rupeeFormat(totalExpense), rupeeFormat(totalBalance)]]
        autoTable(doc, {
            head: TotalHead,
            body: TotalBody,
            startY: 37,
            didDrawPage: function () {

                // // Header
                var str = doc.getNumberOfPages();
                doc.setFontSize(10);
                // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                // var pageSize = doc.internal.pageSize;
                // var pageHeight = pageSize.height
                //     ? pageSize.height
                //     : pageSize.getHeight();
                doc.text(str.toString(), 260, 10);
            }
        })

        // doc.setFontSize(16)
        // doc.text('Opening Balance: ', 15, (doc as any).lastAutoTable.finalY + 16)
        // doc.text('Rs.' + rupeeFormat(openingBalance), 61, (doc as any).lastAutoTable.finalY + 16)

        const head = [['S.No', 'Date', 'Particulars', 'Income', ' Expense', 'Balance']]

        // const foot = [['Total', '', '', totalIncome, totalExpense, totalBalance]]

        let balance = Number(openingBalance)
        // doc.addFont('Times-Bold', 'Times', 'bold')
        // doc.setFont('Times')
        // doc.setFontSize(14)
        // doc.setTextColor("black");
        const body = [['1', '', 'Opening Balance', '', '', rupeeFormat(openingBalance)]]

        doc.setFontSize(12)
        dayBookTransaction.filter(bl => {
            return bl.accountGroupName === 'accounting'
        }).forEach((t, i) => {
            balance = (balance + Number(t.debitAmount)) - Number(t.creditAmount)

            body.push([
                String(i + 2),
                t.date,
                // String(t.accountName),
                String(t.particulars),
                String(parseFloat(t.debitAmount) !== 0 ? rupeeFormat(t.debitAmount) : ''),
                String(parseFloat(t.creditAmount) !== 0 ? rupeeFormat(t.creditAmount) : ''),
                String(rupeeFormat(balance)),
            ])
        })

        autoTable(doc, {
            head: head,
            body: body,
            // foot: foot,
            startY: (doc as any).lastAutoTable.finalY + 10,
            didDrawPage: function () {

                // // Header
                var str = doc.getNumberOfPages();
                doc.setFontSize(10);
                // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                // var pageSize = doc.internal.pageSize;
                // var pageHeight = pageSize.height
                //     ? pageSize.height
                //     : pageSize.getHeight();
                doc.text(str.toString(), 260, 10);
            }
        })

        doc.save("Daybook.pdf")
    }

    useEffect(() => {
        const d2: TransactionType[] = []
        Object.values(groupBy(dayBook2, 'sourceId') as TransactionType[][]).forEach((transaction, i) => {
            console.log(...transaction);

            var debitAmount = parseFloat(transaction[0].debitAmount) - parseFloat(transaction[1].creditAmount)

            let newObject = {
                accountGroupName: transaction[0].accountGroupName,
                accountName: transaction[0].accountName,
                accountNumber: transaction[0].accountNumber,
                accountType: transaction[0].accountType,
                branch: transaction[0].branch,
                createTimestamp: transaction[0].createTimestamp,
                createdBy: transaction[0].createdBy,
                date: transaction[0].date,
                creditAmount: '0',
                debitAmount: debitAmount.toString(),
                financialYear: transaction[0].financialYear,
                id: transaction[0].id,
                number: transaction[0].number,
                orderNumber: transaction[0].orderNumber,
                particulars: transaction[0].particulars,
                paymentMode: transaction[0].paymentMode,
                refNo: transaction[0].paymentMode,
                routeId: transaction[0].routeId,
                source: transaction[0].source,
                sourceId: transaction[0].sourceId,
                transactionFor: transaction[0].transactionFor,
                voucherType: transaction[0].voucherType
            }

            d2.push(newObject)
        })
        setData2(d2)
    }, [dayBook2])

    useEffect(() => {
        if (dayBookTransaction.length > 0) {
            setAccountName(dayBookTransaction[0].accountName)
        }
    }, [dayBookTransaction])

    useEffect(() => {
        if (fromDate !== undefined && toDate !== undefined && accountNumber !== undefined && accountNumber !== '') {
            api.get<APIResponse<TransactionType[]>>(`transaction/daybook/?from_date=${fromDate}&to_date=${toDate}&account_number=${accountNumber}`, config).then(response => {

                if (response.status === 200 && response.data.data) {

                    const result = response.data.data
                    setDayBookTransaction(result)
                }
            }).catch(error => {

            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fromDate, toDate, accountNumber])


    useEffect(() => {
        if (fromDate !== undefined && accountNumber !== '') {
            api.get<APIResponse<openBalance>>(`transaction/opening_balance/?account_no=${accountNumber}&date=${fromDate}`, config).then(response => {

                if (response.status === 200 && response.data.data) {

                    const result = response.data.data
                    // console.log(result);
                    setOpeningBalance((result.openingBalance).toString())
                }
            }).catch(error => {

            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fromDate, accountNumber])

    let balance = Number(openingBalance)

    return <>

        <VerticalLayout>
            <PageTitle
                title='Daybook'
            />

            <div className='card shadow-box-2 px-0 mx-0 mb-1 pt-1'>
                <div className='card-body ps-3 py-0'>
                    <div className='container-fluid'>
                        <div className="row">
                            {/* <div className="col-sm-6 col-md-4 col-lg-4">
                                <FormInput
                                    label='Date'
                                    labelClassName="required"
                                    value={date}
                                    onChange={handleDate}
                                    placeholder="Date"
                                    containerClass="mb-2"
                                    type="date"
                                />
                            </div> */}

                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <FormInput
                                    label='From Date'
                                    labelClassName="required"
                                    value={fromDate}
                                    onChange={handleFromDate}
                                    placeholder="From Date"
                                    containerClass="mb-2"
                                    type="date"
                                />
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <FormInput
                                    label='To Date'
                                    labelClassName="required"
                                    value={toDate}
                                    onChange={handleToDate}
                                    placeholder="To Date"
                                    containerClass="mb-2"
                                    type="date"
                                />
                            </div>

                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <FormInput
                                    value={accountNumber}
                                    labelClassName="required"
                                    onChange={handleAccountNumber}
                                    type='select'
                                    label='Account'
                                >
                                    <Option value=''>Select Account</Option>
                                    <Option value='900001'>Cash</Option>
                                    {BankList.map(b => {
                                        return <Option value={b.accountNumber} key={b.key}>{b.accountName}</Option>
                                    })}
                                </FormInput>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {dayBookTransaction.length > 0 && <div className='card shadow-box-2 p-0 mx-0 mb-1'>
                <div className='card-body px-0 pt-1 pb-0'>
                    <div className='container-fluid'>
                        <div className="row">
                            <div className="col-sm-6 col-md-3 col-lg-3">
                                <div className='rounded  p-1 m-1 yellow-box row'>
                                    <div className="col-sm-4 text-white p-1">
                                        <Feather.CheckSquare
                                            color='white'
                                            size={40}
                                        />
                                    </div>
                                    <div className="col-sm-8" >
                                        <h4 className='text-white'>Opening:<br /> {currencyFormat(openingBalance)}</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-3 col-lg-3">
                                <div className='rounded  p-1 m-1 blue-box row'>
                                    <div className="col-sm-4 text-white p-1">
                                        <Feather.PlusSquare
                                            color='white'
                                            size={40}
                                        />
                                    </div>
                                    <div className="col-sm-8" >
                                        {/* <h4 className='text-white'>Income:<br /> {currencyFormat(totalIncomeWithOpeningBalance)}</h4>
                                        <h4 className='text-warning'>{currencyFormat(totalIncome)}</h4> */}
                                        <h4 className='text-white'>Income:<br />{currencyFormat(totalIncome)}</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-3 col-lg-3">
                                <div className='rounded  p-1 m-1 pink-box row'>
                                    <div className="col-sm-4 text-white p-1">
                                        <Feather.MinusSquare
                                            color='white'
                                            size={40}
                                        />
                                    </div>
                                    <div className="col-sm-8" >
                                        <h4 className='text-white'>Expense:<br /> {currencyFormat(totalExpense)}</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-3 col-lg-3">
                                <div className='rounded  p-1 m-1 green-box row'>
                                    <div className="col-sm-4 text-white p-1">
                                        <Feather.Pocket
                                            color='white'
                                            size={40}
                                        />
                                    </div>
                                    <div className="col-sm-8" >
                                        <h4 className='text-white'>Balance:<br /> {currencyFormat(totalBalance)}</h4>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="text-center col-sm-6 col-md-3 col-lg-3">
                                <h4>Balance:</h4>
                                <h4>{rupeeFormat(totalBalance)}</h4>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>}

            {dayBookTransaction.length > 0 ? <div className="card">
                <div className="card-body">
                    <h4 className='p-0'>Account Name: {accountName}</h4>
                    <div className='table-wrapper'>
                        <table className='table colored'>
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    <th className='text-truncate align-middle'>Date</th>
                                    {/* <th className='text-truncate align-middle'>Account Name</th> */}
                                    <th className='text-truncate align-middle'>Particulars</th>
                                    <th className='text-truncate align-middle text-end'>Income</th>
                                    <th className='text-truncate align-middle text-end'>Expense</th>
                                    <th className='text-truncate align-middle text-end'>Balance</th>

                                </tr>
                            </thead>
                            <tbody>
                                {dayBookTransaction.length > 0 && <tr>
                                    <td>1</td>
                                    <td className='text-truncate'>{LocalFormatDate(fromDate)}</td>
                                    {/* <td></td> */}
                                    <td>Opening Balance</td>
                                    {/* <td className='text-end'>{parseFloat(openingBalance) !== 0 ? rupeeFormat(openingBalance) : ''}</td> */}
                                    <td></td>
                                    <td></td>
                                    <td className='text-end'>{rupeeFormat(openingBalance)}</td>
                                </tr>}
                                {
                                    finalDaybookData.filter(bl => {
                                        return bl.accountGroupName === 'accounting'
                                    }).map((t, i) => {
                                        balance = (balance + Number(t.debitAmount)) - Number(t.creditAmount)
                                        return <DayBookTransactionBody
                                            sno={i + 2}
                                            date={t.date}
                                            accountName={t.accountName}
                                            particulars={t.particulars}
                                            income={t.debitAmount}
                                            expense={t.creditAmount}
                                            balance={String(balance)}
                                            key={t.id}

                                        />
                                    })}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td style={{ textAlign: 'start' }}>
                                        <span className="text-dark fw-bold fs-5">Total</span>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td style={{ textAlign: 'end' }}>
                                        <span className="text-dark fw-bold fs-5">{rupeeFormat(totalIncome)}</span>
                                    </td>
                                    <td style={{ textAlign: 'end' }}>
                                        <span className="text-dark fw-bold fs-5">{rupeeFormat(totalExpense)}</span>
                                    </td>
                                    <td style={{ textAlign: 'end' }}>
                                        <span className="text-dark fw-bold fs-5">{rupeeFormat(totalBalance)}</span>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <div className='my-2 text-end'>
                        <button className='btn btn-primary' onClick={handleDownloadPDF}>Download PDF
                            <i className='fe-download'></i>
                        </button>
                    </div>
                </div>
            </div>
                : <div className='text-center'>
                    <label>No records found</label>
                </div>}


        </VerticalLayout>
    </>
}

const DayBookTransactionBody = ({ sno, date, particulars, accountName, income, expense, balance }: TableBody) => {

    return <tr>
        <td>{sno}</td>
        <td className='text-truncate'>{date}</td>
        {/* <td className='text-capitalize'>{accountName}</td> */}
        <td className='text-uppercase'>{particulars}</td>
        <td className='text-end'>{parseFloat(income) !== 0 ? rupeeFormat(income) : ''}</td>
        <td className='text-end'>{parseFloat(expense) !== 0 ? rupeeFormat(expense) : ''}</td>
        <td className='text-end'>{rupeeFormat(balance)}</td>

    </tr>

}

export default DayBookReport
