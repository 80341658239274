/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../reducers"

export enum VehicleHiringActionList {
    ADD_VEHICLE_HIRING = 'ADD_VEHICLE_HIRING',
    FETCH_VEHICLE_HIRING = 'FETCH_VEHICLE_HIRING',
    UPDATE_VEHICLE_HIRING = 'UPDATE_VEHICLE_HIRING',
    DELETE_VEHICLE_HIRING = 'DELETE_VEHICLE_HIRING',
}

export enum VehicleHiringReasonList {
    EXCURSION = 'Excursion',
    TOUR = 'Tour',
    MARRIAGE = 'Marriage',
    MEETINGS = 'Meetings',
}

export enum VehicleHiringStatusList {
    REQUESTED = 'Requested',
    CONFIRMED = 'Confirmed',
    STARTED = 'Started',
    CLOSED = 'Closed',
}

export type Customer = {
    name: string
    address: string
    contactNumber: string

}


export type VehicleHiringStatus = VehicleHiringStatusList.CLOSED | VehicleHiringStatusList.CONFIRMED | VehicleHiringStatusList.STARTED | VehicleHiringStatusList.REQUESTED

export type VehicleHiringReason = VehicleHiringReasonList.EXCURSION | VehicleHiringReasonList.MARRIAGE | VehicleHiringReasonList.MEETINGS | VehicleHiringReasonList.TOUR

export interface VehicleHiringType {
    // {
    //     "id": 1,
    //     "vehicleId": 1,
    //     "vehicleName": "Vehicle Name 1",
    //     "from": "Tanjore",
    //     "to": "ooty",
    //     "hiringReason": "tour",
    //     "fromDate": "2022-06-03",
    //     "toDate": "2022-06-10",
    //     "totalDays": "7",
    //     "totalKM": "400",
    //     "chargePerKM": "15",
    //     "driverAllowance": "4000",
    //     "cleanerAllowance": "3000",
    //     "haltingAmount": "",
    //     "totalAmount": "10000",
    //     "advanceAmount": "2000",
    //     "customer": {
    //         "name": "Arjun",
    //         "address": "No.5,6th street,adaiyar,chennai",
    //         "contactNumber": "6788765678"
    //     },
    //     "status": "closed",
    //     "driverAssigned": 2,
    //     "driverName": "Sriram.M",
    //     "cleanerAssigned": 6,
    //     "cleanerName": "Dinesh",
    //     "bookingDate": "2022-02-08"
    // },
    id?: number
    vehicleId: number
    vehicleName: string
    startPoint: string
    endPoint: string
    hiringReason: VehicleHiringReason // to be changed after api implemented
    fromDate: string
    toDate: string
    totalDays: string
    totalKM: string
    chargePerKM?: string
    driverAllowance: string
    cleanerAllowance: string
    haltingAmount?: string
    tpAmount?: string
    totalAmount: string
    advanceAmount?: string
    customer?: Customer
    status: VehicleHiringStatus // to be changed after api implemented
    driverAssigned: number
    driverName: string
    cleanerAssigned: number
    cleanerName: string
    bookingDate: string
    createdBy?: string
    createTimestamp?: string
    startingKm?: string
    endingKm?: string
    diesel?: string
}

export interface AddVehicleHiringAction {
    type: VehicleHiringActionList.ADD_VEHICLE_HIRING
    data: VehicleHiringType
}

export interface FetchVehicleHiringAction {
    type: VehicleHiringActionList.FETCH_VEHICLE_HIRING
    data: Array<VehicleHiringType>
}

export interface UpdateVehicleHiringAction {
    type: VehicleHiringActionList.UPDATE_VEHICLE_HIRING
    data: VehicleHiringType
}

export interface DeleteVehicleHiringAction {
    type: VehicleHiringActionList.DELETE_VEHICLE_HIRING
    data: number
}

export type VehicleHiringActions = AddVehicleHiringAction | FetchVehicleHiringAction | UpdateVehicleHiringAction | DeleteVehicleHiringAction

export const addVehicleHiring = (data: VehicleHiringType) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Vehicle Hiring Added")
        return api.post<APIResponse<VehicleHiringType>>('vehicle_hiring/', data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<AddVehicleHiringAction>({
                    type: VehicleHiringActionList.ADD_VEHICLE_HIRING,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Vehicle Hiring Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const updateVehicleHiring = (data: VehicleHiringType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)
        // return Promise.resolve("Vehicle Hiring Updated")

        return api.put<APIResponse<VehicleHiringType>>(`vehicle_hiring/?id=${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<UpdateVehicleHiringAction>({
                    type: VehicleHiringActionList.UPDATE_VEHICLE_HIRING,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Vehicle Hiring Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchVehicleHiring = () => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<VehicleHiringType[]>>('vehicle_hiring/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchVehicleHiringAction>({
                    type: VehicleHiringActionList.FETCH_VEHICLE_HIRING,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteVehicleHiring = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Vehicle Hiring Deleted")
        return api.delete<APIResponse<VehicleHiringType>>(`vehicle_hiring/?id=${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteVehicleHiringAction>({
                    type: VehicleHiringActionList.DELETE_VEHICLE_HIRING,
                    data: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Vehicle Hiring Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}

