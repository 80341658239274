/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { default as assetData } from '../dataSet/asset.json'
import { GetState } from "../reducers"

export enum AssetActionList {
    ADD_ASSET = 'ADD_ASSET',
    FETCH_ASSET = 'FETCH_ASSET',
    UPDATE_ASSET = 'UPDATE_ASSET',
    DELETE_ASSET = 'DELETE_ASSET',
}

export enum AssetTypeList {
    TYRE = 'Tyre',
    OTHERS = 'Others',
}

export enum AssetStatusList {
    AVAILABLE = 'Available',
    ASSIGNED = 'Assigned',
    RETREADED = 'Retreaded',
    SERVICE_IN_PROGRESS = 'Service In Progress',
    EXPIRED = 'Expired',

}

export type AssetStatus = AssetStatusList.ASSIGNED | AssetStatusList.AVAILABLE | AssetStatusList.EXPIRED | AssetStatusList.RETREADED | AssetStatusList.SERVICE_IN_PROGRESS

export type AssetsType = AssetTypeList.OTHERS | AssetTypeList.TYRE

export interface AssetType {
    // id:PK,
    // type: tyre/others
    // brand:FT,
    // name:FT,
    // number:FT,
    // status: Available | Assigned | Retreaded | Service in Progress | Expired
    // retreadeCount: number,
    // nextservice: FT,
    // createdBy,
    // createTimestamp 

    id?: number
    type: AssetsType
    brand: string
    name: string
    number: string
    status: AssetStatus
    retreadCount?: number
    nextService: string
    createdBy?: string
    createTimestamp?: string
}

export interface AddAssetAction {
    type: AssetActionList.ADD_ASSET
    data: AssetType
}

export interface FetchAssetAction {
    type: AssetActionList.FETCH_ASSET
    data: Array<AssetType>
}

export interface UpdateAssetAction {
    type: AssetActionList.UPDATE_ASSET
    data: AssetType
}

export interface DeleteAssetAction {
    type: AssetActionList.DELETE_ASSET
    data: number
}

export type AssetActions = AddAssetAction | FetchAssetAction | UpdateAssetAction | DeleteAssetAction

export const addAsset = (data: AssetType) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Asset Added")
        return api.post<APIResponse<AssetType>>('asset/', data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<AddAssetAction>({
                    type: AssetActionList.ADD_ASSET,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Asset Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const updateAsset = (data: AssetType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Asset Updated")
        return api.put<APIResponse<AssetType>>(`asset/?id=${id}`, data, config
        ).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<UpdateAssetAction>({
                    type: AssetActionList.UPDATE_ASSET,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Asset Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchAsset = () => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<AssetType[]>>('asset/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchAssetAction>({
                    type: AssetActionList.FETCH_ASSET,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteAsset = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Asset Deleted")
        return api.delete<APIResponse<AssetType>>(`asset/?id=${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteAssetAction>({
                    type: AssetActionList.DELETE_ASSET,
                    data: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Asset Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}

