import React, { useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import LogoDark from '../assets/images/supreme-logo.png';
import LogoLight from '../assets/images/supreme-logo.png';

interface AccountLayoutProps {
    helpText?: string;
    bottomLinks?: any;
    isCombineForm?: boolean;
    children?: any;
}

const AuthLayout = ({ helpText, bottomLinks, children, isCombineForm }: AccountLayoutProps) => {
    useEffect(() => {
        if (document.body) document.body.classList.add('authentication-bg', 'authentication-bg-pattern');

        return () => {
            if (document.body) document.body.classList.remove('authentication-bg', 'authentication-bg-pattern');
        };
    }, []);

    return (
        <>
            <div className="account-pages mt-5 mb-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={isCombineForm ? 9 : 4}>
                            <Card className="bg-pattern">
                                <Card.Body className="p-4">
                                    <div className="text-center w-75 m-auto">
                                        <div className="auth-logo">
                                            <Link to="/" className="logo logo-dark text-center">
                                                <span className="logo-lg">
                                                    <img src={LogoDark} alt="" height="50" />
                                                </span>
                                            </Link>

                                            <Link to="/" className="logo logo-light text-center">
                                                <span className="logo-lg">
                                                    <img src={LogoLight} alt="" height="50" />
                                                </span>
                                            </Link>
                                        </div>
                                        <p className="text-muted mb-4 mt-3">{helpText}</p>
                                    </div>
                                    {children}
                                </Card.Body>
                            </Card>

                            {/* bottom links */}
                            {bottomLinks}
                        </Col>
                    </Row>
                </Container>
            </div>

            <footer className="footer footer-alt">
                2015 - {new Date().getFullYear()} &copy; Supreme Bus Service{' '}
                <Link to="#" className="text-white-50">
                    Coderthemes
                </Link>
            </footer>
        </>
    );
};

export default AuthLayout;
