import { Action } from "../actions/types"
import { AssetType, AssetActionList } from "../actions/assetActions"

const initialValue: Array<AssetType> = []

const assetReducer = (state: Array<AssetType> = initialValue, action: Action) => {
    switch (action.type) {

        case AssetActionList.ADD_ASSET:
            return [
                ...state,
                action.data,
            ]

        case AssetActionList.UPDATE_ASSET:
            const updated = [...state].slice()
            const index = updated.findIndex(u => u.id === action.data.id)

            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }

            return updated

        case AssetActionList.FETCH_ASSET:
            return action.data

        case AssetActionList.DELETE_ASSET:
            return state.filter(s => s.id !== action.data)

        default:
            return state
    }
}

export default assetReducer