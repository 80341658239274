/* eslint-disable no-throw-literal */
import React, { ChangeEventHandler, createContext, MouseEventHandler, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import api, { APIResponse, errorHandler, getAxiosRequestConfig } from "../config/api";
import { addKey, isRealNumber, rupeeFormat, today } from "../config/functions";
import { AuthUserType } from "../redux/actions/authUserActions";
import { fetchCollection } from "../redux/actions/collectionActions";
import { CollectionDetailsType, CollectionTypeList } from "../redux/actions/collectionDetailsActions";
import { EmployeeType, EmployeeTypeList } from "../redux/actions/employeeActions";
import { MachineMasterType } from "../redux/actions/machineMasterActions";
import { RouteMasterType } from "../redux/actions/routeMasterActions";
import { TicketDetailsType } from "../redux/actions/ticketDetailsActions";
import { TripDetailsType } from "../redux/actions/tripDetailsActions";
import { Key } from "../redux/actions/types";
import { VehicleMasterType } from "../redux/actions/vehicleMasterActions";
import { StoreState } from "../redux/reducers";
import { AppDispatch } from "../redux/store";
import { FormInput } from "./custom/components";
import PageTitle from "./custom/components/PageTitle";
import VerticalLayout from "./custom/layouts/Vertical";
import ExpenseC1 from "./ExpenseC1";
import ExpenseC2 from "./ExpenseC2";
import Modal from "./Modal";
import Option from "./Option";
import TicketWiseCollectionC1 from "./TicketwiseCollectionC1";
import TicketWiseCollectionC2 from "./TicketwiseCollectionC2";
import { ToastTypes, useToast } from "./toast/ToastProvider";
import TripWiseCollectionC1 from "./TripwiseCollectionC1";
import TripWiseCollectionC2 from "./TripwiseCollectionC2";
import { FormType, FormTypeList } from "./types";

export interface CollectionSaveDataType {
    id?: number
    routeId: number
    routeName?: string
    vehicleId: number
    vehicleName?: string
    date: string
    //c
    invoiceNumber: string
    shortage: number
    excess: number
    balance: number
    totalIncome: number
    totalExpense: number

    //vr
    VRvehicleId?: number
    startingKm: number
    endingKm: number
    diesel: number
    driver: number
    driverName?: string
    cleaner: number
    cleanerName?: string

    spareVehicleId: number | null
    spareStartingKM: number | null
    spareEndingKM: number | null
    spareDiesel: number | null
    //coll machines
    conductor1: number
    conductorName1?: string
    conductor2?: number
    conductorName2?: string

    machineId1?: number
    machineName1?: string
    startingNumber1?: number
    endingNumber1?: number

    machineId2?: number
    machineName2?: string
    startingNumber2?: number
    endingNumber2?: number
    //cd
    collectionDetails: Array<CollectionDetailsType>
    collectionDetails2?: Array<CollectionDetailsType>
    //trip det
    tripDetails: Array<TripDetailsType>
    tripDetails2?: Array<TripDetailsType>
    //tic det
    ticketDetails: Array<TicketDetailsType>
    ticketDetails2?: Array<TicketDetailsType>

}

export interface CollectionContextType {

    routeId: string
    ticketWiseTotalForC1: number
    ticketWiseTotalForC2: number
    ticketWiseAmount1: Array<TicketDetailsType>
    setTicketWiseAmount1: React.Dispatch<React.SetStateAction<Array<TicketDetailsType>>>
    ticketWiseAmount2: Array<TicketDetailsType>
    setTicketWiseAmount2: React.Dispatch<React.SetStateAction<Array<TicketDetailsType>>>

    tripWiseAmount1: Array<TripDetailsType>
    setTripWiseAmount1: React.Dispatch<React.SetStateAction<Array<TripDetailsType>>>
    tripWiseAmount2: Array<TripDetailsType>
    setTripWiseAmount2: React.Dispatch<React.SetStateAction<Array<TripDetailsType>>>
    tripWiseTotalForC1: number
    tripWiseTotalForC2: number

    expense1Total: number
    dieselAmount1: string
    dieselAmount1Error: string
    driverAllowance1: string
    driverAllowance1Error: string
    conductorAllowance1: string
    conductorAllowance1Error: string
    driverSalary1: string
    driverSalary1Error: string
    conductorSalary1: string
    conductorSalary1Error: string
    cleanerSalary1: string
    cleanerSalary1Error: string
    incentive1: string
    incentive1Error: string
    bookingCharge1: string
    bookingCharge1Error: string
    standRent1: string
    standRent1Error: string
    others1: string
    others1Error: string
    handleDieselAmount1: ChangeEventHandler<HTMLInputElement>
    handleDriverAllowance1: ChangeEventHandler<HTMLInputElement>
    handleConductorAllowance1: ChangeEventHandler<HTMLInputElement>
    handleDriverSalary1: ChangeEventHandler<HTMLInputElement>
    handleConductorSalary1: ChangeEventHandler<HTMLInputElement>
    handleCleanerSalary1: ChangeEventHandler<HTMLInputElement>
    handleIncentive1: ChangeEventHandler<HTMLInputElement>
    handleBookingCharge1: ChangeEventHandler<HTMLInputElement>
    handleStandRent1: ChangeEventHandler<HTMLInputElement>
    handleOthers1: ChangeEventHandler<HTMLInputElement>

    expense2Total: number
    dieselAmount2?: string
    dieselAmount2Error?: string
    driverAllowance2?: string
    driverAllowance2Error?: string
    conductorAllowance2?: string
    conductorAllowance2Error?: string
    driverSalary2?: string
    driverSalary2Error?: string
    conductorSalary2?: string
    conductorSalary2Error?: string
    cleanerSalary2?: string
    cleanerSalary2Error?: string
    incentive2?: string
    incentive2Error?: string
    bookingCharge2?: string
    bookingCharge2Error?: string
    standRent2?: string
    standRent2Error?: string
    others2?: string
    others2Error?: string
    handleDieselAmount2: ChangeEventHandler<HTMLInputElement>
    handleDriverAllowance2: ChangeEventHandler<HTMLInputElement>
    handleConductorAllowance2: ChangeEventHandler<HTMLInputElement>
    handleDriverSalary2: ChangeEventHandler<HTMLInputElement>
    handleConductorSalary2: ChangeEventHandler<HTMLInputElement>
    handleCleanerSalary2: ChangeEventHandler<HTMLInputElement>
    handleIncentive2: ChangeEventHandler<HTMLInputElement>
    handleBookingCharge2: ChangeEventHandler<HTMLInputElement>
    handleStandRent2: ChangeEventHandler<HTMLInputElement>
    handleOthers2: ChangeEventHandler<HTMLInputElement>
    startingNo: string
    startingNoError: string
    endingNo: string
    endingNoError: string
    handleStartingNo: ChangeEventHandler<HTMLInputElement>
    handleEndingNo: ChangeEventHandler<HTMLInputElement>

}

export enum ExpenseDetails {
    DIESEL = 'Diesel',
    DRIVER_ALLOWANCE = 'Driver Allowance',
    CONDUCTOR_ALLOWANCE = 'Conductor Allowance',
    DRIVER_SALARY = 'Driver Salary',
    CONDUCTOR_SALARY = 'Conductor Salary',
    CLEANER_SALARY = 'Cleaner Salary',
    INCENTIVE = 'Incentive',
    BOOKING_CHARGE = 'Booking Charge',
    STAND_RENT = 'Stand Rent',
    OTHERS = 'Others'
}

interface Props {
    formType: FormType
    id?: number
}

export const CollectionContext = createContext<CollectionContextType | null>(null)

const CollectionForm = ({ formType, id }: Props) => {
    const navigate = useNavigate()

    const toast = useToast()
    const dispatch = useDispatch<AppDispatch>()
    const authUser = useSelector<StoreState, AuthUserType>(state => state.authUser)
    const token = authUser.token!
    const config = getAxiosRequestConfig(token)

    const employee = useSelector<StoreState, Array<EmployeeType & Key>>(state => addKey(state.employee))
    const machineMaster = useSelector<StoreState, Array<MachineMasterType & Key>>(state => addKey(state.machineMaster))
    const routeMaster = useSelector<StoreState, Array<RouteMasterType & Key>>(state => addKey(state.routeMaster))
    const vehicleMaster = useSelector<StoreState, Array<VehicleMasterType & Key>>(state => addKey(state.vehicleMaster))

    const [showTrip1Modal, setShowTrip1Modal] = useState<boolean>(false)
    const [showTrip2Modal, setShowTrip2Modal] = useState<boolean>(false)
    const [showTicket1Modal, setShowTicket1Modal] = useState<boolean>(false)
    const [showTicket2Modal, setShowTicket2Modal] = useState<boolean>(false)
    const [showExpense1Modal, setShowExpense1Modal] = useState<boolean>(false)
    const [showExpense2Modal, setShowExpense2Modal] = useState<boolean>(false)

    // const [collectionID, setCollectionID] = useState<string>('')

    const [invoiceNo, setInvoiceNo] = useState<string>('')
    const [invoiceNoError, setInvoiceNoError] = useState<string>('')
    const [routeId, setRouteId] = useState<string>('')
    const [routeIdError, setRouteIdError] = useState<string>('')
    const [date, setDate] = useState<string>(today())
    const [dateError, setDateError] = useState<string>('')

    const [shortage, setShortage] = useState<string>('')
    const [shortageError, setShortageError] = useState<string>('')
    const [excess, setExcess] = useState<string>('')
    const [excessError, setExcessError] = useState<string>('')
    const [balance, setBalance] = useState<string>('')
    const [balanceError, setBalanceError] = useState<string>('')

    //For collection machine
    const [conductor1, setConductor1] = useState<string>('')
    const [conductor1Error, setConductor1Error] = useState<string>('')
    const [conductor2, setConductor2] = useState<string>('')
    const [conductor2Error, setConductor2Error] = useState<string>('')

    const [machineId1, setMachineId1] = useState<string>('')
    const [machineId1Error, setMachineId1Error] = useState<string>('')
    const [startingNumber1, setStartingNumber1] = useState<string>('')
    const [startingNumber1Error, setStartingNumber1Error] = useState<string>('')
    const [endingNumber1, setEndingNumber1] = useState<string>('')
    const [endingNumber1Error, setEndingNumber1Error] = useState<string>('')

    const [machineId2, setMachineId2] = useState<string>('')
    const [machineId2Error, setMachineId2Error] = useState<string>('')
    const [startingNumber2, setStartingNumber2] = useState<string>('')
    const [startingNumber2Error, setStartingNumber2Error] = useState<string>('')
    const [endingNumber2, setEndingNumber2] = useState<string>('')
    const [endingNumber2Error, setEndingNumber2Error] = useState<string>('')

    const [vehicleId, setVehicleId] = useState<string>('')
    const [vehicleIdError, setVehicleIdError] = useState<string>('')
    const [startingKm, setStartingKm] = useState<string>('')
    const [startingKmError, setStartingKmError] = useState<string>('')
    const [endingKm, setEndingKm] = useState<string>('')
    const [endingKmError, setEndingKmError] = useState<string>('')
    const [dieselInLitre, setDieselInLitre] = useState<string>('')
    const [dieselInLitreError, setDieselInLitreError] = useState<string>('')

    const [spareVehicleId, setSpareVehicleId] = useState<string>('')
    const [spareVehicleIdError, setSpareVehicleIdError] = useState<string>('')
    const [spareStartingKm, setSpareStartingKm] = useState<string>('')
    const [spareStartingKmError, setSpareStartingKmError] = useState<string>('')
    const [spareEndingKm, setSpareEndingKm] = useState<string>('')
    const [spareEndingKmError, setSpareEndingKmError] = useState<string>('')
    const [spareDieselInLitre, setSpareDieselInLitre] = useState<string>('')
    const [spareDieselInLitreError, setSpareDieselInLitreError] = useState<string>('')

    const [driver, setDriver] = useState<string>('')
    const [driverError, setDriverError] = useState<string>('')
    const [cleaner, setCleaner] = useState<string>('')
    const [cleanerError, setCleanerError] = useState<string>('')

    //EXPENSE
    const [dieselAmount1, setDieselAmount1] = useState<string>('')
    const [dieselAmount1Error, setDieselAmount1Error] = useState<string>('')
    const [driverAllowance1, setDriverAllowance1] = useState<string>('')
    const [driverAllowance1Error, setDriverAllowance1Error] = useState<string>('')
    const [conductorAllowance1, setConductorAllowance1] = useState<string>('')
    const [conductorAllowance1Error, setConductorAllowance1Error] = useState<string>('')
    const [driverSalary1, setDriverSalary1] = useState<string>('')
    const [driverSalary1Error, setDriverSalary1Error] = useState<string>('')
    const [conductorSalary1, setConductorSalary1] = useState<string>('')
    const [conductorSalary1Error, setConductorSalary1Error] = useState<string>('')
    const [cleanerSalary1, setCleanerSalary1] = useState<string>('')
    const [cleanerSalary1Error, setCleanerSalary1Error] = useState<string>('')
    const [incentive1, setIncentive1] = useState<string>('')
    const [incentive1Error, setIncentive1Error] = useState<string>('')
    const [bookingCharge1, setBookingCharge1] = useState<string>('')
    const [bookingCharge1Error, setBookingCharge1Error] = useState<string>('')
    const [standRent1, setStandRent1] = useState<string>('')
    const [standRent1Error, setStandRent1Error] = useState<string>('')
    const [others1, setOthers1] = useState<string>('')
    const [others1Error, setOthers1Error] = useState<string>('')
    // const [expenseAmount, setExpenseAmount] = useState<Array<ExpenseType>>([])
    const expense1Total = (dieselAmount1 !== '' ? parseInt(dieselAmount1) : 0) +
        (driverAllowance1 !== '' ? parseInt(driverAllowance1) : 0) +
        (conductorAllowance1 !== '' ? parseInt(conductorAllowance1) : 0) +
        (driverSalary1 !== '' ? parseInt(driverSalary1) : 0) +
        (conductorSalary1 !== '' ? parseInt(conductorSalary1) : 0) +
        (cleanerSalary1 !== '' ? parseInt(cleanerSalary1) : 0) +
        (incentive1 !== '' ? parseInt(incentive1) : 0) +
        (bookingCharge1 !== '' ? parseInt(bookingCharge1) : 0) +
        (standRent1 !== '' ? parseInt(standRent1) : 0) +
        (others1 !== '' ? parseInt(others1) : 0)

    const [dieselAmount2, setDieselAmount2] = useState<string>('')
    const [dieselAmount2Error, setDieselAmount2Error] = useState<string>('')
    const [driverAllowance2, setDriverAllowance2] = useState<string>('')
    const [driverAllowance2Error, setDriverAllowance2Error] = useState<string>('')
    const [conductorAllowance2, setConductorAllowance2] = useState<string>('')
    const [conductorAllowance2Error, setConductorAllowance2Error] = useState<string>('')
    const [driverSalary2, setDriverSalary2] = useState<string>('')
    const [driverSalary2Error, setDriverSalary2Error] = useState<string>('')
    const [conductorSalary2, setConductorSalary2] = useState<string>('')
    const [conductorSalary2Error, setConductorSalary2Error] = useState<string>('')
    const [cleanerSalary2, setCleanerSalary2] = useState<string>('')
    const [cleanerSalary2Error, setCleanerSalary2Error] = useState<string>('')
    const [incentive2, setIncentive2] = useState<string>('')
    const [incentive2Error, setIncentive2Error] = useState<string>('')
    const [bookingCharge2, setBookingCharge2] = useState<string>('')
    const [bookingCharge2Error, setBookingCharge2Error] = useState<string>('')
    const [standRent2, setStandRent2] = useState<string>('')
    const [standRent2Error, setStandRent2Error] = useState<string>('')
    const [others2, setOthers2] = useState<string>('')
    const [others2Error, setOthers2Error] = useState<string>('')

    const expense2Total = (dieselAmount2 !== '' ? parseInt(dieselAmount2) : 0) +
        (driverAllowance2 !== '' ? parseInt(driverAllowance2) : 0) +
        (conductorAllowance2 !== '' ? parseInt(conductorAllowance2) : 0) +
        (driverSalary2 !== '' ? parseInt(driverSalary2) : 0) +
        (conductorSalary2 !== '' ? parseInt(conductorSalary2) : 0) +
        (cleanerSalary2 !== '' ? parseInt(cleanerSalary2) : 0) +
        (incentive2 !== '' ? parseInt(incentive2) : 0) +
        (bookingCharge2 !== '' ? parseInt(bookingCharge2) : 0) +
        (standRent2 !== '' ? parseInt(standRent2) : 0) +
        (others2 !== '' ? parseInt(others2) : 0)

    const TotalExpense = useMemo<number>(() => {

        return (expense1Total !== undefined ? expense1Total : 0) + (expense2Total !== undefined ? expense2Total : 0)

    }, [expense1Total, expense2Total])


    //TICKET WISE
    const [startingNo, setStartingNo] = useState<string>('')
    const [startingNoError, setStartingNoError] = useState<string>('')
    const [endingNo, setEndingNo] = useState<string>('')
    const [endingNoError, setEndingNoError] = useState<string>('')

    const [ticketWiseAmount1, setTicketWiseAmount1] = useState<Array<TicketDetailsType>>([])
    const [ticketWiseAmount2, setTicketWiseAmount2] = useState<Array<TicketDetailsType>>([])

    const ticketWiseTotalForC1 = useMemo<number>(() => {
        return ticketWiseAmount1.reduce((previous, current) => {
            return previous + (current.ticketPrice !== '' && current.endingNumber !== '' && current.startingNumber !== '' ? parseFloat(current.ticketPrice) * Math.abs(parseFloat(current.endingNumber) - parseFloat(current.startingNumber)) : 0)
        }, 0)

    }, [ticketWiseAmount1])

    const ticketWiseTotalForC2 = useMemo<number>(() => {
        return ticketWiseAmount2.reduce((previous, current) => {
            return previous + (current.ticketPrice !== '' && current.endingNumber !== '' && current.startingNumber !== '' ? parseFloat(current.ticketPrice) * Math.abs(parseFloat(current.endingNumber) - parseFloat(current.startingNumber)) : 0)
        }, 0)

    }, [ticketWiseAmount2])

    const TotalTicketCollection = useMemo<number>(() => {

        return (ticketWiseTotalForC1 !== undefined ? ticketWiseTotalForC1 : 0) + (ticketWiseTotalForC2 !== undefined ? ticketWiseTotalForC2 : 0)

    }, [ticketWiseTotalForC1, ticketWiseTotalForC2])

    //TRIP WISE
    const [tripWiseAmount1, setTripWiseAmount1] = useState<Array<TripDetailsType>>([])
    const [tripWiseAmount2, setTripWiseAmount2] = useState<Array<TripDetailsType>>([])

    const tripWiseTotalForC1 = useMemo<number>(() => {
        return tripWiseAmount1.reduce((previous, current) => {
            return previous + parseFloat(current.amount)
        }, 0)

    }, [tripWiseAmount1])

    const tripWiseTotalForC2 = useMemo<number>(() => {
        return tripWiseAmount2.reduce((previous, current) => {
            return previous + parseFloat(current.amount)
        }, 0)

    }, [tripWiseAmount2])

    const TotalTripCollection = useMemo<number>(() => {

        return (tripWiseTotalForC1 !== undefined ? tripWiseTotalForC1 : 0) + (tripWiseTotalForC2 !== undefined ? tripWiseTotalForC2 : 0)

    }, [tripWiseTotalForC1, tripWiseTotalForC2])


    //REFUND
    const refund1 = useMemo<number>(() => {
        return tripWiseAmount1.reduce((previous, current) => {
            return previous + ((current.refund !== undefined && parseFloat(current.refund) > 0) ? parseFloat(current.refund) : 0)
        }, 0)

    }, [tripWiseAmount1])

    const refund2 = useMemo<number>(() => {
        return tripWiseAmount2.reduce((previous, current) => {
            return previous + ((current.refund !== undefined && parseFloat(current.refund) > 0) ? parseFloat(current.refund) : 0)
        }, 0)

    }, [tripWiseAmount2])

    const TotalRefund = useMemo<number>(() => {

        return (refund1 !== undefined ? refund1 : 0) + (refund2 !== undefined ? refund2 : 0)

    }, [refund1, refund2])


    const handleShortage: ChangeEventHandler<HTMLInputElement> = (e) => {
        setShortage(e.target.value)
        setShortageError('')
    }
    const handleExcess: ChangeEventHandler<HTMLInputElement> = (e) => {
        setExcess(e.target.value)
        setExcessError('')
    }
    const handleBalance: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBalance(e.target.value)
        setBalanceError('')
    }

    const handleStartingNo: ChangeEventHandler<HTMLInputElement> = (e) => {
        setStartingNo(e.target.value)
        setStartingNoError('')
    }

    const handleEndingNo: ChangeEventHandler<HTMLInputElement> = (e) => {
        setEndingNo(e.target.value)
        setEndingNoError('')
    }

    const handleDieselAmount1: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDieselAmount1(e.target.value)
        setDieselAmount1Error('')
    }
    const handleDriverAllowance1: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDriverAllowance1(e.target.value)
        setDriverAllowance1Error('')
    }
    const handleConductorAllowance1: ChangeEventHandler<HTMLInputElement> = (e) => {
        setConductorAllowance1(e.target.value)
        setConductorAllowance1Error('')
    }
    const handleDriverSalary1: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDriverSalary1(e.target.value)
        setDriverSalary1Error('')
    }
    const handleConductorSalary1: ChangeEventHandler<HTMLInputElement> = (e) => {
        setConductorSalary1(e.target.value)
        setConductorSalary1Error('')
    }
    const handleCleanerSalary1: ChangeEventHandler<HTMLInputElement> = (e) => {
        setCleanerSalary1(e.target.value)
        if (parseFloat(cleanerSalary1) < 0) {
            setCleanerSalary1Error('Invalid cleaner salary')
        } else setCleanerSalary1Error('')
    }
    const handleIncentive1: ChangeEventHandler<HTMLInputElement> = (e) => {
        setIncentive1(e.target.value)
        setIncentive1Error('')
    }
    const handleBookingCharge1: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBookingCharge1(e.target.value)
        setBookingCharge1Error('')
    }
    const handleStandRent1: ChangeEventHandler<HTMLInputElement> = (e) => {
        setStandRent1(e.target.value)
        setStandRent1Error('')
    }
    const handleOthers1: ChangeEventHandler<HTMLInputElement> = (e) => {
        setOthers1(e.target.value)
        setOthers1Error('')
    }

    const handleDieselAmount2: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDieselAmount2(e.target.value)
        setDieselAmount2Error('')
    }
    const handleDriverAllowance2: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDriverAllowance2(e.target.value)
        setDriverAllowance2Error('')
    }
    const handleConductorAllowance2: ChangeEventHandler<HTMLInputElement> = (e) => {
        setConductorAllowance2(e.target.value)
        setConductorAllowance2Error('')
    }
    const handleDriverSalary2: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDriverSalary2(e.target.value)
        setDriverSalary2Error('')
    }
    const handleConductorSalary2: ChangeEventHandler<HTMLInputElement> = (e) => {
        setConductorSalary2(e.target.value)
        setConductorSalary2Error('')
    }
    const handleCleanerSalary2: ChangeEventHandler<HTMLInputElement> = (e) => {
        setCleanerSalary2(e.target.value)
        if (parseFloat(cleanerSalary2) < 0) {
            setCleanerSalary2Error('Invalid cleaner salary')
        } else setCleanerSalary2Error('')
    }
    const handleIncentive2: ChangeEventHandler<HTMLInputElement> = (e) => {
        setIncentive2(e.target.value)
        setIncentive2Error('')
    }
    const handleBookingCharge2: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBookingCharge2(e.target.value)
        setBookingCharge2Error('')
    }
    const handleStandRent2: ChangeEventHandler<HTMLInputElement> = (e) => {
        setStandRent2(e.target.value)
        setStandRent2Error('')
    }
    const handleOthers2: ChangeEventHandler<HTMLInputElement> = (e) => {
        setOthers2(e.target.value)
        setOthers2Error('')
    }


    const handleInvoiceNo: ChangeEventHandler<HTMLInputElement> = (e) => {
        setInvoiceNo(e.target.value)
        setInvoiceNoError('')
    }

    const handleRouteId: ChangeEventHandler<HTMLInputElement> = (e) => {
        setRouteId(e.target.value)
        setRouteIdError('')
        setTicketWiseAmount1([])
        setTicketWiseAmount2([])
        setTripWiseAmount1([])
        setTripWiseAmount2([])
    }

    const handleDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDate(e.target.value)
        setDateError('')
    }

    const handleVehicleId: ChangeEventHandler<HTMLInputElement> = (e) => {
        setVehicleId(e.target.value)
        setVehicleIdError('')
    }

    const handleStartingKm: ChangeEventHandler<HTMLInputElement> = (e) => {
        setStartingKm(e.target.value)
        setStartingKmError('')
    }

    const handleEndingKm: ChangeEventHandler<HTMLInputElement> = (e) => {
        setEndingKm(e.target.value)
        setEndingKmError('')
    }

    const handleDieselInLitre: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDieselInLitre(e.target.value)
        setDieselInLitreError('')
    }

    const handleSpareVehicleId: ChangeEventHandler<HTMLInputElement> = (e) => {
        setSpareVehicleId(e.target.value)
        setSpareVehicleIdError('')
    }

    const handleSpareStartingKm: ChangeEventHandler<HTMLInputElement> = (e) => {
        setSpareStartingKm(e.target.value)
        setSpareStartingKmError('')
    }

    const handleSpareEndingKm: ChangeEventHandler<HTMLInputElement> = (e) => {
        setSpareEndingKm(e.target.value)
        setSpareEndingKmError('')
    }

    const handleSpareDieselInLitre: ChangeEventHandler<HTMLInputElement> = (e) => {
        setSpareDieselInLitre(e.target.value)
        setSpareDieselInLitreError('')
    }

    const handleDriver: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDriver(e.target.value)
        setDriverError('')
    }

    const handleConductor1: ChangeEventHandler<HTMLInputElement> = (e) => {
        setConductor1(e.target.value)
        setConductor1Error('')
    }

    const handleConductor2: ChangeEventHandler<HTMLInputElement> = (e) => {
        setConductor2(e.target.value)
        setConductor2Error('')
    }

    const handleCleaner: ChangeEventHandler<HTMLInputElement> = (e) => {
        setCleaner(e.target.value)
        setCleanerError('')
    }

    const handleMachineId1: ChangeEventHandler<HTMLInputElement> = (e) => {
        setMachineId1(e.target.value)
        setMachineId1Error('')
    }

    const handleStartingNumber1: ChangeEventHandler<HTMLInputElement> = (e) => {
        setStartingNumber1(e.target.value)
        setStartingNumber1Error('')
    }

    const handleEndingNumber1: ChangeEventHandler<HTMLInputElement> = (e) => {
        setEndingNumber1(e.target.value)
        setEndingNumber1Error('')
    }

    const handleMachineId2: ChangeEventHandler<HTMLInputElement> = (e) => {
        setMachineId2(e.target.value)
        setMachineId2Error('')
    }

    const handleStartingNumber2: ChangeEventHandler<HTMLInputElement> = (e) => {
        setStartingNumber2(e.target.value)
        setStartingNumber2Error('')
    }

    const handleEndingNumber2: ChangeEventHandler<HTMLInputElement> = (e) => {
        setEndingNumber2(e.target.value)
        setEndingNumber2Error('')
    }

    const tripWiseCollection1ClickHandler: MouseEventHandler<HTMLAnchorElement> = (e) => {
        e.preventDefault()
        if (routeId !== '') {
            setShowTrip1Modal(true)
        } else toast('Select Route First', ToastTypes.ERROR)
    }

    const tripWiseCollection2ClickHandler: MouseEventHandler<HTMLAnchorElement> = (e) => {
        e.preventDefault()
        if (routeId !== '') {
            setShowTrip2Modal(true)
        } else toast('Select Route First', ToastTypes.ERROR)
    }

    const ticketWiseCollection1ClickHandler: MouseEventHandler<HTMLAnchorElement> = (e) => {
        e.preventDefault()
        if (routeId !== '') {
            setShowTicket1Modal(true)
        } else toast('Select Route First', ToastTypes.ERROR)
    }

    const ticketWiseCollection2ClickHandler: MouseEventHandler<HTMLAnchorElement> = (e) => {
        e.preventDefault()
        if (routeId !== '') {
            setShowTicket2Modal(true)
        } else toast('Select Route First', ToastTypes.ERROR)
    }

    const expense1ClickHandler: MouseEventHandler<HTMLAnchorElement> = (e) => {
        e.preventDefault()
        if (routeId !== '') {
            setShowExpense1Modal(true)
        } else toast('Select Route First', ToastTypes.ERROR)
    }

    const expense2ClickHandler: MouseEventHandler<HTMLAnchorElement> = (e) => {
        e.preventDefault()
        if (routeId !== '') {
            setShowExpense2Modal(true)
        } else toast('Select Route First', ToastTypes.ERROR)
    }

    const handleTrip1ModalClose = () => {
        setShowTrip1Modal(false)
    }

    const handleTrip2ModalClose = () => {
        setShowTrip2Modal(false)
    }

    const handleTicket1ModalClose = () => {
        setShowTicket1Modal(false)
    }

    const handleTicket2ModalClose = () => {
        setShowTicket2Modal(false)
    }

    const handleExpense1ModalClose = () => {
        setShowExpense1Modal(false)
    }

    const handleExpense2ModalClose = () => {
        setShowExpense2Modal(false)
    }

    const handleSave = () => {

        if (TotalTripCollection > 0) {
            if (TotalTicketCollection > 0) {
                if (TotalTripCollection + TotalRefund === TotalTicketCollection) {
                    let error = false

                    const collectionDetails1 = [{
                        type: CollectionTypeList.INCOME,
                        details: 'Total Trip Collection',
                        amount: tripWiseTotalForC1,
                    }]

                    const expenseArray1 = [
                        { amount: dieselAmount1, details: ExpenseDetails.DIESEL },
                        { amount: driverAllowance1, details: ExpenseDetails.DRIVER_ALLOWANCE },
                        { amount: conductorAllowance1, details: ExpenseDetails.CONDUCTOR_ALLOWANCE },
                        { amount: driverSalary1, details: ExpenseDetails.DRIVER_SALARY },
                        { amount: conductorSalary1, details: ExpenseDetails.CONDUCTOR_SALARY },
                        { amount: cleanerSalary1, details: ExpenseDetails.CLEANER_SALARY },
                        { amount: incentive1, details: ExpenseDetails.INCENTIVE },
                        { amount: bookingCharge1, details: ExpenseDetails.BOOKING_CHARGE },
                        { amount: standRent1, details: ExpenseDetails.STAND_RENT },
                        { amount: others1, details: ExpenseDetails.OTHERS }
                    ]

                    expenseArray1.forEach(e => {
                        if (isRealNumber(e.amount)) {
                            collectionDetails1.push({
                                amount: Number(e.amount),
                                details: e.details,
                                type: CollectionTypeList.EXPENSE
                            })
                        }
                    })


                    const collectionDetails2 = [{
                        type: CollectionTypeList.INCOME,
                        details: 'Total Trip Collection',
                        amount: tripWiseTotalForC2,
                    }]

                    const expenseArray2 = [
                        { amount: dieselAmount2, details: ExpenseDetails.DIESEL },
                        { amount: driverAllowance2, details: ExpenseDetails.DRIVER_ALLOWANCE },
                        { amount: conductorAllowance2, details: ExpenseDetails.CONDUCTOR_ALLOWANCE },
                        { amount: driverSalary2, details: ExpenseDetails.DRIVER_SALARY },
                        { amount: conductorSalary2, details: ExpenseDetails.CONDUCTOR_SALARY },
                        { amount: cleanerSalary2, details: ExpenseDetails.CLEANER_SALARY },
                        { amount: incentive2, details: ExpenseDetails.INCENTIVE },
                        { amount: bookingCharge2, details: ExpenseDetails.BOOKING_CHARGE },
                        { amount: standRent2, details: ExpenseDetails.STAND_RENT },
                        { amount: others2, details: ExpenseDetails.OTHERS }
                    ]


                    expenseArray2.forEach(e => {
                        if (isRealNumber(e.amount)) {
                            collectionDetails2.push({
                                amount: Number(e.amount),
                                details: e.details,
                                type: CollectionTypeList.EXPENSE
                            })
                        }
                    })

                    const data = {

                        routeId: routeId,
                        vehicleId: vehicleId,
                        date: date,
                        //c
                        invoiceNumber: invoiceNo,
                        shortage: shortage,
                        excess: excess,
                        balance: balance,
                        totalIncome: TotalTripCollection,
                        totalExpense: TotalExpense,

                        //vr
                        startingKm: startingKm,
                        endingKm: endingKm,
                        diesel: dieselInLitre,
                        driver: driver,
                        cleaner: cleaner,

                        spareVehicleId: spareVehicleId,
                        spareStartingKM: spareStartingKm,
                        spareEndingKM: spareEndingKm,
                        spareDiesel: spareDieselInLitre,
                        //coll machines
                        conductor1: conductor1,
                        conductor2: conductor2,

                        machineId: machineId1,
                        startingNumber: startingNumber1,
                        endingNumber: endingNumber1,

                        machineId2: machineId2,
                        startingNumber2: startingNumber2,
                        endingNumber2: endingNumber2,
                        //cd
                        collectionDetails: collectionDetails1,
                        collectionDetails2: collectionDetails2,
                        //trip det
                        tripDetails: tripWiseAmount1,
                        tripDetails2: tripWiseAmount2,
                        //tic det
                        ticketDetails: ticketWiseAmount1,
                        ticketDetails2: ticketWiseAmount2,

                    }

                    data.tripDetails.forEach(td => {
                        if (td.tripId <= 0) {
                            toast("Invalid tripId in TripWise Collection", ToastTypes.ERROR)
                            error = true
                        }

                        if (parseFloat(td.amount) < 0) {
                            toast("Invalid amount in TripWise Collection", ToastTypes.ERROR)
                            error = true
                        }
                    })

                    if (data.tripDetails2 !== undefined) {
                        data.tripDetails2.forEach(td => {
                            if (td.tripId <= 0) {
                                toast("Invalid tripId in TripWise Collection in conductor 2", ToastTypes.ERROR)
                                error = true
                            }

                            if (parseFloat(td.amount) < 0) {
                                toast("Invalid amount in TripWise Collection in conductor 2", ToastTypes.ERROR)
                                error = true
                            }
                        })
                    }

                    data.ticketDetails.forEach(tcd => {
                        if (parseFloat(tcd.ticketPrice) <= 0) {
                            toast("Invalid ticket price in TicketWise Collection", ToastTypes.ERROR)
                            error = true
                        }

                        if (parseFloat(tcd.startingNumber) < 0) {
                            toast("Invalid starting number in TicketWise Collection", ToastTypes.ERROR)
                            error = true
                        }

                        if (parseFloat(tcd.startingNumber) < 0) {
                            toast("Invalid ending number in TicketWise Collection", ToastTypes.ERROR)
                            error = true
                        }
                    })

                    if (data.ticketDetails2) {
                        data.ticketDetails2.forEach(tcd => {
                            if (parseFloat(tcd.ticketPrice) <= 0) {
                                toast("Invalid ticket price in TicketWise Collection in conductor 2", ToastTypes.ERROR)
                                error = true
                            }

                            if (parseFloat(tcd.startingNumber) < 0) {
                                toast("Invalid starting number in TicketWise Collection in conductor 2", ToastTypes.ERROR)
                                error = true
                            }

                            if (parseFloat(tcd.startingNumber) < 0) {
                                toast("Invalid ending number in TicketWise Collection in conductor 2", ToastTypes.ERROR)
                                error = true
                            }
                        })
                    }

                    if (isNaN(parseInt(data.routeId))) {
                        setRouteIdError('Route required')
                        error = true
                    } else if (parseInt(data.routeId) <= 0) {
                        setRouteIdError('Invalid Route')
                        error = true
                    }

                    if (data.date === '') {
                        setDateError('Date required')
                        error = true
                    } else if ((data.date).length > 10) {
                        setDateError('Invalid Date')
                        error = true
                    }

                    if (isNaN(parseInt(data.vehicleId))) {
                        setVehicleIdError('Vehicle required')
                        error = true
                    } else if (parseInt(data.vehicleId) <= 0) {
                        setVehicleIdError('Invalid Vehicle')
                        error = true
                    }

                    if (data.startingKm === '') {
                        setStartingKmError('Starting KM required')
                        error = true
                    } else if (parseInt(data.startingKm) < 0) {
                        setStartingKmError('Invaild Starting Km')
                        error = true
                    }

                    if (data.endingKm === '') {
                        setEndingKmError('Ending KM required')
                        error = true
                    } else if (parseInt(data.endingKm) < 0) {
                        setEndingKmError('Invaild Ending Km')
                        error = true
                    }

                    if (data.diesel === '') {
                        setDieselInLitreError('Diesel required')
                        error = true
                    } else if (parseFloat(data.diesel) < 0) {
                        setDieselInLitreError('Invaild Diesel')
                        error = true
                    }

                    if (data.spareVehicleId !== undefined && data.spareVehicleId !== '') {
                        // if (isNaN(parseInt(data.spareVehicleId))) {
                        //     setSpareVehicleIdError('Spare Vehicle required')
                        //     error = true
                        // } else 
                        if (parseInt(data.spareVehicleId) <= 0) {
                            setSpareVehicleIdError('Invalid Spare Vehicle')
                            error = true
                        }
                    }

                    if (data.spareStartingKM !== undefined && parseInt(data.spareStartingKM) < 0) {
                        setSpareStartingKmError('Invaild Starting Km')
                        error = true
                    }
                    if (data.spareEndingKM !== undefined && parseInt(data.spareEndingKM) < 0) {
                        setSpareEndingKmError('Invaild Spare Ending Km')
                        error = true
                    }
                    if (data.spareDiesel !== undefined && parseFloat(data.spareDiesel) < 0) {
                        setSpareDieselInLitreError('Invaild Spare Diesel')
                        error = true
                    }

                    if (isNaN(parseInt(data.driver))) {
                        setDriverError('Driver required')
                        error = true
                    } else if (parseInt(data.driver) <= 0) {
                        setDriverError('Invalid Driver')
                        error = true
                    }

                    if (isNaN(parseInt(data.conductor1))) {
                        setConductor1Error('Conductor required')
                        error = true
                    } else if (parseInt(data.conductor1) <= 0) {
                        setConductor1Error('Invalid Conductor')
                        error = true
                    }

                    if (isNaN(parseInt(data.cleaner))) {
                        setCleanerError('Cleaner required')
                        error = true
                    } else if (parseInt(data.cleaner) <= 0) {
                        setCleanerError('Invalid Cleaner')
                        error = true
                    }

                    if (data.balance === '') {
                        setBalanceError('Balance required')
                        error = true
                    }
                    // else if (parseFloat(data.balance) < 0) {
                    //     setBalanceError('Invalid Balance')
                    //     error = true
                    // }

                    // if (data.collectionDetails.length > 0) {
                    // setcollError('coll required')
                    // error = true
                    // }

                    if (error === false) {
                        if (formType === FormTypeList.ADD) {

                            api.post<APIResponse<CollectionSaveDataType>>('collection/', data, config).then(response => {
                                if (response.status === 200 && response.data.data) {
                                    dispatch(fetchCollection())
                                    toast(response.data.message
                                        ? response.data.message
                                        : 'Collection  Added', ToastTypes.SUCCESS)
                                    // navigate('/collection')
                                } else {
                                    throw { response }
                                }
                            }).catch(error => {
                                toast(errorHandler(error, 'Unable to add'), ToastTypes.ERROR)
                            })
                        } else if (formType === FormTypeList.UPDATE && id !== undefined) {
                            api.put<APIResponse<CollectionSaveDataType>>(`collection/?id=${id}`, data, config).then(response => {
                                if (response.status === 200) {
                                    dispatch(fetchCollection())
                                    // setCollectionID(response.data.data.id ? response.data.data.id : "")
                                    toast(response.data.message
                                        ? response.data.message
                                        : 'Collection  Updated', ToastTypes.SUCCESS)
                                    navigate('/collection')

                                } else {
                                    throw { response }
                                }
                            }).catch(error => {
                                toast(errorHandler(error, 'Unable to update'), ToastTypes.ERROR)
                            })
                        }
                    }
                } else toast('Trip Wise Collection and Ticket Wise Collection values are in Mismatch.Kindly Check', ToastTypes.ERROR)
            } else toast('Ticket Wise Collection must be valid', ToastTypes.ERROR)
        } else toast('Trip Wise Collection must be valid', ToastTypes.ERROR)
    }

    useEffect(() => {
        if (formType === FormTypeList.UPDATE && id !== undefined) {
            api.get<APIResponse<CollectionSaveDataType>>(`collection/?id=${id}`, config).then(response => {

                if (response.status === 200 && response.data.data) {

                    const result = response.data.data
                    // console.log(result)

                    setDate(result.date)
                    setRouteId(result.routeId.toString())
                    setBalance(result.balance.toString())
                    setShortage(result.shortage.toString())
                    setExcess(result.excess.toString())
                    setInvoiceNo(result.invoiceNumber)

                    setMachineId1(result.machineId1 ? result.machineId1.toString() : '')
                    setStartingNumber1(result.startingNumber1 ? result.startingNumber1.toString() : '')
                    setEndingNumber1(result.endingNumber1 ? result.endingNumber1.toString() : '')

                    setMachineId2(result.machineId2 ? result.machineId2.toString() : '')
                    setStartingNumber2(result.startingNumber2 ? result.startingNumber2.toString() : '')
                    setEndingNumber2(result.endingNumber2 ? result.endingNumber2.toString() : '')

                    setTicketWiseAmount1(result.ticketDetails)
                    setTicketWiseAmount2(result.ticketDetails2 ? result.ticketDetails2 : [])
                    setTripWiseAmount1(result.tripDetails)
                    setTripWiseAmount2(result.tripDetails2 ? result.tripDetails2 : [])

                    setVehicleId(result.vehicleId.toString())
                    setStartingKm(result.startingKm.toString())
                    setEndingKm(result.endingKm.toString())
                    setDieselInLitre(result.diesel.toString())
                    setSpareVehicleId(result.spareVehicleId ? result.spareVehicleId.toString() : '')
                    setSpareStartingKm(result.spareStartingKM ? result.spareStartingKM.toString() : '')
                    setSpareEndingKm(result.spareEndingKM ? result.spareEndingKM.toString() : '')
                    setSpareDieselInLitre(result.spareDiesel ? result.spareDiesel.toString() : '')
                    setDriver(result.driver.toString())

                    setConductor1(result.conductor1.toString())
                    setConductor2(result.conductor2 ? result.conductor2.toString() : '')

                    setCleaner(result.cleaner.toString())

                    result.collectionDetails.filter((e) => {
                        return e.type === CollectionTypeList.EXPENSE
                    }).forEach(e => {
                        if (e.details === ExpenseDetails.DIESEL) {
                            setDieselAmount1(e.amount)
                        } else if (e.details === ExpenseDetails.BOOKING_CHARGE) {
                            setBookingCharge1(e.amount)
                        } else if (e.details === ExpenseDetails.CLEANER_SALARY) {
                            setCleanerSalary1(e.amount)
                        } else if (e.details === ExpenseDetails.CONDUCTOR_ALLOWANCE) {
                            setConductorAllowance1(e.amount)
                        } else if (e.details === ExpenseDetails.CONDUCTOR_SALARY) {
                            setConductorSalary1(e.amount)
                        } else if (e.details === ExpenseDetails.DRIVER_ALLOWANCE) {
                            setDriverAllowance1(e.amount)
                        } else if (e.details === ExpenseDetails.DRIVER_SALARY) {
                            setDriverSalary1(e.amount)
                        } else if (e.details === ExpenseDetails.INCENTIVE) {
                            setIncentive1(e.amount)
                        } else if (e.details === ExpenseDetails.OTHERS) {
                            setOthers1(e.amount)
                        } else if (e.details === ExpenseDetails.STAND_RENT) {
                            setStandRent1(e.amount)
                        }
                    })

                    if (result.collectionDetails2 !== undefined) {

                        result.collectionDetails2.filter((e) => {
                            return e.type === CollectionTypeList.EXPENSE
                        }).forEach(e => {
                            if (e.details === ExpenseDetails.DIESEL) {
                                setDieselAmount2(e.amount)
                            } else if (e.details === ExpenseDetails.BOOKING_CHARGE) {
                                setBookingCharge2(e.amount)
                            } else if (e.details === ExpenseDetails.CLEANER_SALARY) {
                                setCleanerSalary2(e.amount)
                            } else if (e.details === ExpenseDetails.CONDUCTOR_ALLOWANCE) {
                                setConductorAllowance2(e.amount)
                            } else if (e.details === ExpenseDetails.CONDUCTOR_SALARY) {
                                setConductorSalary2(e.amount)
                            } else if (e.details === ExpenseDetails.DRIVER_ALLOWANCE) {
                                setDriverAllowance2(e.amount)
                            } else if (e.details === ExpenseDetails.DRIVER_SALARY) {
                                setDriverSalary2(e.amount)
                            } else if (e.details === ExpenseDetails.INCENTIVE) {
                                setIncentive2(e.amount)
                            } else if (e.details === ExpenseDetails.OTHERS) {
                                setOthers2(e.amount)
                            } else if (e.details === ExpenseDetails.STAND_RENT) {
                                setStandRent2(e.amount)
                            }
                        })
                    } else {
                        setDieselAmount2('')
                        setBookingCharge2('')
                        setCleanerSalary2('')
                        setConductorAllowance2('')
                        setConductorSalary2('')
                        setDriverAllowance2('')
                        setDriverSalary2('')
                        setIncentive2('')
                        setStandRent2('')
                        setOthers2('')
                    }
                } else {
                    throw { response }
                }

            }).catch(error => {
                setBalance('')
                setShortage('')
                setExcess('')

                setConductor1('')
                setConductor2('')

                setMachineId1('')
                setStartingNumber1('')
                setEndingNumber1('')
                setMachineId2('')
                setStartingNumber2('')
                setEndingNumber2('')

                setTicketWiseAmount1([])
                setTripWiseAmount1([])
                setTicketWiseAmount2([])
                setTripWiseAmount2([])

                setInvoiceNo('')
                setVehicleId('')
                setStartingKm('')
                setEndingKm('')
                setDieselInLitre('')
                setSpareVehicleId('')
                setSpareStartingKm('')
                setSpareEndingKm('')
                setSpareDieselInLitre('')
                setDriver('')
                setCleaner('')
                setDieselAmount1('')
                setBookingCharge1('')
                setCleanerSalary1('')
                setConductorAllowance1('')
                setConductorSalary1('')
                setDriverAllowance1('')
                setDriverSalary1('')
                setIncentive1('')
                setStandRent1('')
                setOthers1('')
                setDieselAmount2('')
                setBookingCharge2('')
                setCleanerSalary2('')
                setConductorAllowance2('')
                setConductorSalary2('')
                setDriverAllowance2('')
                setDriverSalary2('')
                setIncentive2('')
                setStandRent2('')
                setOthers2('')
                setRouteId('')
                setDate('')


                toast(errorHandler(error, 'Unable to fetch'), ToastTypes.ERROR)

            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formType])

    useEffect(() => {
        setBalance(((TotalTripCollection ? TotalTripCollection : 0) - (TotalExpense ? TotalExpense : 0) + (excess ? parseInt(excess) : 0)).toString())
    }, [TotalTripCollection, TotalExpense, excess])

    return <CollectionContext.Provider value={{
        routeId,
        setTripWiseAmount1, tripWiseAmount1, tripWiseTotalForC1,
        setTripWiseAmount2, tripWiseAmount2, tripWiseTotalForC2,
        expense1Total,
        dieselAmount1,
        dieselAmount1Error,
        driverAllowance1,
        driverAllowance1Error,
        conductorAllowance1,
        conductorAllowance1Error,
        driverSalary1,
        driverSalary1Error,
        conductorSalary1,
        conductorSalary1Error,
        cleanerSalary1,
        cleanerSalary1Error,
        incentive1,
        incentive1Error,
        bookingCharge1,
        bookingCharge1Error,
        standRent1,
        standRent1Error,
        others1,
        others1Error,
        handleDieselAmount1,
        handleDriverAllowance1,
        handleConductorAllowance1,
        handleDriverSalary1,
        handleConductorSalary1,
        handleCleanerSalary1,
        handleIncentive1,
        handleBookingCharge1,
        handleStandRent1,
        handleOthers1,
        expense2Total,
        dieselAmount2,
        dieselAmount2Error,
        driverAllowance2,
        driverAllowance2Error,
        conductorAllowance2,
        conductorAllowance2Error,
        driverSalary2,
        driverSalary2Error,
        conductorSalary2,
        conductorSalary2Error,
        cleanerSalary2,
        cleanerSalary2Error,
        incentive2,
        incentive2Error,
        bookingCharge2,
        bookingCharge2Error,
        standRent2,
        standRent2Error,
        others2,
        others2Error,
        handleDieselAmount2,
        handleDriverAllowance2,
        handleConductorAllowance2,
        handleDriverSalary2,
        handleConductorSalary2,
        handleCleanerSalary2,
        handleIncentive2,
        handleBookingCharge2,
        handleStandRent2,
        handleOthers2,
        startingNo,
        startingNoError,
        endingNo,
        endingNoError,
        handleStartingNo,
        handleEndingNo,
        setTicketWiseAmount1, ticketWiseAmount1, ticketWiseTotalForC1,
        setTicketWiseAmount2, ticketWiseAmount2, ticketWiseTotalForC2,

    }}> <>
            <Modal
                visible={showTrip1Modal}
                headerText="Trip Wise Collection"
                onClose={handleTrip1ModalClose}
                size="lg"
                centered

            >
                <TripWiseCollectionC1 />
            </Modal>
            <Modal
                visible={showTrip2Modal}
                headerText="Trip Wise Collection"
                onClose={handleTrip2ModalClose}
                size="lg"
                centered

            >
                <TripWiseCollectionC2 />
            </Modal>

            <Modal
                visible={showTicket1Modal}
                headerText="Ticket Wise Collection 1"
                onClose={handleTicket1ModalClose}
                size="lg"
                centered
            >
                <TicketWiseCollectionC1 />
            </Modal>
            <Modal
                visible={showTicket2Modal}
                headerText="Ticket Wise Collection 2"
                onClose={handleTicket2ModalClose}
                size="lg"
                centered
            >
                <TicketWiseCollectionC2 />
            </Modal>

            <Modal
                visible={showExpense1Modal}
                headerText="Expense 1"
                onClose={handleExpense1ModalClose}
                size='lg'
                centered
            >
                <ExpenseC1 />
            </Modal>
            <Modal
                visible={showExpense2Modal}
                headerText="Expense 2"
                onClose={handleExpense2ModalClose}
                size='lg'
                centered
            >
                <ExpenseC2 />
            </Modal>

            <VerticalLayout>
                <PageTitle
                    title={formType === FormTypeList.ADD ? 'Add Collection' : 'Edit Collection'}
                />
                <div className="card">
                    <div className="card-body">
                        <div className='container-fluid overflow-auto'>
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-6">
                                    <FormInput
                                        label='Invoice'
                                        value={invoiceNo}
                                        onChange={handleInvoiceNo}
                                        placeholder="Invoice.No"
                                        errorText={invoiceNoError}
                                        containerClass="mb-2"
                                    />
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-4">
                                    <FormInput
                                        label='Route'
                                        labelClassName="required"
                                        value={routeId}
                                        onChange={handleRouteId}
                                        type='select'
                                        errorText={routeIdError}
                                    >
                                        <option value="">Select</option>
                                        {routeMaster.filter(rm => {
                                            return rm.code !== "HIRE"
                                        }).map(rm => {
                                            return <Option value={rm.id} key={rm.key}>{rm.name}</Option>
                                        })}
                                    </FormInput>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-6">
                                    <FormInput
                                        label='Date'
                                        labelClassName="required"
                                        value={date}
                                        onChange={handleDate}
                                        placeholder="Date"
                                        errorText={dateError}
                                        containerClass="mb-2"
                                        type="date"
                                    />
                                </div>
                            </div>

                            <div className="text-center my-4">
                                <h3 className="fw-bold">Vehicle Details</h3>
                            </div>

                            <div className="row">

                                <div className="col-sm-6 col-md-4 col-lg-3">
                                    <FormInput
                                        label='Vehicle'
                                        labelClassName="required"
                                        value={vehicleId}
                                        onChange={handleVehicleId}
                                        type='select'
                                        errorText={vehicleIdError}
                                    >
                                        <option value="">Select</option>
                                        {vehicleMaster.map(rm => {
                                            return <Option value={rm.id} key={rm.key}>{rm.name}</Option>
                                        })}
                                    </FormInput>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <FormInput
                                        label='Starting KM'
                                        labelClassName="required"
                                        value={startingKm}
                                        onChange={handleStartingKm}
                                        placeholder="Starting KM"
                                        errorText={startingKmError}
                                        containerClass="mb-2"
                                    />
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <FormInput
                                        label='Ending Km'
                                        labelClassName="required"
                                        value={endingKm}
                                        onChange={handleEndingKm}
                                        placeholder="Ending Km"
                                        errorText={endingKmError}
                                        containerClass="mb-2"
                                    />
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <FormInput
                                        label='Diesel(ltr)'
                                        labelClassName="required"
                                        value={dieselInLitre}
                                        onChange={handleDieselInLitre}
                                        placeholder="Diesel In Litre"
                                        errorText={dieselInLitreError}
                                        containerClass="mb-2"
                                    />
                                </div>

                            </div>

                            {/* <div className="row">

                                <div className="col-sm-6 col-md-4 col-lg-3">
                                    <FormInput
                                        value={spareVehicleId}
                                        onChange={handleSpareVehicleId}
                                        type='select'
                                        errorText={spareVehicleIdError}
                                        label='Spare Vehicle'
                                    >
                                        <option value="">Select</option>
                                        {vehicleMaster.map(vm => {

                                            return <Option value={vm.id} key={vm.key}>{vm.name}</Option>
                                        })}
                                    </FormInput>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <FormInput
                                        label='Starting KM'
                                        value={spareStartingKm}
                                        onChange={handleSpareStartingKm}
                                        placeholder="Starting KM"
                                        errorText={spareStartingKmError}
                                        containerClass="mb-2"
                                    />
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <FormInput
                                        label='Ending Km'
                                        value={spareEndingKm}
                                        onChange={handleSpareEndingKm}
                                        placeholder="Ending Km"
                                        errorText={spareEndingKmError}
                                        containerClass="mb-2"
                                    />
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <FormInput
                                        label='Diesel(ltr)'
                                        value={spareDieselInLitre}
                                        onChange={handleSpareDieselInLitre}
                                        placeholder="Diesel In Litre"
                                        errorText={spareDieselInLitreError}
                                        containerClass="mb-2"
                                    />
                                </div>

                            </div> */}

                            <div className="row">

                                <div className="col-sm-6 col-md-4 col-lg-4">
                                    <FormInput
                                        label='Driver'
                                        labelClassName="required"
                                        value={driver}
                                        onChange={handleDriver}
                                        type='select'
                                        errorText={driverError}
                                        containerClass="mb-2"
                                    >
                                        <Option value='' >Select</Option>
                                        {employee.filter(em => {
                                            return em.type === EmployeeTypeList.DRIVER
                                        }).map(e => {
                                            return <Option value={e.id} key={e.key}>{e.name}</Option>
                                        })}
                                    </FormInput>
                                </div>

                                <div className="col-lg-4 col-md-4 col-sm-6">
                                    <FormInput
                                        label='Cleaner'
                                        labelClassName="required"
                                        value={cleaner}
                                        onChange={handleCleaner}
                                        placeholder="Cleaner"
                                        errorText={cleanerError}
                                        type='select'
                                        containerClass="mb-2"
                                    >
                                        <Option value='' >Select</Option>
                                        {employee.filter(em => {
                                            return em.type === EmployeeTypeList.CLEANER
                                        }).map(e => {
                                            return <Option value={e.id} key={e.key}>{e.name}</Option>
                                        })}
                                    </FormInput>
                                </div>

                            </div>

                            <div className="row mt-2">

                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <FormInput
                                        label='Conductor 1'
                                        labelClassName="required"
                                        value={conductor1}
                                        onChange={handleConductor1}
                                        errorText={conductor1Error}
                                        type='select'
                                        containerClass="mb-2"
                                    >
                                        <Option value='' >Select</Option>
                                        {employee.filter(em => {
                                            return em.type === EmployeeTypeList.CONDUCTOR
                                        }).map(e => {
                                            return <Option value={e.id} key={e.key}>{e.name}</Option>
                                        })}
                                    </FormInput>
                                </div>

                                <div className="col-sm-6 col-md-4 col-lg-3">
                                    <FormInput
                                        label='Machine'
                                        value={machineId1}
                                        onChange={handleMachineId1}
                                        type='select'
                                        errorText={machineId1Error}
                                        containerClass="mb-2"
                                    >
                                        <Option value='' >Select</Option>
                                        {machineMaster.map(mm => {
                                            return <Option value={mm.id} key={mm.key}>{mm.code} - {mm.name}</Option>
                                        })}
                                    </FormInput>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <FormInput
                                        label='Ticket Starting Number'
                                        value={startingNumber1}
                                        onChange={handleStartingNumber1}
                                        placeholder="Ticket Starting Number"
                                        errorText={startingNumber1Error}
                                        type='number'
                                        containerClass="mb-2"
                                    />
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <FormInput
                                        label='Ticket Ending Number'
                                        value={endingNumber1}
                                        onChange={handleEndingNumber1}
                                        placeholder="Ticket Ending Number"
                                        errorText={endingNumber1Error}
                                        type='number'
                                        containerClass="mb-2"
                                    />
                                </div>

                            </div>

                            <div className="row">
                                <div className="my-2 col-lg-3 col-md-4 col-sm-6">
                                    <Link to="#" className="fw-bold me-1 fs-4" onClick={tripWiseCollection1ClickHandler}>Trip Collection</Link>:<label className="ms-1 fs-4">{rupeeFormat(tripWiseTotalForC1)}</label>
                                </div>
                                <div className="my-2 col-lg-3 col-md-4 col-sm-6">
                                    <Link to="#" className="fw-bold me-1 fs-4" onClick={ticketWiseCollection1ClickHandler}>Ticket Collection</Link>:<label className="ms-1 fs-4">{rupeeFormat(ticketWiseTotalForC1)}
                                    </label>

                                </div>
                                <div className="my-2 col-lg-3 col-md-4 col-sm-6">
                                    <Link to="#" className="fw-bold me-1 fs-4" onClick={expense1ClickHandler}>Expense</Link>:<label className="ms-1 fs-4">{rupeeFormat(expense1Total ? expense1Total : 0)}</label>
                                </div>
                                <div className="mt-2 mb-0 col-lg-3 col-md-4 col-sm-6">
                                    <h4>Refund : {rupeeFormat(refund1)}</h4>
                                </div>
                            </div>
                            <hr className="w-2" />

                            <div className="row mt-1">

                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <FormInput
                                        label='Conductor 2'
                                        // labelClassName="required"
                                        value={conductor2}
                                        onChange={handleConductor2}
                                        errorText={conductor2Error}
                                        type='select'
                                        containerClass="mb-2"
                                    >
                                        <Option value='' >Select</Option>
                                        {employee.filter(em => {
                                            return em.type === EmployeeTypeList.CONDUCTOR
                                        }).map(e => {
                                            return <Option value={e.id} key={e.key}>{e.name}</Option>
                                        })}
                                    </FormInput>
                                </div>

                                <div className="col-sm-6 col-md-4 col-lg-3">
                                    <FormInput
                                        label='Machine'
                                        value={machineId2}
                                        onChange={handleMachineId2}
                                        type='select'
                                        errorText={machineId2Error}
                                        containerClass="mb-2"
                                    >
                                        <Option value='' >Select</Option>
                                        {machineMaster.map(mm => {
                                            return <Option value={mm.id} key={mm.key}>{mm.code} - {mm.name}</Option>
                                        })}
                                    </FormInput>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <FormInput
                                        label='Ticket Starting Number'
                                        value={startingNumber2}
                                        onChange={handleStartingNumber2}
                                        placeholder="Ticket Starting Number"
                                        errorText={startingNumber2Error}
                                        type='number'
                                        containerClass="mb-2"
                                    />
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <FormInput
                                        label='Ticket Ending Number'
                                        value={endingNumber2}
                                        onChange={handleEndingNumber2}
                                        placeholder="Ticket Ending Number"
                                        errorText={endingNumber2Error}
                                        type='number'
                                        containerClass="mb-2"
                                    />
                                </div>

                            </div>

                            <div className="row">
                                <div className="my-2 col-lg-3 col-md-4 col-sm-6">
                                    <Link to="#" className="fw-bold me-1 fs-4" onClick={tripWiseCollection2ClickHandler}>Trip Collection</Link>:<label className="ms-1 fs-4">{rupeeFormat(tripWiseTotalForC2)}</label>
                                </div>
                                <div className="my-2 col-lg-3 col-md-4 col-sm-6">
                                    <Link to="#" className="fw-bold me-1 fs-4" onClick={ticketWiseCollection2ClickHandler}>Ticket Collection</Link>:<label className="ms-1 fs-4">{rupeeFormat(ticketWiseTotalForC2)}
                                    </label>

                                </div>
                                <div className="my-2 col-lg-3 col-md-4 col-sm-6">
                                    <Link to="#" className="fw-bold me-1 fs-4" onClick={expense2ClickHandler}>Expense</Link>:<label className="ms-1 fs-4">{rupeeFormat(expense2Total ? expense2Total : 0)}</label>
                                </div>
                                <div className="mt-2 mb-0 col-lg-3 col-md-4 col-sm-6">
                                    <h4>Refund : {rupeeFormat(refund2)}</h4>
                                </div>
                            </div>
                            <hr className="w-2" />

                            <div className="row">
                                <div className="my-2 col-lg-3 col-md-4 col-sm-6">
                                    <h4 className="fw-bold me-1 fs-4">Total Trip Collection: {rupeeFormat(TotalTripCollection)} </h4>
                                </div>
                                <div className="my-2 col-lg-3 col-md-4 col-sm-6">
                                    <h4 className="fw-bold me-1 fs-4">Total Ticket Collection: {rupeeFormat(TotalTicketCollection)}</h4>

                                </div>
                                <div className="my-2 col-lg-3 col-md-4 col-sm-6">
                                    <h4 className="fw-bold me-1 fs-4">Total Expense:{rupeeFormat(TotalExpense ? TotalExpense : 0)}</h4>
                                </div>
                                <div className="mt-2 mb-0 col-lg-3 col-md-4 col-sm-6">
                                    <h4 className="fw-bold me-1 fs-4">Total Refund: {rupeeFormat(TotalRefund)}</h4>
                                </div>
                            </div>


                            <div className="row mt-3">
                                <div className="col-lg-4">
                                    <FormInput
                                        label='Shortage'
                                        value={shortage}
                                        onChange={handleShortage}
                                        placeholder="Shortage"
                                        errorText={shortageError}
                                        containerClass="mb-2"
                                        type="number"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-4">
                                    <FormInput
                                        label='Excess'
                                        value={excess}
                                        onChange={handleExcess}
                                        placeholder="Excess"
                                        errorText={excessError}
                                        type="number"
                                        containerClass="mb-2"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-4">
                                    <FormInput
                                        label='Balance'
                                        labelClassName="required"
                                        value={balance}
                                        onChange={handleBalance}
                                        placeholder="Balance"
                                        errorText={balanceError}
                                        type="number"
                                        containerClass="mb-2"
                                    />
                                </div>
                            </div>

                            <div className="text-end">
                                <button className="btn btn-success" onClick={handleSave}>Save</button>
                            </div>

                        </div>
                    </div>
                </div>
            </VerticalLayout>

        </>
    </CollectionContext.Provider>
}
export default CollectionForm