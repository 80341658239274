import { ChangeEventHandler, MouseEventHandler, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { fetchAccountHolder } from "../redux/actions/accountHolderActions"
import { AccountsMasterType, addAccountsMaster, fetchAccountsMaster, updateAccountsMaster } from "../redux/actions/accountsMasterActions"
import { AppDispatch } from "../redux/store"
import { FormInput } from "./custom/components"
import { ToastTypes, useToast } from "./toast/ToastProvider"
import { FormType, FormTypeList } from "./types"

interface Props {
    formType: FormType
    editData?: AccountsMasterType
    onSave: () => void
}

const AccountsMasterForm = ({ formType, onSave, editData }: Props) => {
    const toast = useToast()

    const dispatch = useDispatch<AppDispatch>()
    // const supplier = useSelector<StoreState, Array<AccountsMasterType & Key>>(state => addKey(state.supplier))

    // const [code, setCode] = useState<string>('')
    // const [codeError, setCodeError] = useState<string>('')
    const [name, setName] = useState<string>('')
    const [nameError, setNameError] = useState<string>('')
    const [bank, setBank] = useState<string>('')
    const [bankError, setBankError] = useState<string>('')
    const [branch, setBranch] = useState<string>('')
    const [branchError, setBranchError] = useState<string>('')
    const [address, setAddress] = useState<string>('')
    const [addressError, setAddressError] = useState<string>('')
    const [ifscCode, setIfscCode] = useState<string>('')
    const [ifscCodeError, setIfscCodeError] = useState<string>('')
    const [contactNo, setContactNo] = useState<string>('')
    const [contactNoError, setContactNoError] = useState<string>('')
    const [oldBalance, setOldBalance] = useState<string>('')
    const [oldBalanceError, setOldBalanceError] = useState<string>('')
    const [oldBalanceDate, setOldBalanceDate] = useState<string>('')
    const [oldBalanceDateError, setOldBalanceDateError] = useState<string>('')

    const resetStates = () => {
        // setCode('')
        // setCodeError('')
        setName('')
        setNameError('')
        setAddress('')
        setAddressError('')
        setBank('')
        setBankError('')
        setContactNo('')
        setContactNoError('')
        setBranch('')
        setBranchError('')
        setIfscCode('')
        setIfscCodeError('')
        setOldBalance('')
        setOldBalanceError('')
        setOldBalanceDate('')
        setOldBalanceDateError('')
    }

    const handleOldBalance: ChangeEventHandler<HTMLInputElement> = (e) => {
        setOldBalance(e.target.value)
        setOldBalanceError('')
    }

    const handleOldBalanceDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setOldBalanceDate(e.target.value)
        setOldBalanceDateError('')
    }

    // const handleCode: ChangeEventHandler<HTMLInputElement> = (e) => {
    //     setCode(e.target.value)
    //     setCodeError('')
    // }

    const handleName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setName(e.target.value)
        setNameError('')
    }

    const handleAddress: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAddress(e.target.value)
        setAddressError('')
    }


    const handleBank: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBank(e.target.value)
        setBankError('')
    }

    const handleContactNo: ChangeEventHandler<HTMLInputElement> = (e) => {
        setContactNo(e.target.value)
        setContactNoError('')
    }

    const handleBranch: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBranch(e.target.value)
        setBranchError('')
    }
    const handleIfscCode: ChangeEventHandler<HTMLInputElement> = (e) => {
        setIfscCode(e.target.value)
        setIfscCodeError('')
    }

    const handleClear: MouseEventHandler<HTMLButtonElement> = (e) => {
        resetStates()
    }

    const handleSave = () => {
        let error = false
        const data: AccountsMasterType = {
            // code: code,
            name: name,
            bank: bank,
            branch: branch,
            address: address,
            ifscCode: ifscCode,
            contactNo: contactNo,
            oldBalance: oldBalance,
            oldBalanceDate: oldBalanceDate,
        }

        // if (formType === FormTypeList.ADD) {
        //     if (data.code === "") {
        //         setCodeError('Code required')
        //         error = true
        //     } else if ((data.code).length > 15) {
        //         setCodeError('Code must be within 15 characters')
        //         error = true
        //     } else if (data.code !== "") {
        //         const uniqueIndex = supplier.findIndex(s => {
        //             return s.code.toLowerCase() === data.code.toLowerCase()
        //         })
        //         if (uniqueIndex > -1) {
        //             setCodeError('Code already exists')
        //             error = true
        //         }
        //     }
        // }

        // if (formType === FormTypeList.UPDATE) {
        //     if (data.code === "") {
        //         setCodeError('Code required')
        //         error = true
        //     } else if ((data.code).length > 15) {
        //         setCodeError('Code must be within 15 characters')
        //         error = true
        //     }
        // }

        // if (data.code !== "" && data.code !== undefined) {
        //     if ((data.code).length > 15) {
        //         setCodeError('Code must be within 15 characters')
        //         error = true
        //     }
        // }

        if (data.name === "") {
            setNameError('Name required')
            error = true
        } else if ((data.name).length > 30) {
            setNameError('Name must be within 30 characters')
            error = true
        }

        if (data.bank !== "" && data.bank !== undefined && (data.bank).length > 50) {
            setBankError('Bank must be within 50 characters')
            error = true
        }

        if (data.branch !== "" && data.branch !== undefined && (data.branch)?.length > 30) {
            setBranchError('Branch should be within 30 characters')
            error = true
        }

        if (data.address !== "" && data.address !== undefined) {
            if ((data.address)?.length > 100) {
                setAddressError('Address should be within 100 characters')
                error = true
            }
        }

        if (data.contactNo !== "" && data.contactNo !== undefined && (data.contactNo)?.length > 10) {
            setContactNoError('Invalid Contact No')
            error = true
        }

        if (data.ifscCode !== "" && data.ifscCode !== undefined && (data.ifscCode)?.length > 15) {
            setIfscCodeError('IFSC Code should be  within 15 characters')
            error = true
        }

        if (data.oldBalance !== "" && data.oldBalance !== undefined) {
            if (parseFloat((data.oldBalance)) < 0) {
                setOldBalanceError('Invalid Old Balance')
                error = true
            }
        }

        if (data.oldBalance !== "" && data.oldBalance !== undefined && parseFloat((data.oldBalance)) > 0) {
            if ((data.oldBalanceDate) === '') {
                setOldBalanceDateError('Old Balance Date required')
                error = true
            }
        }

        if (!error) {
            if (formType === FormTypeList.ADD) {
                dispatch(addAccountsMaster(data)).then(text => {
                    toast(text, ToastTypes.SUCCESS)
                    onSave()
                    dispatch(fetchAccountsMaster())
                    dispatch(fetchAccountHolder())
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            } else if (formType === FormTypeList.UPDATE && editData !== undefined && editData.id !== undefined) {
                dispatch(updateAccountsMaster(data, editData.id)).then(text => {
                    toast(text, ToastTypes.SUCCESS)
                    onSave()
                    dispatch(fetchAccountsMaster())
                    dispatch(fetchAccountHolder())
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
        }
    }

    useEffect(() => {
        if (formType === FormTypeList.UPDATE && editData !== undefined) {

            // setCode(editData.code !== undefined ? editData.code : '')
            setName(editData.name)
            setBank(editData.bank !== undefined ? editData.bank : '')
            setBranch(editData.branch !== undefined ? editData.branch : '')
            setAddress(editData.address !== undefined ? editData.address : '')
            setIfscCode(editData.ifscCode !== undefined ? editData.ifscCode : '')
            setContactNo(editData.contactNo !== undefined ? editData.contactNo : '')
            setOldBalance(editData.oldBalance !== undefined ? editData.oldBalance : '')
            setOldBalanceDate(editData.oldBalanceDate !== undefined ? editData.oldBalanceDate : '')

        } else {
            resetStates()
        }
    }, [formType, editData])


    return <div className='container'>
        <div className="row">

            {/* <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Code'
                    labelClassName="required"
                    value={code}
                    onChange={handleCode}
                    placeholder='Code'
                    errorText={codeError}
                    containerClass="mb-2"
                />
            </div> */}

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Account Name'
                    labelClassName="required"
                    value={name}
                    onChange={handleName}
                    placeholder='Name'
                    errorText={nameError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Address'
                    // labelClassName="required"
                    value={address}
                    onChange={handleAddress}
                    placeholder='Address'
                    errorText={addressError}
                    containerClass="mb-2"
                    type="textarea"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Bank'
                    value={bank}
                    onChange={handleBank}
                    placeholder='bank'
                    errorText={bankError}
                    containerClass="mb-2"
                />
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Branch'
                    value={branch}
                    onChange={handleBranch}
                    placeholder='Branch'
                    errorText={branchError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='IFSC Code'
                    value={ifscCode}
                    onChange={handleIfscCode}
                    placeholder='IFSC Code'
                    errorText={ifscCodeError}
                    containerClass="mb-2"
                />
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Contact No'
                    // labelClassName="required"
                    value={contactNo}
                    onChange={handleContactNo}
                    placeholder='Contact No'
                    errorText={contactNoError}
                    containerClass="mb-2"
                    type="number"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Old Balance'
                    value={oldBalance}
                    onChange={handleOldBalance}
                    placeholder='Old Balance'
                    errorText={oldBalanceError}
                    containerClass="mb-2"
                    type="number"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Old Balance Date'
                    value={oldBalanceDate}
                    onChange={handleOldBalanceDate}
                    placeholder='Old Balance Date'
                    errorText={oldBalanceDateError}
                    containerClass="mb-2"
                    type="date"
                />
            </div>
        </div>
        <div className="d-flex justify-content-end hstack gap-1">
            <button className="btn btn-primary" onClick={handleClear}>Clear</button>
            <button className="btn btn-success" onClick={handleSave}>Save</button>
        </div>

    </div>
}
export default AccountsMasterForm