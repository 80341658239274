/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../reducers"

export enum SupplierActionList {
    ADD_SUPPLIER = 'ADD_SUPPLIER',
    FETCH_SUPPLIER = 'FETCH_SUPPLIER',
    UPDATE_SUPPLIER = 'UPDATE_SUPPLIER',
    DELETE_SUPPLIER = 'DELETE_SUPPLIER',
}

export interface SupplierType {
    // id:PK,
    // code:FT UK,
    // name:FT,
    // contactPerson:FT,
    // mobileNumber:FT,
    // GSTNumber:FT,
    // aadharNumber:FT

    id?: number
    code?: string
    name: string
    address?: string
    contactPerson: string
    mobileNumber: string
    gstNumber?: string
    aadharNumber?: string
    panNumber?: string
    createdBy?: string
    createTimestamp?: string

    oldBalance?: string
    oldBalanceDate?: string

}

export interface AddSupplierAction {
    type: SupplierActionList.ADD_SUPPLIER
    data: SupplierType
}

export interface FetchSupplierAction {
    type: SupplierActionList.FETCH_SUPPLIER
    data: Array<SupplierType>
}

export interface UpdateSupplierAction {
    type: SupplierActionList.UPDATE_SUPPLIER
    data: SupplierType
}

export interface DeleteSupplierAction {
    type: SupplierActionList.DELETE_SUPPLIER
    data: number
}

export type SupplierActions = AddSupplierAction | FetchSupplierAction | UpdateSupplierAction | DeleteSupplierAction

export const addSupplier = (data: SupplierType) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Supplier Added")
        return api.post<APIResponse<SupplierType>>('supplier/', data, config).then(response => {
            if (response.status === 200 && response.data.data) {

                dispatch<AddSupplierAction>({
                    type: SupplierActionList.ADD_SUPPLIER,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Supplier Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const updateSupplier = (data: SupplierType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Supplier Updated")
        return api.put<APIResponse<SupplierType>>(`supplier/?id=${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<UpdateSupplierAction>({
                    type: SupplierActionList.UPDATE_SUPPLIER,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Supplier Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchSupplier = () => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<SupplierType[]>>('supplier/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchSupplierAction>({
                    type: SupplierActionList.FETCH_SUPPLIER,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteSupplier = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Supplier Deleted")
        return api.delete<APIResponse<SupplierType>>(`supplier/?id=${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteSupplierAction>({
                    type: SupplierActionList.DELETE_SUPPLIER,
                    data: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Supplier Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}

