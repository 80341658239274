import { Action } from "../actions/types"
import { TripDetailsType, TripDetailsActionList } from "../actions/tripDetailsActions"

const initialValue: Array<TripDetailsType> = []

const tripDetailsReducer = (state: Array<TripDetailsType> = initialValue, action: Action) => {
    switch (action.type) {

        case TripDetailsActionList.ADD_TRIP_DETAILS:
            return [
                ...state,
                action.data,
            ]

        case TripDetailsActionList.UPDATE_TRIP_DETAILS:
            const updated = [...state].slice()
            const index = updated.findIndex(u => u.id === action.data.id)

            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }

            return updated

        case TripDetailsActionList.FETCH_TRIP_DETAILS:
            return action.data

        default:
            return state
    }
}

export default tripDetailsReducer