/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../reducers"

export enum TransactionActionList {
    ADD_TRANSACTION = 'ADD_TRANSACTION',
    FETCH_TRANSACTION = 'FETCH_TRANSACTION',
    UPDATE_TRANSACTION = 'UPDATE_TRANSACTION',
    DELETE_TRANSACTION = 'DELETE_TRANSACTION',
}

export enum VoucherTypes {
    RECEIPT = 'Receipt',
    PAYMENT = 'Payment',
}

export enum PaymentModes {
    CASH = 'Cash',
    BANK = 'Bank',
}

export enum TransactionTypes {
    BUS = 'Bus',
    GENERAL = 'General',
}

export type TransactionVoucherTypes = VoucherTypes.PAYMENT | VoucherTypes.RECEIPT
export type TransactionPaymentModes = PaymentModes.CASH | PaymentModes.BANK
export type TransactionForTypes = TransactionTypes.BUS | TransactionTypes.GENERAL

export interface TransactionType {

    id?: number
    number?: string
    orderNumber?: number
    financialYear: string
    branch: string
    voucherType: TransactionVoucherTypes
    paymentMode: TransactionPaymentModes
    transactionFor: TransactionForTypes
    routeId?: number
    source?: string
    sourceId?: number
    accountGroupName: string
    accountNumber: string
    accountName: string
    accountType: string
    date: string
    particulars: string
    partyAccount?: string
    partyAccountHead?: string
    details?: string
    billNo?: string//only for vehicle service entries in party Ledger report
    debitAmount: string
    creditAmount: string
    balanceAmount?: string
    refNo?: string
    openingBalance?: string //for monthly report only
    createdBy?: string
    createTimestamp?: string
    bankNumber?: string
    bankName?: string

}

export interface AddTransactionAction {
    type: TransactionActionList.ADD_TRANSACTION
    data: TransactionType
}

export interface FetchTransactionAction {
    type: TransactionActionList.FETCH_TRANSACTION
    data: Array<TransactionType>
}

export interface UpdateTransactionAction {
    type: TransactionActionList.UPDATE_TRANSACTION
    data: TransactionType
}

export interface DeleteTransactionAction {
    type: TransactionActionList.DELETE_TRANSACTION
    data: number
}

export type TransactionActions = AddTransactionAction | FetchTransactionAction | UpdateTransactionAction | DeleteTransactionAction

export const addTransaction = (data: TransactionType) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Transaction Added")
        return api.post<APIResponse<TransactionType>>('transaction/', data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<AddTransactionAction>({
                    type: TransactionActionList.ADD_TRANSACTION,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Transaction Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const updateTransaction = (data: TransactionType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)
        // return Promise.resolve("Transaction Updated")

        return api.put<APIResponse<TransactionType>>(`transaction/?id=${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<UpdateTransactionAction>({
                    type: TransactionActionList.UPDATE_TRANSACTION,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Transaction Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchTransaction = () => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)


        return api.get<APIResponse<TransactionType[]>>('transaction/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchTransactionAction>({
                    type: TransactionActionList.FETCH_TRANSACTION,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteTransaction = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Transaction Deleted")
        return api.delete<APIResponse<TransactionType>>(`transaction/?id=${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteTransactionAction>({
                    type: TransactionActionList.DELETE_TRANSACTION,
                    data: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Transaction Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}

