import { ChangeEventHandler, MouseEventHandler, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormInput } from '../components/custom/components'
import PageTitle from '../components/custom/components/PageTitle'
import VerticalLayout from '../components/custom/layouts/Vertical'
import Modal from '../components/Modal'
import Option from '../components/Option'
import { ToastTypes, useToast } from '../components/toast/ToastProvider'
import { FormType, FormTypeList } from '../components/types'
import VehicleRouteMappingMasterForm from '../components/VehicleRouteMappingMasterForm'
import { addKey, isInToday } from '../config/functions'
import { AuthUserType } from '../redux/actions/authUserActions'
import { RouteMasterType } from '../redux/actions/routeMasterActions'
import { Key } from '../redux/actions/types'
import { VehicleMasterType } from '../redux/actions/vehicleMasterActions'
import { deleteVehicleRouteMappingMaster, VehicleRouteMappingMasterType } from '../redux/actions/vehicleRouteMappingMasterActions'
import { StoreState } from '../redux/reducers'
import { AppDispatch } from '../redux/store'

interface TableBody {
    sno: number
    vehicleName: string
    routeName: string
    onEdit: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
    timeStamp: string
    authUser: AuthUserType
}

type HandleEdit = (value: VehicleRouteMappingMasterType) => void
type HandleDelete = (value: VehicleRouteMappingMasterType) => void

const VehicleRouteMappingMaster = () => {
    const toast = useToast()
    const dispatch = useDispatch<AppDispatch>()
    const authUser = useSelector<StoreState, AuthUserType>(state => state.authUser)

    const vehicleRouteMappingMasterList = useSelector<StoreState, Array<VehicleRouteMappingMasterType & Key>>(state => addKey(state.vehicleRouteMappingMaster))
    const routeMaster = useSelector<StoreState, Array<RouteMasterType & Key>>(state => addKey(state.routeMaster))
    const vehiclemaster = useSelector<StoreState, Array<VehicleMasterType & Key>>(state => addKey(state.vehicleMaster))

    const [routeId, setRouteId] = useState<string>('')
    const [vehicleId, setVehicleId] = useState<string>('')

    const [showForm, setShowForm] = useState<boolean>(false)
    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    const [editData, setEditData] = useState<VehicleRouteMappingMasterType>()


    const vehicleRouteMappingMaster = useMemo<Array<VehicleRouteMappingMasterType & Key>>(() => {
        return vehicleRouteMappingMasterList.filter(vrm => {
            return routeId !== '' ? vrm.routeId === parseInt(routeId) : true
        }).filter(vrm => {
            return vehicleId !== '' ? vrm.vehicleId === parseInt(vehicleId) : true
        })
    }, [routeId, vehicleId, vehicleRouteMappingMasterList])


    const handleRouteId: ChangeEventHandler<HTMLInputElement> = (e) => {
        setRouteId(e.target.value)
    }

    const handleVehicleId: ChangeEventHandler<HTMLInputElement> = (e) => {
        setVehicleId(e.target.value)
    }

    const handleAdd = () => {
        setShowForm(true)
    }

    const handleEdit: HandleEdit = (value) => {
        setFormType(FormTypeList.UPDATE)
        setEditData(value)
        setShowForm(true)
    }

    const handleDelete: HandleDelete = (value) => {
        if (value.id !== undefined) {
            dispatch(deleteVehicleRouteMappingMaster(value.id)).then(text => {
                toast(text)
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
        }
    }

    const handleFormClose = () => {
        setShowForm(false)
        setFormType(FormTypeList.ADD)
        setEditData(undefined)
    }


    return <>
        <Modal
            headerText={formType === FormTypeList.ADD ? 'Add Route Mapping Master' : 'Edit Route Mapping Master'}
            visible={showForm}
            onClose={handleFormClose}
            size='lg'
            centered
        >
            <VehicleRouteMappingMasterForm
                formType={formType}
                editData={editData}
                onSave={handleFormClose}
            />
        </Modal>

        <VerticalLayout>
            <PageTitle
                title='Vehicle Route Mapping Master'
                buttonText='ADD'
                onButtonClick={handleAdd}
            />
            <div className='card'>
                <div className='card-body'>
                    <div className='container-fluid'>
                        <div className="row">
                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <FormInput
                                    value={routeId}
                                    onChange={handleRouteId}
                                    type='select'
                                    label='Route'
                                >
                                    <option value="">All</option>
                                    {routeMaster.filter(rm => {
                                        return rm.code !== "HIRE"
                                    }).map(rm => {
                                        return <Option value={rm.id} key={rm.key}>{rm.name}</Option>
                                    })}
                                </FormInput>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <FormInput
                                    value={vehicleId}
                                    onChange={handleVehicleId}
                                    type='select'
                                    label='Vehicle'
                                >
                                    <option value="">All</option>
                                    {vehiclemaster.map(vm => {
                                        return <Option value={vm.id} key={vm.key}>{vm.name}</Option>
                                    })}
                                </FormInput>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="card-body">
                    <div className='table-wrapper'>
                        <table className='table colored'>
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    <th className='text-truncate align-middle'>Route Name</th>
                                    <th className='text-truncate align-middle'>Vehicle Name</th>
                                    <th className='text-truncate align-middle'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {vehicleRouteMappingMaster.map((vrm, i) => {
                                    return <VehicleRouteMappingMasterBody
                                        sno={i + 1}
                                        vehicleName={vrm.vehicleName}
                                        routeName={vrm.routeName}
                                        key={vrm.key}
                                        authUser={authUser}
                                        timeStamp={vrm.createTimestamp !== undefined ? vrm.createTimestamp : ''}
                                        onEdit={() => handleEdit(vrm)}
                                        onDelete={() => handleDelete(vrm)}
                                    />
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </VerticalLayout>
    </>
}

const VehicleRouteMappingMasterBody = ({ sno, routeName, vehicleName, onEdit, onDelete, timeStamp, authUser }: TableBody) => {
    const ShowEditForManager = isInToday(timeStamp)

    return <tr>
        <td>{sno}</td>
        <td>{routeName}</td>
        <td>{vehicleName}</td>
        <td>
            <div className='hstack gap-1'>
                {(authUser.role === 'admin' || authUser.role === 'super' || authUser.role === 'Admin' || authUser.role === 'Super') && <button className='btn btn-xs btn-primary' onClick={onEdit}>Edit</button>}
                {(authUser.role === 'Manager' || authUser.role === 'manager') && ShowEditForManager && <button className='btn btn-xs btn-primary' onClick={onEdit}>Edit</button>}
                <button className='btn btn-xs btn-danger' onClick={onDelete}>Delete</button>
            </div>
        </td>
    </tr>
}

export default VehicleRouteMappingMaster
