/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../reducers"

export enum RouteMasterActionList {
    ADD_ROUTE_MASTER = 'ADD_ROUTE_MASTER',
    FETCH_ROUTE_MASTER = 'FETCH_ROUTE_MASTER',
    UPDATE_ROUTE_MASTER = 'UPDATE_ROUTE_MASTER',
    DELETE_ROUTE_MASTER = 'DELETE_ROUTE_MASTER',
}

export interface RouteMasterType {
    id?: number
    code?: string
    name: string
    startPoint: string
    endPoint: string
    totalKm: string
    stopsNumber: string
    stageNumber: string
    tripsPerDay: string
    createdBy?: string
    createTimestamp?: string
}

export interface AddRouteMasterAction {
    type: RouteMasterActionList.ADD_ROUTE_MASTER
    data: RouteMasterType
}

export interface FetchRouteMasterAction {
    type: RouteMasterActionList.FETCH_ROUTE_MASTER
    data: Array<RouteMasterType>
}

export interface UpdateRouteMasterAction {
    type: RouteMasterActionList.UPDATE_ROUTE_MASTER
    data: RouteMasterType
}

export interface DeleteRouteMasterAction {
    type: RouteMasterActionList.DELETE_ROUTE_MASTER
    data: number
}

export type RouteMasterActions = AddRouteMasterAction | FetchRouteMasterAction | UpdateRouteMasterAction | DeleteRouteMasterAction

export const addRouteMaster = (data: RouteMasterType) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Route Master Added")
        return api.post<APIResponse<RouteMasterType>>('route_master/', data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<AddRouteMasterAction>({
                    type: RouteMasterActionList.ADD_ROUTE_MASTER,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Route Master Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const updateRouteMaster = (data: RouteMasterType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Route Master Updated")
        return api.put<APIResponse<RouteMasterType>>(`route_master/?id=${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<UpdateRouteMasterAction>({
                    type: RouteMasterActionList.UPDATE_ROUTE_MASTER,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Route Master Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchRouteMaster = () => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<RouteMasterType[]>>('route_master/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchRouteMasterAction>({
                    type: RouteMasterActionList.FETCH_ROUTE_MASTER,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteRouteMaster = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Route Master Deleted")
        return api.delete<APIResponse<RouteMasterType>>(`route_master/?id=${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteRouteMasterAction>({
                    type: RouteMasterActionList.DELETE_ROUTE_MASTER,
                    data: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Route Master Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}

