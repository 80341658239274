/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import { default as vehicleReadingData } from '../dataSet/vehicleReading.json'
import { GetState } from "../reducers"

export enum VehicleReadingActionList {
    ADD_VEHICLE_READING = 'ADD_VEHICLE_READING',
    FETCH_VEHICLE_READING = 'FETCH_VEHICLE_READING',
    UPDATE_VEHICLE_READING = 'UPDATE_VEHICLE_READING',
}

export interface VehicleReadingType {
    id?: number
    vehicleId: number
    vehicleName: string
    startingKM: string
    endingKM: string
    totalKM: string
    diesel: string
    averageMilage: string
    driver: number
    driverName: string
    cleaner: number
    cleanerName: string
    conductor: number
    conductorName: string
    date: string

    createdBy?: string
    createTimestamp?: string

    // id ?: number
    // collectionId: number
    // ticketPrice: string
    // startingNumber: string
    // endingNumber: string
}

export interface AddVehicleReadingAction {
    type: VehicleReadingActionList.ADD_VEHICLE_READING
    data: VehicleReadingType
}

export interface FetchVehicleReadingAction {
    type: VehicleReadingActionList.FETCH_VEHICLE_READING
    data: Array<VehicleReadingType>
}

export interface UpdateVehicleReadingAction {
    type: VehicleReadingActionList.UPDATE_VEHICLE_READING
    data: VehicleReadingType
}

export type VehicleReadingActions = AddVehicleReadingAction | FetchVehicleReadingAction | UpdateVehicleReadingAction

export const addVehicleReading = (data: VehicleReadingType) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const { vehicleReading } = getState()
        dispatch<AddVehicleReadingAction>({
            type: VehicleReadingActionList.ADD_VEHICLE_READING,
            data: {
                ...data,
                id: vehicleReading.length + 1
            }
        })
        return Promise.resolve("Vehicle Reading Added")
        // return api.post<APIResponse<VehicleReadingType>>('VehicleReading/', data).then(response => {
        //    if (response.status === 200 && response.data.data) {

        //         return Promise.resolve(response.data.message
        //             ? response.data.message
        //             : 'Successfully logged in')
        //     } else {
        //         throw { response }
        //     }
        // }).catch(error => {
        //     return Promise.reject(error.response
        //         ? error.response.data.message
        //             ? error.response.data.message
        //             : 'Unable to login'
        //         : 'Unable to login')
        // })
    }
}

export const updateVehicleReading = (data: VehicleReadingType, id: number) => {
    return async (dispatch: Dispatch) => {
        dispatch<UpdateVehicleReadingAction>({
            type: VehicleReadingActionList.UPDATE_VEHICLE_READING,
            data: {
                ...data,
                id: id
            }
        })
        return Promise.resolve("Vehicle Reading Updated")
        // return api.post<APIResponse<VehicleReadingType>>('VehicleReading/', data).then(response => {
        //    if (response.status === 200 && response.data.data) {

        //         return Promise.resolve(response.data.message
        //             ? response.data.message
        //             : 'Successfully logged in')
        //     } else {
        //         throw { response }
        //     }
        // }).catch(error => {
        //     return Promise.reject(error.response
        //         ? error.response.data.message
        //             ? error.response.data.message
        //             : 'Unable to login'
        //         : 'Unable to login')
        // })
    }
}

export const fetchVehicleReading = () => {
    return async (dispatch: Dispatch) => {
        dispatch<FetchVehicleReadingAction>({
            type: VehicleReadingActionList.FETCH_VEHICLE_READING,
            data: vehicleReadingData
        })
        // return api.post<APIResponse<VehicleReadingType>>('VehicleReading/', data).then(response => {
        //    if (response.status === 200 && response.data.data) {

        //         return Promise.resolve(response.data.message
        //             ? response.data.message
        //             : 'Successfully logged in')
        //     } else {
        //         throw { response }
        //     }
        // }).catch(error => {
        //     return Promise.reject(error.response
        //         ? error.response.data.message
        //             ? error.response.data.message
        //             : 'Unable to login'
        //         : 'Unable to login')
        // })
    }
}



