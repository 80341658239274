import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import AccountsMaster from '../pages/AccountsMaster'
import AddCollection from '../pages/AddCollection'
import AddTransactionForm from '../pages/AddTransaction'
import Asset from '../pages/Asset'
import Collection from '../pages/Collection'
import CollectionReport from '../pages/CollectionReport'
import CumulativeReport from '../pages/CumulativeReport'
import DayBookReport from '../pages/DayBookReport'
import DocumentMaster from '../pages/DocumentMaster'
import EditCollection from '../pages/EditCollection'
import Employee from '../pages/Employee'
import ExpenseMaster from '../pages/ExpenseMaster'
import Home from '../pages/Home'
import Login from '../pages/Login'
import Logout from '../pages/Logout'
import MachineMaster from '../pages/MachineMaster'
import MDReport from '../pages/MDReport'
import MonthlyBalanceReport from '../pages/MonthlyBalanceReport'
import PartyLedgerReport from '../pages/PartyLedgerReport'
import Role from '../pages/Role'
import RouteLedgerReport from '../pages/RouteLedgerReport'
import RouteMaster from '../pages/RouteMaster'
import RtoMaster from '../pages/RtoMaster'
// import ServiceMaster from '../pages/ServiceMaster'
import SpareParts from '../pages/SpareParts'
import StopMaster from '../pages/StopMaster'
import Supplier from '../pages/Supplier'
import TicketPricing from '../pages/TicketPricing'
import Transaction from '../pages/Transaction'
import TransactionReport from '../pages/TransactionReport'
import TripMaster from '../pages/TripMaster'
import User from '../pages/User'
import VehicleAssetMapping from '../pages/VehicleAssetMapping'
import VehicleDocument from '../pages/VehicleDocument'
import VehicleHiring from '../pages/VehicleHiring'
import VehicleMaster from '../pages/VehicleMaster'
import VehicleRouteMappingMaster from '../pages/VehicleRouteMappingMaster'
import VehicleService from '../pages/VehicleService'
import { fetchAccountHolder } from '../redux/actions/accountHolderActions'
import { fetchAccountsMaster } from '../redux/actions/accountsMasterActions'
import { fetchAsset } from '../redux/actions/assetActions'
import { AuthUserType } from '../redux/actions/authUserActions'
import { fetchCollection } from '../redux/actions/collectionActions'
import { fetchDocumentMaster } from '../redux/actions/documentMasterActions'
import { fetchEmployee } from '../redux/actions/employeeActions'
import { fetchExpenseMaster } from '../redux/actions/expenseMasterActions'
import { fetchMachineMaster } from '../redux/actions/machineMasterActions'
import { fetchRole } from '../redux/actions/roleActions'
import { fetchRouteMaster } from '../redux/actions/routeMasterActions'
import { fetchRtoMaster } from '../redux/actions/rtoMasterActions'
// import { fetchServiceMaster } from '../redux/actions/serviceMasterActions'
// import { fetchSpareParts } from '../redux/actions/sparePartsActions'
import { fetchStopMaster } from '../redux/actions/stopMasterActions'
import { fetchSupplier } from '../redux/actions/supplierActions'
import { fetchTicketPricing } from '../redux/actions/ticketPricingActions'
import { fetchTransaction } from '../redux/actions/transactionActions'
import { fetchTripMaster } from '../redux/actions/tripMasterActions'
import { fetchUser } from '../redux/actions/userActions'
import { fetchVehicleAssetMapping } from '../redux/actions/vehicleAssetMappingActions'
import { fetchVehicleDocument } from '../redux/actions/vehicleDocumentActions'
import { fetchVehicleHiring } from '../redux/actions/vehicleHiringActions'
import { fetchVehicleMaster } from '../redux/actions/vehicleMasterActions'
import { fetchVehicleRouteMappingMaster } from '../redux/actions/vehicleRouteMappingMasterActions'
import { fetchVehicleService } from '../redux/actions/vehicleServiceActions'
import { StoreState } from '../redux/reducers'
import { AppDispatch } from '../redux/store'

const Router = () => {
    const dispatch = useDispatch<AppDispatch>()
    const authUser = useSelector<StoreState, AuthUserType>(state => state.authUser)

    const authendicated = typeof authUser.token === 'string' && authUser.token !== undefined && authUser.token !== ''

    useEffect(() => {
        if (authendicated) {
            dispatch(fetchVehicleMaster())
            dispatch(fetchRouteMaster())
            dispatch(fetchStopMaster())
            dispatch(fetchTripMaster())
            dispatch(fetchVehicleRouteMappingMaster())
            dispatch(fetchTicketPricing())
            dispatch(fetchDocumentMaster())
            dispatch(fetchVehicleDocument())
            // dispatch(fetchSpareParts())
            dispatch(fetchEmployee())
            // dispatch(fetchServiceMaster())
            dispatch(fetchRtoMaster())
            dispatch(fetchUser())
            dispatch(fetchRole())
            dispatch(fetchSupplier())
            dispatch(fetchVehicleHiring())
            dispatch(fetchAsset())
            dispatch(fetchVehicleAssetMapping())
            dispatch(fetchVehicleService())
            dispatch(fetchCollection())
            dispatch(fetchExpenseMaster())
            dispatch(fetchTransaction())
            dispatch(fetchAccountHolder())
            dispatch(fetchMachineMaster())
            dispatch(fetchAccountsMaster())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authendicated])

    // <Link to={`/collection/edit/${collection.id}`}>

    return <BrowserRouter>
        <Routes>
            <Route path='/' element={authendicated ? <Home /> : <Navigate to='/login' />} />
            <Route path='/login' element={!authendicated ? <Login /> : <Navigate to='/' />} />

            <Route path='/collection' element={authendicated ? <Collection /> : <Navigate to='/' />} />
            <Route path='/collection/edit/:collectionId' element={authendicated && (authUser.role === 'admin' || authUser.role === 'super' || authUser.role === 'Admin' || authUser.role === 'Super') ? <EditCollection /> : <Navigate to='/' />} />
            <Route path='/collection/add' element={authendicated ? <AddCollection /> : <Navigate to='/' />} />

            <Route path='/vehicle_master' element={authendicated ? <VehicleMaster /> : <Navigate to='/' />} />
            <Route path='/route_master' element={authendicated ? <RouteMaster /> : <Navigate to='/' />} />
            <Route path='/stop_master' element={authendicated ? <StopMaster /> : <Navigate to='/' />} />
            <Route path='/trip_master' element={authendicated ? <TripMaster /> : <Navigate to='/' />} />
            <Route path='/vehicle_route_mapping_master' element={authendicated ? <VehicleRouteMappingMaster /> : <Navigate to='/' />} />
            <Route path='/ticket_pricing' element={authendicated ? <TicketPricing /> : <Navigate to='/' />} />
            <Route path='/document_master' element={authendicated ? <DocumentMaster /> : <Navigate to='/' />} />
            <Route path='/vehicle_document' element={authendicated ? <VehicleDocument /> : <Navigate to='/' />} />
            <Route path='/employee' element={authendicated ? <Employee /> : <Navigate to='/' />} />
            <Route path='/spare_parts' element={authendicated ? <SpareParts /> : <Navigate to='/' />} />
            {/* <Route path='/service_master' element={authendicated ? <ServiceMaster /> : <Navigate to='/' />} /> */}
            <Route path='/rto_master' element={authendicated ? <RtoMaster /> : <Navigate to='/' />} />
            <Route path='/user' element={authendicated ? <User /> : <Navigate to='/' />} />
            <Route path='/role' element={authendicated ? <Role /> : <Navigate to='/' />} />
            <Route path='/supplier' element={authendicated ? <Supplier /> : <Navigate to='/' />} />
            <Route path='/vehicle_hiring' element={authendicated ? <VehicleHiring /> : <Navigate to='/' />} />
            <Route path='/asset' element={authendicated ? <Asset /> : <Navigate to='/' />} />
            <Route path='/vehicle_asset_mapping' element={authendicated ? <VehicleAssetMapping /> : <Navigate to='/' />} />
            <Route path='/vehicle_service' element={authendicated ? <VehicleService /> : <Navigate to='/' />} />
            <Route path='/expense_master' element={authendicated ? <ExpenseMaster /> : <Navigate to='/' />} />
            <Route path='/transaction' element={authendicated ? < Transaction /> : <Navigate to='/' />} />
            <Route path='/add_transaction' element={authendicated ? < AddTransactionForm /> : <Navigate to='/' />} />
            <Route path='/machine_master' element={authendicated ? < MachineMaster /> : <Navigate to='/' />} />
            <Route path='/collection_report' element={authendicated ? < CollectionReport /> : <Navigate to='/' />} />
            <Route path='/transaction_report' element={authendicated ? < TransactionReport /> : <Navigate to='/' />} />
            <Route path='/cumulative_report' element={authendicated ? < CumulativeReport /> : <Navigate to='/' />} />
            <Route path='/party_ledger_report' element={authendicated ? < PartyLedgerReport /> : <Navigate to='/' />} />
            <Route path='/route_ledger_report' element={authendicated ? < RouteLedgerReport /> : <Navigate to='/' />} />
            <Route path='/md_report' element={authendicated ? < MDReport /> : <Navigate to='/' />} />
            <Route path='/monthly_balance_report' element={authendicated ? < MonthlyBalanceReport /> : <Navigate to='/' />} />
            <Route path='/DayBook_report' element={authendicated ? < DayBookReport /> : <Navigate to='/' />} />
            <Route path='/accounts_master' element={authendicated ? < AccountsMaster /> : <Navigate to='/' />} />
            <Route path='/logout' element={authendicated ? <Logout /> : <Navigate to='/' />} />
        </Routes>
    </BrowserRouter>
}

export default Router