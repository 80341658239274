import { Action } from "../actions/types"
import { VehicleHiringType, VehicleHiringActionList } from "../actions/vehicleHiringActions"

const initialValue: Array<VehicleHiringType> = []

const vehicleHiringReducer = (state: Array<VehicleHiringType> = initialValue, action: Action) => {
    switch (action.type) {

        case VehicleHiringActionList.ADD_VEHICLE_HIRING:
            return [
                ...state,
                action.data,
            ]

        case VehicleHiringActionList.UPDATE_VEHICLE_HIRING:
            const updated = [...state].slice()
            const index = updated.findIndex(u => u.id === action.data.id)

            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }

            return updated

        case VehicleHiringActionList.FETCH_VEHICLE_HIRING:
            return action.data

        case VehicleHiringActionList.DELETE_VEHICLE_HIRING:
            return state.filter(s => s.id !== action.data)

        default:
            return state
    }
}

export default vehicleHiringReducer