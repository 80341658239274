/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../reducers"

export enum AccountHolderActionList {
    ADD_ACCOUNT_HOLDER = 'ADD_ACCOUNT_HOLDER',
    FETCH_ACCOUNT_HOLDER = 'FETCH_ACCOUNT_HOLDER',
    UPDATE_ACCOUNT_HOLDER = 'UPDATE_ACCOUNT_HOLDER',
    DELETE_ACCOUNT_HOLDER = 'DELETE_ACCOUNT_HOLDER',
}

export interface AccountHolderType {

    id?: number
    accountHead: string
    accountNumber: string
    accountName: string

}

export interface AddAccountHolderAction {
    type: AccountHolderActionList.ADD_ACCOUNT_HOLDER
    data: AccountHolderType
}

export interface FetchAccountHolderAction {
    type: AccountHolderActionList.FETCH_ACCOUNT_HOLDER
    data: Array<AccountHolderType>
}

export interface UpdateAccountHolderAction {
    type: AccountHolderActionList.UPDATE_ACCOUNT_HOLDER
    data: AccountHolderType
}

export interface DeleteAccountHolderAction {
    type: AccountHolderActionList.DELETE_ACCOUNT_HOLDER
    data: number
}

export type AccountHolderActions = AddAccountHolderAction | FetchAccountHolderAction | UpdateAccountHolderAction | DeleteAccountHolderAction

export const addAccountHolder = (data: AccountHolderType) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("AccountHolder Added")
        return api.post<APIResponse<AccountHolderType>>('account_holder/', data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<AddAccountHolderAction>({
                    type: AccountHolderActionList.ADD_ACCOUNT_HOLDER,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Account Holder Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const updateAccountHolder = (data: AccountHolderType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)
        // return Promise.resolve("AccountHolder Updated")

        return api.put<APIResponse<AccountHolderType>>(`account_holder/?id=${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<UpdateAccountHolderAction>({
                    type: AccountHolderActionList.UPDATE_ACCOUNT_HOLDER,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Account Holder Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchAccountHolder = () => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)


        return api.get<APIResponse<AccountHolderType[]>>('account_holder/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchAccountHolderAction>({
                    type: AccountHolderActionList.FETCH_ACCOUNT_HOLDER,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteAccountHolder = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("AccountHolder Deleted")
        return api.delete<APIResponse<AccountHolderType>>(`account_holder/?id=${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteAccountHolderAction>({
                    type: AccountHolderActionList.DELETE_ACCOUNT_HOLDER,
                    data: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Account Holder Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}

