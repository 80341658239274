/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../reducers"

export enum VehicleDocumentActionList {
    ADD_VEHICLE_DOCUMENT = 'ADD_VEHICLE_DOCUMENT',
    FETCH_VEHICLE_DOCUMENT = 'FETCH_VEHICLE_DOCUMENT',
    UPDATE_VEHICLE_DOCUMENT = 'UPDATE_VEHICLE_DOCUMENT',
    DELETE_VEHICLE_DOCUMENT = 'DELETE_VEHICLE_DOCUMENT',
}

export interface VehicleDocumentType {

    id?: number
    vehicleId: number
    vehicleName: string
    vehicleRegNo?: string
    documentCode: string
    documentName?: string
    registeredDate: string
    validityMonths: string
    expiryDate: string
    renewalAmount?: string
    attachmentUrl?: File
    createdBy?: string
    createTimestamp?: string
    expiredOrNot?: string
}

export interface AddVehicleDocumentAction {
    type: VehicleDocumentActionList.ADD_VEHICLE_DOCUMENT
    data: VehicleDocumentType
}

export interface FetchVehicleDocumentAction {
    type: VehicleDocumentActionList.FETCH_VEHICLE_DOCUMENT
    data: Array<VehicleDocumentType>
}

export interface UpdateVehicleDocumentAction {
    type: VehicleDocumentActionList.UPDATE_VEHICLE_DOCUMENT
    data: VehicleDocumentType
}

export interface DeleteVehicleDocumentAction {
    type: VehicleDocumentActionList.DELETE_VEHICLE_DOCUMENT
    data: number
}

export type VehicleDocumentActions = AddVehicleDocumentAction | FetchVehicleDocumentAction | UpdateVehicleDocumentAction | DeleteVehicleDocumentAction

export const addVehicleDocument = (data: FormData) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Vehicle Document Added")
        return api.post<APIResponse<VehicleDocumentType>>('vehicle_document/', data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<AddVehicleDocumentAction>({
                    type: VehicleDocumentActionList.ADD_VEHICLE_DOCUMENT,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Vehicle Document Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const updateVehicleDocument = (data: FormData, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Vehicle Document Updated")
        return api.post<APIResponse<VehicleDocumentType>>(`vehicle_document/update/?id=${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<UpdateVehicleDocumentAction>({
                    type: VehicleDocumentActionList.UPDATE_VEHICLE_DOCUMENT,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Vehicle Document Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchVehicleDocument = () => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<VehicleDocumentType[]>>('vehicle_document/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchVehicleDocumentAction>({
                    type: VehicleDocumentActionList.FETCH_VEHICLE_DOCUMENT,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteVehicleDocument = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Vehicle Document Deleted")
        return api.delete<APIResponse<VehicleDocumentType>>(`vehicle_document/?id=${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteVehicleDocumentAction>({
                    type: VehicleDocumentActionList.DELETE_VEHICLE_DOCUMENT,
                    data: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Vehicle Document Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}

