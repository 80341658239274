import { Action } from "../actions/types"
import { SupplierType, SupplierActionList } from "../actions/supplierActions"

const initialValue: Array<SupplierType> = []

const supplierReducer = (state: Array<SupplierType> = initialValue, action: Action) => {
    switch (action.type) {

        case SupplierActionList.ADD_SUPPLIER:
            return [
                ...state,
                action.data,
            ]

        case SupplierActionList.UPDATE_SUPPLIER:
            const updated = [...state].slice()
            const index = updated.findIndex(u => u.id === action.data.id)

            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }

            return updated

        case SupplierActionList.FETCH_SUPPLIER:
            return action.data

        case SupplierActionList.DELETE_SUPPLIER:
            return state.filter(s => s.id !== action.data)

        default:
            return state
    }
}

export default supplierReducer