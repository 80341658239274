import React, { ChangeEventHandler, MouseEventHandler, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { addKey } from "../config/functions"
import { fetchAccountHolder } from "../redux/actions/accountHolderActions"
import { addEmployee, EmployeeSalaryType, EmployeesType, EmployeeType, EmployeeTypeList, SalaryTypes, updateEmployee } from "../redux/actions/employeeActions"
// import { Key } from "../redux/actions/types"
// import { StoreState } from "../redux/reducers"
import { AppDispatch } from "../redux/store"
import { FormInput } from "./custom/components"
import Option from "./Option"
import { ToastTypes, useToast } from "./toast/ToastProvider"
import { FormType, FormTypeList } from "./types"

interface Props {
    formType: FormType
    editData?: EmployeeType
    onSave: () => void
}
const JOB_TYPES = addKey([
    {
        text: 'Driver',
        value: 'Driver'
    },
    {
        text: 'Conductor',
        value: 'Conductor'
    },
    {
        text: 'Mechanic',
        value: 'Mechanic'
    },
    {
        text: 'Electrician',
        value: 'Electrician'
    },
    {
        text: 'Cleaner',
        value: 'Cleaner'
    },
    {
        text: 'Checker',
        value: 'Checker'
    },
    {
        text: 'Accountant',
        value: 'Accountant'
    },
    {
        text: 'Office Boy',
        value: 'Office Boy'
    },
    {
        text: 'Office Staff',
        value: 'Office Staff'
    },
    {
        text: 'Manager',
        value: 'Manager'
    }
])



const EmployeeForm = ({ formType, onSave, editData }: Props) => {
    // const employee = useSelector<StoreState, Array<EmployeeType & Key>>(state => addKey(state.employee))
    const toast = useToast()

    const dispatch = useDispatch<AppDispatch>()

    const [code, setCode] = useState<string>('')
    const [codeError, setCodeError] = useState<string>('')
    const [name, setName] = useState<string>('')
    const [nameError, setNameError] = useState<string>('')
    const [designation, setDesignation] = useState<string>('')
    const [designationError, setDesignationError] = useState<string>('')
    const [type, setType] = useState<EmployeesType>(EmployeeTypeList.ACCOUNTANT)
    const [typeError, setTypeError] = useState<string>('')
    const [salary, setSalary] = useState<EmployeeSalaryType>(SalaryTypes.MONTHLY)
    const [salaryError, setSalaryError] = useState<string>('')
    const [dateOfJoin, setDateOfJoin] = useState<string>('')
    const [dateOfJoinError, setDateOfJoinError] = useState<string>('')
    const [dateOfLeave, setDateOfLeave] = useState<string>('')
    const [dateOfLeaveError, setDateOfLeaveError] = useState<string>('')
    const [mobile, setMobile] = useState<string>('')
    const [mobileError, setMobileError] = useState<string>('')
    const [address, setAddress] = useState<string>('')
    const [addressError, setAddressError] = useState<string>('')
    const [city, setCity] = useState<string>('')
    const [cityError, setCityError] = useState<string>('')
    const [state, setState] = useState<string>('')
    const [stateError, setStateError] = useState<string>('')
    const [pinCode, setPinCode] = useState<string>('')
    const [pinCodeError, setPinCodeError] = useState<string>('')
    const [drivingLicenseNumber, setDrivingLicenseNumber] = useState<string>('')
    const [drivingLicenseNumberError, setDrivingLicenseNumberError] = useState<string>('')
    const [drivingLicenseExpiryDate, setDrivingLicenseExpiryDate] = useState<string>('')
    const [drivingLicenseExpiryDateError, setDrivingLicenseExpiryDateError] = useState<string>('')
    const [aadharNumber, setAadharNumber] = useState<string>('')
    const [aadharNumberError, setAadharNumberError] = useState<string>('')
    const [attachments, setAttachments] = useState<File[]>([])
    const [attachmentsError, setAttachmentsError] = useState<string>('')
    const [rname, setRname] = useState<string>('')
    const [rnameError, setRnameError] = useState<string>('')
    const [rrelationship, setRrelationship] = useState<string>('')
    const [rrelationshipError, setRrelationshipError] = useState<string>('')
    const [rcontactNumber, setRcontactNumber] = useState<string>('')
    const [rcontactNumberError, setRcontactNumberError] = useState<string>('')


    const resetStates = () => {
        setCode('')
        setCodeError('')
        setName('')
        setNameError('')
        setType(EmployeeTypeList.ACCOUNTANT)
        setTypeError('')
        setDesignation('')
        setDesignationError('')
        setSalary(SalaryTypes.MONTHLY)
        setSalaryError('')
        setDateOfJoin('')
        setDateOfJoinError('')
        setDateOfLeave('')
        setDateOfLeaveError('')
        setMobile('')
        setMobileError('')
        setAddress('')
        setAddressError('')
        setCity('')
        setCityError('')
        setState('')
        setStateError('')
        setPinCode('')
        setPinCodeError('')
        setDrivingLicenseNumber('')
        setDrivingLicenseNumberError('')
        setDrivingLicenseExpiryDate('')
        setDrivingLicenseExpiryDateError('')
        setAadharNumber('')
        setAadharNumberError('')
        setAttachments([])
        setAttachmentsError('')
        setRname('')
        setRnameError('')
        setRrelationship('')
        setRrelationshipError('')
        setRcontactNumber('')
        setRcontactNumberError('')
    }

    const handleCode: ChangeEventHandler<HTMLInputElement> = (e) => {
        setCode(e.target.value)
        setCodeError('')
    }

    const handleName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setName(e.target.value)
        setNameError('')
    }

    const handleType: ChangeEventHandler<HTMLInputElement> = (e) => {
        setType(e.target.value as EmployeesType)
        setTypeError('')
    }

    const handleDesignation: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDesignation(e.target.value)
        setDesignationError('')
    }

    const handleDaily: ChangeEventHandler<HTMLInputElement> = (e) => {
        setSalary(SalaryTypes.DAILY)
        setSalaryError('')
    }

    const handleMonthly: ChangeEventHandler<HTMLInputElement> = (e) => {
        setSalary(SalaryTypes.MONTHLY)
        setSalaryError('')
    }

    const handleWeekly: ChangeEventHandler<HTMLInputElement> = (e) => {
        setSalary(SalaryTypes.WEEKLY)
        setSalaryError('')
    }

    const handleDateOfJoin: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDateOfJoin(e.target.value)
        setDateOfJoinError('')
    }

    const handleDateOfLeave: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDateOfLeave(e.target.value)
        setDateOfLeaveError('')
    }

    const handleMobile: ChangeEventHandler<HTMLInputElement> = (e) => {
        setMobile(e.target.value)
        setMobileError('')
    }

    const handleAddress: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAddress(e.target.value)
        setAddressError('')
    }

    const handleCity: ChangeEventHandler<HTMLInputElement> = (e) => {
        setCity(e.target.value)
        setCityError('')
    }

    const handleState: ChangeEventHandler<HTMLInputElement> = (e) => {
        setState(e.target.value)
        setStateError('')
    }

    const handlePinCode: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPinCode(e.target.value)
        setPinCodeError('')
    }

    const handleRname: ChangeEventHandler<HTMLInputElement> = (e) => {
        setRname(e.target.value)
        setRnameError('')
    }

    const handleRrelationship: ChangeEventHandler<HTMLInputElement> = (e) => {
        setRrelationship(e.target.value)
        setRrelationshipError('')
    }

    const handleRcontactNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
        setRcontactNumber(e.target.value)
        setRcontactNumberError('')
    }

    const handleDrivingLicenseNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDrivingLicenseNumber(e.target.value)
        setDrivingLicenseNumberError('')
    }

    const handleDrivingLicenseExpiryDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDrivingLicenseExpiryDate(e.target.value)
        setDrivingLicenseExpiryDateError('')
    }

    const handleAadharNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAadharNumber(e.target.value)
        setAadharNumberError('')
    }

    const handleAttachments: ChangeEventHandler<HTMLInputElement> = (e) => {
        if (e.target.files !== null) {
            const fileArray: File[] = []
            let fileError = false
            const fileList: FileList = e.target.files
            for (let i = 0; i < fileList.length; i++) {
                const file = fileList.item(i)
                if (file === null) {
                    fileError = true
                    continue
                } else if (!(file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf')) {
                    fileError = true
                    continue
                } else if (file.size / 1024 / 1024 > 2) {
                    fileError = true
                    continue
                } else {
                    fileArray.push(file)
                }
            }

            if (fileError) {
                toast('Some files does not meet requirements', ToastTypes.ERROR)
            }

            setAttachments(fileArray)
        } else {
            setAttachmentsError('You\'ve selected nothing')
        }
    }

    const handleClear: MouseEventHandler<HTMLButtonElement> = (e) => {
        resetStates()
    }

    const handleSave = () => {
        let error = false
        const data = {
            code: code,
            name: name,
            type: type,
            designation: designation,
            salaryType: salary,
            dateOfJoin: dateOfJoin,
            dateOfLeave: dateOfLeave ? dateOfLeave : '',
            mobileNumber: mobile,
            address: address,
            city: city,
            state: state,
            pincode: pinCode,
            drivingLicenseExpiryDate: drivingLicenseExpiryDate ? drivingLicenseExpiryDate : '',
            drivingLicenseNumber: drivingLicenseNumber ? drivingLicenseNumber : '',
            aadharNumber: aadharNumber ? aadharNumber : '',
            attachments: attachments ? attachments : [],
            reference: {
                name: rname,
                contactNumber: rcontactNumber,
                relationship: rrelationship
            }
        }

        if (data.code !== "") {
            if ((data.code).length > 15) {
                setCodeError('Code must be within 15 characters')
                error = true
            }
        }


        // if (formType === FormTypeList.ADD) {
        //     if (data.code === "") {
        //         setCodeError('Code required')
        //         error = true
        //     } else if ((data.code).length > 15) {
        //         setCodeError('Code must be within 15 characters')
        //         error = true
        //     } else if (data.code !== "") {
        //         const uniqueIndex = employee.findIndex(e => {
        //             return e.code.toLowerCase() === data.code.toLowerCase()
        //         })
        //         if (uniqueIndex > -1) {
        //             setCodeError('Code already exists')
        //             error = true
        //         }
        //     }
        // }

        // if (formType === FormTypeList.UPDATE) {
        //     if (data.code === "") {
        //         setCodeError('Code required')
        //         error = true
        //     } else if ((data.code).length > 15) {
        //         setCodeError('Code must be within 15 characters')
        //         error = true
        //     }
        // }

        if (data.name === "") {
            setNameError('Name required')
            error = true
        } else if ((data.name).length > 30) {
            setNameError('Name must be within 30 characters')
            error = true
        }

        if ([EmployeeTypeList.ACCOUNTANT, EmployeeTypeList.CHECKER, EmployeeTypeList.CLEANER, EmployeeTypeList.CONDUCTOR, EmployeeTypeList.DRIVER, EmployeeTypeList.ELECTRICIAN, EmployeeTypeList.MANAGER, EmployeeTypeList.MECHANIC, EmployeeTypeList.OFFICE_BOY, EmployeeTypeList.OFFICE_STAFF].indexOf(data.type as EmployeesType) === -1) {
            setTypeError('Type must be valid')
            error = true
        }
        // if (data.type == "") {
        //     setTypeError('Type required')
        //     error = true
        // } else
        // ACCOUNTANT,CHECKER,CLEANER,CONDUCTOR,DRIVER,ELECTRICIAN,MANAGER,MECHANIC,OFFICE_BOY,OFFICE_STAFF.

        if (data.designation === "") {
            setDesignationError('Designation required')
            error = true
        } else if ((data.designation).length > 30) {
            setDesignationError('Designation must be within 30 characters')
            error = true
        }

        // if (data.salaryType === "") {
        //     setSalaryError('Salary Type required')
        //     error = true
        // }
        if ([SalaryTypes.MONTHLY, SalaryTypes.WEEKLY, SalaryTypes.DAILY].indexOf(data.salaryType as EmployeeSalaryType) === -1) {
            setSalaryError('SalaryType must be MONTHLY/WEEKLY/DAILY.')
            error = true
        }

        if (data.dateOfJoin === "") {
            setDateOfJoinError('Date Of Join required')
            error = true
        }
        // if (data.dateOfLeave === "") {
        //     setDateOfLeaveError('Date Of Leave required')
        //     error = true
        // }
        if (data.mobileNumber === "") {
            setMobileError('Mobile.No required')
            error = true
        }

        if (data.address === "") {
            setAddressError('Address required')
            error = true
        } else if ((data.address).length > 100) {
            setAddressError('Address must be within 100 characters')
            error = true
        }

        if (data.city === "") {
            setCityError('City required')
            error = true
        } else if ((data.city).length > 30) {
            setCityError('City must be within 30 characters')
            error = true
        }

        if (data.state === "") {
            setStateError('State required')
            error = true
        } else if ((data.state).length > 30) {
            setStateError('State must be within 30 characters')
            error = true
        }

        if (data.pincode === "") {
            setPinCodeError('Pincode required')
            error = true
        } else if ((data.pincode).length !== 6) {
            setPinCodeError('Invalid Pincode')
            error = true
        }

        // if (data.drivingLicenseExpiryDate === "") {
        //     setDrivingLicenseExpiryDateError('Driving License Expiry Date required')
        //     error = true
        // }
        if (data.drivingLicenseNumber !== "") {
            if ((data.drivingLicenseNumber).length > 30) {
                setDrivingLicenseNumberError('Driving License.No must be within 30 characters')
                error = true
            }
        }

        if (data.aadharNumber !== "") {
            if ((data.aadharNumber).length !== 12) {
                setAadharNumberError('Invalid Aadhar.No ')
                error = true
            }
        }

        if (data.reference.name !== "" && (data.reference.name).length > 30) {
            setRnameError('Reference name must be within 30 characters')
            error = true
        }

        if (data.reference.relationship !== "" && (data.reference.name).length > 30) {
            setRrelationshipError('Reference relationship must be within 30 characters')
            error = true
        }

        // if (data.reference.contactNumber === "") {
        //     setRcontactNumberError('Reference contactNumber required')
        //     error = true
        // }


        if (!error) {
            if (formType === FormTypeList.ADD) {

                const formData = new FormData()

                formData.append('name', data.name)
                formData.append('code', data.code)
                formData.append('name', data.name)
                formData.append('type', data.type)
                formData.append('designation', data.designation)
                formData.append('salaryType', data.salaryType)
                formData.append('dateOfJoin', data.dateOfJoin)
                formData.append('dateOfLeave', data.dateOfLeave)
                formData.append('mobileNumber', data.mobileNumber)
                formData.append('address', data.address)
                formData.append('city', data.city)
                formData.append('state', data.state)
                formData.append('pincode', data.pincode)
                formData.append('drivingLicenseExpiryDate', data.drivingLicenseExpiryDate)
                formData.append('drivingLicenseNumber', data.drivingLicenseNumber)
                formData.append('aadharNumber', data.aadharNumber)
                formData.append('reference', JSON.stringify(data.reference))
                formData.append('drivingLicenseNumber', data.drivingLicenseNumber)
                data.attachments.forEach(file => formData.append('attachments[]', file))

                dispatch(addEmployee(formData)).then(text => {
                    toast(text)
                    dispatch(fetchAccountHolder())
                    onSave()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            } else if (formType === FormTypeList.UPDATE && editData !== undefined && editData.id !== undefined) {
                dispatch(updateEmployee(data, editData.id)).then(text => {
                    toast(text)
                    dispatch(fetchAccountHolder())
                    onSave()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
        }
    }

    useEffect(() => {
        if (formType === FormTypeList.UPDATE && editData !== undefined) {

            setCode(editData.code !== undefined ? editData.code : '')
            setName(editData.name)
            setType(editData.type as EmployeesType)
            setDesignation(editData.designation)
            setSalary(editData.salaryType)
            setDateOfJoin(editData.dateOfJoin)
            setDateOfLeave(editData.dateOfLeave ? editData.dateOfLeave : '')
            setMobile(editData.mobileNumber)
            setAddress(editData.address)
            setCity(editData.city)
            setState(editData.state)
            setPinCode(editData.pincode)
            setRname(editData.reference.name)
            setRrelationship(editData.reference.relationship)
            setRcontactNumber(editData.reference.contactNumber)
            setAadharNumber(editData.aadharNumber ? editData.aadharNumber : '')
            setDrivingLicenseExpiryDate(editData.drivingLicenseExpiryDate ? editData.drivingLicenseExpiryDate : '')
            setDrivingLicenseNumber(editData.drivingLicenseNumber ? editData.drivingLicenseNumber : '')

        } else {
            resetStates()
        }
    }, [formType, editData])


    return <div className='container'>
        <div className="row">

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Code'
                    // labelClassName="required"
                    value={code}
                    onChange={handleCode}
                    placeholder='Code'
                    errorText={codeError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Name'
                    labelClassName="required"
                    value={name}
                    onChange={handleName}
                    placeholder='Name'
                    errorText={nameError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Type'
                    labelClassName="required"
                    value={type}
                    onChange={handleType}
                    type='select'
                    errorText={typeError}
                >
                    <Option value=''>Select</Option>
                    {JOB_TYPES.map(dt => {
                        return <Option value={dt.value} key={dt.key}>{dt.text}</Option>
                    })}
                </FormInput>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Designation'
                    labelClassName="required"
                    value={designation}
                    onChange={handleDesignation}
                    placeholder='Designation'
                    errorText={designationError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3 '>
                <label htmlFor="salary" className="required">Salary Type </label>
                <div className="hstack gap-1">
                    <FormInput
                        label='Monthly'
                        onChange={handleMonthly}
                        errorText={salaryError}
                        containerClass="my-2"
                        type="radio"
                        name="salary"
                        checked={salary === SalaryTypes.MONTHLY}
                    />

                    <FormInput
                        label='Weekly'
                        onChange={handleWeekly}
                        errorText={salaryError}
                        containerClass="my-2"
                        type="radio"
                        name="salary"
                        checked={salary === SalaryTypes.WEEKLY}
                    />

                    <FormInput
                        label='Daily'
                        onChange={handleDaily}
                        errorText={salaryError}
                        containerClass="my-2"
                        type="radio"
                        name="salary"
                        checked={salary === SalaryTypes.DAILY}
                    />
                </div>
            </div>
            {/* <div className='col-sm-6 col-md-4 col-lg-3'>
                <label htmlFor="salary" >Salary Type </label>
                <div className="hstack gap-1 mt-2">
                    <div className="form-check ">
                        <input className="form-check-input" type="radio" name="salary" />
                        <label className="form-check-label">Monthly </label>
                    </div>
                    <div className="form-check ">
                        <input className="form-check-input" type="radio" name="salary" />
                        <label className="form-check-label">Weekly </label>
                    </div>
                </div>
            </div> */}
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Date Of Join'
                    labelClassName="required"
                    value={dateOfJoin}
                    onChange={handleDateOfJoin}
                    placeholder='Date Of Join'
                    errorText={dateOfJoinError}
                    type='date'
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Date Of Leave'
                    value={dateOfLeave}
                    onChange={handleDateOfLeave}
                    placeholder='Date Of Leave'
                    errorText={dateOfLeaveError}
                    type='date'
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Mobile'
                    labelClassName="required"
                    value={mobile}
                    onChange={handleMobile}
                    placeholder='Mobile'
                    errorText={mobileError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Address'
                    labelClassName="required"
                    value={address}
                    onChange={handleAddress}
                    placeholder='Address'
                    errorText={addressError}
                    type='text'
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='City'
                    labelClassName="required"
                    value={city}
                    onChange={handleCity}
                    placeholder='City'
                    errorText={cityError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='State'
                    labelClassName="required"
                    value={state}
                    onChange={handleState}
                    placeholder='State'
                    errorText={stateError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Pincode'
                    labelClassName="required"
                    value={pinCode}
                    onChange={handlePinCode}
                    placeholder='Pincode'
                    errorText={pinCodeError}
                    containerClass="mb-2"
                    type="number"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Driving License ExpiryDate'
                    value={drivingLicenseExpiryDate}
                    onChange={handleDrivingLicenseExpiryDate}
                    placeholder='Driving License ExpiryDate'
                    errorText={drivingLicenseExpiryDateError}
                    type='date'
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Driving License Number'
                    value={drivingLicenseNumber}
                    onChange={handleDrivingLicenseNumber}
                    placeholder='Driving License Number'
                    errorText={drivingLicenseNumberError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Aadhar Number'
                    value={aadharNumber}
                    onChange={handleAadharNumber}
                    placeholder='Aadhar Number'
                    errorText={aadharNumberError}
                    containerClass="mb-2"
                />
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Referred Person Name'
                    value={rname}
                    onChange={handleRname}
                    errorText={rnameError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Referred Person Relationship'
                    value={rrelationship}
                    onChange={handleRrelationship}
                    errorText={rrelationshipError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Referred Person Contact Number'
                    value={rcontactNumber}
                    onChange={handleRcontactNumber}
                    errorText={rcontactNumberError}
                    containerClass="mb-2"
                />
            </div>

            {formType === FormTypeList.ADD && <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Attachments'
                    onChange={handleAttachments}
                    errorText={attachmentsError}
                    containerClass="mb-2"
                    type="file"
                    multiple
                />
            </div>}

        </div>
        <div className="d-flex justify-content-end hstack gap-1">
            <button className="btn btn-primary" onClick={handleClear}>Clear</button>
            <button className="btn btn-success" onClick={handleSave}>Save</button>
        </div>

    </div>
}
export default EmployeeForm