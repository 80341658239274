/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../reducers"

export enum RoleActionList {
    ADD_ROLE = 'ADD_ROLE',
    FETCH_ROLE = 'FETCH_ROLE',
    UPDATE_ROLE = 'UPDATE_ROLE',
    DELETE_ROLE = 'DELETE_ROLE',
}

export interface RoleType {

    id?: number
    role: string
    module: string
    access: string
    createdBy?: string
    createTimestamp?: string

}

export interface AddRoleAction {
    type: RoleActionList.ADD_ROLE
    data: RoleType
}

export interface FetchRoleAction {
    type: RoleActionList.FETCH_ROLE
    data: Array<RoleType>
}

export interface UpdateRoleAction {
    type: RoleActionList.UPDATE_ROLE
    data: RoleType
}

export interface DeleteRoleAction {
    type: RoleActionList.DELETE_ROLE
    data: number
}

export type RoleActions = AddRoleAction | FetchRoleAction | UpdateRoleAction | DeleteRoleAction

export const addRole = (data: RoleType) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Role Added")
        return api.post<APIResponse<RoleType>>('role_master/', data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<AddRoleAction>({
                    type: RoleActionList.ADD_ROLE,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Role Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const updateRole = (data: RoleType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)
        // return Promise.resolve("Role Updated")

        return api.put<APIResponse<RoleType>>(`role_master/?id=${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<UpdateRoleAction>({
                    type: RoleActionList.UPDATE_ROLE,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Role Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchRole = () => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)


        return api.get<APIResponse<RoleType[]>>('role_master/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchRoleAction>({
                    type: RoleActionList.FETCH_ROLE,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteRole = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Role Deleted")
        return api.delete<APIResponse<RoleType>>(`role_master/?id=${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteRoleAction>({
                    type: RoleActionList.DELETE_ROLE,
                    data: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Role Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}

