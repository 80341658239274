import { ChangeEventHandler, useContext } from 'react'
import { useSelector } from 'react-redux'
import { addKey, rupeeFormat } from '../config/functions'
// import { CollectionContext, CollectionContextType } from '../pages/Collection'
import { TicketPricingType } from '../redux/actions/ticketPricingActions'
import { Key } from '../redux/actions/types'
import { StoreState } from '../redux/reducers'
import { CollectionContext, CollectionContextType } from './CollectionForm'
import { FormInput } from './custom/components'

const TicketWiseCollectionC1 = () => {
    const { routeId, ticketWiseTotalForC1, ticketWiseAmount1, setTicketWiseAmount1 } = useContext<CollectionContextType | null>(CollectionContext)!

    const ticketPricing = useSelector<StoreState, Array<TicketPricingType & Key>>(state => {
        const filtered = state.ticketPricing.filter(tp => {
            return tp.routeId.toString() === routeId
        })

        const flags: Array<any> = []
        const output: TicketPricingType[] = []
        const l = filtered.length
        for (let i = 0; i < filtered.length; i++) {
            if (flags[filtered[i].ticketRate as keyof typeof flags]) continue
            flags[filtered[i].ticketRate as keyof typeof flags] = true
            output.push(filtered[i])
        }

        return addKey(output)
    })

    return <>
        <div className='container'>
            <table className='table m-0'>
                <thead>
                    <tr>
                        <th className='text-center'>Ticket Price</th>
                        <th className='text-center'>Starting No</th>
                        <th className='text-center'>Ending No</th>
                        <th className='text-center'>Total Count</th>
                        <th className='text-center'>Amount</th>
                    </tr>
                </thead>

                {ticketPricing.map((tp, i) => {
                    const index = ticketWiseAmount1.findIndex(ta => {
                        return ta.ticketPrice === tp.ticketRate
                    })
                    const onStartingNoChange: ChangeEventHandler<HTMLInputElement> = (e) => {
                        setTicketWiseAmount1(prev => {
                            if (index > -1) {
                                const updated = [...prev]
                                updated[index].startingNumber = e.target.value
                                return updated
                            }

                            return tp.ticketRate !== undefined ? [
                                ...prev,
                                {
                                    startingNumber: e.target.value,
                                    endingNumber: '',
                                    ticketPrice: tp.ticketRate
                                }
                            ] : prev
                        })
                    }
                    const onEndingNoChange: ChangeEventHandler<HTMLInputElement> = (e) => {
                        setTicketWiseAmount1(prev => {
                            if (index > -1) {
                                const updated = [...prev]
                                updated[index].endingNumber = e.target.value
                                return updated
                            }

                            return tp.id !== undefined ? [
                                ...prev,
                                {
                                    startingNumber: '',
                                    endingNumber: e.target.value,
                                    ticketPrice: tp.ticketRate
                                }
                            ] : prev
                        })
                    }
                    return < TicketBody
                        ticketRate={index > -1 ? ticketWiseAmount1[index].ticketPrice : ''}
                        label={tp.ticketRate}
                        key={i}
                        handleStartingNo={onStartingNoChange}
                        handleEndingNo={onEndingNoChange}
                        endingNo={index > -1 ? ticketWiseAmount1[index].endingNumber : ''}
                        startingNo={index > -1 ? ticketWiseAmount1[index].startingNumber : ''}
                    />
                })}
            </table>
            <div className='text-end'>
                <h4>Total Amount : {rupeeFormat(ticketWiseTotalForC1)}</h4>
            </div>
        </div>
    </>
}
interface TicketBodyProps {
    ticketRate: string
    label: string
    startingNo: string
    endingNo: string
    handleStartingNo: ChangeEventHandler<HTMLInputElement>
    handleEndingNo: ChangeEventHandler<HTMLInputElement>
}

const TicketBody = ({ ticketRate, handleEndingNo, handleStartingNo, label, endingNo, startingNo }: TicketBodyProps) => {
    const { startingNoError, endingNoError } = useContext<CollectionContextType | null>(CollectionContext)!

    const count = startingNo !== '' && endingNo !== '' ? Math.abs(parseFloat(endingNo) - parseFloat(startingNo)) : ''

    const amount = count !== '' ? count * parseFloat(ticketRate) : ''

    return <tbody>
        <tr>
            <td className=''>{rupeeFormat(parseInt(label))}</td>
            <td className=''>
                <FormInput
                    value={startingNo}
                    onChange={handleStartingNo}
                    placeholder="Starting No"
                    errorText={startingNoError}
                />
            </td>
            <td className=''>
                <FormInput
                    value={endingNo}
                    onChange={handleEndingNo}
                    placeholder="Ending No"
                    errorText={endingNoError}
                />
            </td>
            <td className=''>{count}
            </td>

            <td className=''>{amount}</td>
        </tr>
    </tbody>

    // return <div className="row">
    //     <div className='col-sm-2 col-lg-3'>
    //         <label>{label}</label>
    //     </div>

    //     <div className="col-lg-3 col-md-4 col-sm-6">
    //         <FormInput
    //             label='Starting No'
    //             value={startingNo}
    //             onChange={handleStartingNo}
    //             placeholder="Starting No"
    //             errorText={startingNoError}
    //             containerClass="mb-2"
    //         />
    //     </div>
    //     <div className="col-lg-3 col-md-4 col-sm-6">
    //         <FormInput
    //             label='Ending No'
    //             value={endingNo}
    //             onChange={handleEndingNo}
    //             placeholder="Ending No"
    //             errorText={endingNoError}
    //             containerClass="mb-2"
    //         />
    //     </div>
    //     <div className="col-lg-3 col-md-4 col-sm-6">
    //         <label >Total Count</label>
    //     </div>
    //     <div className="col-lg-3 col-md-4 col-sm-6">
    //         <label >Amount</label>
    //     </div>
    // </div>
}
export default TicketWiseCollectionC1