import { combineReducers } from "redux";
import { AuthUserActionList } from "../actions/authUserActions";
import { Action } from "../actions/types";
import accountHolderReducer from "./accountHolderReducer";
import accountsMasterReducer from "./accountsMasterReducer";
import assetReducer from "./assetReducer";
import authUserReducer from "./authUserReducer";
import collectionDetailsReducer from "./collectionDetailsReducer";
import collectionReducer from "./collectionReducer";
import documentMasterReducer from "./documentMasterReducer";
import employeeReducer from "./employeeReducer";
import expenseMasterReducer from "./expenseMasterReducer";
import machineMasterReducer from "./machineMasterReducer";
import openingBalanceReducer from "./openingBalanceReducer";
import roleReducer from "./roleReducer";
import routeMasterReducer from "./routeMasterReducer";
import rtoMasterReducer from "./rtoMasterReducer";
import serviceMasterReducer from "./serviceMasterReducer";
import sparePartsReducer from "./sparePartsReducer";
import stopMasterReducer from "./stopMasterReducer";
import supplierReducer from "./supplierReducer";
import ticketDetailsReducer from "./ticketDetailsReducer";
import ticketPricingReducer from "./ticketPricingReducer";
import transactionReducer from "./transactionReducer";
import tripDetailsReducer from "./tripDetailsReducer";
import tripMasterReducer from "./tripMasterReducer";
import userReducer from "./userReducer";
import vehicleAssetMappingReducer from "./vehicleAssetMappingReducer";
import vehicleDocumentReducer from "./vehicleDocumentReducer";
import vehicleHiringReducer from "./vehicleHiringReducer";
import vehicleMasterReducer from "./vehicleMasterReducer";
import vehicleReadingReducer from "./vehicleReadingReducer";
import vehicleRouteMappingMasterReducer from "./vehicleRouteMappingMasterReducer";
import vehicleServiceReducer from "./vehicleServiceReducer";

const combinedReducers = combineReducers({
    authUser: authUserReducer,
    vehicleMaster: vehicleMasterReducer,
    routeMaster: routeMasterReducer,
    stopMaster: stopMasterReducer,
    tripMaster: tripMasterReducer,
    vehicleRouteMappingMaster: vehicleRouteMappingMasterReducer,
    ticketPricing: ticketPricingReducer,
    documentMaster: documentMasterReducer,
    spareParts: sparePartsReducer,
    vehicleDocument: vehicleDocumentReducer,
    employee: employeeReducer,
    serviceMaster: serviceMasterReducer,
    rtoMaster: rtoMasterReducer,
    user: userReducer,
    role: roleReducer,
    supplier: supplierReducer,
    vehicleHiring: vehicleHiringReducer,
    asset: assetReducer,
    vehicleAssetMapping: vehicleAssetMappingReducer,
    vehicleService: vehicleServiceReducer,
    ticketDetails: ticketDetailsReducer,
    tripDetails: tripDetailsReducer,
    collectionDetails: collectionDetailsReducer,
    collection: collectionReducer,
    vehicleReading: vehicleReadingReducer,
    expenseMaster: expenseMasterReducer,
    openingBalance: openingBalanceReducer,
    accountHolder: accountHolderReducer,
    transaction: transactionReducer,
    machineMaster: machineMasterReducer,
    accountsMaster: accountsMasterReducer,

})

const reducers = (state: StoreState | undefined, action: Action) => {
    if (action.type === AuthUserActionList.LOGOUT_USER) {
        state = undefined
    }
    return combinedReducers(state, action)
}

export type StoreState = ReturnType<typeof combinedReducers>
export type GetState = () => StoreState

export default reducers