import { ChangeEventHandler, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { FormInput } from '../components/custom/components'
import PageTitle from '../components/custom/components/PageTitle'
import VerticalLayout from '../components/custom/layouts/Vertical'
import Option, { OptionType } from '../components/Option'
import api, { APIResponse, getAxiosRequestConfig } from '../config/api'
import { addKey, enumToArrayOfObj, rupeeFormat, today, viewFormatDate } from '../config/functions'
import { AccountHolderType } from '../redux/actions/accountHolderActions'
import { AuthUserType } from '../redux/actions/authUserActions'
import { PaymentModes, TransactionForTypes, TransactionPaymentModes, TransactionType, TransactionTypes, TransactionVoucherTypes, VoucherTypes } from '../redux/actions/transactionActions'
import { Key } from '../redux/actions/types'
import { StoreState } from '../redux/reducers'

interface TableBody {
    sno: number
    // number?: string
    // orderNumber?: string
    financialYear: string
    // branch: string
    voucherType: string
    paymentMode: string
    transactionFor: string
    // accountGroupName: string
    accountNumber: string
    accountName: string
    // accountType: string
    date: string
    particulars: string
    debitAmount: string
    creditAmount: string
    // balanceAmount?: string
    // createdBy?: string
    // createTimestamp?: string
    // bankNumber?: string
    // bankName?: string
    // onEdit: MouseEventHandler<HTMLButtonElement>
    // onDelete: MouseEventHandler<HTMLButtonElement>
}

const Transaction = () => {
    // const toast = useToast()

    // const dispatch = useDispatch<AppDispatch>()
    const authUser = useSelector<StoreState, AuthUserType>(state => state.authUser)
    const token = authUser.token!
    const config = getAxiosRequestConfig(token)

    // const transactionList = useSelector<StoreState, Array<TransactionType & Key>>(state => addKey(state.transaction))
    const accountHolder = useSelector<StoreState, Array<AccountHolderType & Key>>(state => addKey(state.accountHolder))

    const [fromDate, setFromDate] = useState<string>(today())
    const [toDate, setToDate] = useState<string>(today())

    const [accountNumber, setAccountNumber] = useState<string>('')
    const [voucherType, setVoucherType] = useState<string>('')
    const [transactionFor, setTransactionFor] = useState<string>('')
    const [paymentMode, setPaymentMode] = useState<string>('')


    const [transaction, setTransaction] = useState<TransactionType[]>([])

    const voucherTypes = useMemo<(OptionType & Key)[]>(() => {
        return addKey(enumToArrayOfObj(VoucherTypes))
    }, [])

    const transactionTypes = useMemo<(OptionType & Key)[]>(() => {
        return addKey(enumToArrayOfObj(TransactionTypes))
    }, [])

    const paymentModes = useMemo<(OptionType & Key)[]>(() => {
        return addKey(enumToArrayOfObj(PaymentModes))
    }, [])


    const handleAdd = () => {
        window.location.href = '/add_transaction'
    }

    const handleFromDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setFromDate(e.target.value)
    }

    const handleToDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setToDate(e.target.value)
    }

    const handlePaymentMode: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPaymentMode(e.target.value as TransactionPaymentModes)
    }

    const handleVoucherType: ChangeEventHandler<HTMLInputElement> = (e) => {
        setVoucherType(e.target.value as TransactionVoucherTypes)
    }

    const handleTransactionFor: ChangeEventHandler<HTMLInputElement> = (e) => {
        setTransactionFor(e.target.value as TransactionForTypes)
    }

    const handleAccountNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAccountNumber(e.target.value)
    }


    // const handleEdit: HandleEdit = (value) => {
    //     setFormType(FormTypeList.UPDATE)
    //     setEditData(value)
    //     setShowForm(true)
    // }

    // const handleDelete: HandleDelete = (value) => {
    //     if (value.id !== undefined) {
    //         dispatch(deleteRouteMaster(value.id)).then(text => {
    //             toast(text)
    //         }).catch(text => {
    //             toast(text, ToastTypes.ERROR)
    //         })
    //     }
    // }

    // const handleFormClose = () => {
    //     setShowForm(false)
    //     setFormType(FormTypeList.ADD)
    //     setEditData(undefined)
    // }
    useEffect(() => {
        if (toDate !== undefined && fromDate !== undefined) {
            api.get<APIResponse<TransactionType[]>>(`transaction/filter/?from_date=${fromDate}&to_date=${toDate}&payment_mode=${paymentMode}&transaction_for=${transactionFor}&voucher_type=${voucherType}&account_number=${accountNumber}`, config).then(response => {

                if (response.status === 200 && response.data.data) {

                    const result = response.data.data
                    setTransaction(result)
                }
            }).catch(error => {

            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toDate, fromDate, accountNumber, paymentMode, transactionFor, voucherType])

    return <>
        {/* <Modal
            headerText={formType === FormTypeList.ADD ? 'Add Route Master' : 'Edit Route Master'}
            visible={showForm}
            onClose={handleFormClose}
            size='xl'
            centered
        >
            <RouteMasterForm
                formType={formType}
                editData={editData}
                onSave={handleFormClose}
            />

        </Modal> */}
        <VerticalLayout>
            <PageTitle
                title='Transaction'
                buttonText='Add Transaction'
                onButtonClick={handleAdd}
            />

            <div className='card'>
                <div className='card-body'>
                    <div className='container-fluid'>
                        <div className="row">
                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <FormInput
                                    label='From Date'
                                    value={fromDate}
                                    onChange={handleFromDate}
                                    placeholder="From Date"
                                    containerClass="mb-2"
                                    type="date"
                                />
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <FormInput
                                    label='To Date'
                                    value={toDate}
                                    onChange={handleToDate}
                                    placeholder="To Date"
                                    containerClass="mb-2"
                                    type="date"
                                />
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <FormInput
                                    value={voucherType}
                                    onChange={handleVoucherType}
                                    type='select'
                                    label='Voucher Type'
                                >
                                    <option value="">All</option>
                                    {voucherTypes.map(vt => {
                                        return <Option value={vt.value} key={vt.key}>{vt.text}</Option>
                                    })}
                                </FormInput>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <FormInput
                                    value={paymentMode}
                                    onChange={handlePaymentMode}
                                    type='select'
                                    label='Payment Modes'
                                >
                                    <option value="">All</option>
                                    {paymentModes.map(pm => {
                                        return <Option value={pm.value} key={pm.key}>{pm.text}</Option>
                                    })}
                                </FormInput>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <FormInput
                                    value={transactionFor}
                                    onChange={handleTransactionFor}
                                    type='select'
                                    label='Transaction For'
                                >
                                    <option value="">All</option>
                                    {transactionTypes.map(tt => {
                                        return <Option value={tt.value} key={tt.key}>{tt.text}</Option>
                                    })}
                                </FormInput>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <FormInput
                                    value={accountNumber}
                                    onChange={handleAccountNumber}
                                    type='select'
                                    label='Account'
                                >
                                    <Option value=''>All</Option>
                                    {accountHolder.map(ah => {
                                        return <Option value={ah.accountNumber} key={ah.key}>{ah.accountName}({(ah.accountHead)})</Option>
                                    })}
                                </FormInput>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="card-body">
                    <div className='table-wrapper'>
                        <table className='table colored'>
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    <th className='text-truncate align-middle'>Financial Year</th>
                                    <th className='text-truncate align-middle'>Date</th>
                                    <th className='text-truncate align-middle'>Voucher Type</th>
                                    <th className='text-truncate align-middle'>Payment Mode</th>
                                    <th className='text-truncate align-middle'>Transaction For</th>
                                    <th className='text-truncate align-middle'>Account Name</th>
                                    <th className='text-truncate align-middle'>Account Number</th>
                                    <th className='text-truncate align-middle'>Particulars</th>
                                    <th className='text-truncate align-middle'>Debit Amount</th>
                                    <th className='text-truncate align-middle'>Credit Amount</th>
                                    {/* <th className='text-truncate align-middle'>Balance Amount</th> */}

                                </tr>
                            </thead>
                            <tbody>
                                {transaction.filter(bl => {
                                    return bl.accountGroupName !== 'accounting'
                                }).map((t, i) => {
                                    return <TransactionBody
                                        sno={i + 1}
                                        financialYear={t.financialYear}
                                        date={t.date}
                                        voucherType={t.voucherType}
                                        paymentMode={t.paymentMode}
                                        transactionFor={t.transactionFor}
                                        accountName={t.accountName}
                                        accountNumber={t.accountNumber}
                                        particulars={t.particulars}
                                        debitAmount={t.debitAmount}
                                        creditAmount={t.creditAmount}
                                    // balanceAmount={t.balanceAmount}
                                    // key={t.key}
                                    // onEdit={() => handleEdit(t)}
                                    // onDelete={() => handleDelete(t)}
                                    />
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </VerticalLayout>
    </>
}

const TransactionBody = ({ sno, financialYear, voucherType, paymentMode, transactionFor, accountName, date, particulars, accountNumber, debitAmount, creditAmount }: TableBody) => {
    return <tr>
        <td>{sno}</td>
        <td>{financialYear}</td>
        <td className='text-truncate'>{viewFormatDate(date)}</td>
        <td className='text-capitalize'>{voucherType}</td>
        <td className='text-capitalize'>{paymentMode}</td>
        <td className='text-capitalize'>{transactionFor}</td>
        <td className='text-capitalize text-truncate'>{accountName}</td>
        <td className='text-truncate'>{accountNumber}</td>
        <td className='text-capitalize'>{particulars}</td>
        <td>{rupeeFormat(debitAmount)}</td>
        <td>{rupeeFormat(creditAmount)}</td>
        {/* <td>{rupeeFormat(balanceAmount)}</td> */}
        {/* <td className='hstack gap-1'>
            <button className='btn btn-primary' onClick={onEdit}>Edit</button>
            <button className='btn btn-danger' onClick={onDelete}>Delete</button>
        </td> */}
    </tr>
}

export default Transaction
