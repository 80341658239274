import { MouseEventHandler, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PageTitle from '../components/custom/components/PageTitle'
import VerticalLayout from '../components/custom/layouts/Vertical'
import MachineMasterForm from '../components/MachineMasterForm'
import Modal from '../components/Modal'
import { ToastTypes, useToast } from '../components/toast/ToastProvider'
import { FormType, FormTypeList } from '../components/types'
import { addKey, isInToday } from '../config/functions'
import { AuthUserType } from '../redux/actions/authUserActions'
import { deleteMachineMaster, MachineMasterType } from '../redux/actions/machineMasterActions'
import { Key } from '../redux/actions/types'
import { StoreState } from '../redux/reducers'
import { AppDispatch } from '../redux/store'

interface TableBody {
    sno: number
    code: string
    name: string
    onEdit: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
    timeStamp: string
    authUser: AuthUserType
}

type HandleEdit = (value: MachineMasterType) => void
type HandleDelete = (value: MachineMasterType) => void

const MachineMaster = () => {
    const toast = useToast()
    const dispatch = useDispatch<AppDispatch>()
    const authUser = useSelector<StoreState, AuthUserType>(state => state.authUser)


    const machineMaster = useSelector<StoreState, Array<MachineMasterType & Key>>(state => addKey(state.machineMaster))
    const [showForm, setShowForm] = useState<boolean>(false)
    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    const [editData, setEditData] = useState<MachineMasterType>()

    const handleAdd = () => {
        setShowForm(true)
    }

    const handleEdit: HandleEdit = (value) => {
        setFormType(FormTypeList.UPDATE)
        setEditData(value)
        setShowForm(true)
    }

    const handleDelete: HandleDelete = (value) => {
        if (value.id !== undefined) {
            dispatch(deleteMachineMaster(value.id)).then(text => {
                toast(text)
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
        }
    }

    const handleFormClose = () => {
        setShowForm(false)
        setFormType(FormTypeList.ADD)
        setEditData(undefined)
    }

    return <>
        <Modal
            headerText={formType === FormTypeList.ADD ? 'Add Machine Master' : 'Edit Machine Master'}
            visible={showForm}
            onClose={handleFormClose}
            size='lg'
            centered
        >
            <MachineMasterForm
                formType={formType}
                editData={editData}
                onSave={handleFormClose}
            />
        </Modal>
        <VerticalLayout>
            <PageTitle
                title='Machine Master'
                buttonText='ADD'
                onButtonClick={handleAdd}
            />
            <div className="card">
                <div className="card-body">
                    <div className='table-wrapper'>
                        <table className='table colored'>
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    <th className='text-truncate align-middle'>Machine Code</th>
                                    <th className='text-truncate align-middle'>Machine Name</th>
                                    <th className='text-truncate align-middle'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {machineMaster.map((dm, i) => {
                                    return <MachineMasterBody
                                        sno={i + 1}
                                        code={dm.code}
                                        name={dm.name}
                                        key={dm.key}
                                        authUser={authUser}
                                        timeStamp={dm.createTimestamp !== undefined ? dm.createTimestamp : ''}
                                        onEdit={() => handleEdit(dm)}
                                        onDelete={() => handleDelete(dm)}
                                    />
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </VerticalLayout>
    </>
}

const MachineMasterBody = ({ sno, code, name, onEdit, onDelete, timeStamp, authUser }: TableBody) => {
    const ShowEditForManager = isInToday(timeStamp)

    return <tr>
        <td className='align-middle'>{sno}</td>
        <td className='align-middle'>{code}</td>
        <td className='align-middle'>{name}</td>
        <td >
            <div className='hstack gap-1'>
                {(authUser.role === 'admin' || authUser.role === 'super' || authUser.role === 'Admin' || authUser.role === 'Super') && <button className='btn btn-xs btn-primary' onClick={onEdit}>Edit</button>}
                {(authUser.role === 'Manager' || authUser.role === 'manager') && ShowEditForManager && <button className='btn btn-xs btn-primary' onClick={onEdit}>Edit</button>}
                <button className='btn btn-xs btn-danger' onClick={onDelete}>Delete</button>
            </div>
        </td>
    </tr>
}

export default MachineMaster
