/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../reducers"

export enum EmployeeActionList {
    ADD_EMPLOYEE = 'ADD_EMPLOYEE',
    FETCH_EMPLOYEE = 'FETCH_EMPLOYEE',
    UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE',
    UPDATE_EMPLOYEE_ATTACHMENTS = 'UPDATE_EMPLOYEE_ATTACHMENTS',
    DELETE_EMPLOYEE = 'DELETE_EMPLOYEE',
}

export enum EmployeeTypeList {
    DRIVER = 'Driver',
    CONDUCTOR = 'Conductor',
    MECHANIC = 'Mechanic',
    ELECTRICIAN = 'Electrician',
    CLEANER = 'Cleaner',
    CHECKER = 'Checker',
    ACCOUNTANT = 'Accountant',
    OFFICE_BOY = 'Office Boy',
    OFFICE_STAFF = 'Office Staff',
    MANAGER = 'Manager',
}

export enum SalaryTypes {
    MONTHLY = 'Monthly',
    WEEKLY = 'Weekly',
    DAILY = 'Daily',
}

export type Reference = {
    name: string
    relationship: string
    contactNumber: string
}

export interface Attachment {
    type?: string
    file?: File
    url?: string
}

export type Attachments = Attachment[]

export type EmployeeSalaryType = SalaryTypes.MONTHLY | SalaryTypes.WEEKLY | SalaryTypes.DAILY

export type EmployeesType = EmployeeTypeList.ACCOUNTANT | EmployeeTypeList.CHECKER | EmployeeTypeList.CLEANER | EmployeeTypeList.CONDUCTOR | EmployeeTypeList.DRIVER | EmployeeTypeList.ELECTRICIAN | EmployeeTypeList.MANAGER | EmployeeTypeList.OFFICE_BOY | EmployeeTypeList.MECHANIC | EmployeeTypeList.OFFICE_STAFF

export interface EmployeeType {

    id?: number
    code?: string
    name: string
    type: EmployeesType
    designation: string
    dateOfJoin: string
    dateOfLeave?: string
    salaryType: EmployeeSalaryType
    mobileNumber: string
    address: string
    city: string
    state: string
    pincode: string
    reference: Reference
    drivingLicenseNumber?: string
    drivingLicenseExpiryDate?: string
    aadharNumber?: string
    attachments?: Attachment[]
    createdBy?: string
    createTimestamp?: string
    expiredOrNot?: string//for dashboard
}

export interface AddEmployeeAction {
    type: EmployeeActionList.ADD_EMPLOYEE
    data: EmployeeType
}

export interface FetchEmployeeAction {
    type: EmployeeActionList.FETCH_EMPLOYEE
    data: Array<EmployeeType>
}

export interface UpdateEmployeeAction {
    type: EmployeeActionList.UPDATE_EMPLOYEE
    data: EmployeeType
}

export interface UpdateEmployeeAttachmentsAction {
    type: EmployeeActionList.UPDATE_EMPLOYEE_ATTACHMENTS
    data: Attachments
}


export interface DeleteEmployeeAction {
    type: EmployeeActionList.DELETE_EMPLOYEE
    data: number
}

export type EmployeeActions = AddEmployeeAction | FetchEmployeeAction | UpdateEmployeeAction | DeleteEmployeeAction

export const addEmployee = (data: FormData) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.post<APIResponse<EmployeeType>>('employee/', data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<AddEmployeeAction>({
                    type: EmployeeActionList.ADD_EMPLOYEE,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Employee Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const updateEmployee = (data: EmployeeType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Employee Updated")
        return api.put<APIResponse<EmployeeType>>(`employee/?id=${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<UpdateEmployeeAction>({
                    type: EmployeeActionList.UPDATE_EMPLOYEE,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Employee Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const updateEmployeeAttachments = (data: FormData, employeeId: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Employee Updated")
        return api.post<APIResponse<Attachments>>(`employee/update_attachment/?employee_id=${employeeId}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<UpdateEmployeeAttachmentsAction>({
                    type: EmployeeActionList.UPDATE_EMPLOYEE_ATTACHMENTS,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Employee Attachments Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}


export const fetchEmployee = () => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<EmployeeType[]>>('employee/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchEmployeeAction>({
                    type: EmployeeActionList.FETCH_EMPLOYEE,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteEmployee = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Employee Deleted")
        return api.delete<APIResponse<EmployeeType>>(`employee/?id=${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteEmployeeAction>({
                    type: EmployeeActionList.DELETE_EMPLOYEE,
                    data: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Employee Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}
