import VerticalLayout from '../components/custom/layouts/Vertical'
import PageTitle from '../components/custom/components/PageTitle'
import { deleteSpareParts, SparePartsType } from '../redux/actions/sparePartsActions'
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from '../redux/reducers'
import { Key } from '../redux/actions/types'
import { addKey, isInToday } from '../config/functions'
import Modal from '../components/Modal'
import { MouseEventHandler, useState } from 'react'
import { FormType, FormTypeList } from '../components/types'
import { ToastTypes, useToast } from '../components/toast/ToastProvider'
import { AppDispatch } from '../redux/store'
import SparePartsForm from '../components/SparePartsForm'
import { AuthUserType } from '../redux/actions/authUserActions'

interface TableBody {
    sno: number
    code: string
    name: string
    number: string
    brand: string
    usedFor: string
    onEdit: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
    timeStamp: string
    authUser: AuthUserType
}

type HandleEdit = (value: SparePartsType) => void
type HandleDelete = (value: SparePartsType) => void



const SpareParts = () => {
    const toast = useToast()

    const dispatch = useDispatch<AppDispatch>()
    const authUser = useSelector<StoreState, AuthUserType>(state => state.authUser)


    const SpareParts = useSelector<StoreState, Array<SparePartsType & Key>>(state => addKey(state.spareParts))
    const [showForm, setShowForm] = useState<boolean>(false)
    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    const [editData, setEditData] = useState<SparePartsType>()

    const handleAdd = () => {
        setShowForm(true)
    }

    const handleEdit: HandleEdit = (value) => {
        setFormType(FormTypeList.UPDATE)
        setEditData(value)
        setShowForm(true)
    }

    const handleDelete: HandleDelete = (value) => {
        if (value.id !== undefined) {
            dispatch(deleteSpareParts(value.id)).then(text => {
                toast(text)
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
        }
    }

    const handleFormClose = () => {
        setShowForm(false)
        setFormType(FormTypeList.ADD)
        setEditData(undefined)
    }

    return <>
        <Modal
            headerText={formType === FormTypeList.ADD ? 'Add Spare Parts' : 'Edit Spare Parts'}
            visible={showForm}
            onClose={handleFormClose}
            size='xl'
            centered
        >
            <SparePartsForm
                formType={formType}
                editData={editData}
                onSave={handleFormClose}
            />

        </Modal>
        <VerticalLayout>
            <PageTitle
                title='Spare Parts'
                buttonText='ADD'
                onButtonClick={handleAdd}
            />
            <div className="card">
                <div className="card-body">
                    <div className='table-wrapper'>
                        <table className='table colored'>
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    <th className='text-truncate align-middle'>Code</th>
                                    <th className='text-truncate align-middle'>Name</th>
                                    <th className='text-truncate align-middle'>Number</th>
                                    <th className='text-truncate align-middle'>Brand</th>
                                    <th className='text-truncate align-middle'>Used For</th>
                                    <th className='text-truncate align-middle'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {SpareParts.map((sp, i) => {
                                    return <SparePartsBody
                                        sno={i + 1}
                                        code={sp.code}
                                        name={sp.name}
                                        number={sp.number != undefined ? sp.number : ''}
                                        brand={sp.brand}
                                        usedFor={sp.usedFor}
                                        key={sp.key}
                                        authUser={authUser}
                                        timeStamp={sp.createTimestamp !== undefined ? sp.createTimestamp : ''}
                                        onEdit={() => handleEdit(sp)}
                                        onDelete={() => handleDelete(sp)}
                                    />
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </VerticalLayout>
    </>
}

const SparePartsBody = ({ sno, code, brand, name, number, usedFor, onEdit, onDelete , timeStamp, authUser}: TableBody) => {
    const ShowEditForManager = isInToday(timeStamp)

    return <tr>
        <td>{sno}</td>
        <td>{code}</td>
        <td className='text-capitalize'>{name}</td>
        <td className='text-capitalize'>{number}</td>
        <td className='text-capitalize'>{brand}</td>
        <td className='text-capitalize'>{usedFor}</td>
        <td className='hstack gap-1'>
            {(authUser.role === 'admin' || authUser.role === 'super' || authUser.role === 'Admin' || authUser.role === 'Super') && <button className='btn btn-xs btn-primary' onClick={onEdit}>Edit</button>}
            {(authUser.role === 'Manager' || authUser.role === 'manager') && ShowEditForManager && <button className='btn btn-xs btn-primary' onClick={onEdit}>Edit</button>}
            <button className='btn btn-xs btn-danger' onClick={onDelete}>Delete</button>
        </td>
    </tr>
}

export default SpareParts
