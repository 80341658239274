import React, { ChangeEventHandler, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { addKey } from "../config/functions"
import { AssetType } from "../redux/actions/assetActions"
import { Key } from "../redux/actions/types"
import { VehicleMasterType } from "../redux/actions/vehicleMasterActions"
import { addVehicleAssetMapping, AssetLocation, AssetLocationList, updateVehicleAssetMapping, VehicleAssetMappingType } from "../redux/actions/vehicleAssetMappingActions"
import { StoreState } from "../redux/reducers"
import { AppDispatch } from "../redux/store"
import { FormInput } from "./custom/components"
import Option from "./Option"
import { ToastTypes, useToast } from "./toast/ToastProvider"
import { FormType, FormTypeList } from "./types"

interface Props {
    formType: FormType
    editData?: VehicleAssetMappingType
    onSave: () => void
}

const LOCATION = addKey([
    {
        text: 'Front Left',
        value: 'FL'
    },
    {
        text: 'Front Right',
        value: 'FR'
    },
    {
        text: 'Rear Left In',
        value: 'RLI'
    },
    {
        text: 'Rear Left Out',
        value: 'RLO'
    },
    {
        text: 'Rear Right In',
        value: 'RRI'
    },
    {
        text: 'Rear Right Out',
        value: 'RRO'
    },
    {
        text: 'Spare Wheel',
        value: 'SW'
    }
])

const VehicleAssetMappingForm = ({ formType, onSave, editData }: Props) => {
    const toast = useToast()

    const dispatch = useDispatch<AppDispatch>()
    const asset = useSelector<StoreState, Array<AssetType & Key>>(state => addKey(state.asset))
    const vehicleMaster = useSelector<StoreState, Array<VehicleMasterType & Key>>(state => addKey(state.vehicleMaster))

    const [vehicleId, setVehicleId] = useState<string>('')
    const [vehicleIdError, setVehicleIdError] = useState<string>('')
    const [vehicleName, setVehicleName] = useState<string>('')
    const [assetName, setAssetName] = useState<string>('')
    const [assetId, setAssetId] = useState<string>('')
    const [assetIdError, setAssetIdError] = useState<string>('')
    const [location, setLocation] = useState<AssetLocation>(AssetLocationList.FL)
    const [locationError, setLocationError] = useState<string>('')

    const resetStates = () => {
        setVehicleId('')
        setVehicleIdError('')
        setVehicleName('')
        setAssetName('')
        setAssetId('')
        setAssetIdError('')
        setLocation(AssetLocationList.FL)
        setLocationError('')
    }

    const handleVehicleId: ChangeEventHandler<HTMLInputElement> = (e) => {
        setVehicleId(e.target.value)
        const index = vehicleMaster.findIndex(vm => vm.id !== undefined && vm.id.toString() === e.target.value)

        if (index > -1) {
            setVehicleName(vehicleMaster[index].name)
        }
        setVehicleIdError('')
    }

    const handleAssetId: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAssetId(e.target.value)
        const index = asset.findIndex(a => a.id !== undefined && a.id.toString() === e.target.value)

        if (index > -1) {
            setAssetName(asset[index].name)
        }
        setAssetIdError('')
    }

    const handleLocation: ChangeEventHandler<HTMLInputElement> = (e) => {
        setLocation(e.target.value as AssetLocation)
        setLocationError('')
    }

    const handleClear = () => {
        resetStates()
    }

    const handleSave = () => {
        let error = false
        const data: VehicleAssetMappingType = {
            assetId: parseInt(assetId),
            assetName: assetName,
            vehicleId: parseInt(vehicleId),
            vehicleName: vehicleName,
            location: location
        }

        if (isNaN(data.vehicleId)) {
            setVehicleIdError('Vehicle required')
            error = true
        } else if (data.vehicleId <= 0) {
            setVehicleIdError('Invalid Vehicle')
            error = true
        }

        if (isNaN(data.assetId)) {
            setAssetIdError('Asset required')
            error = true
        } else if (data.assetId <= 0) {
            setAssetIdError('Invalid Asset')
            error = true
        }

        if (LOCATION.map(l => l.value).indexOf(data.location as AssetLocation) === -1) {
            setLocationError('Location must be FL/FR/RLI/RLO/RRI/RRO')
            error = true
        }

        if (!error) {
            if (formType === FormTypeList.ADD) {

                dispatch(addVehicleAssetMapping(data)).then(text => {
                    toast(text)
                    onSave()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            } else if (formType === FormTypeList.UPDATE && editData !== undefined && editData.id !== undefined) {
                dispatch(updateVehicleAssetMapping(data, editData.id)).then(text => {
                    toast(text)
                    onSave()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
        }
    }

    useEffect(() => {
        if (formType === FormTypeList.UPDATE && editData !== undefined) {
            setVehicleId(editData.vehicleId !== undefined ? editData.vehicleId.toString() : '')
            setVehicleName(editData.vehicleName)
            setAssetId(editData.assetId !== undefined ? editData.assetId.toString() : '')
            setAssetName(editData.assetName)
            setLocation(editData.location)
        } else {
            resetStates()
        }
    }, [formType, editData])


    return <div className='container'>
        <div className="row">

            <div className='col-sm-6 col-lg-4'>
                {/* <FormInput
                    value={assetName}
                    onChange={handleassetName}
                    type='select'
                    label='Route'
                    errorText={assetNameError}
                >
                    <option value="">Select</option>
                    {Asset.map(rm => {
                        return <Option value={rm.name} key={rm.key}>{rm.name}</Option>
                    })}
                </FormInput> */}
                <FormInput
                    label='Asset'
                    labelClassName="required"
                    value={assetId}
                    onChange={handleAssetId}
                    type='select'
                    errorText={assetIdError}
                    containerClass="mb-2"

                >
                    <option value="">Select</option>
                    {asset.map(a => {
                        return <Option value={a.id} key={a.key}>{a.name}-{a.number}</Option>
                    })}
                </FormInput>

            </div>

            <div className='col-sm-6 col-lg-4'>
                <FormInput
                    label='Vehicle'
                    labelClassName="required"
                    value={vehicleId}
                    onChange={handleVehicleId}
                    type='select'
                    errorText={vehicleIdError}
                    containerClass="mb-2"

                >
                    <option value="">Select</option>
                    {vehicleMaster.map(vm => {
                        return <Option value={vm.id} key={vm.key}>{vm.name}</Option>
                    })}
                </FormInput>
            </div>

            <div className='col-sm-6 col-lg-4'>
                <FormInput
                    label='Location'
                    labelClassName="required"
                    value={location}
                    onChange={handleLocation}
                    type='select'
                    errorText={locationError}
                    containerClass="mb-2"

                >
                    <option value="">Select</option>
                    {LOCATION.map(l => {
                        return <Option value={l.value} key={l.key}>{l.text}</Option>
                    })}
                </FormInput>
            </div>

        </div>
        <div className="d-flex justify-content-end hstack gap-1 mt-1">
            <button className="btn btn-primary" onClick={handleClear}>Clear</button>
            <button className="btn btn-success" onClick={handleSave}>Save</button>
        </div>

    </div>
}
export default VehicleAssetMappingForm