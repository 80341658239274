import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { ChangeEventHandler, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { FormInput } from '../components/custom/components'
import PageTitle from '../components/custom/components/PageTitle'
import VerticalLayout from '../components/custom/layouts/Vertical'
import Option from '../components/Option'
import { useToast } from '../components/toast/ToastProvider'
import api, { APIResponse, getAxiosRequestConfig } from '../config/api'
import { addKey, LocalFormatDate, rupeeFormat, today } from '../config/functions'
import { AccountHolderType } from '../redux/actions/accountHolderActions'
import { AuthUserType } from '../redux/actions/authUserActions'
import { TransactionType } from '../redux/actions/transactionActions'
import { Key } from '../redux/actions/types'
import { StoreState } from '../redux/reducers'

interface openBalance {
    openingBalance: String
}

interface TableBody {
    sno: number
    // accountNumber: string
    // accountName: string
    // accountType: string
    date: string
    particulars: string
    income: string
    expense: string
    balance?: string
    openingBalance?: string
}

const TransactionReport = () => {

    const toast = useToast()
    const authUser = useSelector<StoreState, AuthUserType>(state => state.authUser)
    const token = authUser.token!
    const config = getAxiosRequestConfig(token)

    // const transactionList = useSelector<StoreState, Array<TransactionType & Key>>(state => addKey(state.transaction))
    const accountHolder = useSelector<StoreState, Array<AccountHolderType & Key>>(state => addKey(state.accountHolder))

    const [fromDate, setFromDate] = useState<string>(today())
    const [toDate, setToDate] = useState<string>(today())
    // const [paymentMode, setPaymentMode] = useState<TransactionPaymentModes>(PaymentModes.CASH)
    const [accountNumber, setAccountNumber] = useState<string>('')

    const [transaction, setTransaction] = useState<TransactionType[]>([])

    const [openingBalance, setOpeningBalance] = useState<string>('0')

    const BankList = useMemo<Array<AccountHolderType & Key>>(() => {
        return accountHolder.filter(ah => {
            return ah.accountName.substring(0, 6) === 'BANK -'
        })
    }, [accountHolder])

    // const paymentModes = useMemo<(OptionType & Key)[]>(() => {
    //     return addKey(enumToArrayOfObj(PaymentModes))
    // }, [])

    // const handlePaymentMode: ChangeEventHandler<HTMLInputElement> = (e) => {
    //     setPaymentMode(e.target.value as TransactionPaymentModes)
    // }

    // const handleBankNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
    //     setBankNumber(e.target.value)
    //     setBankNumberError('')
    // }

    const handleFromDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setFromDate(e.target.value)
    }

    const handleToDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setToDate(e.target.value)
    }

    const handleAccountNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAccountNumber(e.target.value)
    }

    const totalIncome = useMemo<number>(() => {
        return transaction.filter(bl => {
            return bl.accountGroupName !== 'accounting'
        }).reduce((previous, current) => {
            return previous + parseFloat(current.debitAmount)
        }, 0)

    }, [transaction])

    const totalExpense = useMemo<number>(() => {
        return transaction.filter(bl => {
            return bl.accountGroupName !== 'accounting'
        }).reduce((previous, current) => {
            return previous + parseFloat(current.creditAmount)
        }, 0)

    }, [transaction])

    const totalBalance = useMemo<number>(() => {
        return parseFloat(openingBalance) + totalIncome - totalExpense

    }, [totalIncome, totalExpense, openingBalance])

    const totalIncomeWithOpeningBalance = useMemo<number>(() => {
        return parseFloat(openingBalance) + totalIncome
    }, [totalIncome, openingBalance])


    const handleDownloadPDF = () => {
        const doc = new jsPDF('l', 'mm', 'a4')

        // doc.addFont('Times-Bold', 'Times', 'bold')
        // doc.setFont('Times')
        doc.setFontSize(18)
        doc.text('SUPREME  BUS  SERVICE', 100, 13)

        doc.setFontSize(14)
        doc.text('Transaction Date :- ', 15, 26)
        doc.text('From Date: ' + fromDate, 65, 26)
        doc.text('To Date: ' + toDate, 155, 26)

        doc.text('Total', 15, 35)
        const TotalHead = [['Opening Balance', 'Total Income', 'Total Expense', 'Total Balance']]
        const TotalBody = [[rupeeFormat(openingBalance), rupeeFormat(totalIncome), rupeeFormat(totalExpense), rupeeFormat(totalBalance)]]

        autoTable(doc, {
            head: TotalHead,
            body: TotalBody,
            startY: 37,
            didDrawPage: function () {

                // // Header
                var str = doc.getNumberOfPages();
                doc.setFontSize(10);
                // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                // var pageSize = doc.internal.pageSize;
                // var pageHeight = pageSize.height
                //     ? pageSize.height
                //     : pageSize.getHeight();
                doc.text(str.toString(), 260, 10);
            }
        })

        doc.setFontSize(16)
        doc.text('Opening Balance: ', 15, (doc as any).lastAutoTable.finalY + 16)
        doc.text('Rs.' + rupeeFormat(openingBalance), 61, (doc as any).lastAutoTable.finalY + 16)

        const head = [
            ['S.No', 'Date', 'Particulars', 'Income', ' Expense', 'Balance'],
            ['1', '', 'Opening Balance', '', '', rupeeFormat(openingBalance)]
        ]

        // const foot = [['Total', '', '', totalIncome, totalExpense, totalBalance]]

        let balance = Number(openingBalance)

        const body = transaction.filter(bl => {
            return bl.accountGroupName !== 'accounting'
        }).map((t, i) => {
            balance = (balance + Number(t.debitAmount)) - Number(t.creditAmount)

            return [
                i + 2,
                t.date,
                String(t.particulars),
                String(parseFloat(t.debitAmount) !== 0 ? rupeeFormat(t.debitAmount) : ''),
                String(parseFloat(t.creditAmount) !== 0 ? rupeeFormat(t.creditAmount) : ''),
                String(rupeeFormat(balance)),
            ]
        })

        autoTable(doc, {
            head: head,
            body: body,
            // foot: foot,
            startY: (doc as any).lastAutoTable.finalY + 20,
            didDrawPage: function () {

                // // Header
                var str = doc.getNumberOfPages();
                doc.setFontSize(10);
                // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                // var pageSize = doc.internal.pageSize;
                // var pageHeight = pageSize.height
                //     ? pageSize.height
                //     : pageSize.getHeight();
                doc.text(str.toString(), 260, 10);
            }
        })
        // doc.setFontSize(14)
        // doc.text('Total', 15, (doc as any).lastAutoTable.finalY + 10)
        // // doc.text(String(totalOpeningBalance), 98, (doc as any).lastAutoTable.finalY + 10)
        // doc.text(String(totalIncome), 153, (doc as any).lastAutoTable.finalY + 10)
        // doc.text(String(totalExpense), 194, (doc as any).lastAutoTable.finalY + 10)
        // doc.text(String(totalBalance), 242, (doc as any).lastAutoTable.finalY + 10)

        doc.save("Daybook Summary.pdf")
    }

    useEffect(() => {
        if (toDate !== undefined && fromDate !== undefined && accountNumber !== '') {
            api.get<APIResponse<TransactionType[]>>(`transaction/report/?from_date=${fromDate}&to_date=${toDate}&account_number=${accountNumber}`, config).then(response => {

                if (response.status === 200 && response.data.data) {

                    const result = response.data.data
                    setTransaction(result)
                }
            }).catch(error => {

            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toDate, fromDate, accountNumber])


    useEffect(() => {
        if (fromDate !== undefined && accountNumber !== '') {
            api.get<APIResponse<openBalance>>(`transaction/opening_balance/?account_no=${accountNumber}&date=${fromDate}`, config).then(response => {

                if (response.status === 200 && response.data.data) {

                    const result = response.data.data
                    // console.log(result);
                    setOpeningBalance((result.openingBalance).toString())
                }
            }).catch(error => {

            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fromDate, accountNumber])

    // //BANK
    // useEffect(() => {
    //     if (toDate !== undefined && fromDate !== undefined && paymentMode === PaymentModes.BANK && bankNumber !== '') {
    //         api.get<APIResponse<TransactionType[]>>(`transaction/report/?from_date=${fromDate}&to_date=${toDate}&account_number=${bankNumber}`, config).then(response => {

    //             if (response.status === 200 && response.data.data) {

    //                 const result = response.data.data
    //                 setBankTransaction(result)
    //             }
    //         }).catch(error => {

    //         })
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [toDate, fromDate, bankNumber, paymentMode])


    // useEffect(() => {
    //     if (fromDate !== undefined && paymentMode === PaymentModes.BANK && bankNumber !== '') {
    //         api.get<APIResponse<openBalance>>(`transaction/opening_balance/?account_no=${bankNumber}&date=${fromDate}`, config).then(response => {

    //             if (response.status === 200 && response.data.data) {

    //                 const result = response.data.data
    //                 // console.log(result);

    //                 setBankOpeningBalance((result.openingBalance).toString())
    //             }
    //         }).catch(error => {

    //         })
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [fromDate, bankNumber, paymentMode])

    let balance = Number(openingBalance)

    return <>

        <VerticalLayout>
            <PageTitle
                title='Daybook Summary'
            />

            <div className='card'>
                <div className='card-body'>
                    <div className='container-fluid'>
                        <div className="row">
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <FormInput
                                    label='From Date'
                                    value={fromDate}
                                    onChange={handleFromDate}
                                    placeholder="From Date"
                                    containerClass="mb-2"
                                    type="date"
                                />
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <FormInput
                                    label='To Date'
                                    value={toDate}
                                    onChange={handleToDate}
                                    placeholder="To Date"
                                    containerClass="mb-2"
                                    type="date"
                                />
                            </div>

                            {/* <div className="col-sm-6 col-md-4 col-lg-4">
                                <FormInput
                                    value={paymentMode}
                                    onChange={handlePaymentMode}
                                    type='select'
                                    label='Payment Modes'
                                >
                                    <option value="">All</option>
                                    {paymentModes.map(pm => {
                                        return <Option value={pm.value} key={pm.key}>{pm.text}</Option>
                                    })}
                                </FormInput>
                            </div> */}

                            {/* <div className='col-sm-6 col-md-4 col-lg-4 my-2'>
                                <FormInput
                                    label='Bank'
                                    labelClassName="required"
                                    type='select'
                                    value={bankNumber}
                                    onChange={handleBankNumber}
                                    errorText={bankNumberError}
                                    containerClass="mb-2"
                                >
                                    <Option value=''>Select Bank</Option>
                                    <Option value='900001'>Cash</Option>
                                    {BankList.map(b => {
                                        return <Option value={b.accountNumber} key={b.key}>{b.accountName}({(b.accountHead)})</Option>
                                    })}
                                </FormInput>
                            </div> */}


                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <FormInput
                                    value={accountNumber}
                                    labelClassName="required"
                                    onChange={handleAccountNumber}
                                    type='select'
                                    label='Account'
                                >
                                    <Option value=''>Select Account</Option>
                                    <Option value='900001'>Cash</Option>
                                    {BankList.map(b => {
                                        return <Option value={b.accountNumber} key={b.key}>{b.accountName}({(b.accountHead)})</Option>
                                    })}
                                </FormInput>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {transaction.length > 0 && <div className='card'>
                <div className='card-body'>
                    <div className='container-fluid'>
                        <div className="row">
                            <div className="col-sm-6 col-md-3 col-lg-3">
                                <h4>Opening Balance: {rupeeFormat(openingBalance)}</h4>
                            </div>
                            <div className="col-sm-6 col-md-3 col-lg-3">
                                <h4>Income: {rupeeFormat(totalIncome)}</h4>
                            </div>
                            <div className="col-sm-6 col-md-3 col-lg-3">
                                <h4>Expense: {rupeeFormat(totalExpense)}</h4>
                            </div>
                            <div className="col-sm-6 col-md-3 col-lg-3">
                                <h4>Balance: {rupeeFormat(totalBalance)}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}

            {transaction.length > 0 ? <div className="card">
                <div className="card-body">
                    {/* <h4>Cash</h4> */}
                    <div className='table-wrapper'>
                        <table className='table colored'>
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    <th className='text-truncate align-middle'>Date</th>
                                    <th className='text-truncate align-middle'>Particulars</th>
                                    <th className='text-truncate align-middle text-end'>Income</th>
                                    <th className='text-truncate align-middle text-end'>Expense</th>
                                    <th className='text-truncate align-middle text-end'>Balance</th>

                                </tr>
                            </thead>
                            <tbody>
                                {transaction.length > 0 && <tr>
                                    <td>1</td>
                                    <td className='text-truncate'>{LocalFormatDate(fromDate)}</td>
                                    <td>Opening Balance</td>
                                    <td className='text-end'>{rupeeFormat(openingBalance)}</td>
                                    <td></td>
                                    <td className='text-end'>{rupeeFormat(openingBalance)}</td>
                                </tr>}
                                {transaction.filter(bl => {
                                    return bl.accountGroupName !== 'accounting'
                                }).map((t, i) => {
                                    balance = (balance + Number(t.debitAmount)) - Number(t.creditAmount)
                                    return <TransactionBody
                                        sno={i + 2}
                                        date={t.date}
                                        particulars={t.particulars}
                                        income={t.debitAmount}
                                        expense={t.creditAmount}
                                        balance={String(balance)}
                                        key={t.id}

                                    />
                                })}
                            </tbody>
                            {transaction.length > 0 && <tfoot>
                                <tr>
                                    <td style={{ textAlign: 'start' }}>
                                        <span className="text-dark fw-bold fs-5">Total</span>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td style={{ textAlign: 'end' }}>
                                        <span className="text-dark fw-bold fs-5">{rupeeFormat(totalIncomeWithOpeningBalance)}</span>
                                    </td>
                                    <td style={{ textAlign: 'end' }}>
                                        <span className="text-dark fw-bold fs-5">{rupeeFormat(totalExpense)}</span>
                                    </td>
                                    <td style={{ textAlign: 'end' }}>
                                        <span className="text-dark fw-bold fs-5">{rupeeFormat(totalBalance)}</span>
                                    </td>
                                </tr>
                            </tfoot>}
                        </table>
                    </div>
                    <div className='my-2 text-end'>
                        <button className='btn btn-primary' onClick={handleDownloadPDF}>Download PDF
                            <i className='fe-download'></i>
                        </button>
                    </div>
                </div>
            </div>
                : <div className='text-center'>
                    <label>No records found</label>
                </div>}


        </VerticalLayout>
    </>
}

const TransactionBody = ({ sno, date, particulars, income, expense, balance }: TableBody) => {
    // const [currentBalance, setCurrentBalance] = useState<number>(0)
    // if (sno === 2) {
    //     setCurrentBalance(parseFloat(openingBalance !== undefined ? openingBalance : '0') + parseFloat(income) - parseFloat(expense))
    // } else if (sno > 2) {
    //     setCurrentBalance(currentBalance + parseFloat(income) - parseFloat(expense))
    // }
    return <tr>
        <td>{sno}</td>
        <td className='text-truncate'>{date}</td>
        <td className='text-capitalize'>{particulars}</td>
        <td className='text-end'>{parseFloat(income) !== 0 ? rupeeFormat(income) : ''}</td>
        <td className='text-end'>{parseFloat(expense) !== 0 ? rupeeFormat(expense) : ''}</td>
        <td className='text-end'>{rupeeFormat(balance)}</td>

    </tr>

}

export default TransactionReport
