import React, { ChangeEventHandler, MouseEventHandler, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
// import { addKey } from "../config/functions"
import { addRtoMaster, RtoMasterType, updateRtoMaster } from "../redux/actions/rtoMasterActions"
// import { Key } from "../redux/actions/types"
// import { StoreState } from "../redux/reducers"
import { AppDispatch } from "../redux/store"
import { FormInput } from "./custom/components"
import { ToastTypes, useToast } from "./toast/ToastProvider"
import { FormType, FormTypeList } from "./types"

interface Props {
    formType: FormType
    editData?: RtoMasterType
    onSave: () => void
}
// id:PK,
// code:FT/UK,
// name:FT,
// area:FT

const RtoMasterForm = ({ formType, onSave, editData }: Props) => {
    const toast = useToast()

    const dispatch = useDispatch<AppDispatch>()
    // const rtoMaster = useSelector<StoreState, Array<RtoMasterType & Key>>(state => addKey(state.rtoMaster))

    const [code, setCode] = useState<string>('')
    const [codeError, setCodeError] = useState<string>('')
    const [name, setName] = useState<string>('')
    const [nameError, setNameError] = useState<string>('')
    const [area, setArea] = useState<string>('')
    const [areaError, setAreaError] = useState<string>('')

    const resetStates = () => {
        setCode('')
        setCodeError('')
        setName('')
        setNameError('')
        setArea('')
        setAreaError('')
    }

    const handleCode: ChangeEventHandler<HTMLInputElement> = (e) => {
        setCode(e.target.value)
        setCodeError('')
    }

    const handleName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setName(e.target.value)
        setNameError('')
    }

    const handleArea: ChangeEventHandler<HTMLInputElement> = (e) => {
        setArea(e.target.value)
        setAreaError('')
    }

    const handleClear: MouseEventHandler<HTMLButtonElement> = (e) => {
        resetStates()
    }

    const handleSave = () => {
        let error = false
        const data: RtoMasterType = {
            code: code,
            name: name,
            area: area
        }
        // if (formType === FormTypeList.ADD) {
        //     if (data.code === "") {
        //         setCodeError('Code required')
        //         error = true
        //     } else if ((data.code).length > 15) {
        //         setCodeError('Code must be within 15 characters')
        //         error = true
        //     } else if (data.code !== "") {
        //         const uniqueIndex = rtoMaster.findIndex(rm => {
        //             return rm.code.toLowerCase() === data.code.toLowerCase()
        //         })
        //         if (uniqueIndex > -1) {
        //             setCodeError('Code already exists')
        //             error = true
        //         }

        //     }
        // }

        // if (formType === FormTypeList.UPDATE) {
        //     if (data.code === "") {
        //         setCodeError('Code required')
        //         error = true
        //     } else if ((data.code).length > 15) {
        //         setCodeError('Code must be within 15 characters')
        //         error = true
        //     }
        // }

        if (data.code !== "" && data.code !== undefined) {
            if ((data.code).length > 15) {
                setCodeError('Code must be within 15 characters')
                error = true
            }
        }

        if (data.name === "") {
            setNameError('Name required')
            error = true
        } else if ((data.name).length > 30) {
            setNameError('Name must be within 30 characters')
            error = true
        }

        if (data.area === "") {
            setAreaError('Area required')
            error = true
        } else if ((data.area).length > 30) {
            setAreaError('Area must be within 30 characters')
            error = true
        }

        if (!error) {
            if (formType === FormTypeList.ADD) {

                dispatch(addRtoMaster(data)).then(text => {
                    toast(text)
                    onSave()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            } else if (formType === FormTypeList.UPDATE && editData !== undefined && editData.id !== undefined) {
                dispatch(updateRtoMaster(data, editData.id)).then(text => {
                    toast(text)
                    onSave()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
        }
    }

    useEffect(() => {
        if (formType === FormTypeList.UPDATE && editData !== undefined) {

            setCode(editData.code !== undefined ? editData.code : '')
            setName(editData.name)
            setArea(editData.area)

        } else {
            resetStates()
        }
    }, [formType, editData])


    return <div className='container'>
        <div className="row">

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Code'
                    // labelClassName="required"
                    value={code}
                    onChange={handleCode}
                    placeholder='Code'
                    errorText={codeError}
                    containerClass="mb-2"
                />
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Name'
                    labelClassName="required"
                    value={name}
                    onChange={handleName}
                    placeholder='Name'
                    errorText={nameError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Area'
                    labelClassName="required"
                    value={area}
                    onChange={handleArea}
                    placeholder='Area'
                    errorText={areaError}
                    containerClass="mb-2"
                />
            </div>

        </div>
        <div className="d-flex justify-content-end hstack gap-1">
            <button className="btn btn-primary" onClick={handleClear}>Clear</button>
            <button className="btn btn-success" onClick={handleSave}>Save</button>
        </div>

    </div>
}
export default RtoMasterForm