/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import { default as serviceMasterData } from '../dataSet/serviceMaster.json'
import { GetState } from "../reducers"

export enum ServiceMasterActionList {
    ADD_SERVICE_MASTER = 'ADD_SERVICE_MASTER',
    FETCH_SERVICE_MASTER = 'FETCH_SERVICE_MASTER',
    UPDATE_SERVICE_MASTER = 'UPDATE_SERVICE_MASTER',
    DELETE_SERVICE_MASTER = 'DELETE_SERVICE_MASTER',
}

export interface ServiceMasterType {

    id?: number
    code: string
    type: string
    description: string
    createdBy?: string
    createTimestamp?: string
}

export interface AddServiceMasterAction {
    type: ServiceMasterActionList.ADD_SERVICE_MASTER
    data: ServiceMasterType
}

export interface FetchServiceMasterAction {
    type: ServiceMasterActionList.FETCH_SERVICE_MASTER
    data: Array<ServiceMasterType>
}

export interface UpdateServiceMasterAction {
    type: ServiceMasterActionList.UPDATE_SERVICE_MASTER
    data: ServiceMasterType
}

export interface DeleteServiceMasterAction {
    type: ServiceMasterActionList.DELETE_SERVICE_MASTER
    data: number
}

export type ServiceMasterActions = AddServiceMasterAction | FetchServiceMasterAction | UpdateServiceMasterAction | DeleteServiceMasterAction

export const addServiceMaster = (data: ServiceMasterType) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const { serviceMaster } = getState()
        dispatch<AddServiceMasterAction>({
            type: ServiceMasterActionList.ADD_SERVICE_MASTER,
            data: {
                ...data,
                id: serviceMaster.length + 1
            }
        })
        return Promise.resolve("Service Master Added")
        // return api.post<APIResponse<ServiceMasterType>>('ServiceMaster/', data).then(response => {
        //    if (response.status === 200 && response.data.data) {

        //         return Promise.resolve(response.data.message
        //             ? response.data.message
        //             : 'Successfully logged in')
        //     } else {
        //         throw { response }
        //     }
        // }).catch(error => {
        //     return Promise.reject(error.response
        //         ? error.response.data.message
        //             ? error.response.data.message
        //             : 'Unable to login'
        //         : 'Unable to login')
        // })
    }
}

export const updateServiceMaster = (data: ServiceMasterType, id: number) => {
    return async (dispatch: Dispatch) => {
        dispatch<UpdateServiceMasterAction>({
            type: ServiceMasterActionList.UPDATE_SERVICE_MASTER,
            data: {
                ...data,
                id: id
            }
        })
        return Promise.resolve("Service Master Updated")

        // return api.post<APIResponse<ServiceMasterType>>('ServiceMaster/', data).then(response => {
        //    if (response.status === 200 && response.data.data) {

        //         return Promise.resolve(response.data.message
        //             ? response.data.message
        //             : 'Successfully logged in')
        //     } else {
        //         throw { response }
        //     }
        // }).catch(error => {
        //     return Promise.reject(error.response
        //         ? error.response.data.message
        //             ? error.response.data.message
        //             : 'Unable to login'
        //         : 'Unable to login')
        // })
    }
}

export const fetchServiceMaster = () => {
    return async (dispatch: Dispatch) => {
        dispatch<FetchServiceMasterAction>({
            type: ServiceMasterActionList.FETCH_SERVICE_MASTER,
            data: serviceMasterData
        })
        // return api.post<APIResponse<ServiceMasterType>>('ServiceMaster/', data).then(response => {
        //    if (response.status === 200 && response.data.data) {

        //         return Promise.resolve(response.data.message
        //             ? response.data.message
        //             : 'Successfully logged in')
        //     } else {
        //         throw { response }
        //     }
        // }).catch(error => {
        //     return Promise.reject(error.response
        //         ? error.response.data.message
        //             ? error.response.data.message
        //             : 'Unable to login'
        //         : 'Unable to login')
        // })
    }
}

export const deleteServiceMaster = (id: number) => {
    return async (dispatch: Dispatch) => {
        dispatch<DeleteServiceMasterAction>({
            type: ServiceMasterActionList.DELETE_SERVICE_MASTER,
            data: id
        })
        return Promise.resolve("Service Master Deleted")
        // return api.post<APIResponse<ServiceMasterType>>('ServiceMaster/', data).then(response => {
        //    if (response.status === 200 && response.data.data) {

        //         return Promise.resolve(response.data.message
        //             ? response.data.message
        //             : 'Successfully logged in')
        //     } else {
        //         throw { response }
        //     }
        // }).catch(error => {
        //     return Promise.reject(error.response
        //         ? error.response.data.message
        //             ? error.response.data.message
        //             : 'Unable to login'
        //         : 'Unable to login')
        // })
    }
}

