import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { ChangeEventHandler, Fragment, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { FormInput } from '../components/custom/components'
import PageTitle from '../components/custom/components/PageTitle'
import VerticalLayout from '../components/custom/layouts/Vertical'
import Option from '../components/Option'
import api, { APIResponse, getAxiosRequestConfig } from '../config/api'
import { addKey, currencyFormat, LocalFormatDate, rupeeFormat, today } from '../config/functions'
import { VehicleReadingType } from '../redux/actions/collectionActions'
import { RouteMasterType } from '../redux/actions/routeMasterActions'
import { Key } from '../redux/actions/types'
import { StoreState } from '../redux/reducers'
// import { CollectionSaveDataType } from '../components/CollectionForm'
import * as Feather from 'react-feather'
import { ExpenseDetails } from '../components/CollectionForm'
import { ToastTypes, useToast } from '../components/toast/ToastProvider'
import { AuthUserType } from '../redux/actions/authUserActions'
import { CollectionDetailsType, CollectionTypeList } from '../redux/actions/collectionDetailsActions'

export interface CollectionReportType {

    id?: number
    routeId: number
    routeName: string
    vehicleId: number
    vehicleName: string
    date: string
    invoiceNumber: string
    totalIncome: string
    totalExpense: string
    shortage?: string
    excess?: string
    balance: string
    createdBy?: string
    createTimestamp?: string
    vehicleReading?: VehicleReadingType[]
    collectionDetails: CollectionDetailsType[]
}


interface TableBody {

    sno: number
    id: number
    date: string
    routeName: string
    diesel?: string
    driverSalary?: string
    conductorSalary?: string
    salary?: string
    cleanerSalary?: string
    driverAllowance?: string
    conductorAllowance?: string
    allowance?: string
    incentive?: string
    bookingCharge?: string
    standRent?: string
    Others?: string
    totalIncome: string
    totalExpense: string
    shortage?: string
    excess?: string
    balance: string
    createdBy?: string
    createTimestamp?: string
}


const CollectionReport = () => {
    const toast = useToast()

    const authUser = useSelector<StoreState, AuthUserType>(state => state.authUser)
    const token = authUser.token!
    const config = getAxiosRequestConfig(token)

    // const collection = useSelector<StoreState, Array<CollectionType & Key>>(state => addKey(state.collection))
    const routeMaster = useSelector<StoreState, Array<RouteMasterType & Key>>(state => addKey(state.routeMaster))

    // const [showForm, setShowForm] = useState<boolean>(false)
    // const [viewForm, setViewForm] = useState<boolean>(false)
    // const [editData, setEditData] = useState<CollectionType>()

    const [fromDate, setFromDate] = useState<string>(today())
    const [toDate, setToDate] = useState<string>(today())
    const [routeId, setRouteId] = useState<string>('')
    const [collection, setCollection] = useState<CollectionReportType[]>([])


    const handleFromDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setFromDate(e.target.value)
    }

    const handleToDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setToDate(e.target.value)
    }

    const handleRouteId: ChangeEventHandler<HTMLInputElement> = (e) => {
        setRouteId(e.target.value)
    }

    const totalIncome = useMemo<number>(() => {
        return collection.reduce((previous, current) => {
            return previous + parseFloat(current.totalIncome)
        }, 0)

    }, [collection])

    const totalExpense = useMemo<number>(() => {
        return collection.reduce((previous, current) => {
            return previous + parseFloat(current.totalExpense)
        }, 0)

    }, [collection])

    // const totalShortage = useMemo<number>(() => {
    //     return collection.reduce((previous, current) => {
    //         return previous + (current.shortage !== undefined ? parseFloat(current.shortage) : 0)
    //     }, 0)

    // }, [collection])

    const totalDiesel = useMemo<number>(() => {
        return collection.map(c => {
            return c.collectionDetails.filter((ccd, i) => {
                return ccd.type === CollectionTypeList.EXPENSE && ccd.details === ExpenseDetails.DIESEL
            }).reduce((previous, current) => {
                return previous + (current.amount !== undefined ? parseFloat(current.amount) : 0)
            }, 0)
        }).reduce((previous, current) => {
            return previous + current
        }, 0)
    }, [collection])

    const totalDriverSalary = useMemo<number>(() => {
        return collection.map(c => {
            return c.collectionDetails.filter((ccd, i) => {
                return ccd.type === CollectionTypeList.EXPENSE && ccd.details === ExpenseDetails.DRIVER_SALARY
            }).reduce((previous, current) => {
                return previous + (current.amount !== undefined ? parseFloat(current.amount) : 0)
            }, 0)
        }).reduce((previous, current) => {
            return previous + current
        }, 0)
    }, [collection])

    const totalConductorSalary = useMemo<number>(() => {
        return collection.map(c => {
            return c.collectionDetails.filter((ccd, i) => {
                return ccd.type === CollectionTypeList.EXPENSE && ccd.details === ExpenseDetails.CONDUCTOR_SALARY
            }).reduce((previous, current) => {
                return previous + (current.amount !== undefined ? parseFloat(current.amount) : 0)
            }, 0)
        }).reduce((previous, current) => {
            return previous + current
        }, 0)
    }, [collection])

    const totalSalary = useMemo<number>(() => {
        return totalDriverSalary + totalConductorSalary

    }, [totalConductorSalary, totalDriverSalary])


    const totalCleanerSalary = useMemo<number>(() => {
        return collection.map(c => {
            return c.collectionDetails.filter((ccd, i) => {
                return ccd.type === CollectionTypeList.EXPENSE && ccd.details === ExpenseDetails.CONDUCTOR_SALARY
            }).reduce((previous, current) => {
                return previous + (current.amount !== undefined ? parseFloat(current.amount) : 0)
            }, 0)
        }).reduce((previous, current) => {
            return previous + current
        }, 0)
    }, [collection])

    const totalDriverAllowance = useMemo<number>(() => {
        return collection.map(c => {
            return c.collectionDetails.filter((ccd, i) => {
                return ccd.type === CollectionTypeList.EXPENSE && ccd.details === ExpenseDetails.DRIVER_ALLOWANCE
            }).reduce((previous, current) => {
                return previous + (current.amount !== undefined ? parseFloat(current.amount) : 0)
            }, 0)
        }).reduce((previous, current) => {
            return previous + current
        }, 0)
    }, [collection])

    const totalConductorAllowance = useMemo<number>(() => {
        return collection.map(c => {
            return c.collectionDetails.filter((ccd, i) => {
                return ccd.type === CollectionTypeList.EXPENSE && ccd.details === ExpenseDetails.CONDUCTOR_ALLOWANCE
            }).reduce((previous, current) => {
                return previous + (current.amount !== undefined ? parseFloat(current.amount) : 0)
            }, 0)
        }).reduce((previous, current) => {
            return previous + current
        }, 0)
    }, [collection])

    const totalAllowance = useMemo<number>(() => {
        return totalDriverAllowance + totalConductorAllowance

    }, [totalConductorAllowance, totalDriverAllowance])

    const totalIncentive = useMemo<number>(() => {
        return collection.map(c => {
            return c.collectionDetails.filter((ccd, i) => {
                return ccd.type === CollectionTypeList.EXPENSE && ccd.details === ExpenseDetails.INCENTIVE
            }).reduce((previous, current) => {
                return previous + (current.amount !== undefined ? parseFloat(current.amount) : 0)
            }, 0)
        }).reduce((previous, current) => {
            return previous + current
        }, 0)
    }, [collection])

    const totalBookingCharge = useMemo<number>(() => {
        return collection.map(c => {
            return c.collectionDetails.filter((ccd, i) => {
                return ccd.type === CollectionTypeList.EXPENSE && ccd.details === ExpenseDetails.BOOKING_CHARGE
            }).reduce((previous, current) => {
                return previous + (current.amount !== undefined ? parseFloat(current.amount) : 0)
            }, 0)
        }).reduce((previous, current) => {
            return previous + current
        }, 0)
    }, [collection])

    const totalStandRent = useMemo<number>(() => {
        return collection.map(c => {
            return c.collectionDetails.filter((ccd, i) => {
                return ccd.type === CollectionTypeList.EXPENSE && ccd.details === ExpenseDetails.STAND_RENT
            }).reduce((previous, current) => {
                return previous + (current.amount !== undefined ? parseFloat(current.amount) : 0)
            }, 0)
        }).reduce((previous, current) => {
            return previous + current
        }, 0)
    }, [collection])

    const totalOthers = useMemo<number>(() => {
        return collection.map(c => {
            return c.collectionDetails.filter((ccd, i) => {
                return ccd.type === CollectionTypeList.EXPENSE && ccd.details === ExpenseDetails.OTHERS
            }).reduce((previous, current) => {
                return previous + (current.amount !== undefined ? parseFloat(current.amount) : 0)
            }, 0)
        }).reduce((previous, current) => {
            return previous + current
        }, 0)
    }, [collection])

    const totalBalance = useMemo<number>(() => {
        return collection.reduce((previous, current) => {
            return previous + parseFloat(current.balance)
        }, 0)

    }, [collection])

    const handleDownloadPDF = () => {
        const doc = new jsPDF('l', 'mm', 'a4')

        doc.addFont('Times-Bold', 'Times', 'bold')
        doc.setFont('Times')
        doc.setFontSize(18)
        doc.text('SUPREME  BUS  SERVICE', 100, 13)

        doc.setFontSize(14)
        doc.text('Transaction Date :- ', 15, 25)
        doc.text('From Date: ' + LocalFormatDate(fromDate), 65, 25)
        doc.text('To Date: ' + LocalFormatDate(toDate), 155, 25)

        doc.setFontSize(14)
        // doc.text('Total', 15, 35)

        const TotalHead = [['Total Income', 'Total Diesel', 'Total Salary', 'Total Clnr Sal', 'Total Alw.', 'Total Incent', 'Total B.Charge', 'Total S.Rent', 'Total Others', 'Total Exp.', 'Total Balance']]

        const TotalBody = [[rupeeFormat(totalIncome), rupeeFormat(totalDiesel), rupeeFormat(totalSalary), rupeeFormat(totalCleanerSalary), rupeeFormat(totalAllowance), rupeeFormat(totalIncentive), rupeeFormat(totalBookingCharge), rupeeFormat(totalStandRent), rupeeFormat(totalOthers), rupeeFormat(totalExpense), rupeeFormat(totalBalance)]]

        autoTable(doc, {
            head: TotalHead,
            body: TotalBody,
            startY: 37,
            didDrawPage: function () {

                // // Header
                var str = doc.getNumberOfPages();
                doc.setFontSize(10);
                // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                // var pageSize = doc.internal.pageSize;
                // var pageHeight = pageSize.height
                //     ? pageSize.height
                //     : pageSize.getHeight();
                doc.text(str.toString(), 260, 10);
            }
        })

        const head = [['S.No', 'Date', 'Route', 'Income', 'Diesel', 'Salary', 'Clnr Sal', 'Alw.', 'Incent', 'B.Charge', 'S.Rent', 'Others', 'Total Exp.', 'Balance']]

        // const foot = [['Total', '', '', totalIncome, totalDiesel, totalSalary, totalCleanerSalary, totalAllowance, totalIncentive, totalBookingCharge, totalStandRent, totalOthers, totalExpense, totalBalance]]


        const body = collection.map((c, i) => {
            let diesel = '0'
            let driverSalary = '0'
            let conductorSalary = '0'
            let cleanerSalary = '0'
            let driverAllowance = '0'
            let conductorAllowance = '0'
            let incentive = '0'
            let bookingCharge = '0'
            let standRent = '0'
            let others = '0'


            if (c.collectionDetails && c.collectionDetails.length > 0) {
                c.collectionDetails.filter((ccd, i) => {
                    return ccd.type === CollectionTypeList.EXPENSE
                }).forEach(cd => {
                    if (cd.details === ExpenseDetails.DIESEL) {
                        diesel = diesel === '0' ? cd.amount : (diesel + cd.amount)
                    } else if (cd.details === ExpenseDetails.DRIVER_SALARY) {
                        driverSalary = driverSalary === '0' ? cd.amount : (driverSalary + cd.amount)
                    } else if (cd.details === ExpenseDetails.CONDUCTOR_SALARY) {
                        conductorSalary = conductorSalary === '0' ? cd.amount : (conductorSalary + cd.amount)
                    } else if (cd.details === ExpenseDetails.CLEANER_SALARY) {
                        cleanerSalary = cleanerSalary === '0' ? cd.amount : (cleanerSalary + cd.amount)
                    } else if (cd.details === ExpenseDetails.DRIVER_ALLOWANCE) {
                        driverAllowance = driverAllowance === '0' ? cd.amount : (driverAllowance + cd.amount)
                    } else if (cd.details === ExpenseDetails.CONDUCTOR_ALLOWANCE) {
                        conductorAllowance = conductorAllowance === '0' ? cd.amount : (conductorAllowance + cd.amount)
                    } else if (cd.details === ExpenseDetails.BOOKING_CHARGE) {
                        bookingCharge = bookingCharge === '0' ? cd.amount : (bookingCharge + cd.amount)
                    } else if (cd.details === ExpenseDetails.INCENTIVE) {
                        incentive = incentive === '0' ? cd.amount : (incentive + cd.amount)
                    } else if (cd.details === ExpenseDetails.STAND_RENT) {
                        standRent = standRent === '0' ? cd.amount : (standRent + cd.amount)
                    } else if (cd.details === ExpenseDetails.OTHERS) {
                        others = others === '0' ? cd.amount : (others + cd.amount)
                    }
                })
            }
            let salary = Number(driverSalary + conductorSalary)
            let allowance = Number(driverAllowance + conductorAllowance)
            return [
                i + 1,
                c.date,
                String(c.routeName),
                String(c.totalIncome !== '0' ? rupeeFormat(c.totalIncome) : ''),
                String(diesel !== '0' ? rupeeFormat(diesel) : ''),
                // String(driverSalary!=='0'? rupeeFormat(driverSalary):''),
                // String(conductorSalary!=='0'? rupeeFormat(conductorSalary):''),
                String(salary !== 0 ? rupeeFormat(salary) : ''),
                String(cleanerSalary !== '0' ? rupeeFormat(cleanerSalary) : ''),
                String(allowance !== 0 ? rupeeFormat(allowance) : ''),
                // String(driverAllowance!=='0'? rupeeFormat(driverAllowance):''),
                // String(conductorAllowance!=='0'? rupeeFormat(conductorAllowance):''),
                String(incentive !== '0' ? rupeeFormat(incentive) : ''),
                String(bookingCharge !== '0' ? rupeeFormat(bookingCharge) : ''),
                String(standRent !== '0' ? rupeeFormat(standRent) : ''),
                String(others !== '0' ? rupeeFormat(others) : ''),
                String(c.totalExpense !== '0' ? rupeeFormat(c.totalExpense) : ''),
                // String(c.shortage!== '0' ? rupeeFormat(c.shortage):''),
                // String(c.excess!== '0' ? rupeeFormat(c.excess):''),
                String(c.balance !== '0' ? rupeeFormat(c.balance) : '')

            ]
        })

        autoTable(doc, {
            head: head,
            body: body,
            // foot: foot,
            startY: (doc as any).lastAutoTable.finalY + 10,
            didDrawPage: function () {

                // // Header
                var str = doc.getNumberOfPages();
                doc.setFontSize(10);
                // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                // var pageSize = doc.internal.pageSize;
                // var pageHeight = pageSize.height
                //     ? pageSize.height
                //     : pageSize.getHeight();
                doc.text(str.toString(), 260, 10);
            }
        })

        // doc.setFontSize(11)
        // doc.text('Total', 15, (doc as any).lastAutoTable.finalY + 10)
        // doc.text(String(totalIncome), 66, (doc as any).lastAutoTable.finalY + 10)
        // doc.text(String(totalDiesel), 86, (doc as any).lastAutoTable.finalY + 10)
        // doc.text(String(totalSalary), 104, (doc as any).lastAutoTable.finalY + 10)
        // doc.text(String(totalCleanerSalary), 121, (doc as any).lastAutoTable.finalY + 10)
        // doc.text(String(totalAllowance), 142, (doc as any).lastAutoTable.finalY + 10)
        // doc.text(String(totalIncentive), 163, (doc as any).lastAutoTable.finalY + 10)
        // doc.text(String(totalBookingCharge), 183, (doc as any).lastAutoTable.finalY + 10)
        // doc.text(String(totalStandRent), 208, (doc as any).lastAutoTable.finalY + 10)
        // doc.text(String(totalOthers), 228, (doc as any).lastAutoTable.finalY + 10)
        // doc.text(String(totalExpense), 243, (doc as any).lastAutoTable.finalY + 10)
        // doc.text(String(totalBalance), 267, (doc as any).lastAutoTable.finalY + 10)

        doc.save("Collection Report.pdf")
    }

    useEffect(() => {
        // if (editData !== undefined && editData.id !== undefined) {
        api.get<APIResponse<CollectionReportType[]>>(`collection/report/?from_date=${fromDate}&to_date=${toDate}&route_id=${routeId}`, config).then(response => {

            if (response.status === 200 && response.data.data) {
                const result = response.data.data
                // console.log(result);
                setCollection(result)


            } else {
                // eslint-disable-next-line no-throw-literal
                throw { response }
            }

        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? toast(error.response.data.message, ToastTypes.ERROR)
                    : 'Unable to fetch'
                : 'Unable to fetch')

        })
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fromDate, toDate, routeId])

    return <Fragment>
        {/* <Modal
            headerText={formType === FormTypeList.ADD ? 'Add Collection' : 'Edit Collection'}
            visible={showForm}
            onClose={handleFormClose}
            centered
            size='xl'
        >
            <CollectionForm
                formType={formType}
                editData={editData}
                // onSave={handleFormClose}
            />

        </Modal> */}

        <VerticalLayout>
            <PageTitle
                title='Collection Report'
            />

            <div className='card shadow-box-2 px-0 mx-0 mb-1 pt-1'>
                <div className='card-body ps-3 py-0'>
                    <div className='container-fluid'>
                        <div className="row">
                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <FormInput
                                    label='From Date'
                                    value={fromDate}
                                    onChange={handleFromDate}
                                    placeholder="From Date"
                                    containerClass="mb-2"
                                    type="date"
                                />
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <FormInput
                                    label='To Date'
                                    value={toDate}
                                    onChange={handleToDate}
                                    placeholder="To Date"
                                    containerClass="mb-2"
                                    type="date"
                                />
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <FormInput
                                    value={routeId}
                                    onChange={handleRouteId}
                                    type='select'
                                    label='Route'
                                >
                                    <option value="">All</option>
                                    {routeMaster.filter(rm => {
                                        return rm.code !== 'HIRE'
                                    }).map(rm => {
                                        return <Option value={rm.id} key={rm.key}>{rm.name}</Option>
                                    })}
                                </FormInput>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {collection.length > 0 && <div className='card shadow-box-2 p-0 mx-0 mb-1'>
                <div className='card-body px-0 pt-1 pb-0'>
                    <div className='container-fluid'>
                        <div className="row">
                            <div className="col-sm-6 col-md-4 col-lg-4 ">
                                <div className='rounded  p-1 m-1 blue-box row'>
                                    <div className="col-sm-3 text-white p-1">
                                        <Feather.PlusSquare
                                            color='white'
                                            size={40}
                                        />
                                    </div>
                                    <div className="col-sm-9" >
                                        <h4 className='text-white'>Income: <br />{currencyFormat(totalIncome)}</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className='rounded  p-1 m-1 pink-box row'>
                                    <div className="col-sm-3 text-white p-1">
                                        <Feather.MinusSquare
                                            color='white'
                                            size={40}
                                        />
                                    </div>
                                    <div className="col-sm-9" >
                                        <h4 className='text-white'>Expense:<br /> {currencyFormat(totalExpense)}</h4>
                                    </div>
                                </div>

                            </div>
                            {/* <div className="text-center col-sm-6 col-md-4 col-lg-4">
                                <h4> Excess / Shortage: {currencyFormat(totalExcess)}/{currencyFormat(totalShortage)}</h4>
                            </div> */}
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className='rounded  p-1 m-1 green-box row'>
                                    <div className="col-sm-3 text-white p-1">
                                        <Feather.Pocket
                                            color='white'
                                            size={40}
                                        />
                                    </div>
                                    <div className="col-sm-9" >
                                        <h4 className='text-white'>Balance:<br /> {currencyFormat(totalBalance)}</h4>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="text-center col-sm-6 col-md-4 col-lg-4">
                                <h4>Balance: {currencyFormat(totalBalance)}</h4>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>}

            {collection.length > 0 ? <div className="card">
                <div className="card-body">
                    <div className='table-wrapper'>
                        <table className='table colored' id='collection-table'>
                            <thead>
                                <tr className=''>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    <th className='text-truncate align-middle'>Date</th>
                                    <th className='text-truncate align-middle'>Route</th>
                                    <th className='text-truncate align-middle text-end'>Collection</th>
                                    <th className='text-truncate align-middle text-end'>Diesel</th>
                                    <th className='text-truncate align-middle text-end'>Salary</th>
                                    {/* <th className='text-truncate align-middle text-end'>Conductor Salary</th> */}
                                    <th className='text-truncate align-middle text-end'>Clnr Sal</th>
                                    <th className='text-truncate align-middle text-end'>Alw.</th>
                                    {/* <th className='text-truncate align-middle text-end'>Conductor Allowance</th> */}
                                    <th className='text-truncate align-middle text-end'>Incent</th>
                                    <th className='text-truncate align-middle text-end'>B.Charge</th>
                                    <th className='text-truncate align-middle text-end'>S.Rent</th>
                                    <th className='text-truncate align-middle text-end'>Others</th>
                                    <th className='text-truncate align-middle text-end'>Total Exp.</th>
                                    {/* <th className='text-truncate align-middle text-end'>Shortage</th>
                                    <th className='text-truncate align-middle text-end'>Excess</th> */}
                                    <th className='text-truncate align-middle text-end'>Cash Rcvd.</th>

                                </tr>
                            </thead>
                            <tbody>
                                {collection !== undefined && collection.map((c, i) => {
                                    let diesel = '0'
                                    let driverSalary = '0'
                                    let conductorSalary = '0'
                                    let cleanerSalary = '0'
                                    let driverAllowance = '0'
                                    let conductorAllowance = '0'
                                    let incentive = '0'
                                    let bookingCharge = '0'
                                    let standRent = '0'
                                    let others = '0'


                                    if (c.collectionDetails && c.collectionDetails.length > 0) {
                                        c.collectionDetails.filter((ccd, i) => {
                                            return ccd.type === CollectionTypeList.EXPENSE
                                        }).forEach(cd => {
                                            if (cd.details === ExpenseDetails.DIESEL) {
                                                diesel = diesel === '0' ? cd.amount : (diesel + cd.amount)
                                            } else if (cd.details === ExpenseDetails.DRIVER_SALARY) {
                                                driverSalary = driverSalary === '0' ? cd.amount : (driverSalary + cd.amount)
                                            } else if (cd.details === ExpenseDetails.CONDUCTOR_SALARY) {
                                                conductorSalary = conductorSalary === '0' ? cd.amount : (conductorSalary + cd.amount)
                                            } else if (cd.details === ExpenseDetails.CLEANER_SALARY) {
                                                cleanerSalary = cleanerSalary === '0' ? cd.amount : (cleanerSalary + cd.amount)
                                            } else if (cd.details === ExpenseDetails.DRIVER_ALLOWANCE) {
                                                driverAllowance = driverAllowance === '0' ? cd.amount : (driverAllowance + cd.amount)
                                            } else if (cd.details === ExpenseDetails.CONDUCTOR_ALLOWANCE) {
                                                conductorAllowance = conductorAllowance === '0' ? cd.amount : (conductorAllowance + cd.amount)
                                            } else if (cd.details === ExpenseDetails.BOOKING_CHARGE) {
                                                bookingCharge = bookingCharge === '0' ? cd.amount : (bookingCharge + cd.amount)
                                            } else if (cd.details === ExpenseDetails.INCENTIVE) {
                                                incentive = incentive === '0' ? cd.amount : (incentive + cd.amount)
                                            } else if (cd.details === ExpenseDetails.STAND_RENT) {
                                                standRent = standRent === '0' ? cd.amount : (standRent + cd.amount)
                                            } else if (cd.details === ExpenseDetails.OTHERS) {
                                                others = others === '0' ? cd.amount : (others + cd.amount)
                                            }
                                        })

                                        // const index = drivers.findIndex(d => d.id === (cd ? cd[0].driverId : undefined))
                                        // if (index > -1) {
                                        //     driverName = drivers[index].name
                                        // }
                                    }
                                    return <CollectionBody
                                        sno={i + 1}
                                        id={c.id !== undefined ? c.id : 0}
                                        date={c.date}
                                        routeName={c.routeName}
                                        totalIncome={c.totalIncome !== '0' ? rupeeFormat(c.totalIncome) : ''}
                                        totalExpense={c.totalExpense !== '0' ? rupeeFormat(c.totalExpense) : ''}
                                        shortage={c.shortage !== '0' ? rupeeFormat(c.shortage) : ''}
                                        excess={c.excess !== '0' ? rupeeFormat(c.excess) : ''}
                                        balance={c.balance !== '0' ? rupeeFormat(c.balance) : ''}
                                        createdBy={c.createdBy}
                                        createTimestamp={c.createTimestamp}
                                        diesel={diesel !== '0' ? rupeeFormat(diesel) : ''}
                                        driverSalary={driverSalary !== '0' ? rupeeFormat(driverSalary) : ''}
                                        conductorSalary={conductorSalary !== '0' ? rupeeFormat(conductorSalary) : ''}
                                        salary={driverSalary !== '0' ? rupeeFormat(driverSalary + conductorSalary) : ''}
                                        cleanerSalary={cleanerSalary !== '0' ? rupeeFormat(cleanerSalary) : ''}
                                        driverAllowance={driverAllowance !== '0' ? rupeeFormat(driverAllowance) : ''}
                                        conductorAllowance={conductorAllowance !== '0' ? rupeeFormat(conductorAllowance) : ''}
                                        allowance={driverAllowance !== '0' ? rupeeFormat(driverAllowance + conductorAllowance) : ''}
                                        incentive={incentive !== '0' ? rupeeFormat(incentive) : ''}
                                        bookingCharge={bookingCharge !== '0' ? rupeeFormat(bookingCharge) : ''}
                                        standRent={standRent !== '0' ? rupeeFormat(standRent) : ''}
                                        Others={others !== '0' ? rupeeFormat(others) : ''}
                                        key={c.id}
                                    />
                                })}
                            </tbody>
                            <tfoot>
                                <tr>

                                    <td colSpan={3} style={{ textAlign: 'start' }}>
                                        <span className="text-dark fw-bold fs-5">Total</span>
                                    </td>
                                    <td style={{ textAlign: 'end' }}>
                                        <span className="text-dark fw-bold fs-5">{rupeeFormat(totalIncome)}</span>
                                    </td>
                                    <td style={{ textAlign: 'end' }}>
                                        <span className="text-dark fw-bold fs-5">{rupeeFormat(totalDiesel)}</span>
                                    </td>
                                    <td style={{ textAlign: 'end' }}>
                                        <span className="text-dark fw-bold fs-5">{rupeeFormat(totalSalary)}</span>
                                    </td>
                                    {/* <td style={{ textAlign: 'end' }}>
                                        <span className="text-dark fw-bold fs-5">{rupeeFormat(totalConductorSalary)}</span>
                                    </td> */}
                                    <td style={{ textAlign: 'end' }}>
                                        <span className="text-dark fw-bold fs-5">{rupeeFormat(totalCleanerSalary)}</span>
                                    </td>
                                    <td style={{ textAlign: 'end' }}>
                                        <span className="text-dark fw-bold fs-5">{rupeeFormat(totalAllowance)}</span>
                                    </td>
                                    {/* <td style={{ textAlign: 'end' }}>
                                        <span className="text-dark fw-bold fs-5">{rupeeFormat(totalConductorAllowance)}</span>
                                    </td> */}
                                    <td style={{ textAlign: 'end' }}>
                                        <span className="text-dark fw-bold fs-5">{rupeeFormat(totalIncentive)}</span>
                                    </td>
                                    <td style={{ textAlign: 'end' }}>
                                        <span className="text-dark fw-bold fs-5">{rupeeFormat(totalBookingCharge)}</span>
                                    </td>
                                    <td style={{ textAlign: 'end' }}>
                                        <span className="text-dark fw-bold fs-5">{rupeeFormat(totalStandRent)}</span>
                                    </td>
                                    <td style={{ textAlign: 'end' }}>
                                        <span className="text-dark fw-bold fs-5">{rupeeFormat(totalOthers)}</span>
                                    </td>
                                    <td style={{ textAlign: 'end' }}>
                                        <span className="text-dark fw-bold fs-5">{rupeeFormat(totalExpense)}</span>
                                    </td>
                                    <td style={{ textAlign: 'end' }}>
                                        <span className="text-dark fw-bold fs-5">{rupeeFormat(totalBalance)}</span>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <div className='my-2 text-end'>
                        <button className='btn btn-primary' onClick={handleDownloadPDF}>Download PDF
                            <i className='fe-download'></i>
                        </button>
                    </div>
                </div>
            </div> :
                <div className='text-center'>
                    <label >No records found</label>
                </div>}
        </VerticalLayout>
    </Fragment>
}

const CollectionBody = ({ sno, date, totalIncome, totalExpense, shortage, excess, balance, createdBy = '', createTimestamp = '', id, diesel, driverSalary, conductorSalary, cleanerSalary, driverAllowance, conductorAllowance, incentive, bookingCharge, standRent, Others, routeName, allowance, salary }: TableBody) => {

    return <tr>
        <td>{sno}</td>
        <td className='text-truncate'>{date}</td>
        <td>{routeName}</td>
        <td className='text-truncate text-end pe-2' >{totalIncome}</td>
        <td className='text-truncate text-end pe-2' >{diesel}</td>
        {/* <td className='text-truncate text-end pe-2' >{(driverSalary!==undefined &&driverSalary}</td> */}
        <td className='text-truncate text-end pe-2' >{salary}</td>
        <td className='text-truncate text-end pe-2' >{cleanerSalary}</td>
        <td className='text-truncate text-end pe-2' >{allowance}</td>
        {/* <td className='text-truncate text-end pe-2' >{(conductorAllowance!==undefined &&conductorAllowance}</td> */}
        <td className='text-truncate text-end pe-2' >{incentive}</td>
        <td className='text-truncate text-end pe-2' >{bookingCharge}</td>
        <td className='text-truncate text-end pe-2' >{standRent}</td>
        <td className='text-truncate text-end pe-2' >{Others}</td>
        <td className='text-truncate text-end pe-2' >{totalExpense}</td>

        <td className='text-truncate text-end pe-2' >{balance}</td>

    </tr>

}

export default CollectionReport
