/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../reducers"

export enum AccountsMasterActionList {
    ADD_ACCOUNTS_MASTER = 'ADD_ACCOUNTS_MASTER',
    FETCH_ACCOUNTS_MASTER = 'FETCH_ACCOUNTS_MASTER',
    UPDATE_ACCOUNTS_MASTER = 'UPDATE_ACCOUNTS_MASTER',
    DELETE_ACCOUNTS_MASTER = 'DELETE_ACCOUNTS_MASTER',
}

export interface AccountsMasterType {

    id?: number
    // code: string
    name: string

    bank?: string
    branch?: string
    address?: string
    ifscCode?: string

    contactNo?: string
    oldBalance?: string
    oldBalanceDate?: string
    // createdBy?: string
    createTimestamp?: string
}

export interface AddAccountsMasterAction {
    type: AccountsMasterActionList.ADD_ACCOUNTS_MASTER
    data: AccountsMasterType
}

export interface FetchAccountsMasterAction {
    type: AccountsMasterActionList.FETCH_ACCOUNTS_MASTER
    data: Array<AccountsMasterType>
}

export interface UpdateAccountsMasterAction {
    type: AccountsMasterActionList.UPDATE_ACCOUNTS_MASTER
    data: AccountsMasterType
}

export interface DeleteAccountsMasterAction {
    type: AccountsMasterActionList.DELETE_ACCOUNTS_MASTER
    data: number
}

export type AccountsMasterActions = AddAccountsMasterAction | FetchAccountsMasterAction | UpdateAccountsMasterAction | DeleteAccountsMasterAction

export const addAccountsMaster = (data: AccountsMasterType) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("AccountsMaster Added")
        return api.post<APIResponse<AccountsMasterType>>('accounts_master/', data, config).then(response => {
            if (response.status === 200 && response.data.data) {

                dispatch<AddAccountsMasterAction>({
                    type: AccountsMasterActionList.ADD_ACCOUNTS_MASTER,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Accounts Master Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const updateAccountsMaster = (data: AccountsMasterType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("AccountsMaster Updated")
        return api.put<APIResponse<AccountsMasterType>>(`accounts_master/?id=${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<UpdateAccountsMasterAction>({
                    type: AccountsMasterActionList.UPDATE_ACCOUNTS_MASTER,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Accounts Master Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchAccountsMaster = () => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<AccountsMasterType[]>>('accounts_master/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchAccountsMasterAction>({
                    type: AccountsMasterActionList.FETCH_ACCOUNTS_MASTER,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteAccountsMaster = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("AccountsMaster Deleted")
        return api.delete<APIResponse<AccountsMasterType>>(`accounts_master/?id=${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteAccountsMasterAction>({
                    type: AccountsMasterActionList.DELETE_ACCOUNTS_MASTER,
                    data: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Accounts Master Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}

