import { ChangeEventHandler, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import FormInput from '../components/custom/components/FormInput'
import { addKey } from "../config/functions"
import { addVehicleMaster, updateVehicleMaster, VehicleCategory, VehicleCategoryList, VehicleCode, VehicleCodeList, VehicleMasterType, VehicleType, VehicleTypeList } from "../redux/actions/vehicleMasterActions"
import { AppDispatch } from "../redux/store"
import Option from "./Option"
import { ToastTypes, useToast } from "./toast/ToastProvider"
import { FormType, FormTypeList } from "./types"

interface Props {
    formType: FormType
    editData?: VehicleMasterType
    onSave: () => void

}

const VEHICLE_CODE = addKey([

    {
        text: "AMT",
        value: "AMT"
    },
    {
        text: "SUP",
        value: "SUP",
    }
]
)

const VEHICLE_TYPES = addKey([
    {
        text: 'Mofussil',
        value: 'Mofussil'
    },
    {
        text: 'Spare',
        value: 'Spare'
    },
    {
        text: 'Town',
        value: 'Town'
    }
])

const CATEGORY = addKey([
    {
        text: 'CAR',
        value: 'Car'
    },
    {
        text: 'BUS',
        value: 'Bus'
    },
    {
        text: 'VAN',
        value: 'Van'
    },
    {
        text: 'LORRY',
        value: 'Lorry'
    }
])

const VehicleMasterForm = ({ formType, editData, onSave }: Props) => {
    // const rtoMaster = useSelector<StoreState, Array<RtoMasterType & Key>>(state => addKey(state.rtoMaster))

    const toast = useToast()

    const dispatch = useDispatch<AppDispatch>()

    const [type, setType] = useState<VehicleType>(VehicleTypeList.MOFUSSIL)
    const [typeError, setTypeError] = useState<string>('')
    const [code, setCode] = useState<VehicleCode>(VehicleCodeList.SUP)
    const [codeError, setCodeError] = useState<string>('')
    const [name, setName] = useState<string>('')
    const [nameError, setNameError] = useState<string>('')
    const [regNo, setRegNo] = useState<string>('')
    const [regNoError, setRegNoError] = useState<string>('')
    const [brand, setBrand] = useState<string>('')
    const [brandError, setBrandError] = useState<string>('')
    const [category, setCategory] = useState<VehicleCategory>(VehicleCategoryList.BUS)
    const [categoryError, setCategoryError] = useState<string>('')
    const [yearOfMfg, setYearOfMfg] = useState<string>('')
    const [YearOfMfgError, setYearOfMfgError] = useState<string>('')
    const [purchaseDate, setPurchaseDate] = useState<string>('')
    const [purchaseDateError, setPurchaseDateError] = useState<string>('')
    const [chassisNo, setChassisNo] = useState<string>('')
    const [chassisNoError, setChassisNoError] = useState<string>('')
    const [engineNo, setEngineNo] = useState<string>('')
    const [engineNoError, setEngineNoError] = useState<string>('')
    const [mileage, setMileage] = useState<string>('')
    const [mileageError, setMileageError] = useState<string>('')
    const [rtoId, setRtoId] = useState<string>('')
    const [rtoIdError, setRtoIdError] = useState<string>('')
    const [rtoName, setRtoName] = useState<string>('')
    const [rtoArea, setRtoArea] = useState<string>('')

    const resetStates = () => {
        setCode(VehicleCodeList.SUP)
        setCodeError('')
        setType(VehicleTypeList.MOFUSSIL)
        setTypeError('')
        setPurchaseDate('')
        setPurchaseDateError('')
        setName('')
        setNameError('')
        setRegNo('')
        setRegNoError('')
        setBrand('')
        setBrandError('')
        setCategory(VehicleCategoryList.BUS)
        setCategoryError('')
        setYearOfMfg('')
        setYearOfMfgError('')
        setChassisNo('')
        setChassisNoError('')
        setEngineNo('')
        setEngineNoError('')
        setMileage('')
        setMileageError('')
        setRtoId('')
        setRtoIdError('')
    }

    const handleType: ChangeEventHandler<HTMLInputElement> = (e) => {
        setType(e.target.value as VehicleType)
        setTypeError('')
    }

    const handleCode: ChangeEventHandler<HTMLInputElement> = (e) => {
        setCode(e.target.value as VehicleCode)
        setCodeError('')
    }

    const handleName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setName(e.target.value)
        setNameError('')
    }

    const handleRegNo: ChangeEventHandler<HTMLInputElement> = (e) => {
        setRegNo(e.target.value)
        setRegNoError('')
    }

    const handlePurchaseDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPurchaseDate(e.target.value)
        setPurchaseDateError('')
    }

    const handleBrand: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBrand(e.target.value)
        setBrandError('')
    }

    const handleCategory: ChangeEventHandler<HTMLInputElement> = (e) => {
        setCategory(e.target.value as VehicleCategory)
        setCategoryError('')
    }

    const handleYearOfMfg: ChangeEventHandler<HTMLInputElement> = (e) => {
        setYearOfMfg(e.target.value)
        setYearOfMfgError('')
    }

    const handleChassisNo: ChangeEventHandler<HTMLInputElement> = (e) => {
        setChassisNo(e.target.value)
        setChassisNoError('')
    }

    const handleEngineNo: ChangeEventHandler<HTMLInputElement> = (e) => {
        setEngineNo(e.target.value)
        setEngineNoError('')
    }

    const handleMileage: ChangeEventHandler<HTMLInputElement> = (e) => {
        setMileage(e.target.value)
        setMileageError('')
    }

    // const handleRtoId: ChangeEventHandler<HTMLInputElement> = (e) => {
    //     setRtoId(e.target.value)
    //     const index = rtoMaster.findIndex(rm => rm.id !== undefined && rm.id.toString() === e.target.value)

    //     if (index > -1) {
    //         setRtoName(rtoMaster[index].name)
    //         setRtoArea(rtoMaster[index].area)
    //     }

    //     setRtoIdError('')
    // }

    const handleClear = () => {
        resetStates()
    }



    const handleSave = () => {
        let error = false
        const data: VehicleMasterType = {
            name: name,
            type: type,
            code: code,
            brand: brand,
            category: category,
            chassisNumber: chassisNo,
            engineNumber: engineNo,
            mileage: mileage,
            registrationNumber: regNo,
            yearMFG: yearOfMfg,
            purchaseDate: purchaseDate,
            // rtoId: parseInt(rtoId),
            // rtoName: rtoName,
            // rtoArea: rtoArea
        }

        if (data.purchaseDate !== "" && data.purchaseDate !== undefined) {
            if ((data.purchaseDate).length > 10) {
                setPurchaseDateError("Purchase Date must be within 10 characters")
                error = true
            }
        }

        if ([VehicleCodeList.AMT, VehicleCodeList.SUP].indexOf(data.code as VehicleCode) === -1) {
            setCodeError("Code must be AMT/SUP only")
            error = true
        } else if ((data.code).length > 10) {
            setCodeError("Code must be within 10 characters")
            error = true
        }

        if (data.brand === "") {
            setBrandError('Brand required')
            error = true
        } else if ((data.brand).length > 30) {
            setBrandError("Brand must be within 30 characters")
            error = true
        }

        if ([VehicleCategoryList.BUS, VehicleCategoryList.CAR, VehicleCategoryList.LORRY, VehicleCategoryList.VAN].indexOf(data.category as VehicleCategory) === -1) {
            setCategoryError('Category must be Car/Lorry/Van/Bus')
            error = true
        }

        if (data.chassisNumber === "") {
            setChassisNoError('Chassis Number required')
            error = true
        } else if ((data.chassisNumber).length > 30) {
            setChassisNoError("Chassis Number must be within 30 characters")
            error = true
        }

        if (data.engineNumber === "") {
            setEngineNoError('Engine Number required')
            error = true
        } else if ((data.engineNumber).length > 30) {
            setEngineNoError("Engine Number must be within 30 characters")
            error = true
        }

        if (data.mileage === "") {
            setMileageError('Mileage required')
            error = true
        } else if (parseFloat(data.mileage) <= 0) {
            setMileageError(' Mileage must be a valid one')
            error = true
        }

        if (data.name === "") {
            setNameError('Vehicle Name required')
            error = true
        } else if ((data.name).length > 30) {
            setNameError("Vehicle Name must be within 30 characters")
            error = true
        }

        if (data.registrationNumber === "") {
            setRegNoError('Reg.No required')
            error = true
        } else if ((data.registrationNumber).length > 15) {
            setRegNoError("Registration number must be within 15 characters")
            error = true
        }

        // if (isNaN(data.rtoId)) {
        //     setRtoIdError(' RTO required')
        //     error = true
        // } else if (data.rtoId <= 0) {
        //     setRtoIdError(' RTO must be a valid one')
        //     error = true
        // }

        //  if (data.type === "") {
        //     setTypeError('Type required')
        //     error = true
        // } else
        if ([VehicleTypeList.MOFUSSIL, VehicleTypeList.SPARE, VehicleTypeList.TOWN].indexOf(data.type as VehicleType) === -1) {
            setTypeError('Vehicle type must be Mofussil/Spare/Town')
            error = true
        }

        if (data.yearMFG === "") {
            setYearOfMfgError('Year Of MFG required')
            error = true
        } else if ((data.yearMFG).length !== 4) {
            setYearOfMfgError("Manufacturing year must be 4 characters")
            error = true
        }

        if (!error) {
            if (formType === FormTypeList.ADD) {

                dispatch(addVehicleMaster(data)).then(text => {
                    toast(text)
                    onSave()
                    // dispatch(fetchAccountHolder())
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            } else if (formType === FormTypeList.UPDATE && editData !== undefined && editData.id !== undefined) {
                dispatch(updateVehicleMaster(data, editData.id)).then(text => {
                    toast(text)
                    onSave()
                    // dispatch(fetchAccountHolder())
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
        }
    }

    useEffect(() => {
        if (formType === FormTypeList.UPDATE && editData !== undefined) {

            setType(editData.type)
            setCode(editData.code)
            setName(editData.name)
            setRegNo(editData.registrationNumber)
            setBrand(editData.brand)
            setCategory(editData.category)
            setYearOfMfg(editData.yearMFG)
            setPurchaseDate(editData.purchaseDate !== undefined ? editData.purchaseDate : '')
            setChassisNo(editData.chassisNumber)
            setEngineNo(editData.engineNumber)
            setMileage(editData.mileage)
            setRtoId(editData.rtoId !== undefined ? editData.rtoId.toString() : '')
            setRtoArea(editData.rtoArea !== undefined ? editData.rtoArea : '')
            setRtoName(editData.rtoName !== undefined ? editData.rtoName : '')
        } else {
            resetStates()
        }
    }, [formType, editData])

    return <div className='container'>
        <div className='row'>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    type='select'
                    label='Type'
                    labelClassName="required"
                    value={type}
                    onChange={handleType}
                    errorText={typeError}
                    containerClass="mb-2"
                >
                    <Option value=''>Select</Option>
                    {VEHICLE_TYPES.map(vt => {
                        return <Option value={vt.value} key={vt.key}>{vt.text}</Option>
                    })}
                </FormInput>
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    type="select"
                    label='Code'
                    labelClassName="required"
                    value={code}
                    onChange={handleCode}
                    placeholder='Code'
                    errorText={codeError}
                    containerClass="mb-2"
                >
                    <Option value='' >Select</Option>
                    {VEHICLE_CODE.map(vc => {
                        return <Option value={vc.value} key={vc.key}>{vc.text}</Option>
                    })}
                </FormInput>

            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Name'
                    labelClassName="required"
                    value={name}
                    onChange={handleName}
                    placeholder='Name'
                    errorText={nameError}
                    containerClass="mb-2"
                />
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Registration No'
                    labelClassName="required"
                    value={regNo}
                    onChange={handleRegNo}
                    placeholder='Registration No'
                    errorText={regNoError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Brand'
                    labelClassName="required"
                    value={brand}
                    onChange={handleBrand}
                    placeholder='Brand'
                    errorText={brandError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    type='select'
                    label='Category'
                    labelClassName="required"
                    value={category}
                    onChange={handleCategory}
                    errorText={categoryError}
                    containerClass="mb-2"
                >
                    <Option value=''>Select</Option>
                    {CATEGORY.map(ct => {
                        return <Option value={ct.value} key={ct.key}>{ct.text}</Option>
                    })}
                </FormInput>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Year of MFG'
                    labelClassName="required"
                    value={yearOfMfg}
                    onChange={handleYearOfMfg}
                    placeholder='Year of MFG'
                    errorText={YearOfMfgError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Purchase Date'
                    labelClassName="required"
                    value={purchaseDate}
                    onChange={handlePurchaseDate}
                    placeholder='Purchase Date'
                    errorText={purchaseDateError}
                    type='date'
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Chassis No'
                    labelClassName="required"
                    value={chassisNo}
                    onChange={handleChassisNo}
                    placeholder='Chassis No'
                    errorText={chassisNoError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Engine No'
                    labelClassName="required"
                    value={engineNo}
                    onChange={handleEngineNo}
                    placeholder='Engine No'
                    errorText={engineNoError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Mileage'
                    labelClassName="required"
                    value={mileage}
                    onChange={handleMileage}
                    placeholder='Mileage'
                    errorText={mileageError}
                    containerClass="mb-2"
                />
            </div>
            {/* <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='RTO'
                    labelClassName="required"
                    value={rtoId}
                    onChange={handleRtoId}
                    placeholder='RTO'
                    errorText={rtoIdError}
                    type='select'
                    containerClass="mb-2"
                >
                    <Option value=''>Select</Option>
                    {rtoMaster.map(rm => {
                        return <Option value={rm.id} key={rm.key}>{rm.name}({rm.area})</Option>
                    })
                    }
                </FormInput>
            </div> */}
        </div>
        <div className="d-flex justify-content-end hstack gap-1">
            <button className="btn btn-primary" onClick={handleClear}>Clear</button>
            <button className="btn btn-success" onClick={handleSave}>Save</button>
        </div>

    </div>
}

export default VehicleMasterForm