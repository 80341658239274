import React from 'react'
import { useParams } from 'react-router-dom'
import CollectionForm from '../components/CollectionForm'
import { FormTypeList } from '../components/types'

const EditCollection = () => {
    const { collectionId } = useParams()

    return <CollectionForm
        formType={FormTypeList.UPDATE}
        id={collectionId !== undefined ? parseInt(collectionId) : 0}
    />
}

export default EditCollection