import { nanoid } from 'nanoid'
import { OptionType } from '../components/Option'
import { Key } from "../redux/actions/types"

export const addKey = <D>(ao: Array<D>): Array<D & Key> => {
    return ao.map(o => {
        return {
            ...o,
            key: nanoid()
        }
    })
}

export const isValidEmail = (email: string) => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gm.test(String(email).toLowerCase())
}

export const currencyFormat = (currency?: number | string): string => {

    return (currency !== undefined ? parseFloat(currency.toString()) : 0).toLocaleString('en-IN', {
        // style: 'currency',
        maximumFractionDigits: 2,
        currency: 'INR'
    })
}

export const rupeeFormat = (currency?: number | string): string => {

    return (currency !== undefined ? parseFloat(currency.toString()).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : '0')
}

export const today = (): string => {
    const dateObj = new Date()
    const date = dateObj.getDate().toString().padStart(2, '0')
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0')
    const year = dateObj.getFullYear()

    return [year, month, date].join('-')
}

export const isInToday = (timestamp: string) => {
    var today = new Date();
    var inputDate = new Date(timestamp);

    if (today.setHours(0, 0, 0, 0) === inputDate.setHours(0, 0, 0, 0)) {
        return true
    } else {
        return false
    }
}
// export const yesterday = (d: string) => {
//     const dateObj = new Date(d)
//     const date = (dateObj.getDate() - 1).toString().padStart(2, '0')
//     const month = (dateObj.getMonth() + 1).toString().padStart(2, '0')
//     const year = dateObj.getFullYear()

//     return [year, month, date].join('-')
// }

export const viewFormatDate = (date: string) => {
    return new Date(date).toLocaleDateString('en-US', {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
    })//Jan 23,2023

}

export const LocalFormatDate = (date: string) => {
    const dateObj = new Date(date)
    const day = dateObj.getDate().toString().padStart(2, '0')
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0')
    const year = dateObj.getFullYear()

    return [day, month, year].join('-')//02-12-2023
}

export const YearMonthDateFormatDate = (date: string) => {
    const dateObj = new Date(date)
    const day = dateObj.getDate().toString().padStart(2, '0')
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0')
    const year = dateObj.getFullYear()

    return [year, month, day].join('-')//2023-12-02
}

export const toYMD = (date: string) => {
    return date.split('-').reverse().join('-')
}

export const enumToArrayOfObj = <L extends any>(list: L) => {
    const output: OptionType[] = []
    for (let l in list) {
        output.push({
            text: String(list[l]),
            value: String(list[l])
        })
    }

    return output
}

export const isRealNumber = (value: string | number) => {

    return !isNaN(Number(value)) && Number(value) > 0
}