import { ChangeEventHandler, MouseEventHandler, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormInput } from '../components/custom/components'
import PageTitle from '../components/custom/components/PageTitle'
import VerticalLayout from '../components/custom/layouts/Vertical'
import Modal from '../components/Modal'
import Option from '../components/Option'
import { ToastTypes, useToast } from '../components/toast/ToastProvider'
import { FormType, FormTypeList } from '../components/types'
import VehicleAssetMappingForm from '../components/VehicleAssetMappingForm'
import { addKey, isInToday } from '../config/functions'
import { AssetType } from '../redux/actions/assetActions'
import { AuthUserType } from '../redux/actions/authUserActions'
import { Key } from '../redux/actions/types'
import { deleteVehicleAssetMapping, VehicleAssetMappingType } from '../redux/actions/vehicleAssetMappingActions'
import { VehicleMasterType } from '../redux/actions/vehicleMasterActions'
import { StoreState } from '../redux/reducers'
import { AppDispatch } from '../redux/store'

interface TableBody {
    sno: number
    vehicleName: string
    assetName: string
    location: string
    onEdit: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
    timeStamp: string
    authUser: AuthUserType
}

type HandleEdit = (value: VehicleAssetMappingType) => void
type HandleDelete = (value: VehicleAssetMappingType) => void

const VehicleAssetMapping = () => {
    const toast = useToast()
    const dispatch = useDispatch<AppDispatch>()
    const authUser = useSelector<StoreState, AuthUserType>(state => state.authUser)


    const vehicleAssetMappingList = useSelector<StoreState, Array<VehicleAssetMappingType & Key>>(state => addKey(state.vehicleAssetMapping))
    const assetMaster = useSelector<StoreState, Array<AssetType & Key>>(state => addKey(state.asset))
    const vehiclemaster = useSelector<StoreState, Array<VehicleMasterType & Key>>(state => addKey(state.vehicleMaster))

    const [assetId, setAssetId] = useState<string>('')
    const [vehicleId, setVehicleId] = useState<string>('')

    const [showForm, setShowForm] = useState<boolean>(false)
    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    const [editData, setEditData] = useState<VehicleAssetMappingType>()


    const vehicleAssetMapping = useMemo<Array<VehicleAssetMappingType & Key>>(() => {
        return vehicleAssetMappingList.filter(vam => {
            return assetId !== '' ? vam.assetId === parseInt(assetId) : true
        }).filter(vam => {
            return vehicleId !== '' ? vam.vehicleId === parseInt(vehicleId) : true
        })
    }, [assetId, vehicleId, vehicleAssetMappingList])


    const handleAssetId: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAssetId(e.target.value)
    }

    const handleVehicleId: ChangeEventHandler<HTMLInputElement> = (e) => {
        setVehicleId(e.target.value)
    }

    const handleAdd = () => {
        setShowForm(true)
    }

    const handleEdit: HandleEdit = (value) => {
        setFormType(FormTypeList.UPDATE)
        setEditData(value)
        setShowForm(true)
    }

    const handleDelete: HandleDelete = (value) => {
        if (value.id !== undefined) {
            dispatch(deleteVehicleAssetMapping(value.id)).then(text => {
                toast(text)
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
        }
    }

    const handleFormClose = () => {
        setShowForm(false)
        setFormType(FormTypeList.ADD)
        setEditData(undefined)
    }


    return <>
        <Modal
            headerText={formType === FormTypeList.ADD ? 'Add Vehicle Asset Mapping' : 'Edit Vehicle Asset Mapping'}
            visible={showForm}
            onClose={handleFormClose}
            size='lg'
            centered
        >
            <VehicleAssetMappingForm
                formType={formType}
                editData={editData}
                onSave={handleFormClose}
            />
        </Modal>

        <VerticalLayout>
            <PageTitle
                title='Vehicle Asset Mapping '
                buttonText='ADD'
                onButtonClick={handleAdd}
            />
            <div className='card'>
                <div className='card-body'>
                    <div className='container-fluid'>
                        <div className="row">
                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <FormInput
                                    value={assetId}
                                    onChange={handleAssetId}
                                    type='select'
                                    label='Asset'
                                >
                                    <option value="">All</option>
                                    {assetMaster.map(rm => {
                                        return <Option value={rm.id} key={rm.key}>{rm.name}</Option>
                                    })}
                                </FormInput>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <FormInput
                                    value={vehicleId}
                                    onChange={handleVehicleId}
                                    type='select'
                                    label='Vehicle'
                                >
                                    <option value="">All</option>
                                    {vehiclemaster.map(vm => {
                                        return <Option value={vm.id} key={vm.key}>{vm.name}</Option>
                                    })}
                                </FormInput>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="card-body">
                    <div className='table-wrapper'>
                        <table className='table colored'>
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    <th className='text-truncate align-middle'>Vehicle Name</th>
                                    <th className='text-truncate align-middle'>Asset Name</th>
                                    <th className='text-truncate align-middle'>Location</th>
                                    <th className='text-truncate align-middle'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {vehicleAssetMapping.map((vam, i) => {
                                    return <VehicleAssetMappingBody
                                        sno={i + 1}
                                        vehicleName={vam.vehicleName}
                                        assetName={vam.assetName}
                                        location={vam.location}
                                        key={vam.key}
                                        authUser={authUser}
                                        timeStamp={vam.createTimestamp !== undefined ? vam.createTimestamp : ''}
                                        onEdit={() => handleEdit(vam)}
                                        onDelete={() => handleDelete(vam)}
                                    />
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </VerticalLayout>
    </>
}

const VehicleAssetMappingBody = ({ sno, assetName, vehicleName, location, onEdit, onDelete, timeStamp, authUser }: TableBody) => {
    const ShowEditForManager = isInToday(timeStamp)

    return <tr>
        <td>{sno}</td>
        <td>{vehicleName}</td>
        <td>{assetName}</td>
        <td>{location}</td>
        <td>
            <div className='hstack gap-1'>
                {(authUser.role === 'admin' || authUser.role === 'super' || authUser.role === 'Admin' || authUser.role === 'Super') && <button className='btn btn-xs btn-primary' onClick={onEdit}>Edit</button>}
                {(authUser.role === 'Manager' || authUser.role === 'manager') && ShowEditForManager && <button className='btn btn-xs btn-primary' onClick={onEdit}>Edit</button>}
                <button className='btn btn-xs btn-danger' onClick={onDelete}>Delete</button>
            </div>
        </td>
    </tr>
}

export default VehicleAssetMapping
