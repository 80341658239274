// monthly report - with individual bank loop
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { ChangeEventHandler, Fragment, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { FormInput } from '../components/custom/components'
import PageTitle from '../components/custom/components/PageTitle'
import VerticalLayout from '../components/custom/layouts/Vertical'
import { useToast } from '../components/toast/ToastProvider'
import api, { APIResponse, getAxiosRequestConfig } from '../config/api'
import { addKey, LocalFormatDate, rupeeFormat, today } from '../config/functions'
import { AccountHolderType } from '../redux/actions/accountHolderActions'
import { AuthUserType } from '../redux/actions/authUserActions'
import { TransactionType } from '../redux/actions/transactionActions'
import { Key } from '../redux/actions/types'
import { StoreState } from '../redux/reducers'

interface openBalance {
    openingBalance: String
}

export interface MonthlyBalanceReportType {

    accountName: string
    income: string
    routeExpense: string
    // totalServiceAmount: string
    // totalPaidAmount: string
    maintenanceExpense: string
    topay: string
}

interface TableBody {

    sno: number
    accountName: string
    income: string
    routeExpense: string
    maintenanceExpense: string
    balance: string
    toPay: string
    lastIncome: string
}

interface TableBody2 {
    sno: number
    // accountNumber: string
    // accountName: string
    // accountType: string
    date: string
    particulars?: string
    details: string
    income: string
    expense: string
    balance?: string
    openingBalance?: string
}

interface BankTableType {
    // sno: number
    accountNo?: string
    accountName?: string
    fromDate?: string
    bankData: TransactionType[]
    openingBalance: string
    totalBankIncome: string
    totalIncomeWithBankOpeningBalance: string
    totalBankExpense: string
    totalBankBalance: string
}

const MonthlyBalanceReport = () => {
    const toast = useToast()

    const authUser = useSelector<StoreState, AuthUserType>(state => state.authUser)
    const token = authUser.token!
    const config = getAxiosRequestConfig(token)

    const accountHolder = useSelector<StoreState, Array<AccountHolderType & Key>>(state => addKey(state.accountHolder))


    const [fromDate, setFromDate] = useState<string>(today())
    const [toDate, setToDate] = useState<string>(today())
    const [data, setData] = useState<MonthlyBalanceReportType[]>([])
    // const [bankData, setBankData] = useState<BankTableType[]>([])

    // const [bankNumber, setBankNumber] = useState<string>('')
    // const [bankNumberError, setBankNumberError] = useState<string>('')

    const [cashTransaction, setCashTransaction] = useState<TransactionType[]>([])
    const [cashTransaction2, setCashTransaction2] = useState<TransactionType[]>([])

    const [bankTransaction, setBankTransaction] = useState<TransactionType[]>([])

    const [cashOpeningBalance, setCashOpeningBalance] = useState<string>('')
    // const [totalBankOpeningBalance, setTotalBankOpeningBalance] = useState<string>('')

    const BankList = useMemo<Array<AccountHolderType & Key>>(() => {
        return accountHolder.filter(ah => {
            return ah.accountNumber
        }).filter(ah => {
            return ah.accountName.substring(0, 6) === 'BANK -'
        })
    }, [accountHolder])

    const BankNumberList = useMemo<Array<string>>(() => {
        return BankList.map(ah => {
            return ah.accountNumber
        })
    }, [BankList])

    // console.log(BankNumberList);

    const handleFromDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setFromDate(e.target.value)
    }

    const handleToDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setToDate(e.target.value)
    }

    const groupBy = (xs: any[], key: string) => {
        return xs.reduce((rv, x) => {
            (rv[x[key]] = rv[x[key]] || []).push(x)
            return rv
        }, {})
    }
    //cash2
    const cash2 = useMemo<TransactionType[]>(() => {
        const data = cashTransaction.filter(ah => {
            return ah.source === 'Collection'
        })
        return data
    }, [cashTransaction])

    //route
    const totalRouteIncome = useMemo<number>(() => {
        return data.reduce((previous, current) => {
            return previous + parseFloat(current.income)
        }, 0)

    }, [data])

    const totalRouteExpense = useMemo<number>(() => {
        return data.reduce((previous, current) => {
            return previous + parseFloat(current.routeExpense)
        }, 0)

    }, [data])

    const totalMaintenanceExpense = useMemo<number>(() => {
        return data.reduce((previous, current) => {
            return previous + parseFloat(current.maintenanceExpense)
        }, 0)

    }, [data])

    const totalRouteBalance = useMemo<number>(() => {
        return Number(totalRouteIncome) - Number(totalRouteExpense) - Number(totalMaintenanceExpense)

    }, [totalRouteIncome, totalRouteExpense, totalMaintenanceExpense])

    const totalToPay = useMemo<number>(() => {
        return data.reduce((previous, current) => {
            return previous + parseFloat(current.topay)
        }, 0)


    }, [data])

    // const totalToPay = useMemo<number>(() => {
    //     return data.map((d, i) => {
    //         return Number(d.totalServiceAmount) - Number(d.totalPaidAmount)
    //     }).reduce((previous, current) => {
    //         return previous + current
    //     }, 0)

    // }, [data])

    const totalLastIncome = useMemo<number>(() => {
        return Number(totalRouteBalance) - Number(totalToPay)

    }, [totalRouteBalance, totalToPay])

    //CASH
    const finalCashData = useMemo<TransactionType[]>(() => {
        const db = [...cashTransaction2,
        ...cashTransaction.filter(ah => {
            return ah.source !== 'Collection'
        })]
        return db
    }, [cashTransaction2, cashTransaction])

    const totalIncome = useMemo<number>(() => {
        return finalCashData.reduce((previous, current) => {
            return previous + parseFloat(current.debitAmount)
        }, 0)

    }, [finalCashData])

    const totalExpense = useMemo<number>(() => {
        return finalCashData.reduce((previous, current) => {
            return previous + parseFloat(current.creditAmount)
        }, 0)

    }, [finalCashData])

    const totalBalance = useMemo<number>(() => {
        return parseFloat(cashOpeningBalance) + totalIncome - totalExpense

    }, [totalIncome, totalExpense, cashOpeningBalance])

    const totalIncomeWithCashOpeningBalance = useMemo<number>(() => {
        return parseFloat(cashOpeningBalance) + totalIncome
    }, [totalIncome, cashOpeningBalance])

    // BANK
    // const totalBankIncome = useMemo<number>(() => {
    //     return bankTransaction.reduce((previous, current) => {
    //         return previous + parseFloat(current.debitAmount)
    //     }, 0)

    // }, [bankTransaction])
    // // console.log(totalBankIncome);

    // const totalBankExpense = useMemo<number>(() => {
    //     return bankTransaction.reduce((previous, current) => {
    //         return previous + parseFloat(current.creditAmount)
    //     }, 0)

    // }, [bankTransaction])
    // console.log(totalBankExpense);

    // const totalBankOpeningBalance = useMemo<number>(() => {
    //     return bankTransaction.reduce((previous, current) => {
    //         return previous + (current.openingBalance !== undefined ? parseFloat(current.openingBalance) : 0)
    //     }, 0)
    // }, [bankTransaction])
    // console.log(totalBankOpeningBalance);

    // const TotalBankBalance = useMemo<number>(() => {
    //     return totalBankOpeningBalance + totalBankIncome - totalBankExpense

    // }, [totalBankIncome, totalBankExpense, totalBankOpeningBalance])


    const TotalAllBankBalance = useMemo<number>(() => {
        const arr: number[] = []
        Object.values(groupBy(bankTransaction, 'accountNumber') as TransactionType[][]).forEach((transaction, i) => {

            let totalBankIncome = transaction.reduce((previous, current) => {
                return previous + parseFloat(current.debitAmount)
            }, 0)

            let totalBankExpense = transaction.reduce((previous, current) => {
                return previous + parseFloat(current.creditAmount)
            }, 0)

            let totalBankBalance = (transaction[0].openingBalance !== undefined ? parseFloat(transaction[0].openingBalance) : 0) + totalBankIncome - totalBankExpense

            arr.push(totalBankBalance)
            // return totalBankBalance
        })
        const totalAllBankBalance = arr.reduce((previous, current) => {
            return previous + current
        }, 0)

        return totalAllBankBalance

    }, [bankTransaction])

    //totalMonthlyClosingBalance
    const totalMonthlyClosingBalance = useMemo<number>(() => {
        return 3000 + (!isNaN(totalBalance) ? totalBalance : 0) + (!isNaN(TotalAllBankBalance) ? TotalAllBankBalance : 0)

    }, [totalBalance, TotalAllBankBalance])

    const handleDownloadPDF = () => {

        const doc = new jsPDF('p', 'mm', 'a4')

        //route
        const head1 = [['S.No', 'Account Name', 'Income', 'Route Expense', 'Maintenance Expense', 'Balance', 'To Pay', 'Last Income']]

        const foot1 = [['Total', '', rupeeFormat(totalRouteIncome), rupeeFormat(totalRouteExpense), rupeeFormat(totalMaintenanceExpense), rupeeFormat(totalRouteBalance), rupeeFormat(totalToPay), rupeeFormat(totalLastIncome)]]

        const body1 = data.map((d, i) => {
            let Balance = Number(d.income) - Number(d.routeExpense) - Number(d.maintenanceExpense)
            let toPay = Number(d.topay)
            let lastIncome = Number(Balance) - Number(toPay)
            return [
                i + 1,
                String(d.accountName),
                String(rupeeFormat(d.income)),
                String(rupeeFormat(d.routeExpense)),
                String(parseFloat(d.maintenanceExpense) !== 0 ? rupeeFormat(d.maintenanceExpense) : ''),
                String(rupeeFormat(Balance)),
                String(toPay !== 0 ? rupeeFormat(toPay) : ''),
                String(rupeeFormat(lastIncome))

            ]
        })
        //cash
        const head2 = [
            ['S.No', 'Date', 'Particulars', 'Income', 'Expense', 'Balance']
        ]

        const foot2 = [['Total', '', '', rupeeFormat(totalIncome), rupeeFormat(totalExpense), rupeeFormat(totalBalance)]]

        let balance1 = Number(cashOpeningBalance)
        const body2 = [['1', '', 'Opening Balance', '', '', rupeeFormat(cashOpeningBalance)]]

        cashTransaction.forEach((t, i) => {
            balance1 = (balance1 + Number(t.debitAmount)) - Number(t.creditAmount)

            body2.push([
                String(i + 2),
                t.date,
                String(t.details ? t.details : ''),
                String(parseFloat(t.debitAmount) !== 0 ? rupeeFormat(t.debitAmount) : ''),
                String(parseFloat(t.creditAmount) !== 0 ? rupeeFormat(t.creditAmount) : ''),
                String(rupeeFormat(balance1)),
            ])
        })


        // const head3 = [['S.No', 'Date', 'Particulars', 'Income', ' Expense', 'Balance']]

        // const foot3 = [['Total', '', '', totalIncome2, totalExpense2, totalBalance2]]

        // let balance2 = Number(bankOpeningBalance)

        // const body3 = bankTransaction.map((bt, i) => {
        //     balance2 = (balance2 + Number(bt.debitAmount)) - Number(bt.creditAmount)

        //     return [
        //         i + 1,
        //         bt.date,
        //         String(bt.particulars),
        //         String(bt.debitAmount),
        //         String(bt.creditAmount),
        //         String(balance2),
        //     ]
        // })

        //total
        const head4 = [['S.No', 'Particulars', 'Amount']]

        const foot4 = [['Total', '', rupeeFormat(totalMonthlyClosingBalance)]]

        const body4 = [
            ['1', 'Cash Bag', rupeeFormat('3000')],
            ['2', 'Cash', totalBalance ? rupeeFormat(totalBalance) : 0],
        ]
        Object.values(groupBy(bankTransaction, 'accountNumber') as TransactionType[][]).forEach((transaction, i) => {

            let totalBankIncome = transaction.reduce((previous, current) => {
                return previous + parseFloat(current.debitAmount)
            }, 0)

            let totalBankExpense = transaction.reduce((previous, current) => {
                return previous + parseFloat(current.creditAmount)
            }, 0)

            let totalBankBalance = (transaction[0].openingBalance !== undefined ? parseFloat(transaction[0].openingBalance) : 0) + totalBankIncome - totalBankExpense

            body4.push([i + 3, transaction[0].accountName, (Number(totalBankBalance) ? rupeeFormat(totalBankBalance) : 0)])

        })
        // ['3', 'Closing Bank', TotalBankBalance ? TotalBankBalance : 0],


        doc.addFont('Times-Bold', 'Times', 'bold')
        doc.setFont('Times')
        doc.setFontSize(18)
        doc.text('SUPREME  BUS  SERVICE', 73, 13)

        // let pageCount = doc.getNumberOfPages()
        // let i = 0
        // for (i = 0; i < pageCount; i++) {
        //     doc.setPage(i)
        //     let currentPage = doc.getCurrentPageInfo().pageNumber
        //     doc.setFontSize(12)
        //     doc.text(currentPage + '/' + pageCount, 10, 10)
        // }
        // let totalPageNo = doc.getNumberOfPages()

        doc.setFontSize(13)
        doc.text('Transaction Date :- ', 15, 26)
        doc.text('From Date: ' + LocalFormatDate(fromDate), 65, 26)
        doc.text('To Date: ' + LocalFormatDate(toDate), 140, 26)

        doc.setFontSize(14)
        doc.text('ROUTE', 15, 35)
        autoTable(doc, {
            head: head1,
            body: body1,
            foot: foot1,
            startY: 37,
            didDrawPage: function () {

                // // Header
                // doc.setFontSize(10);
                // doc.setTextColor(40);
                // doc.text("Report", data.settings.margin.right, 15);

                // // Footer
                var str = doc.getNumberOfPages();
                // var str = "Page " + doc.getNumberOfPages();

                doc.setFontSize(10);
                // // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                // var pageSize = doc.internal.pageSize;
                // var pageHeight = pageSize.height
                //     ? pageSize.height
                //     : pageSize.getHeight();
                doc.text(str.toString(), 185, 10);
            }
        })


        doc.setFontSize(14)
        doc.text('CASH', 15, (doc as any).lastAutoTable.finalY + 13)
        doc.setFontSize(12)
        // doc.text('Opening Balance :', 15, (doc as any).lastAutoTable.finalY + 18)
        // doc.text('Rs.' + cashOpeningBalance, 50, (doc as any).lastAutoTable.finalY + 18)
        autoTable(doc, {
            head: head2,
            body: body2,
            foot: foot2,
            startY: (doc as any).lastAutoTable.finalY + 15,
            didDrawPage: function () {

                // // Header
                var str = doc.getNumberOfPages();
                doc.setFontSize(10);
                // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                // var pageSize = doc.internal.pageSize;
                // var pageHeight = pageSize.height
                //     ? pageSize.height
                //     : pageSize.getHeight();
                doc.text(str.toString(), 185, 10);
            }
        })

        //bank
        if (bankTransaction.length > 0) {
            Object.values(groupBy(bankTransaction, 'accountNumber') as TransactionType[][]).forEach((transaction) => {

                let totalBankIncome = transaction.reduce((previous, current) => {
                    return previous + parseFloat(current.debitAmount)
                }, 0)

                let totalBankExpense = transaction.reduce((previous, current) => {
                    return previous + parseFloat(current.creditAmount)
                }, 0)

                let totalBankBalance = (transaction[0].openingBalance !== undefined ? parseFloat(transaction[0].openingBalance) : 0) + totalBankIncome - totalBankExpense

                let totalIncomeWithBankOpeningBalance = (transaction[0].openingBalance ? parseFloat(transaction[0].openingBalance) : 0) + totalBankIncome

                let balance2 = (transaction[0].openingBalance !== undefined ? parseFloat(transaction[0].openingBalance) : 0)

                doc.setFontSize(14)
                doc.text(transaction[0].accountName, 15, (doc as any).lastAutoTable.finalY + 13)
                doc.setFontSize(12)
                // doc.text('Opening Balance :', 15, (doc as any).lastAutoTable.finalY + 18)
                // doc.text('Rs.' + balance2, 50, (doc as any).lastAutoTable.finalY + 18)

                const bankBody = [['1', '', 'Opening Balance', '', '', rupeeFormat(balance2)],]
                transaction.forEach((bt, i) => {
                    balance2 = (balance2 + Number(bt.debitAmount)) - Number(bt.creditAmount)

                    bankBody.push([
                        String(i + 2),
                        bt.date,
                        String(bt.details ? bt.details : ''),
                        String(parseFloat(bt.debitAmount) !== 0 ? rupeeFormat(bt.debitAmount) : ''),
                        String(parseFloat(bt.creditAmount) !== 0 ? rupeeFormat(bt.creditAmount) : ''),
                        String(rupeeFormat(balance2)),
                    ])
                })
                autoTable(doc, {
                    head: [['S.No', 'Date', 'Particulars', 'Income', 'Expense', 'Balance']],

                    body: bankBody,

                    foot: [['Total', '', '', rupeeFormat(totalBankIncome), rupeeFormat(totalBankExpense), rupeeFormat(totalBankBalance)]],
                    startY: (doc as any).lastAutoTable.finalY + 15,
                    didDrawPage: function () {

                        // // Header
                        var str = doc.getNumberOfPages();
                        doc.setFontSize(10);
                        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                        // var pageSize = doc.internal.pageSize;
                        // var pageHeight = pageSize.height
                        //     ? pageSize.height
                        //     : pageSize.getHeight();
                        doc.text(str.toString(), 185, 10);
                    }
                })
                // ]
                // const Bankhead = [['S.No', 'Date', 'Particulars', 'Income', ' Expense', 'Balance']]

                // const Bankfoot = [['Total', '', '', totalBankIncome, totalBankExpense, totalBankBalance]]

                // const Bankbody = transaction.map((bt, i) => {
                //     balance2 = (balance2 + Number(bt.debitAmount)) - Number(bt.creditAmount)

                //     return [
                //         i + 1,
                //         bt.date,
                //         String(bt.particulars),
                //         String(bt.debitAmount),
                //         String(bt.creditAmount),
                //         String(balance2),
                //     ]
                // })
            })
        }

        doc.setFontSize(14)
        doc.text('MONTHLY CLOSING BALANCE', 15, (doc as any).lastAutoTable.finalY + 15)
        autoTable(doc, {
            head: head4,
            body: body4,
            foot: foot4,
            startY: (doc as any).lastAutoTable.finalY + 17,
            didDrawPage: function () {

                // // Header
                var str = doc.getNumberOfPages();

                doc.setFontSize(10);
                // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                // var pageSize = doc.internal.pageSize;
                // var pageHeight = pageSize.height
                //     ? pageSize.height
                //     : pageSize.getHeight();
                doc.text(str.toString(), 185, 10);
            }
        })
        // doc.text(String(totalOpeningBalance), 98, (doc as any).lastAutoTable.finalY + 10)
        // doc.text(String(totalIncome), 150, (doc as any).lastAutoTable.finalY + 10)
        // doc.text(String(totalExpense), 190, (doc as any).lastAutoTable.finalY + 10)
        // doc.text(String(totalClosingBalance), 240, (doc as any).lastAutoTable.finalY + 10)

        doc.save("Monthly Summary Report.pdf")
    }

    useEffect(() => {
        const c2: TransactionType[] = []
        Object.values(groupBy(cash2, 'details') as TransactionType[][]).forEach((transaction, i) => {
            // console.log(transaction);
            // if (transaction.length === 2) {
            var debitAmount = parseFloat(transaction[0].debitAmount) - (transaction[1]?.creditAmount !== undefined ? parseFloat(transaction[1]?.creditAmount) : 0)

            let newObject = {
                accountGroupName: transaction[0].accountGroupName,
                accountName: transaction[0].accountName,
                accountNumber: transaction[0].accountNumber,
                accountType: transaction[0].accountType,
                branch: transaction[0].branch,
                createTimestamp: transaction[0].createTimestamp,
                createdBy: transaction[0].createdBy,
                date: transaction[0].date,
                creditAmount: '0',
                debitAmount: debitAmount.toString(),
                financialYear: transaction[0].financialYear,
                id: transaction[0].id,
                number: transaction[0].number,
                orderNumber: transaction[0].orderNumber,
                particulars: transaction[0].particulars,
                details: transaction[0].details,
                paymentMode: transaction[0].paymentMode,
                refNo: transaction[0].paymentMode,
                routeId: transaction[0].routeId,
                source: transaction[0].source,
                sourceId: transaction[0].sourceId,
                transactionFor: transaction[0].transactionFor,
                voucherType: transaction[0].voucherType
            }
            c2.push(newObject)
            // } else {

            //     c2.push(...transaction)
            // }
        })
        setCashTransaction2(c2)
    }, [cash2])

    useEffect(() => {
        if (fromDate !== undefined && toDate !== undefined) {
            api.get<APIResponse<MonthlyBalanceReportType[]>>(`transaction/monthly_balance_report/?from_date=${fromDate}&to_date=${toDate}`, config).then(response => {

                if (response.status === 200 && response.data.data) {
                    const result = response.data.data
                    setData(result)

                } else {
                    // eslint-disable-next-line no-throw-literal
                    throw { response }
                }

            }).catch(error => {

            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fromDate, toDate])

    //CASH
    useEffect(() => {
        if (toDate !== undefined && fromDate !== undefined) {
            api.get<APIResponse<TransactionType[]>>(`transaction/account_transaction_for_monthly_report/?from_date=${fromDate}&to_date=${toDate}&account_number=900001`, config).then(response => {

                if (response.status === 200 && response.data.data) {

                    const result = response.data.data
                    // console.log(result);
                    setCashTransaction(result)
                }
            }).catch(error => {

            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toDate, fromDate])


    useEffect(() => {
        if (fromDate !== undefined) {
            api.get<APIResponse<openBalance>>(`transaction/opening_balance/?account_no=900001&date=${fromDate}`, config).then(response => {

                if (response.status === 200 && response.data.data) {

                    const result = response.data.data
                    // console.log(result);

                    setCashOpeningBalance((result.openingBalance).toString())
                }
            }).catch(error => {

            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fromDate])

    //BANK
    useEffect(() => {
        if (toDate !== undefined && fromDate !== undefined && BankNumberList.length > 0) {

            api.get<APIResponse<TransactionType[]>>(`transaction/account_transaction_for_monthly_report/?from_date=${fromDate}&to_date=${toDate}&account_number=${BankNumberList.join('|')}`, config).then(response => {

                if (response.status === 200 && response.data.data) {

                    const result = response.data.data
                    // console.log(result);
                    setBankTransaction(result)
                }
            }).catch(error => {

            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toDate, fromDate])

    // useEffect(() => {
    //     if (fromDate !== undefined) {

    //         api.get<APIResponse<openBalance>>(`transaction/opening_balance/?account_no=${BankNumberList.join('|')}&date=${fromDate}`, config).then(response => {

    //             if (response.status === 200 && response.data.data) {

    //                 const result = response.data.data
    //                 // console.log(result);

    //                 setTotalBankOpeningBalance((result.openingBalance).toString())
    //             }
    //         }).catch(error => {

    //         })
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [fromDate])

    let balance1 = Number(cashOpeningBalance)

    return <Fragment>

        <VerticalLayout>
            <PageTitle
                title='Monthly Summary Report'
            />

            <div className='card shadow-box-2 px-0 mx-0 mb-1 pt-1'>
                <div className='card-body ps-3 py-0'>
                    <div className='container-fluid'>
                        <div className="row">
                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <FormInput
                                    label='From Date'
                                    value={fromDate}
                                    onChange={handleFromDate}
                                    placeholder="From Date"
                                    containerClass="mb-2"
                                    type="date"
                                />
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <FormInput
                                    label='To Date'
                                    value={toDate}
                                    onChange={handleToDate}
                                    placeholder="To Date"
                                    containerClass="mb-2"
                                    type="date"
                                />
                            </div>
                            {/* <div className="col-sm-6 col-md-4 col-lg-3">
                                <FormInput
                                    label='Bank'
                                    labelClassName="required"
                                    type='select'
                                    value={bankNumber}
                                    placeholder='Select Bank'
                                    onChange={handleBankNumber}
                                    errorText={bankNumberError}
                                    containerClass="mb-2"
                                >
                                    <Option value=''>Select Bank</Option>
                                    {BankList.map(b => {
                                        return <Option value={b.accountNumber} key={b.key}>{b.accountName}({(b.accountHead)})</Option>
                                    })}
                                </FormInput>
                            </div> */}

                        </div>
                    </div>
                </div>
            </div>


            {data.length > 0 && <div className='card shadow-box-2 p-0 mx-0 mb-1'>
                <div className='card-body px-2 py-0'>
                    <h4>Route Summary</h4>
                    <div className='table-wrapper'>
                        <table className='table colored'>
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    <th className='text-truncate align-middle'>Account Name</th>
                                    <th className='text-truncate align-middle text-end'>Income</th>
                                    <th className='text-truncate align-middle text-end'>Route Expense</th>
                                    <th className='text-truncate align-middle text-end'>Maintenance Expense</th>
                                    <th className='text-truncate align-middle text-end'>Balance</th>
                                    <th className='text-truncate align-middle text-end'>To Pay</th>
                                    <th className='text-truncate align-middle text-end'>Last Income</th>

                                </tr>
                            </thead>
                            <tbody>
                                {data !== undefined && data.map((d, i) => {
                                    let Balance = Number(d.income) - Number(d.routeExpense) - Number(d.maintenanceExpense)
                                    let toPay = Number(d.topay)
                                    let lastIncome = Number(Balance) - Number(toPay)

                                    return <RouteBody
                                        sno={i + 1}
                                        accountName={d.accountName}
                                        income={rupeeFormat(d.income)}
                                        routeExpense={rupeeFormat(d.routeExpense)}
                                        maintenanceExpense={rupeeFormat(d.maintenanceExpense)}
                                        balance={rupeeFormat(Balance)}
                                        toPay={rupeeFormat(toPay)}
                                        lastIncome={rupeeFormat(lastIncome)}
                                        key={i}
                                    />
                                })}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan={2} style={{ textAlign: 'start' }}>
                                        <span className="text-dark fw-bold fs-5">Total</span>
                                    </td>
                                    <td style={{ textAlign: 'end' }}>
                                        <span className="text-dark fw-bold fs-5">{rupeeFormat(totalRouteIncome)}</span>
                                    </td>
                                    <td style={{ textAlign: 'end' }}>
                                        <span className="text-dark fw-bold fs-5 pe-2">{rupeeFormat(totalRouteExpense)}</span>
                                    </td>
                                    <td style={{ textAlign: 'end' }}>
                                        <span className="text-dark fw-bold fs-5 pe-2">{rupeeFormat(totalMaintenanceExpense)}</span>
                                    </td>
                                    <td style={{ textAlign: 'end' }}>
                                        <span className="text-dark fw-bold fs-5">{rupeeFormat(totalRouteBalance)}</span>
                                    </td>
                                    <td style={{ textAlign: 'end' }}>
                                        <span className="text-dark fw-bold fs-5">{rupeeFormat(totalToPay)}</span>
                                    </td>
                                    <td style={{ textAlign: 'end' }}>
                                        <span className="text-dark fw-bold fs-5">{rupeeFormat(totalLastIncome)}</span>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>

                </div>
            </div>}

            <div className='card shadow-box-2 p-0 mx-0 mb-1'>
                <div className='card-body px-2 py-0'>
                    <h4>Cash Book</h4>
                    <div className='table-wrapper'>
                        <table className='table colored'>
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    {/* <th className='text-truncate align-middle'>Date</th> */}
                                    <th className='text-truncate align-middle'>Particulars</th>
                                    <th className='text-truncate align-middle text-end'>Income</th>
                                    <th className='text-truncate align-middle text-end'>Expense</th>
                                    <th className='text-truncate align-middle text-end'>Balance</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    {/* <td className='text-truncate'>{LocalFormatDate(fromDate)}</td> */}
                                    <td>Opening Balance</td>
                                    {/* <td>{parseFloat(cashOpeningBalance) !== 0 ? rupeeFormat(cashOpeningBalance) : ''}</td> */}
                                    <td></td>
                                    <td></td>
                                    <td className='text-end'>{rupeeFormat(cashOpeningBalance)}</td>
                                </tr>
                                {[...cashTransaction2, ...cashTransaction.filter(ah => {
                                    return ah.source !== 'Collection'
                                })].map((t, i) => {
                                    balance1 = (balance1 + Number(t.debitAmount)) - Number(t.creditAmount)
                                    return <CashBankBody
                                        sno={i + 2}
                                        date={t.date}
                                        details={t.details ? t.details : ''}
                                        income={t.debitAmount}
                                        expense={t.creditAmount}
                                        balance={String(balance1)}
                                        key={t.id}

                                    />
                                })}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td style={{ textAlign: 'start' }}>
                                        <span className="text-dark fw-bold fs-5">Total</span>
                                    </td>
                                    <td></td>
                                    {/* <td></td> */}
                                    <td style={{ textAlign: 'end' }}>
                                        <span className="text-dark fw-bold fs-5">{rupeeFormat(totalIncome)}</span>
                                    </td>
                                    <td style={{ textAlign: 'end' }}>
                                        <span className="text-dark fw-bold fs-5">{rupeeFormat(totalExpense)}</span>
                                    </td>
                                    <td style={{ textAlign: 'end' }}>
                                        <span className="text-dark fw-bold fs-5">{rupeeFormat(totalBalance)}</span>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
                {/* </div> : <label className='text-center'>No records found</label>} */}
            </div>

            {Object.values(groupBy(bankTransaction, 'accountNumber')).map((transaction) => {
                return <BankView
                    transaction={transaction as TransactionType[]}
                    fromDate={fromDate}
                />
            })}

            <div className='card shadow-box-2  mx-0 mb-1'>
                <div className='card-body px-2 mb-2 pt-0'>
                    <h4>Monthly Closing Balance</h4>
                    <div className='table-wrapper'>
                        <table className='table colored' id='MonthlyClosingBalance'>
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    <th className='text-truncate align-middle'>Particulars</th>
                                    <th className='text-truncate align-middle text-end pe-5'>Amount</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>Cash Bag</td>
                                    <td className='text-end pe-5'>{rupeeFormat(3000)}</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Cash</td>
                                    <td className='text-end pe-5'>{rupeeFormat(totalBalance)}</td>
                                </tr>
                                {Object.values(groupBy(bankTransaction, 'accountNumber') as TransactionType[][]).map((transaction, i) => {

                                    let totalBankIncome = transaction.reduce((previous, current) => {
                                        return previous + parseFloat(current.debitAmount)
                                    }, 0)

                                    let totalBankExpense = transaction.reduce((previous, current) => {
                                        return previous + parseFloat(current.creditAmount)
                                    }, 0)

                                    let totalBankBalance = (transaction[0].openingBalance !== undefined ? parseFloat(transaction[0].openingBalance) : 0) + totalBankIncome - totalBankExpense

                                    return <tr>
                                        <td>{i + 3}</td>
                                        <td>{transaction[0].accountName}</td>
                                        <td className='text-end pe-5'>{!isNaN(Number(totalBankBalance)) ? rupeeFormat(totalBankBalance) : 0}</td>
                                    </tr>

                                })
                                }
                                {/* <tr>
                                    <td>2</td>
                                    <td>Closing Bank</td>
                                    <td>{rupeeFormat(TotalBankBalance)}</td>
                                </tr> */}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td style={{ textAlign: 'start' }}>
                                        <span className="text-dark fw-bold fs-5">Total</span>
                                    </td>
                                    <td></td>
                                    <td className='pe-5' style={{ textAlign: 'end' }}>
                                        <span className="text-dark fw-bold fs-5">{rupeeFormat(totalMonthlyClosingBalance)}</span>
                                    </td>
                                </tr>

                            </tfoot>
                        </table>
                    </div>
                    <div className='mb-2 mt-0 pt-0 text-end'>
                        <button className='btn btn-primary' onClick={handleDownloadPDF}>Download PDF
                            <i className='fe-download'></i>
                        </button>
                    </div>

                </div>
            </div>

        </VerticalLayout>
    </Fragment>
}

const RouteBody = ({ sno, accountName, income, routeExpense, maintenanceExpense, balance, toPay, lastIncome }: TableBody) => {

    return <tr>
        <td>{sno}</td>
        <td className='text-truncate'>{accountName}</td>
        <td className='text-truncate text-end' >{income}</td>
        <td className='text-truncate text-end pe-3' >{routeExpense}</td>
        <td className='text-truncate text-end pe-3' >{maintenanceExpense}</td>
        <td className='text-truncate text-end' >{balance}</td>
        <td className='text-truncate text-end' >{toPay}</td>
        <td className='text-truncate text-end' >{lastIncome}</td>

    </tr>

}

const CashBankBody = ({ sno, date, particulars, income, expense, balance, details }: TableBody2) => {

    return <tr>
        <td>{sno}</td>
        {/* <td className='text-truncate'>{date}</td> */}
        <td className='text-capitalize'>{details}</td>
        <td className='text-end'>{parseFloat(income) !== 0 ? rupeeFormat(income) : ''}</td>
        <td className='text-end'>{parseFloat(expense) !== 0 ? rupeeFormat(expense) : ''}</td>
        <td className='text-end'>{rupeeFormat(balance)}</td>

    </tr>

}

const BankTable = ({ fromDate, bankData, openingBalance, totalBankBalance, totalBankExpense, totalBankIncome, accountName, totalIncomeWithBankOpeningBalance }: BankTableType) => {

    let balance = Number(openingBalance)

    return <>
        {/* {bankData.length > 0 &&  */}
        <div className='card shadow-box-2 p-0 mx-0 mb-1'>
            <div className='card-body px-2 py-0'>
                <h4>{accountName + ' Summary'}</h4>
                <div className='table-wrapper'><table className='table colored'>
                    <thead>
                        <tr>
                            <th className='text-truncate align-middle'>S.No</th>
                            {/* <th className='text-truncate align-middle'>Date</th> */}
                            <th className='text-truncate align-middle'>Particulars</th>
                            <th className='text-truncate align-middle text-end'>Income</th>
                            <th className='text-truncate align-middle text-end'>Expense</th>
                            <th className='text-truncate align-middle text-end'>Balance</th>

                        </tr>
                    </thead>
                    <tbody>
                        {bankData.length > 0 && <tr>
                            <td>1</td>
                            {/* <td className='text-truncate'>{LocalFormatDate(fromDate ? fromDate : '')}</td> */}
                            <td>Opening Balance</td>
                            {/* <td>{parseFloat(openingBalance) !== 0 ? rupeeFormat(openingBalance) : ''}</td> */}
                            <td></td>
                            <td></td>
                            <td className='text-end'>{rupeeFormat(openingBalance)}</td>
                        </tr>}
                        {bankData.filter(bl => {
                            return bl.accountGroupName === 'accounting'
                        }).map((t, i) => {
                            balance = (balance + Number(t.debitAmount)) - Number(t.creditAmount)
                            return <CashBankBody
                                sno={i + 2}
                                date={t.date}
                                details={t.details ? t.details : ''}
                                income={t.debitAmount}
                                expense={t.creditAmount}
                                balance={String(balance)}
                                key={t.id}

                            />
                        })}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td style={{ textAlign: 'start' }}>
                                <span className="text-dark fw-bold fs-5">Total</span>
                            </td>
                            <td></td>
                            {/* <td></td> */}
                            <td style={{ textAlign: 'end' }}>
                                <span className="text-dark fw-bold fs-5">{rupeeFormat(totalBankIncome)}</span>
                            </td>
                            <td style={{ textAlign: 'end' }}>
                                <span className="text-dark fw-bold fs-5">{rupeeFormat(totalBankExpense)}</span>
                            </td>
                            <td style={{ textAlign: 'end' }}>
                                <span className="text-dark fw-bold fs-5">{rupeeFormat(totalBankBalance)}</span>
                            </td>
                        </tr>
                    </tfoot>
                </table>
                </div>

            </div>
            {/* </div> : <label className='text-center'>No records found</label>} */}
        </div>
        {/* } */}

    </>
}

interface BankViewProps {
    transaction: TransactionType[]
    fromDate: string
    // config: string
}

const BankView = ({ transaction, fromDate }: BankViewProps) => {

    // const [bankOpeningBalance, setBankOpeningBalance] = useState<string>('')
    // const authUser = useSelector<StoreState, AuthUserType>(state => state.authUser)
    // const token = authUser.token!
    // const config = getAxiosRequestConfig(token)
    // opening_balance
    if (transaction.length > 0) {

        // api.get<APIResponse<openBalance>>(`transaction/opening_balance/?account_no=${transaction[0].accountNumber}&date=${fromDate}`, config).then(response => {

        //     if (response.status === 200 && response.data.data) {

        //         const result = response.data.data
        //         setBankOpeningBalance((result.openingBalance).toString())
        //     }
        // }).catch(error => {

        // })

        let totalBankIncome = transaction.filter(bl => {
            return bl.accountGroupName === 'accounting'
        }).reduce((previous, current) => {
            return previous + parseFloat(current.debitAmount)
        }, 0)

        let totalBankExpense = transaction.filter(bl => {
            return bl.accountGroupName === 'accounting'
        }).reduce((previous, current) => {
            return previous + parseFloat(current.creditAmount)
        }, 0)

        let totalBankBalance = (transaction[0].openingBalance ? parseFloat(transaction[0].openingBalance) : 0) + totalBankIncome - totalBankExpense

        let totalIncomeWithBankOpeningBalance = (transaction[0].openingBalance ? parseFloat(transaction[0].openingBalance) : 0) + totalBankIncome

        // let obj = {
        //     accountNo: transaction[0].accountNumber,
        //     accountName: transaction[0].accountName,
        //     openingBalance: bankOpeningBalance,
        //     bankData: transaction,
        //     totalBankIncome: totalBankIncome.toString(),
        //     totalBankExpense: totalBankExpense.toString(),
        //     totalBankBalance: totalBankBalance.toString(),
        // }

        // bankData.push(obj)
        // console.log(bankData);

        return <BankTable
            bankData={transaction}
            fromDate={fromDate}
            accountName={transaction[0].accountName}
            openingBalance={transaction[0].openingBalance ? transaction[0].openingBalance : '0'}
            totalBankIncome={totalBankIncome.toString()}
            totalBankExpense={totalBankExpense.toString()}
            totalBankBalance={totalBankBalance.toString()}
            totalIncomeWithBankOpeningBalance={totalIncomeWithBankOpeningBalance.toString()}
        />
    } else {
        return null
    }
}

export default MonthlyBalanceReport
