/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
// import { default as vehicleServiceData } from '../dataSet/vehicleService.json'
import { GetState } from "../reducers"

export enum VehicleServiceActionList {
    ADD_VEHICLE_SERVICE = 'ADD_VEHICLE_SERVICE',
    FETCH_VEHICLE_SERVICE = 'FETCH_VEHICLE_SERVICE',
    UPDATE_VEHICLE_SERVICE = 'UPDATE_VEHICLE_SERVICE',
    DELETE_VEHICLE_SERVICE = 'DELETE_VEHICLE_SERVICE',
}

export enum PaymentModesForVs {
    CASH = 'Cash',
    CREDIT = 'Credit',
}
// export enum VehicleServiceStatusList {
//     PENDING = 'Pending',
//     SETTLED = 'Settled',
// }

// export type VehicleServiceStatus = VehicleServiceStatusList.SETTLED | VehicleServiceStatusList.PENDING
export type VehicleServicePaymentModes = PaymentModesForVs.CASH | PaymentModesForVs.CREDIT

export interface VehicleServiceType {
    id?: number
    date: string
    routeId: number
    routeName: string
    supplierId: number
    supplierName: string
    billNumber: string
    details?: string
    amount: string
    // status: VehicleServiceStatus
    // settledDate?: string | null
    paymentMode: VehicleServicePaymentModes
    createdBy?: string
    createTimestamp?: string
}

export interface AddVehicleServiceAction {
    type: VehicleServiceActionList.ADD_VEHICLE_SERVICE
    data: VehicleServiceType
}

export interface FetchVehicleServiceAction {
    type: VehicleServiceActionList.FETCH_VEHICLE_SERVICE
    data: Array<VehicleServiceType>
}

export interface UpdateVehicleServiceAction {
    type: VehicleServiceActionList.UPDATE_VEHICLE_SERVICE
    data: VehicleServiceType
}

export interface DeleteVehicleServiceAction {
    type: VehicleServiceActionList.DELETE_VEHICLE_SERVICE
    data: number
}

export type VehicleServiceActions = AddVehicleServiceAction | FetchVehicleServiceAction | UpdateVehicleServiceAction | DeleteVehicleServiceAction

export const addVehicleService = (data: VehicleServiceType) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Vehicle Service Added")
        return api.post<APIResponse<VehicleServiceType>>('vehicle_service/', data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<AddVehicleServiceAction>({
                    type: VehicleServiceActionList.ADD_VEHICLE_SERVICE,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Vehicle Service Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const updateVehicleService = (data: VehicleServiceType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)
        // return Promise.resolve("Vehicle Service Updated")
        return api.put<APIResponse<VehicleServiceType>>(`vehicle_service/?id=${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<UpdateVehicleServiceAction>({
                    type: VehicleServiceActionList.UPDATE_VEHICLE_SERVICE,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Vehicle Service Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchVehicleService = () => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<VehicleServiceType[]>>('vehicle_service/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchVehicleServiceAction>({
                    type: VehicleServiceActionList.FETCH_VEHICLE_SERVICE,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteVehicleService = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Vehicle Service Deleted")
        return api.delete<APIResponse<VehicleServiceType>>(`vehicle_service/?id=${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteVehicleServiceAction>({
                    type: VehicleServiceActionList.DELETE_VEHICLE_SERVICE,
                    data: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Vehicle Service Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}

