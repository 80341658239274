import { ChangeEventHandler, Fragment, MouseEventHandler, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import FormInput from '../components/custom/components/FormInput'
import PageTitle from '../components/custom/components/PageTitle'
import VerticalLayout from '../components/custom/layouts/Vertical'
import Modal from "../components/Modal"
import Option from "../components/Option"
import { ToastTypes, useToast } from "../components/toast/ToastProvider"
import TransactionPartyLedgerForm from "../components/TransactionPartyLedgerForm"
import { FormType, FormTypeList, FromList } from "../components/types"
import api, { APIResponse, getAxiosRequestConfig } from "../config/api"
import { addKey, isInToday, rupeeFormat, today } from "../config/functions"
import { AccountHolderType } from "../redux/actions/accountHolderActions"
import { AuthUserType } from "../redux/actions/authUserActions"
import { RouteMasterType } from "../redux/actions/routeMasterActions"
import { addTransaction, fetchTransaction, PaymentModes, TransactionForTypes, TransactionPaymentModes, TransactionType, TransactionTypes, TransactionVoucherTypes, updateTransaction, VoucherTypes } from "../redux/actions/transactionActions"
import { Key } from "../redux/actions/types"
import { StoreState } from "../redux/reducers"
import { AppDispatch } from "../redux/store"
import DeleteTransactionForm from "./DeleteTransactionForm"

//  id?: number
// number ?: string
// orderNumber ?: string
// financialYear: string
// branch: string
// voucherType: string
// paymentMode: string
// transactionFor: string
// accountGroupName: string
// accountNumber: string
// accountName: string
// accountType: string
// date: string
// particulars: string
// amount: string
// discountAmount: string
// createdBy ?: string
// createTimestamp ?: string

interface TableBody {
    sno: number
    accountName: string
    particulars: string
    details: string
    debitAmount: string
    creditAmount: string
    onEdit: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
    authUser: AuthUserType
    timeStamp: string
}

type HandleEdit = (value: TransactionType) => void
type HandleDelete = (value: TransactionType) => void

export interface openingBalance {
    openingBalance: string
}

export interface currentBalance {
    currentBalance: string
}
interface AllBankBalances {
    accountName: string
    currentBalance: number
}


const AddTransactionForm = () => {

    const authUser = useSelector<StoreState, AuthUserType>(state => state.authUser)
    const token = authUser.token!
    const config = getAxiosRequestConfig(token)

    const routeMaster = useSelector<StoreState, Array<RouteMasterType & Key>>(state => addKey(state.routeMaster))
    const accountHolder = useSelector<StoreState, Array<AccountHolderType & Key>>(state => addKey(state.accountHolder))
    // const transaction = useSelector<StoreState, Array<TransactionType & Key>>(state => addKey(state.transaction))

    const toast = useToast()
    const dispatch = useDispatch<AppDispatch>()

    const [transaction, setTransaction] = useState<TransactionType[]>([])
    const [allBankBalances, setAllBankBalances] = useState<AllBankBalances[]>([])

    const [financialYear, setFinancialYear] = useState<string>('')
    const [financialYearError, setFinancialYearError] = useState<string>('')
    const [branch, setBranch] = useState<string>('')
    const [branchError, setBranchError] = useState<string>('')
    const [voucherType, setVoucherType] = useState<TransactionVoucherTypes>(VoucherTypes.PAYMENT)
    const [voucherTypeError, setVoucherTypeError] = useState<string>('')
    const [paymentMode, setPaymentMode] = useState<TransactionPaymentModes>(PaymentModes.CASH)
    const [paymentModeError, setPaymentModeError] = useState<string>('')
    const [transactionFor, setTransactionFor] = useState<TransactionForTypes>(TransactionTypes.BUS)
    const [transactionForError, setTransactionForError] = useState<string>('')
    const [accountGroupName, setAccountGroupName] = useState<string>('')
    // const [accountGroupNameError, setAccountGroupNameError] = useState<string>('')
    const [accountNumber, setAccountNumber] = useState<string>('')
    const [accountNumberError, setAccountNumberError] = useState<string>('')
    const [accountName, setAccountName] = useState<string>('')
    // const [accountNameError, setAccountNameError] = useState<string>('')
    const [accountType, setAccountType] = useState<string>('')
    const [accountTypeError, setAccountTypeError] = useState<string>('')
    const [date, setDate] = useState<string>(today())
    const [dateError, setDateError] = useState<string>('')
    const [particulars, setParticulars] = useState<string>('')
    const [particularsError, setParticularsError] = useState<string>('')
    const [amount, setAmount] = useState<string>('')
    const [amountError, setAmountError] = useState<string>('')
    // const [discountAmount, setDiscountAmount] = useState<string>('0')
    // const [discountAmountError, setDiscountAmountError] = useState<string>('')
    const [accountHead, setAccountHead] = useState<string>('')
    const [accountHeadError, setAccountHeadError] = useState<string>('')
    const [refNo, setRefNo] = useState<string>('')
    const [refNoError, setRefNoError] = useState<string>('')

    const [bankNumber, setBankNumber] = useState<string>('')
    const [bankNumberError, setBankNumberError] = useState<string>('')
    const [bankName, setBankName] = useState<string>('')
    // const [bankNameError, setBankNameError] = useState<string>('')

    const [currentBalance, setCurrentBalance] = useState<string>('0')
    const [currentCashBalance, setCurrentCashBalance] = useState<string>('0')
    const [currentBankBalance, setCurrentBankBalance] = useState<string>('0')

    const [showModal, setShowModal] = useState<boolean>(false)
    const [showDeleteForm, setShowDeleteForm] = useState<boolean>(false)
    const [routeId, setRouteId] = useState<string>('')
    const [routeIdError, setRouteIdError] = useState<string>('')

    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    const [editData, setEditData] = useState<TransactionType>()

    const [oldBalancePayment, setOldBalancePayment] = useState<string>('')
    const [oldBalanceMonth, setOldBalanceMonth] = useState<string>('')
    const [checked, setChecked] = useState<boolean>(false)


    const BankList = useMemo<Array<AccountHolderType & Key>>(() => {
        return accountHolder.filter(ah => {
            return ah.accountName.substring(0, 6) === 'BANK -'
        })
    }, [paymentMode, accountHolder])

    const BankNumberList = useMemo<Array<string>>(() => {
        return BankList.map(ah => {
            return ah.accountNumber
        })
    }, [BankList])

    const AccountHeadList = useMemo<Array<string>>(() => {
        return accountHolder.map(ah => {
            return ah.accountHead
        })
    }, [accountHolder])
    // console.log(AccountHeadList);

    // const cashBankList = useMemo<Array<AccountHolderType & Key>>(() => {
    //     return accountHolder.filter(ah => {
    //         return ah.accountName.substring(0, 4) === 'Bank' || ah.accountName.substring(0, 4) === 'Cash'
    //     })
    // }, [accountHolder])

    const AllBankBalances = () => {
        if (BankNumberList !== undefined && BankList !== undefined) {
            api.get<APIResponse<AllBankBalances[]>>(`transaction/all_bank_balances/?account_no=${BankNumberList.join('|')}`, config).then(response => {

                if (response.status === 200 && response.data.data) {

                    const result = response.data.data
                    // console.log(result);
                    setAllBankBalances(result)
                }
            }).catch(error => {

            })
        }
    }

    const CurrentCashBalance = () => {
        if (paymentMode !== undefined) {
            api.get<APIResponse<currentBalance>>(`transaction/current_balance/?account_no=900001`, config).then(response => {

                if (response.status === 200 && response.data.data) {

                    const result = response.data.data
                    setCurrentCashBalance((result.currentBalance).toString())

                }
            }).catch(error => {

            })
        }
    }

    const CurrentDateTransactions = () => {
        if (date !== undefined && date !== '') {
            api.get<APIResponse<TransactionType[]>>(`transaction/add_transaction/?date=${date}`, config).then(response => {

                if (response.status === 200 && response.data.data) {

                    const result = response.data.data
                    setTransaction(result)
                }
            }).catch(error => {

            })
        }
    }

    const TotalBankBalance = useMemo<number>(() => {
        return allBankBalances.reduce((previous, current) => {
            return previous + current.currentBalance
        }, 0)
    }, [allBankBalances])

    const TotalBalance = useMemo<number>(() => {
        return parseFloat(currentCashBalance) + TotalBankBalance
    }, [TotalBankBalance, currentCashBalance])

    const resetStates = () => {
        setFormType(FormTypeList.ADD)
        // setFinancialYear('')
        setChecked(false)
        setOldBalancePayment('')
        setOldBalanceMonth('')
        setRefNo('')
        setRefNoError('')
        setAccountHead('')
        setAccountHeadError('')
        setRouteId('')
        setRouteIdError('')
        setFinancialYearError('')
        setBranch('')
        setBranchError('')
        setVoucherType(VoucherTypes.PAYMENT)
        setVoucherTypeError('')
        setPaymentMode(PaymentModes.CASH)
        setPaymentModeError('')
        setTransactionFor(TransactionTypes.BUS)
        setTransactionForError('')
        setAccountGroupName('')
        // setAccountGroupNameError('')
        setAccountNumber('')
        setAccountNumberError('')
        setAccountName('')
        // setAccountNameError('')
        setAccountType('')
        setAccountTypeError('')
        // setDate(today())
        // setDateError('')
        setParticulars('')
        setParticularsError('')
        setAmount('')
        setAmountError('')
        // setDiscountAmount('0')
        // setDiscountAmountError('')
        setBankNumber('')
        setBankNumberError('')
        setBankName('')
        // setBankNameError('')
    }

    // const handleFinancialYear: ChangeEventHandler<HTMLInputElement> = (e) => {
    //     setFinancialYear(e.target.value)
    //     setFinancialYearError('')
    // }

    // const handleBranch: ChangeEventHandler<HTMLInputElement> = (e) => {
    //     setBranch(e.target.value)
    //     setBranchError('')
    // }

    const handleOldBalancePayment: ChangeEventHandler<HTMLInputElement> = (e) => {
        if (oldBalancePayment === '') {
            setOldBalancePayment(oldBalanceMonth + ' month balance payment')
            setParticulars(oldBalanceMonth + ' month balance payment')
            setChecked(true)
        } else if (oldBalancePayment !== '') {
            setOldBalancePayment('')
            setParticulars('')
            setChecked(false)
        }
    }


    const handleRouteId: ChangeEventHandler<HTMLInputElement> = (e) => {
        setRouteId(e.target.value)
    }

    const handleRefNo: ChangeEventHandler<HTMLInputElement> = (e) => {
        setRefNo(e.target.value)
    }

    const handleAccountHead: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAccountHead(e.target.value)
    }

    const handlePayment: ChangeEventHandler<HTMLInputElement> = (e) => {
        setVoucherType(VoucherTypes.PAYMENT)
        setVoucherTypeError('')
    }

    const handleReceipt: ChangeEventHandler<HTMLInputElement> = (e) => {
        setVoucherType(VoucherTypes.RECEIPT)
        setVoucherTypeError('')
    }

    const handleCash: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPaymentMode(PaymentModes.CASH)
        setPaymentModeError('')
    }
    const handleBank: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPaymentMode(PaymentModes.BANK)
        setPaymentModeError('')
    }

    const handleBus: ChangeEventHandler<HTMLInputElement> = (e) => {
        setTransactionFor(TransactionTypes.BUS)
        setTransactionForError('')
    }
    const handleGeneral: ChangeEventHandler<HTMLInputElement> = (e) => {
        setTransactionFor(TransactionTypes.GENERAL)
        setTransactionForError('')
    }

    const handleAccountNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAccountNumber(e.target.value)
        const index = accountHolder.findIndex(ah => ah.accountNumber !== undefined && ah.accountNumber.toString() === e.target.value)

        if (index > -1) {
            setAccountGroupName(accountHolder[index].accountHead)
            setAccountName(accountHolder[index].accountName)
        }
        setAccountNumberError('')
    }

    const handleBankNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBankNumber(e.target.value)
        const index = accountHolder.findIndex(ah => ah.accountNumber !== undefined && ah.accountNumber.toString() === e.target.value)

        if (index > -1) {
            setBankName(accountHolder[index].accountName)
        }
        setBankNumberError('')
    }

    // const handleAccountType: ChangeEventHandler<HTMLInputElement> = (e) => {
    //     setAccountType(e.target.value)
    //     setAccountTypeError('')
    // }

    const handleDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDate(e.target.value)
        setDateError('')
    }


    const handleAmount: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAmount(e.target.value)
        setAmountError('')
    }

    // const handleDiscountAmount: ChangeEventHandler<HTMLInputElement> = (e) => {
    //     setDiscountAmount(e.target.value)
    //     setDiscountAmountError('')
    // }

    const handleParticulars: ChangeEventHandler<HTMLInputElement> = (e) => {
        setParticulars(e.target.value)
        setParticularsError('')
    }

    const handlePartyLedger = () => {
        if (accountNumber) {
            setShowModal(true)
        } else toast('Select Account', ToastTypes.ERROR)
    }

    const handleFormClose = () => {
        setShowModal(false)
    }

    const handleClear = () => {
        resetStates()
    }

    const handleEdit: HandleEdit = (value) => {
        setFormType(FormTypeList.UPDATE)
        setEditData(value)
        // console.log(editData);

        // if (editData !== undefined) {
        //     setBranch(editData?.branch)
        //     setVoucherType(editData?.voucherType)
        //     setPaymentMode(editData?.paymentMode)
        //     setTransactionFor(editData.transactionFor)
        //     setRouteId(editData?.routeId !== undefined ? (editData?.routeId).toString() : '')
        //     setAccountGroupName(editData?.accountGroupName)
        //     setAccountNumber(editData?.accountNumber)
        //     setAccountName(editData?.accountName)
        //     setAccountType(editData?.accountType)
        //     setDate(editData?.date)
        //     setParticulars(editData?.particulars)
        //     setAmount(parseFloat(editData?.creditAmount) !== 0 ? editData?.creditAmount : editData?.debitAmount)
        // }
    }

    // const handleDeleteYes: HandleDelete = (value) => {
    //     if (value.id !== undefined) {
    //         dispatch(deleteTransaction(value.id)).then(text => {
    //             toast(text, ToastTypes.SUCCESS)
    //             dispatch(fetchTransaction())
    //             // resetStates()
    //             CurrentDateTransactions()
    //             CurrentCashBalance()
    //             AllBankBalances()
    //             // onSave()
    //         }).catch(text => {
    //             toast(text, ToastTypes.ERROR)
    //         })
    //     }
    // }

    const handleDelete: HandleDelete = (value) => {
        setEditData(value)
        setShowDeleteForm(true)
    }

    const handleDeleteFormClose = () => {
        setShowDeleteForm(false)
        dispatch(fetchTransaction())
        // resetStates()
        CurrentDateTransactions()
        CurrentCashBalance()
        AllBankBalances()
        // onSave()
    }

    const handleSave = () => {
        // console.log(accountNumber);
        // console.log(accountName);

        let error = false
        const data: TransactionType = {
            financialYear: financialYear,
            branch: branch,
            voucherType: voucherType,
            paymentMode: paymentMode,
            refNo: refNo,
            transactionFor: transactionFor,
            routeId: (routeId ? parseInt(routeId) : 0),
            accountGroupName: accountGroupName,
            accountNumber: accountNumber,
            accountName: accountName,
            accountType: accountType,
            date: date,
            particulars: particulars,
            debitAmount: amount,
            creditAmount: amount,
            bankNumber: (bankNumber !== undefined ? bankNumber : ''),
            bankName: (bankName !== undefined ? bankName : ''),

        }

        if (data.financialYear === "") {
            setFinancialYearError('Financial Year required')
            error = true
        }
        // else if ((data.financialYear).length !== 4) {
        //     setFinancialYearError("Financial year must be 4 characters")
        //     error = true
        // }

        if (data.date === "") {
            setDateError('Date required')
            error = true
        } else if ((data.date).length > 10) {
            setDateError('Invalid Date')
            error = true
        }

        if (data.particulars === "") {
            setParticularsError('Particulars required')
            error = true
        } else if ((data.particulars).length > 100) {
            setParticularsError('Particulars must be within 100 characters')
            error = true
        }

        if (data.accountNumber === "") {
            setAccountNumberError('Account Details required')
            error = true
        } else if (parseInt(data.accountNumber) <= 0) {
            setAccountNumberError('Invalid Account Details')
            error = true
        }

        if (data.paymentMode === PaymentModes.BANK && data.bankNumber !== undefined) {
            if (data.bankNumber === "") {
                setBankNumberError('Bank Details required')
                error = true
            } else if (parseInt(data.bankNumber) <= 0) {
                setBankNumberError('Invalid Bank Details')
                error = true
            }
        }

        if (data.transactionFor === TransactionTypes.BUS && data.routeId !== undefined) {

            // if (data.routeId === 0) {
            if (isNaN(data.routeId)) {
                setRouteIdError('Route required')
                error = true
            } else if (data.routeId <= 0) {
                setRouteIdError('Invalid Route')
                error = true
            }
        }

        if (data.debitAmount === "") {
            setAmountError('Amount required')
            error = true
        } else if (parseFloat(data.debitAmount) <= 0) {
            setAmountError('Invalid Amount')
            error = true
        }

        // if (data.discountAmount === "") {
        //     setDiscountAmountError('Discount Amount required')
        //     error = true
        // } else if (parseFloat(data.discountAmount) < 0) {
        //     setDiscountAmountError('Invalid Discount Amount')
        //     error = true
        // }

        if (!error) {
            if (voucherType === VoucherTypes.PAYMENT) {
                if (paymentMode === PaymentModes.CASH ? parseFloat(currentCashBalance) > 0 : parseFloat(currentBankBalance) > 0) {
                    if (paymentMode === PaymentModes.CASH ? parseFloat(amount) < parseFloat(currentCashBalance) : parseFloat(amount) < parseFloat(currentBankBalance)) {

                        if (formType === FormTypeList.ADD) {

                            dispatch(addTransaction(data)).then(text => {
                                toast(text, ToastTypes.SUCCESS)
                                dispatch(fetchTransaction())
                                resetStates()
                                CurrentDateTransactions()
                                CurrentCashBalance()
                                AllBankBalances()
                                setChecked(false)
                                // onSave()
                            }).catch(text => {
                                toast(text, ToastTypes.ERROR)
                            })
                        } else if (formType === FormTypeList.UPDATE && editData !== undefined && editData.id !== undefined) {
                            dispatch(updateTransaction(data, editData.id)).then(text => {
                                toast(text, ToastTypes.SUCCESS)
                                dispatch(fetchTransaction())
                                resetStates()
                                CurrentDateTransactions()
                                CurrentCashBalance()
                                AllBankBalances()
                                setChecked(false)
                                // onSave()
                            }).catch(text => {
                                toast(text, ToastTypes.ERROR)
                            })
                        }

                    } else toast('Insufficient Balance to make transaction', ToastTypes.ERROR)
                } else toast('Insufficient Balance to make transaction', ToastTypes.ERROR)
            } else if (voucherType === VoucherTypes.RECEIPT) {
                if (formType === FormTypeList.ADD) {

                    dispatch(addTransaction(data)).then(text => {
                        toast(text, ToastTypes.SUCCESS)
                        dispatch(fetchTransaction())
                        resetStates()
                        CurrentDateTransactions()
                        CurrentCashBalance()
                        AllBankBalances()
                        setChecked(false)
                        // onSave()
                    }).catch(text => {
                        toast(text, ToastTypes.ERROR)
                    })
                } else if (formType === FormTypeList.UPDATE && editData !== undefined && editData.id !== undefined) {
                    dispatch(updateTransaction(data, editData.id)).then(text => {
                        toast(text, ToastTypes.SUCCESS)
                        dispatch(fetchTransaction())
                        resetStates()
                        CurrentDateTransactions()
                        CurrentCashBalance()
                        AllBankBalances()
                        setChecked(false)
                        // onSave()
                    }).catch(text => {
                        toast(text, ToastTypes.ERROR)
                    })
                }
            }
        }
    }

    useEffect(() => {
        if (formType === FormTypeList.UPDATE && editData !== undefined) {
            // console.log(editData);
            // console.log(editData.partyAccount);

            setBranch(editData?.branch)
            setVoucherType(editData?.voucherType)
            setPaymentMode(editData?.paymentMode)
            setTransactionFor(editData.transactionFor)
            setRouteId(editData?.routeId !== undefined ? (editData?.routeId).toString() : '')
            // setAccountGroupName(editData?.partyAccountHead !== undefined ? editData?.partyAccountHead : '')
            setAccountHead(editData?.partyAccountHead !== undefined ? editData?.partyAccountHead : '')
            setAccountNumber(editData?.partyAccount !== undefined ? editData?.partyAccount : '')

            const index = accountHolder.findIndex(ah => ah.accountNumber !== undefined && editData?.partyAccount !== undefined && ah.accountNumber.toString() === (editData?.partyAccount).toString())

            if (index > -1) {
                setAccountGroupName(accountHolder[index].accountHead)
                setAccountName(accountHolder[index].accountName)
                // console.log(accountGroupName);
                // console.log(accountName);
            }

            // setAccountName(editData?.accountName)
            setAccountType(editData?.accountType)
            setDate(editData?.date)
            setRefNo(editData?.refNo !== undefined ? editData?.refNo : '')
            setParticulars(editData?.particulars)
            setAmount(parseFloat(editData?.creditAmount) !== 0 ? editData?.creditAmount : editData?.debitAmount)

            if (editData?.paymentMode === PaymentModes.BANK) {
                setBankNumber(editData?.accountNumber)
                const index = accountHolder.findIndex(ah => ah.accountNumber !== undefined && ah.accountNumber.toString() === (editData?.accountNumber).toString())

                if (index > -1) {
                    setBankName(accountHolder[index].accountName)
                }
                // console.log(bankNumber);
                // console.log(bankName);
            }
        } else {
            resetStates()
        }
    }, [formType, editData])

    useEffect(() => {
        const d = new Date()
        const todayDate = d.getDate()  //27
        const currentMonth = d.getMonth() + 1  //6
        const currentYear = d.getFullYear()  //2022

        if (currentMonth >= 4 && todayDate >= 1) {
            let year = currentYear + '-' + (currentYear + 1)
            setFinancialYear(year)
        } else if (currentMonth < 4) {
            let year = (currentYear - 1) + '-' + currentYear
            setFinancialYear(year)
        }

    }, [])

    useEffect(() => {
        if (date !== '') {
            const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

            const d = new Date(date)
            const month = d.getMonth()
            const obMonth = months[month !== 0 ? month - 1 : 11];
            // console.log(obMonth);
            setOldBalanceMonth(obMonth)
            console.log(oldBalanceMonth);
        }

    }, [date, oldBalancePayment])

    useEffect(() => {
        if (date !== '' && oldBalancePayment !== '') {
            setParticulars(oldBalanceMonth + ' month balance payment')
        }

    }, [date, oldBalancePayment, oldBalanceMonth])

    useEffect(() => {
        if (accountNumber !== undefined && accountNumber !== '') {
            api.get<APIResponse<currentBalance>>(`transaction/current_balance/?account_no=${accountNumber}`, config).then(response => {

                if (response.status === 200 && response.data.data) {

                    const result = response.data.data
                    setCurrentBalance((result.currentBalance).toString())
                }
            }).catch(error => {

            })
        }

    }, [accountNumber])

    //Bank
    useEffect(() => {
        if (bankNumber !== undefined && bankNumber !== '') {
            api.get<APIResponse<currentBalance>>(`transaction/current_balance/?account_no=${bankNumber}`, config).then(response => {

                if (response.status === 200 && response.data.data) {

                    const result = response.data.data
                    setCurrentBankBalance((result.currentBalance).toString())
                }
            }).catch(error => {

            })
        }

    }, [bankNumber, paymentMode])

    //cash
    useEffect(() => {
        if (paymentMode !== undefined) {
            CurrentCashBalance()
        }
    }, [paymentMode])

    useEffect(() => {
        if (BankNumberList !== undefined) {
            AllBankBalances()
        }
    }, [])

    useEffect(() => {
        CurrentDateTransactions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date])

    // useEffect(() => {
    //     if (accountNumber !== undefined && accountNumber !== '') {
    //         api.get<APIResponse<prevoiusBalance>>(`transaction/previous_balance/?account_no=${accountNumber}`, config).then(response => {

    //             if (response.status === 200 && response.data.data) {

    //                 const result = response.data.data
    //                 setCurrentBalance((result.prevoiusBalance).toString())
    //             }
    //         }).catch(error => {

    //         })
    //     }

    // }, [accountNumber])


    return <Fragment>
        <Modal
            headerText={'Party Ledger'}
            visible={showModal}
            onClose={handleFormClose}
            centered
            size='xl'
        >
            <TransactionPartyLedgerForm
                acName={accountName}
                // currentBalance={currentBalance}
                acNumber={accountNumber}
                onSave={handleFormClose}
                formType={FromList.TRANSACTION}
            />
        </Modal>

        <Modal
            headerText={'Delete Transaction'}
            visible={showDeleteForm}
            onClose={handleDeleteFormClose}
            centered
            size='sm'
        >
            <DeleteTransactionForm
                editData={editData}
                onClose={handleDeleteFormClose}
            />
        </Modal>

        <VerticalLayout>
            <PageTitle
                title={formType === FormTypeList.ADD ? 'Add Transaction' : 'Edit Transaction'}
            />
            <div className="row pb-5 px-3">

                <div className='container col-lg-6'>
                    <div className='row pe-2'>

                        <div className="hstack me-1">
                            <div className='col-sm-6 col-md-6 col-lg-6 me-0'>
                                <div className="hstack">
                                    <label className=''>Financial Year : </label>
                                    <label className="ms-2 fs-5 badge rounded-pill bg-dark">{financialYear}</label>
                                </div>
                                <span className="text-danger">{financialYearError}</span>
                            </div>

                            <div className='col-sm-6 col-md-6 col-lg-6 me-2'>
                                <FormInput
                                    label='Date'
                                    labelClassName="required"
                                    value={date}
                                    onChange={handleDate}
                                    placeholder='Date'
                                    errorText={dateError}
                                    containerClass="mb-2"
                                    type="date"
                                />
                            </div>
                        </div>

                        {/* <div className='col-sm-6 col-md-8 col-lg-6 my-2'>
                    <FormInput
                        label='Branch'
                        labelClassName="required"
                        // type='text'
                        value={branch}
                        onChange={handleBranch}
                        errorText={branchError}
                        containerClass="mb-2"
                        type="hidden"

                    />
                    </div> */}

                        <div className="hstack">
                            <div className='col-sm-6 col-md-6 col-lg-6'>
                                <label htmlFor="paymentMode" className="required">Payment Mode</label>
                                <div className="hstack gap-1">
                                    <FormInput
                                        label='Cash'
                                        onChange={handleCash}
                                        errorText={paymentModeError}
                                        containerClass="my-2"
                                        type="radio"
                                        name="paymentMode"
                                        checked={paymentMode === PaymentModes.CASH}
                                    />

                                    <FormInput
                                        label='Bank'
                                        onChange={handleBank}
                                        errorText={paymentModeError}
                                        containerClass="my-2"
                                        type="radio"
                                        name="paymentMode"
                                        checked={paymentMode === PaymentModes.BANK}
                                    />
                                </div>
                            </div>

                            <div className='col-sm-6 col-md-6 col-lg-6'>
                                <label htmlFor="voucher" className="required">Voucher Type</label>
                                <div className="hstack gap-1">
                                    <FormInput
                                        label='Receipt'
                                        onChange={handleReceipt}
                                        errorText={voucherTypeError}
                                        containerClass="my-2"
                                        type="radio"
                                        name="voucher"
                                        checked={voucherType === VoucherTypes.RECEIPT}
                                    />

                                    <FormInput
                                        label='Payment'
                                        onChange={handlePayment}
                                        errorText={voucherTypeError}
                                        containerClass="my-2"
                                        type="radio"
                                        name="voucher"
                                        checked={voucherType === VoucherTypes.PAYMENT}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="vstack">
                            <div>
                                {paymentMode === PaymentModes.BANK && <div className='col-sm-6 col-md-6 col-lg-6'>
                                    <FormInput
                                        label='Bank'
                                        labelClassName="required"
                                        type='select'
                                        value={bankNumber}
                                        placeholder='Select Bank'
                                        onChange={handleBankNumber}
                                        errorText={bankNumberError}
                                        containerClass="mb-1"
                                    >
                                        <Option value=''>Select Bank</Option>
                                        {BankList.map(b => {
                                            return <Option value={b.accountNumber} key={b.key}>{b.accountName}({(b.accountHead)})</Option>
                                        })}
                                    </FormInput>
                                </div>}
                            </div>
                            <div>
                                {paymentMode === PaymentModes.CASH && <div className='col-sm-8 col-md-8 col-lg-8 mb-1'>
                                    <label className="fs-5 badge text-dark bg-warning">Cash Balance : {rupeeFormat(currentCashBalance)}</label>
                                </div>}
                                {paymentMode === PaymentModes.BANK && <div className='col-sm-8 col-md-8 col-lg-8 mb-1'>
                                    <label className="fs-5 badge text-dark bg-warning">Bank Balance : {rupeeFormat(currentBankBalance)}</label>
                                </div>}
                            </div>
                        </div>
                        <div className="hstack mt-1">
                            <div className='col-sm-6 col-md-6 col-lg-6 '>
                                <label htmlFor="transactionFor" className="required">Transaction For</label>
                                <div className="hstack gap-1">
                                    <FormInput
                                        label='Bus'
                                        onChange={handleBus}
                                        errorText={transactionForError}
                                        containerClass="my-2"
                                        type="radio"
                                        name="transactionFor"
                                        checked={transactionFor === TransactionTypes.BUS}
                                    />

                                    <FormInput
                                        label='General'
                                        onChange={handleGeneral}
                                        errorText={transactionForError}
                                        containerClass="my-2"
                                        type="radio"
                                        name="transactionFor"
                                        checked={transactionFor === TransactionTypes.GENERAL}
                                    />
                                </div>
                            </div>

                            {transactionFor === TransactionTypes.BUS && <div className="col-sm-6 col-md-6 col-lg-6">
                                <FormInput
                                    value={routeId}
                                    onChange={handleRouteId}
                                    labelClassName="required"
                                    errorText={routeIdError}
                                    type='select'
                                    label='Route'
                                >
                                    <option value="">Select</option>
                                    {routeMaster.filter(rm => {
                                        return rm.code !== "HIRE"
                                    }).map(rm => {
                                        return <Option value={rm.id} key={rm.key}>{rm.name}</Option>
                                    })}
                                </FormInput>
                            </div>}
                        </div>

                        <div >
                            <h4>{voucherType === VoucherTypes.RECEIPT ? 'From' : 'To'}</h4>
                        </div>

                        <div className="col-sm-6 col-md-8 col-lg-8 mb-2">
                            <FormInput
                                value={accountHead}
                                onChange={handleAccountHead}
                                labelClassName="required"
                                errorText={accountHeadError}
                                type='select'
                                label='Account Group'
                            >
                                <option value="">Select</option>
                                {/* .filter((c, index) => {
                                 return chars.indexOf(c) === index;
                                }); */}
                                {AccountHeadList.filter((a, i) => {
                                    return AccountHeadList.indexOf(a) === i;
                                }).map((ah, i) => {
                                    return <Option value={ah} key={i}>{ah}</Option>
                                })}
                            </FormInput>
                        </div>

                        <div className='col-sm-6 col-md-12 col-lg-12 my-1 hstack'>
                            <div className='col-sm-6 col-md-6 col-lg-6'>
                                <FormInput
                                    label='Account'
                                    labelClassName="required"
                                    type='select'
                                    value={accountNumber}
                                    onChange={handleAccountNumber}
                                    errorText={accountNumberError}
                                    containerClass="mb-2"
                                >
                                    <Option value=''>Select</Option>
                                    {accountHolder.filter((a, i) => {
                                        return a.accountHead === accountHead;
                                    }).filter(bl => {
                                        return bl.accountName !== 'Main Account' && bl.accountName !== 'Advance Account' && bl.accountName !== 'Purchase Account' && bl.accountName !== 'Purchase Return Account' && bl.accountName !== 'Sales Account'
                                    }).map(ah => {
                                        return <Option value={ah.accountNumber} key={ah.key}>{ah.accountName}</Option>
                                    })}
                                </FormInput>
                            </div>
                            <div className='col-sm-6 col-md-6 col-lg-6 mt-2 p-1'>
                                <button className="btn btn-danger btn-xs bg-gradient fs-5 ms-1" onClick={handlePartyLedger}>Party Ledger</button>

                            </div>

                        </div>

                        {accountNumber && <div className='col-sm-6 col-md-8 col-lg-8 mb-1'>
                            {/* <label className=''>Balance : </label> */}
                            <label className="fs-5 badge text-dark bg-warning">Balance : {rupeeFormat(currentBalance)}</label>
                        </div>}


                        {/* <div className='col-sm-6 col-md-8 col-lg-8 my-2'>
                    <FormInput
                        label='Account Type'
                        labelClassName="required"
                        // type='text'
                        value={accountType}
                        onChange={handleAccountType}
                        errorText={accountTypeError}
                        containerClass="mb-2"
                        type="hidden"

                    />
                </div> */}
                        <div className='col-sm-6 col-md-8 col-lg-8 my-2 d-flex align-middle'>
                            <input id='ob' type={"checkbox"} className='me-1'
                                value={oldBalancePayment} onChange={handleOldBalancePayment} checked={checked} />
                            <label htmlFor="ob">Old Balance Payment</label>
                            {/* <FormInput
                                label='Old Balance Payment'
                                // labelClassName="required"
                                value={oldBalancePayment}
                                onChange={handleOldBalancePayment}
                                // placeholder='oldBalancePayment'
                                errorText={oldBalancePaymentError}
                                containerClass="my-2"
                                type="radio"
                            /> */}
                        </div>
                        <div className='col-sm-6 col-md-8 col-lg-8 mb-2'>
                            <FormInput
                                label='Particulars/Narration'
                                labelClassName="required"
                                value={particulars}
                                onChange={handleParticulars}
                                placeholder='Particulars'
                                errorText={particularsError}
                                containerClass="mt-0"
                                type="textarea"
                                disabled={oldBalancePayment !== ''}
                            />
                        </div>




                        {paymentMode === PaymentModes.BANK && <div className='col-sm-6 col-md-8 col-lg-8 my-2'>
                            <FormInput
                                label='Ref.No'
                                // labelClassName="required"
                                value={refNo}
                                onChange={handleRefNo}
                                placeholder='Ref.No'
                                errorText={refNoError}
                                containerClass="mb-2"
                            />
                        </div>}

                        <div className='col-sm-6 col-md-8 col-lg-8 my-2'>
                            <FormInput
                                label='Amount'
                                labelClassName="required"
                                value={amount}
                                onChange={handleAmount}
                                placeholder='Amount'
                                errorText={amountError}
                                containerClass="mb-2"
                                type="number"
                            />
                        </div>

                        {/* <div className='col-sm-6 col-md-8 col-lg-8 my-2'>
                    <FormInput
                        label='Discount Amount'
                        labelClassName="required"
                        value={discountAmount}
                        onChange={handleDiscountAmount}
                        placeholder='Discount Amount'
                        errorText={discountAmountError}
                        containerClass="mb-2"
                        type="number"
                    />
                </div> */}

                        <div className="d-flex justify-content-end hstack gap-1 mb-2">
                            <button className="btn btn-primary" onClick={handleClear}>Clear</button>
                            <button className="btn btn-success" onClick={handleSave}>{formType === FormTypeList.ADD ? 'Add' : 'Update'}</button>
                        </div>
                    </div>
                </div>

                <div className='container col-lg-6'>
                    <div className="row">
                        <div className="card">
                            <div className="card-body">
                                <div className='table-wrapper'>
                                    <table className='table colored'>
                                        <thead>
                                            <tr>
                                                {/* <th className='text-truncate align-middle'>S.No</th> */}
                                                <th className='text-truncate align-middle'>Account Name</th>
                                                <th className='text-truncate align-middle text-end'>Current Balance</th>
                                                {/* <th className='text-truncate align-middle'>Debit</th>
                                                <th className='text-truncate align-middle'>Credit</th> */}
                                                {/* <th className='text-truncate align-middle'>Action</th> */}

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* .filter(t => {
                                                return t.date === today()
                                            }) 
                                            .filter(bl => {
                                                // return bl.accountGroupName !== 'accounting'
                                            })*/}
                                            <tr>
                                                <td className='text-truncate align-middle'>Cash Account</td>
                                                <td className='text-truncate text-end align-middle'>{rupeeFormat(currentCashBalance)}</td>
                                            </tr>
                                            {allBankBalances.map((abb, i) => {
                                                return <tr>
                                                    <td className='text-truncate align-middle'>{abb.accountName}</td>
                                                    <td className='text-truncate text-end align-middle'>{rupeeFormat(abb.currentBalance)}</td>
                                                </tr>
                                            })}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td className='text-dark fw-bold fs-5'>Total Balance</td>
                                                <td className='text-dark fw-bold fs-5 text-end'>{rupeeFormat(TotalBalance)}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="card">
                            <div className="card-body">
                                <div className='table-wrapper'>
                                    <table className='table colored'>
                                        <thead>
                                            <tr>
                                                <th className='text-truncate align-middle'>S.No</th>
                                                <th className='text-truncate align-middle'>Account Name</th>
                                                <th className='text-truncate align-middle'>Particulars</th>
                                                <th className='text-truncate align-middle'>Debit</th>
                                                <th className='text-truncate align-middle'>Credit</th>
                                                <th className='text-truncate align-middle'>Action</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* .filter(t => {
                                                return t.date === today()
                                            }) 
                                            .filter(bl => {
                                                // return bl.accountGroupName !== 'accounting'
                                            })*/}

                                            {[
                                                ...transaction.filter((a, i, arr) => {

                                                    return arr.filter((b) => {
                                                        return a.number === b.number
                                                    }).length === 2
                                                }).filter((c) => {
                                                    return c.orderNumber === 2
                                                }),
                                                ...transaction.filter((a, i, arr) => {

                                                    return arr.filter((b) => {
                                                        return a.number === b.number
                                                    }).length !== 2
                                                })
                                            ].map((tr, i) => {
                                                return <TransactionAddBody
                                                    sno={i + 1}
                                                    accountName={tr.accountName}
                                                    particulars={tr.particulars}
                                                    details={tr.details !== undefined ? tr.details : ''}
                                                    debitAmount={tr.debitAmount.toString()}
                                                    creditAmount={tr.creditAmount.toString()}
                                                    key={tr.id}
                                                    authUser={authUser}
                                                    timeStamp={tr.createTimestamp !== undefined ? tr.createTimestamp : ''}
                                                    onEdit={() => handleEdit(tr)}
                                                    onDelete={() => handleDelete(tr)}

                                                />
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            {/* </div> */}
        </VerticalLayout >
    </Fragment>
}

const TransactionAddBody = ({ sno, accountName, creditAmount, debitAmount, particulars, details, onEdit, onDelete, authUser, timeStamp }: TableBody) => {
    const ShowEditForManager = isInToday(timeStamp)

    return <tr>
        <td className='align-middle'>{sno}</td>
        <td className='text-capitalize align-middle'>{accountName}</td>
        <td className='text-capitalize align-middle'>{details}</td>
        <td className='text-capitalize align-middle'>{debitAmount}</td>
        <td className='text-capitalize align-middle'>{creditAmount}</td>
        <td className="align-middle">
            <div className='vstack gap-1'>
                {/* {(authUser.role === 'admin' || authUser.role === 'super' || authUser.role === 'Admin' || authUser.role === 'Super') && <button className='btn btn-primary' onClick={onEdit}>Edit</button>} */}

                {(authUser.role === 'admin' || authUser.role === 'super' || authUser.role === 'Admin' || authUser.role === 'Super') && <button className='btn btn-xs btn-primary' onClick={onEdit}>Edit</button>}
                {(authUser.role === 'Manager' || authUser.role === 'manager') && ShowEditForManager && <button className='btn btn-xs btn-primary' onClick={onEdit}>Edit</button>}
                <button className='btn btn-xs btn-danger' onClick={onDelete}>Delete</button>
            </div>
        </td>
    </tr>
}

export default AddTransactionForm