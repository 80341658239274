import { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { viewFormatDate } from '../config/functions'
import { CollectionType } from '../redux/actions/collectionActions'

// defaults.global.tooltips.enabled = true
// defaults.global.legend.position = 'bottom'

interface BarChartProps {
    ariyalur: CollectionType[]
    veeramangudi: CollectionType[]
    pattukottai: CollectionType[]
    papanasam: CollectionType[]
    kumbakonam: CollectionType[]
}

const getChartDataset = (collection: CollectionType[]) => {
    const data: number[] = []

    let oneDay = 1000 * 60 * 60 * 24
    let today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())

    for (let i = 10; i >= 1; i--) {
        const previousDateMs = today.getTime() - (oneDay * i)//current date ms-1day ms)
        // console.log(previousDateMs);
        const index = collection.findIndex((a) => {
            let arr = a.date.split('-').map((v) => Number(v))
            let ad = new Date(arr[0], arr[1] - 1, arr[2])
            // console.log(ad);
            return previousDateMs === ad.getTime()
        })
        if (index > -1) {
            data.push(collection[index].balance)
        } else {
            data.push(0)
        }
    }
    return data
}

export const chartLabelForXaxis = () => {
    const label: string[] = []
    let oneDay = 1000 * 60 * 60 * 24//1 day in milliseconds
    let today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())

    for (let i = 10; i >= 1; i--) {
        const previousDateMs = today.getTime() - (oneDay * i)//(currentDateInMs-1dayInMs)
        const date = new Date(previousDateMs)
        const prevDate = viewFormatDate(date.toString())

        // console.log(prevDate);

        label.push(prevDate)
    }
    return label
}

const BarChart = ({ ariyalur, veeramangudi, pattukottai, papanasam, kumbakonam }: BarChartProps) => {

    const [ariyalurBalance, setAriyalurBalance] = useState<number[]>([])
    const [veeramangudiBalance, setVeeramangudiBalance] = useState<number[]>([])
    const [pattukottaiBalance, setPattukottairBalance] = useState<number[]>([])
    const [papanasamBalance, setPapanasamBalance] = useState<number[]>([])
    const [kumbakonamBalance, setKumbakonamBalance] = useState<number[]>([])
    const [label, setLabel] = useState<string[]>([])

    // console.log(label);
    // console.log(ariyalur);
    // console.log(ariyalurBalance);

    useEffect(() => {
        setLabel(chartLabelForXaxis())
    }, [])
    useEffect(() => {
        setAriyalurBalance(getChartDataset(ariyalur))
    }, [ariyalur])
    useEffect(() => {
        setVeeramangudiBalance(getChartDataset(veeramangudi))
    }, [veeramangudi])
    useEffect(() => {
        setPapanasamBalance(getChartDataset(papanasam))
    }, [papanasam])
    useEffect(() => {
        setPattukottairBalance(getChartDataset(pattukottai))
    }, [pattukottai])
    useEffect(() => {
        setKumbakonamBalance(getChartDataset(kumbakonam))
    }, [kumbakonam])

    return (
        <div>
            <Bar
                data={{
                    labels: label,
                    datasets: [
                        {
                            label: 'Veeramangudi',
                            data: veeramangudiBalance,
                            backgroundColor: '#348BFB',
                            borderColor: 'black',
                        },
                        {
                            label: 'Pattukottai',
                            data: pattukottaiBalance,
                            backgroundColor: '#45E393',
                            borderColor: 'black',
                        },
                        {
                            label: 'Papanasam',
                            data: papanasamBalance,
                            backgroundColor: '#FCAF3B',
                            borderColor: 'black',
                        },
                        {
                            label: 'Ariyalur',
                            data: ariyalurBalance,
                            backgroundColor: '#FE4A65',
                            borderColor: 'black',
                        },
                        {
                            label: 'Kumbakonam',
                            data: kumbakonamBalance,
                            backgroundColor: '#765ACB', // #5B717B
                            borderColor: 'black',
                        },
                    ],
                }}
                height={350}
                width={200}
                options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                },
                            },
                        ],
                    },
                    // title: {
                    //     display: true,
                    //     text: 'Collection',
                    //     fontSize: 15
                    // },
                    legend: {
                        labels: {
                            fontSize: 20,
                        },
                        position: 'bottom'
                    },
                    tooltips: {

                    }
                }}
            />
        </div>
    )
}

export default BarChart
