import jsPDF from "jspdf"
import autoTable from 'jspdf-autotable'
import { ChangeEventHandler, useEffect, useMemo, useState } from "react"
import * as Feather from "react-feather"
import { useSelector } from "react-redux"
import { FormInput } from "../components/custom/components"
import PageTitle from "../components/custom/components/PageTitle"
import VerticalLayout from "../components/custom/layouts/Vertical"
import Option from "../components/Option"
import api, { APIResponse, getAxiosRequestConfig } from "../config/api"
import { addKey, currencyFormat, LocalFormatDate, rupeeFormat, today } from "../config/functions"
import { AccountHolderType } from "../redux/actions/accountHolderActions"
import { AuthUserType } from "../redux/actions/authUserActions"
import { TransactionType } from "../redux/actions/transactionActions"
import { Key } from "../redux/actions/types"
import { StoreState } from "../redux/reducers"

// interface Props {
//     formType: FromTypeForTransaction
//     // currentBalance: string
//     acNumber?: String
//     acName?: String
//     onSave?: () => void
// }

interface TableBody {
    sno: number
    // accountNumber: string
    // accountName: string
    // accountType: string
    date: string
    particulars: string
    paymentMode: string
    income: string
    expense: string
    balance?: string
    openingBalance?: string
}

const RouteLedgerReport = () => {
    const authUser = useSelector<StoreState, AuthUserType>(state => state.authUser)
    const token = authUser.token!
    const config = getAxiosRequestConfig(token)

    const accountHolder = useSelector<StoreState, Array<AccountHolderType & Key>>(state => addKey(state.accountHolder))

    const [fromDate, setFromDate] = useState<string>(today())
    const [fromDateError, setFromDateError] = useState<string>('')
    const [toDate, setToDate] = useState<string>(today())
    const [toDateError, setToDateError] = useState<string>('')
    const [accountNumber, setAccountNumber] = useState<string>('')
    const [accountNumberError, setAccountNumberError] = useState<string>('')
    const [accountName, setAccountName] = useState<string>('')

    const [data, setData] = useState<TransactionType[]>([])

    const [openingBalance, setOpeningBalance] = useState<string>('')

    const AccountHeadList = useMemo<Array<string>>(() => {
        return accountHolder.map(ah => {
            return ah.accountHead
        })
    }, [accountHolder])

    const resetStates = () => {
        setFromDate('')
        setFromDateError('')
        setToDate('')
        setToDateError('')
    }

    const handleFromDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setFromDate(e.target.value)
        setFromDateError('')
    }

    const handleToDate: ChangeEventHandler<HTMLInputElement> = (e) => {
        setToDate(e.target.value)
        setToDateError('')
    }


    const handleAccountNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
        setAccountNumber(e.target.value)
        const index = accountHolder.findIndex(ah => ah.accountNumber !== undefined && ah.accountNumber.toString() === e.target.value)

        if (index > -1) {
            // setAccountGroupName(accountHolder[index].accountHead)
            setAccountName(accountHolder[index].accountName)
        }
        setAccountNumberError('')
    }

    const handleClear = () => {
        resetStates()
    }

    const totalDebit = useMemo<number | undefined>(() => {
        if (data !== undefined) {
            return data.reduce((previous, current) => {
                return previous + parseFloat(current.debitAmount)
            }, 0)

        }
    }, [data])

    const totalCredit = useMemo<number | undefined>(() => {
        if (data !== undefined) {
            return data.reduce((previous, current) => {
                return previous + parseFloat(current.creditAmount)
            }, 0)

        }
    }, [data])


    const currentBalance = useMemo<number | undefined>(() => {
        if (totalCredit !== undefined && totalDebit !== undefined) {

            // {/*changed td col for I->E and */ }
            return totalCredit - totalDebit
        }
    }, [totalCredit, totalDebit])

    // const totalDebitWithOpeningBalance = useMemo<number>(() => {
    //     return parseFloat(openingBalance) + (totalDebit !== undefined ? totalDebit : 0)
    // }, [totalDebit, openingBalance])

    const handleSubmit = () => {
        if (data !== undefined) {
            const doc = new jsPDF('l', 'mm', 'a4')

            const head1 = [['S.No', 'Date', 'Particulars', 'Payment Mode', 'Income', 'Expense', 'Balance']]

            let balance = Number(openingBalance)
            // const body1 = [['1', '', 'Opening Balance', '', '', '', rupeeFormat(openingBalance)]]
            const body1 = data.map((d, i) => {
                // {/*changed td col for I->E and */ }
                balance = (balance + Number(d.creditAmount)) - Number(d.debitAmount)

                return [
                    String(i + 2),
                    String(d.date),
                    // {/*changed td col for I->E and */ }
                    String(d.particulars === 'Daily Collection' ? 'Collection' : (d.particulars === 'Daily Expense' ? 'Expense' : d.particulars)),
                    String(d.paymentMode),
                    // {/*changed td col for I->E and */ }
                    String(parseFloat(d.creditAmount) !== 0 ? rupeeFormat(d.creditAmount) : ''),
                    String(parseFloat(d.debitAmount) !== 0 ? rupeeFormat(d.debitAmount) : ''),
                    String(rupeeFormat(balance)),
                ]
            })

            const TotalHead = [['Total Income', 'Total Expense', 'Current Balance']]
            // {/*changed td col for I->E and */ }
            const TotalBody = [[(totalCredit ? rupeeFormat(totalCredit) : '0'), (totalDebit ? rupeeFormat(totalDebit) : '0'), (currentBalance ? rupeeFormat(currentBalance) : '0')]]
            //

            doc.setFontSize(18)
            doc.text('SUPREME BUS SERVICE', 100, 12)

            doc.setFontSize(12)
            doc.text('Transaction Date :- ', 13, 26)
            doc.text('From Date: ' + LocalFormatDate(fromDate), 65, 26)
            doc.text('To Date: ' + LocalFormatDate(toDate), 155, 26)

            doc.setFontSize(12)
            doc.text('Account Name :', 13, 34)
            doc.text(accountName !== undefined ? accountName.toString() : '', 52, 34)
            doc.text('Account Number :', 13, 42)
            doc.text(accountNumber !== undefined ? accountNumber.toString() : '', 52, 42)

            doc.setFontSize(13)
            // doc.text('Total', 15, 53)

            autoTable(doc, {
                head: TotalHead,
                body: TotalBody,
                startY: 56,
                didDrawPage: function () {

                    // // Header
                    var str = doc.getNumberOfPages();
                    doc.setFontSize(10);
                    // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                    // var pageSize = doc.internal.pageSize;
                    // var pageHeight = pageSize.height
                    //     ? pageSize.height
                    //     : pageSize.getHeight();
                    doc.text(str.toString(), 260, 10);
                }
            })

            doc.setFontSize(13)
            doc.text('Transaction Details', 15, (doc as any).lastAutoTable.finalY + 10)
            autoTable(doc, {
                head: head1,
                body: body1,
                startY: (doc as any).lastAutoTable.finalY + 13,
                didDrawPage: function () {

                    // // Header
                    var str = doc.getNumberOfPages();
                    doc.setFontSize(10);
                    // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                    // var pageSize = doc.internal.pageSize;
                    // var pageHeight = pageSize.height
                    //     ? pageSize.height
                    //     : pageSize.getHeight();
                    doc.text(str.toString(), 260, 10);
                }
            })

            // doc.setFontSize(14)
            // doc.text('Opening Balance :', 195, (doc as any).lastAutoTable.finalY + 10)
            // doc.text(openingBalance ? openingBalance?.toString() : '0', 243, (doc as any).lastAutoTable.finalY + 10)
            // doc.text('Total Debit :', 195, (doc as any).lastAutoTable.finalY + 18)
            // doc.text(totalDebit ? totalDebit?.toString() : '0', 243, (doc as any).lastAutoTable.finalY + 18)
            // doc.text('Total Credit :', 195, (doc as any).lastAutoTable.finalY + 26)
            // doc.text(totalCredit ? totalCredit?.toString() : '0', 243, (doc as any).lastAutoTable.finalY + 26)
            // doc.text('Current Balance :', 195, (doc as any).lastAutoTable.finalY + 34)
            // doc.text(currentBalance ? currentBalance.toString() : '0', 243, (doc as any).lastAutoTable.finalY + 34)

            doc.save("Route Ledger.pdf")
            // onSave()
        }
    }

    useEffect(() => {
        if (toDate !== undefined && fromDate !== undefined && accountNumber !== '') {
            api.get<APIResponse<TransactionType[]>>(`transaction/route_ledger/?from_date=${fromDate}&to_date=${toDate}&account_no=${accountNumber}`, config).then(response => {

                if (response.status === 200 && response.data.data) {

                    const result = response.data.data
                    setData(result)
                }
            }).catch(error => {

            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toDate, fromDate, accountNumber])


    // useEffect(() => {
    //     if (fromDate !== undefined && accountNumber !== '') {
    //         api.get<APIResponse<openBalance>>(`transaction/opening_balance/?account_no=${accountNumber}&date=${fromDate}`, config).then(response => {

    //             if (response.status === 200 && response.data.data) {

    //                 const result = response.data.data
    //                 // console.log(result);

    //                 setOpeningBalance((result.openingBalance).toString())
    //             }
    //         }).catch(error => {

    //         })
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [fromDate, accountNumber])

    let balance = 0

    return <VerticalLayout>
        <PageTitle
            title='Route Ledger'
        />
        <div className='container'>
            <div className="row pb-5">
                <div className='card shadow-box-2 px-0 mx-0 mb-1 pt-1'>
                    <div className='card-body ps-3 py-0'>
                        <div className='container-fluid'>
                            <div className="row">
                                <div className='col-sm-6 col-lg-4'>
                                    <FormInput
                                        label='From Date'
                                        labelClassName="required"
                                        value={fromDate}
                                        onChange={handleFromDate}
                                        type='date'
                                        errorText={fromDateError}
                                        containerClass="mb-2"

                                    />
                                </div>

                                <div className='col-sm-6 col-lg-4'>
                                    <FormInput
                                        label='To Date'
                                        labelClassName="required"
                                        value={toDate}
                                        onChange={handleToDate}
                                        type='date'
                                        errorText={toDateError}
                                        containerClass="mb-2"

                                    />
                                </div>

                                {/* {formType === FromList.REPORT && <div className="col-sm-6 col-md-6 col-lg-4 mb-2">
                <FormInput
                    value={accountHead}
                    onChange={handleAccountHead}
                    labelClassName="required"
                    errorText={accountHeadError}
                    type='select'
                    label='Account Group'
                >
                    <option value="">Select</option> */}
                                {/* .filter((c, index) => {
                                 return chars.indexOf(c) === index;
                                }); */}
                                {/* {AccountHeadList.filter((a, i) => {
                        return AccountHeadList.indexOf(a) === i;
                    }).map((ah, i) => {
                        return <Option value={ah} key={i}>{ah}</Option>
                    })}
                </FormInput>
            </div>} */}

                                <div className='col-sm-6 col-md-6 col-lg-4'>
                                    <FormInput
                                        label='Route'
                                        labelClassName="required"
                                        type='select'
                                        value={accountNumber}
                                        onChange={handleAccountNumber}
                                        errorText={accountNumberError}
                                        containerClass="mb-2"
                                    >
                                        <Option value=''>Select</Option>
                                        {accountHolder.filter((a, i) => {
                                            return a.accountHead === 'route';
                                        }).filter(bl => {
                                            return bl.accountName !== 'Main Account' && bl.accountName !== 'Advance Account' && bl.accountName !== 'Purchase Account' && bl.accountName !== 'Purchase Return Account' && bl.accountName !== 'Sales Account'
                                        }).map(ah => {
                                            return <Option value={ah.accountNumber} key={ah.key}>{ah.accountName}</Option>
                                        })}
                                    </FormInput>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {data.length > 0 && <div className="my-1">
                    {/* <h4>Transaction Details</h4> */}

                    <div className='card shadow-box-2 p-0 mx-0 mb-1'>
                        <div className='card-body px-0 pt-1 pb-0'>
                            <div className='container-fluid'>
                                <div className="row p-0">

                                    {/* <div className="col-sm-6 col-md-3 col-lg-3">
                                <div className='rounded  p-2 m-3 yellow-box row'>
                                    <div className="col-sm-4 text-white p-2">
                                        <Feather.CheckSquare
                                            color='white'
                                            size={40}
                                        />
                                    </div>
                                    <div className="col-sm-8" >
                                        <h4 className='text-white'>Opening:<br /> {currencyFormat(openingBalance)}</h4>
                                    </div>
                                </div>
                            </div> */}
                                    <div className="col-sm-6 col-md-3 col-lg-4">
                                        <div className='rounded  p-1 m-1 blue-box row'>
                                            <div className="col-sm-4 text-white p-1">
                                                <Feather.PlusSquare
                                                    color='white'
                                                    size={40}
                                                />
                                            </div>
                                            <div className="col-sm-8" >
                                                {/* <h4 className='text-white'>Income:<br /> {currencyFormat(totalDebit)}</h4> */}
                                                {/*changed td col for I->E and */}
                                                <h4 className='text-white'>Income:<br /> {currencyFormat(totalCredit)}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-4">
                                        <div className='rounded  p-1 m-1 pink-box row'>
                                            <div className="col-sm-4 text-white p-1">
                                                <Feather.MinusSquare
                                                    color='white'
                                                    size={40}
                                                />
                                            </div>
                                            <div className="col-sm-8" >
                                                {/*changed td col for I->E and */}
                                                <h4 className='text-white'>Expense:<br /> {currencyFormat(totalDebit)}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3 col-lg-4">
                                        <div className='rounded  p-1 m-1 green-box row'>
                                            <div className="col-sm-4 text-white p-1">
                                                <Feather.Pocket
                                                    color='white'
                                                    size={40}
                                                />
                                            </div>
                                            <div className="col-sm-8" >
                                                <h4 className='text-white'>Balance:<br /> {currencyFormat(currentBalance)}</h4>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='table-wrapper'>
                        <table className="table colored">
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No.</th>
                                    <th className='text-truncate align-middle'>Date</th>
                                    <th className='text-truncate align-middle'>Particulars</th>
                                    <th className='text-truncate align-middle'>Payment mode</th>
                                    {/* <th className='text-truncate align-middle'>Voucher type</th> */}
                                    <th className='text-truncate align-middle text-end'>Income</th>
                                    <th className='text-truncate align-middle text-end'>Expense</th>
                                    <th className='text-truncate align-middle text-end'>Balance</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* {data.map((d, i) => {
                        balance = (balance + Number(d.debitAmount)) - Number(d.creditAmount)
                        return <RouteLedgerRow {...d} sno={i + 1} key={d.id} />
                    })} */}
                                {/* {data.length > 0 && <tr>
                            <td>1</td>
                            <td className='text-truncate'>{LocalFormatDate(fromDate)}</td>
                            <td>Opening Balance</td>
                            <td></td>
                            {/* <td className='text-end'>{parseFloat(openingBalance) !== 0 ? rupeeFormat(openingBalance) : ''}</td> */}
                                {/* <td></td>
                            <td></td>
                            <td className='text-end'>{rupeeFormat(openingBalance)}</td>
                        </tr>} */}
                                {data.length > 0 && data.map((d, i) => {
                                    // {/*changed td col for I->E and */ }
                                    balance = (balance + Number(d.creditAmount)) - Number(d.debitAmount)
                                    // balance = (balance + Number(d.debitAmount)) - Number(d.creditAmount)
                                    return <RouteLedgerRow
                                        sno={i + 1}
                                        date={d.date}
                                        particulars={d.particulars}
                                        paymentMode={d.paymentMode}
                                        income={d.debitAmount}
                                        expense={d.creditAmount}
                                        balance={String(balance)}
                                        key={d.id}

                                    />
                                })}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan={4} style={{ textAlign: 'left' }}>
                                        <span className="text-dark fw-bold fs-5">Total</span>
                                    </td>
                                    {/*changed td col for I->E and */}
                                    <td style={{ textAlign: 'right' }}>
                                        <span className="text-dark fw-bold fs-5">{rupeeFormat(totalCredit)}</span>
                                    </td>
                                    <td style={{ textAlign: 'right' }}>
                                        <span className="text-dark fw-bold fs-5">{rupeeFormat(totalDebit)}</span>
                                    </td>
                                    <td style={{ textAlign: 'right' }}>
                                        <span className="text-dark fw-bold fs-5">{rupeeFormat(currentBalance)}</span>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>}
                <div className="d-flex justify-content-end hstack gap-1 mt-1">
                    <button className="btn btn-primary" onClick={handleClear}>Clear</button>
                    {data.length > 0 && <button className="btn btn-success" onClick={handleSubmit}> <i className='fe-download'></i>Get Report</button>}
                </div>
            </div>
        </div>
    </VerticalLayout>
}
const RouteLedgerRow = ({ sno, date, particulars, paymentMode, income, expense, balance }: TableBody) => {
    return <tr>
        <td>{sno}</td>
        <td>{date}</td>
        {/* <td>{particulars}</td> */}
        <td>{particulars === 'Daily Collection' ? 'Collection' : (particulars === 'Daily Expense' ? 'Expense' : particulars)}</td>
        <td>{paymentMode}</td>
        {/*changed td col for I->E and */}
        <td className="text-end">{parseFloat(expense) !== 0 ? rupeeFormat(expense) : ''}</td>
        <td className="text-end" >{parseFloat(income) !== 0 ? rupeeFormat(income) : ''}</td>
        <td className="text-end">{rupeeFormat(balance)}</td>
    </tr>
}

export default RouteLedgerReport