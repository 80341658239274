import { Fragment, MouseEventHandler, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AssetForm from '../components/AssetForm'
import PageTitle from '../components/custom/components/PageTitle'
import VerticalLayout from '../components/custom/layouts/Vertical'
import Modal from '../components/Modal'
import { ToastTypes, useToast } from '../components/toast/ToastProvider'
import { FormType, FormTypeList } from '../components/types'
import { addKey, isInToday } from '../config/functions'
import { AssetStatus, AssetsType, AssetType, deleteAsset } from '../redux/actions/assetActions'
import { AuthUserType } from '../redux/actions/authUserActions'
import { Key } from '../redux/actions/types'
import { fetchVehicleAssetMapping } from '../redux/actions/vehicleAssetMappingActions'
import { StoreState } from '../redux/reducers'
import { AppDispatch } from '../redux/store'

interface TableBody {

    sno: number
    type: AssetsType // to be changed after api implemented 
    brand: string
    name: string
    number: string
    status: AssetStatus // to be changed after api implemented
    retreadeCount?: number
    nextService: string
    createdBy?: string
    createTimestamp?: string
    onEdit: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
    timeStamp: string
    authUser: AuthUserType
}

type HandleEdit = (value: AssetType) => void
type HandleDelete = (value: AssetType) => void

const Asset = () => {

    const toast = useToast()

    const dispatch = useDispatch<AppDispatch>()
    const authUser = useSelector<StoreState, AuthUserType>(state => state.authUser)
    const asset = useSelector<StoreState, Array<AssetType & Key>>(state => addKey(state.asset))
    const [showForm, setShowForm] = useState<boolean>(false)
    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    const [editData, setEditData] = useState<AssetType>()

    const handleAdd = () => {
        setShowForm(true)
    }

    const handleEdit: HandleEdit = (value) => {
        setFormType(FormTypeList.UPDATE)
        setEditData(value)
        setShowForm(true)
    }

    const handleDelete: HandleDelete = (value) => {
        if (value.id !== undefined) {
            dispatch(deleteAsset(value.id)).then(text => {
                toast(text, ToastTypes.SUCCESS)
                dispatch(fetchVehicleAssetMapping())
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
        }
    }


    const handleFormClose = () => {
        setShowForm(false)
        setFormType(FormTypeList.ADD)
        setEditData(undefined)
    }

    return <Fragment>
        <Modal
            headerText={formType === FormTypeList.ADD ? 'Add Asset' : 'Edit Asset'}
            visible={showForm}
            onClose={handleFormClose}
            centered
            size='lg'
        >
            <AssetForm
                formType={formType}
                editData={editData}
                onSave={handleFormClose}
            />

        </Modal>
        <VerticalLayout>
            <PageTitle
                title='Asset'
                buttonText='ADD'
                onButtonClick={handleAdd}
            />
            <div className="card">
                <div className="card-body">
                    <div className='table-wrapper'>
                        <table className='table colored'>
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    <th className='text-truncate align-middle'>Type</th>
                                    <th className='text-truncate align-middle'>Brand</th>
                                    <th className='text-truncate align-middle'>Name</th>
                                    <th className='text-truncate align-middle'>Number Of Asset</th>
                                    <th className='text-truncate align-middle'>Status</th>
                                    <th className='text-truncate align-middle'>Retread Count</th>
                                    <th className='text-truncate align-middle'>Next service</th>
                                    <th className='text-truncate align-middle text-center'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {asset.map((a, i) => {
                                    return <AssetBody
                                        sno={i + 1}
                                        type={a.type}
                                        brand={a.brand}
                                        name={a.name}
                                        number={a.number}
                                        status={a.status}
                                        retreadeCount={a.retreadCount}
                                        nextService={a.nextService}
                                        key={a.key}
                                        authUser={authUser}
                                        timeStamp={a.createTimestamp !== undefined ? a.createTimestamp : ''}
                                        onEdit={() => handleEdit(a)}
                                        onDelete={() => handleDelete(a)}
                                    />
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </VerticalLayout>
    </Fragment>
}

const AssetBody = ({ sno, brand, number, status, retreadeCount, nextService, name, type, onEdit, onDelete , timeStamp, authUser}: TableBody) => {
    const ShowEditForManager = isInToday(timeStamp)

    return <tr>
        <td>{sno}</td>
        <td>{type}</td>
        <td>{brand}</td>
        <td>{name}</td>
        <td>{number}</td>
        <td>{status}</td>
        <td>{retreadeCount}</td>
        <td>{nextService}</td>
        <td>
            <div className='hstack gap-1'>
            {(authUser.role === 'admin' || authUser.role === 'super' || authUser.role === 'Admin' || authUser.role === 'Super') && <button className='btn btn-xs btn-primary' onClick={onEdit}>Edit</button>}
            {(authUser.role === 'Manager' || authUser.role === 'manager') && ShowEditForManager && <button className='btn btn-xs btn-primary' onClick={onEdit}>Edit</button>}
                <button className='btn btn-xs btn-danger' onClick={onDelete}>Delete</button>
            </div>
        </td>
    </tr>

}

export default Asset
