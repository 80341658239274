/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../reducers"

export enum TripMasterActionList {
    ADD_TRIP_MASTER = 'ADD_TRIP_MASTER',
    FETCH_TRIP_MASTER = 'FETCH_TRIP_MASTER',
    UPDATE_TRIP_MASTER = 'UPDATE_TRIP_MASTER',
    DELETE_TRIP_MASTER = 'DELETE_TRIP_MASTER',
}

export interface TripMasterType {
    id?: number
    routeId: number
    routeName: string
    tripName: string
    number: string
    startTime: string
    endTime: string
    createdBy?: string
    createTimestamp?: string
}

export interface AddTripMasterAction {
    type: TripMasterActionList.ADD_TRIP_MASTER
    data: TripMasterType
}

export interface FetchTripMasterAction {
    type: TripMasterActionList.FETCH_TRIP_MASTER
    data: Array<TripMasterType>
}

export interface UpdateTripMasterAction {
    type: TripMasterActionList.UPDATE_TRIP_MASTER
    data: TripMasterType
}

export interface DeleteTripMasterAction {
    type: TripMasterActionList.DELETE_TRIP_MASTER
    data: number
}

export type TripMasterActions = AddTripMasterAction | FetchTripMasterAction | UpdateTripMasterAction | DeleteTripMasterAction

export const addTripMaster = (data: TripMasterType) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Trip Master Added")
        return api.post<APIResponse<TripMasterType>>('trip_master/', data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<AddTripMasterAction>({
                    type: TripMasterActionList.ADD_TRIP_MASTER,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Trip Master Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const updateTripMaster = (data: TripMasterType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Trip Master Updated")
        return api.put<APIResponse<TripMasterType>>(`trip_master/?id=${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<UpdateTripMasterAction>({
                    type: TripMasterActionList.UPDATE_TRIP_MASTER,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Trip Master Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchTripMaster = () => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<TripMasterType[]>>('trip_master/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchTripMasterAction>({
                    type: TripMasterActionList.FETCH_TRIP_MASTER,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteTripMaster = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        dispatch<DeleteTripMasterAction>({
            type: TripMasterActionList.DELETE_TRIP_MASTER,
            data: id
        })
        // return Promise.resolve("Trip Master Deleted")
        return api.delete<APIResponse<TripMasterType>>(`trip_master/?id=${id}`, config).then(response => {
            if (response.status === 200) {

                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Trip Master Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}

