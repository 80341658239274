/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import { default as tripDetailsData } from '../dataSet/tripDetails.json'
import { GetState } from "../reducers"

export enum TripDetailsActionList {
    ADD_TRIP_DETAILS = 'ADD_TRIP_DETAILS',
    FETCH_TRIP_DETAILS = 'FETCH_TRIP_DETAILS',
    UPDATE_TRIP_DETAILS = 'UPDATE_TRIP_DETAILS',
}

export interface TripDetailsType {
    // "id": 1,
    // "collectionId": 2,
    // "tripId": 1,
    // "amount": "1000"

    id?: number
    collectionId?: number
    conductorId?: number
    tripId: number
    amount: string
    refund?: string
    remarks?: string

}

export interface AddTripDetailsAction {
    type: TripDetailsActionList.ADD_TRIP_DETAILS
    data: TripDetailsType
}

export interface FetchTripDetailsAction {
    type: TripDetailsActionList.FETCH_TRIP_DETAILS
    data: Array<TripDetailsType>
}

export interface UpdateTripDetailsAction {
    type: TripDetailsActionList.UPDATE_TRIP_DETAILS
    data: TripDetailsType
}

export type TripDetailsActions = AddTripDetailsAction | FetchTripDetailsAction | UpdateTripDetailsAction

export const addTripDetails = (data: TripDetailsType) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const { tripDetails } = getState()
        dispatch<AddTripDetailsAction>({
            type: TripDetailsActionList.ADD_TRIP_DETAILS,
            data: {
                ...data,
                id: tripDetails.length + 1
            }
        })
        return Promise.resolve("Trip details Added")
        // return api.post<APIResponse<TripDetailsType>>('TripDetails/', data).then(response => {
        //    if (response.status === 200 && response.data.data) {

        //         return Promise.resolve(response.data.message
        //             ? response.data.message
        //             : 'Successfully logged in')
        //     } else {
        //         throw { response }
        //     }
        // }).catch(error => {
        //     return Promise.reject(error.response
        //         ? error.response.data.message
        //             ? error.response.data.message
        //             : 'Unable to login'
        //         : 'Unable to login')
        // })
    }
}

export const updateTripDetails = (data: TripDetailsType, id: number) => {
    return async (dispatch: Dispatch) => {
        dispatch<UpdateTripDetailsAction>({
            type: TripDetailsActionList.UPDATE_TRIP_DETAILS,
            data: {
                ...data,
                id: id
            }
        })
        return Promise.resolve("Trip details Updated")
        // return api.post<APIResponse<TripDetailsType>>('TripDetails/', data).then(response => {
        //    if (response.status === 200 && response.data.data) {

        //         return Promise.resolve(response.data.message
        //             ? response.data.message
        //             : 'Successfully logged in')
        //     } else {
        //         throw { response }
        //     }
        // }).catch(error => {
        //     return Promise.reject(error.response
        //         ? error.response.data.message
        //             ? error.response.data.message
        //             : 'Unable to login'
        //         : 'Unable to login')
        // })
    }
}

export const fetchTripDetails = () => {
    return async (dispatch: Dispatch) => {
        dispatch<FetchTripDetailsAction>({
            type: TripDetailsActionList.FETCH_TRIP_DETAILS,
            data: tripDetailsData
        })
        // return api.post<APIResponse<TripDetailsType>>('TripDetails/', data).then(response => {
        //    if (response.status === 200 && response.data.data) {

        //         return Promise.resolve(response.data.message
        //             ? response.data.message
        //             : 'Successfully logged in')
        //     } else {
        //         throw { response }
        //     }
        // }).catch(error => {
        //     return Promise.reject(error.response
        //         ? error.response.data.message
        //             ? error.response.data.message
        //             : 'Unable to login'
        //         : 'Unable to login')
        // })
    }
}



