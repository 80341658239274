/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../reducers"

export enum CollectionActionList {
    ADD_COLLECTION = 'ADD_COLLECTION',
    FETCH_COLLECTION = 'FETCH_COLLECTION',
    UPDATE_COLLECTION = 'UPDATE_COLLECTION',
}

export interface ExpenseType {
    dieselAmount?: string
    driverAllowance?: string
    conductorAllowance?: string
    driverSalary?: string
    conductorSalary?: string
    cleanerSalary?: string
    incentive?: string
    bookingCharge?: string
    standRent?: string
    others?: string
}

export interface VehicleReadingType {
    averageMilage: number
    cleanerId: number
    cleanerName: string
    conductorId?: number
    conductor1Name?: string
    conductor2Name?: string
    date: string
    diesel: number
    driverId: number
    driverName: string
    endingKm: number
    id: number
    startingKm: number
    totalKm: number
    vehicleId: number
    source?: string
    sourceId?: number
}

export interface CollectionType {

    id?: number
    routeId: number
    routeName: string
    vehicleId: number
    vehicleName: string
    date: string
    invoiceNumber: string
    totalIncome: string
    totalExpense: string
    shortage?: string
    excess?: string
    balance: number
    // machineId?: string
    // machineName?: string
    // startingNumber?: string
    // endingNumber?: string
    createdBy?: string
    createTimestamp?: string
    vehicleReading?: VehicleReadingType
    // mileage?: number
}

export interface AddCollectionAction {
    type: CollectionActionList.ADD_COLLECTION
    data: CollectionType
}

export interface FetchCollectionAction {
    type: CollectionActionList.FETCH_COLLECTION
    data: Array<CollectionType>
}

export interface UpdateCollectionAction {
    type: CollectionActionList.UPDATE_COLLECTION
    data: CollectionType
}

export type CollectionActions = AddCollectionAction | FetchCollectionAction | UpdateCollectionAction

export const addCollection = (data: CollectionType) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Collection  Added")
        return api.post<APIResponse<CollectionType>>('collection/', data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<AddCollectionAction>({
                    type: CollectionActionList.ADD_COLLECTION,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Collection  Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const updateCollection = (data: CollectionType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Collection  Updated")
        return api.put<APIResponse<CollectionType>>(`Collection/?id=${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<UpdateCollectionAction>({
                    type: CollectionActionList.UPDATE_COLLECTION,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Collection  Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}



export const fetchCollection = () => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<CollectionType[]>>('collection/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchCollectionAction>({
                    type: CollectionActionList.FETCH_COLLECTION,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}



