import { Action } from "../actions/types"
import { UserType, UserActionList } from "../actions/userActions"

const initialValue: Array<UserType> = []

const userReducer = (state: Array<UserType> = initialValue, action: Action) => {
    switch (action.type) {

        case UserActionList.ADD_USER:
            return [
                ...state,
                action.data,
            ]

        case UserActionList.UPDATE_USER:
            const updated = [...state].slice()
            const index = updated.findIndex(u => u.userId === action.data.userId)

            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }

            return updated

        case UserActionList.FETCH_USER:
            return action.data

        case UserActionList.DELETE_USER:
            return state.filter(s => s.userId !== action.data)

        default:
            return state
    }
}

export default userReducer