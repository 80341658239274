/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../reducers"

export enum MachineMasterActionList {
    ADD_MACHINE_MASTER = 'ADD_MACHINE_MASTER',
    FETCH_MACHINE_MASTER = 'FETCH_MACHINE_MASTER',
    UPDATE_MACHINE_MASTER = 'UPDATE_MACHINE_MASTER',
    DELETE_MACHINE_MASTER = 'DELETE_MACHINE_MASTER',
}

export interface MachineMasterType {
    id?: number
    code: string
    name: string
    createdBy?: string
    createTimestamp?: string
}

export interface AddMachineMasterAction {
    type: MachineMasterActionList.ADD_MACHINE_MASTER
    data: MachineMasterType
}

export interface FetchMachineMasterAction {
    type: MachineMasterActionList.FETCH_MACHINE_MASTER
    data: Array<MachineMasterType>
}

export interface UpdateMachineMasterAction {
    type: MachineMasterActionList.UPDATE_MACHINE_MASTER
    data: MachineMasterType
}

export interface DeleteMachineMasterAction {
    type: MachineMasterActionList.DELETE_MACHINE_MASTER
    data: number
}

export type MachineMasterActions = AddMachineMasterAction | FetchMachineMasterAction | UpdateMachineMasterAction | DeleteMachineMasterAction

export const addMachineMaster = (data: MachineMasterType) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Machine Master Added")
        return api.post<APIResponse<MachineMasterType>>('machine_master/', data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<AddMachineMasterAction>({
                    type: MachineMasterActionList.ADD_MACHINE_MASTER,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Machine Master Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const updateMachineMaster = (data: MachineMasterType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Machine Master Updated")
        return api.put<APIResponse<MachineMasterType>>(`machine_master/?id=${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<UpdateMachineMasterAction>({
                    type: MachineMasterActionList.UPDATE_MACHINE_MASTER,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Machine Master Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchMachineMaster = () => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<MachineMasterType[]>>('machine_master/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchMachineMasterAction>({
                    type: MachineMasterActionList.FETCH_MACHINE_MASTER,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteMachineMaster = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Machine Master Deleted")
        return api.delete<APIResponse<MachineMasterType>>(`machine_master/?id=${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteMachineMasterAction>({
                    type: MachineMasterActionList.DELETE_MACHINE_MASTER,
                    data: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Machine Master Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}

