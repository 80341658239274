import React from 'react'
import PageTitle from '../components/custom/components/PageTitle'
import VerticalLayout from '../components/custom/layouts/Vertical'
import TransactionPartyLedgerForm from '../components/TransactionPartyLedgerForm'
import { FromList } from '../components/types'

const PartyLedgerReport = () => {

    return <VerticalLayout>
        <PageTitle
            title='Party Ledger'
        />
        <TransactionPartyLedgerForm
            formType={FromList.REPORT}
        />
    </VerticalLayout>

}

export default PartyLedgerReport