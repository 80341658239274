import React, { ChangeEventHandler, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { addKey } from "../config/functions"
import { addMachineMaster, MachineMasterType, updateMachineMaster } from "../redux/actions/machineMasterActions"
import { Key } from "../redux/actions/types"
import { StoreState } from "../redux/reducers"
import { AppDispatch } from "../redux/store"
import { FormInput } from "./custom/components"
import { ToastTypes, useToast } from "./toast/ToastProvider"
import { FormType, FormTypeList } from "./types"

interface Props {
    formType: FormType
    editData?: MachineMasterType
    onSave: () => void
}

const MachineMasterForm = ({ formType, onSave, editData }: Props) => {
    const toast = useToast()

    const dispatch = useDispatch<AppDispatch>()
    const machineMaster = useSelector<StoreState, Array<MachineMasterType & Key>>(state => addKey(state.machineMaster))

    const [machineCode, setMachineCode] = useState<string>('')
    const [machineCodeError, setMachineCodeError] = useState<string>('')

    const [machineName, setMachineName] = useState<string>('')
    const [machineNameError, setMachineNameError] = useState<string>('')

    const resetStates = () => {
        setMachineName('')
        setMachineNameError('')
        setMachineCode('')
        setMachineCodeError('')
    }

    const handleMachineCode: ChangeEventHandler<HTMLInputElement> = (e) => {
        setMachineCode(e.target.value)
        setMachineCodeError('')
    }

    const handleMachineName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setMachineName(e.target.value)
        setMachineNameError('')
    }

    const handleClear = () => {
        resetStates()
    }

    const handleSave = () => {
        let error = false
        const data: MachineMasterType = {
            name: machineName,
            code: machineCode,
        }

        if (formType === FormTypeList.ADD) {
            if (data.code === "") {
                setMachineCodeError('Code required')
                error = true
            } else if ((data.code).length > 15) {
                setMachineCodeError('Machine Code must be within 15 characters')
                error = true
            } else if (data.code !== "") {
                const uniqueIndex = machineMaster.findIndex(mm => {
                    return mm.code.toLowerCase() === data.code.toLowerCase()
                })
                if (uniqueIndex > -1) {
                    setMachineCodeError('Code already exists')
                    error = true
                }
            }
        }

        if (formType === FormTypeList.UPDATE) {
            if (data.code === "") {
                setMachineCodeError('Machine Code required')
                error = true
            } else if ((data.code).length > 15) {
                setMachineCodeError('Machine Code must be within 15 characters')
                error = true
            }
        }

        if (data.name === "") {
            setMachineNameError('Machine Name required')
            error = true
        } else if ((data.name).length > 30) {
            setMachineNameError('Machine Name should be within 30 characters')
            error = true
        }

        if (!error) {
            if (formType === FormTypeList.ADD) {

                dispatch(addMachineMaster(data)).then(text => {
                    toast(text)
                    onSave()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            } else if (formType === FormTypeList.UPDATE && editData !== undefined && editData.id !== undefined) {
                dispatch(updateMachineMaster(data, editData.id)).then(text => {
                    toast(text)
                    onSave()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
        }
    }

    useEffect(() => {
        if (formType === FormTypeList.UPDATE && editData !== undefined) {

            setMachineCode(editData.code)
            setMachineName(editData.name)

        } else {
            resetStates()
        }
    }, [formType, editData])


    return <div className='container'>
        <div className="row">

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Machine Code'
                    labelClassName="required"
                    value={machineCode}
                    onChange={handleMachineCode}
                    placeholder='Machine Code'
                    errorText={machineCodeError}
                >
                </FormInput>

            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Machine Name'
                    labelClassName="required"
                    value={machineName}
                    onChange={handleMachineName}
                    placeholder='Machine Name'
                    errorText={machineNameError}
                // containerClass="mb-2"
                />
            </div>


        </div>
        <div className="d-flex justify-content-end hstack gap-1">
            <button className="btn btn-primary" onClick={handleClear}>Clear</button>
            <button className="btn btn-success" onClick={handleSave}>Save</button>
        </div>

    </div>
}
export default MachineMasterForm