import { useEffect, useMemo, useState } from 'react'
import * as Feather from 'react-feather'
import { useSelector } from 'react-redux'
import api, { APIResponse, getAxiosRequestConfig } from '../config/api'
import { currencyFormat, LocalFormatDate } from '../config/functions'
import { AuthUserType } from '../redux/actions/authUserActions'
import { CollectionType } from '../redux/actions/collectionActions'
import { StoreState } from '../redux/reducers'
import BarChart, { chartLabelForXaxis } from './BarChart'
import LineChart from './LineChart'

const getDriverNamesForTable = (collection: CollectionType[]) => {
    const data: string[] = []

    let oneDay = 1000 * 60 * 60 * 24
    let today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())

    for (let i = 10; i >= 1; i--) {
        const previousDateMs = today.getTime() - (oneDay * i)//current date ms-1day ms)
        // console.log(previousDateMs);
        const index = collection.findIndex((a) => {
            let arr = a.date.split('-').map((v) => Number(v))
            let ad = new Date(arr[0], arr[1] - 1, arr[2])
            // console.log(ad);
            return previousDateMs === ad.getTime()
        })
        if (index > -1) {

            data.push(collection.at(index)?.vehicleReading?.driverName || '')//optional Chaining
        } else {
            data.push('')
        }
    }
    return data
}

const getConductorNamesForTable = (collection: CollectionType[]) => {
    const data: string[] = []

    let oneDay = 1000 * 60 * 60 * 24
    let today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())

    for (let i = 10; i >= 1; i--) {
        const previousDateMs = today.getTime() - (oneDay * i)//current date ms-1day ms)
        // console.log(previousDateMs);
        const index = collection.findIndex((a) => {
            let arr = a.date.split('-').map((v) => Number(v))
            let ad = new Date(arr[0], arr[1] - 1, arr[2])
            // console.log(ad);
            return previousDateMs === ad.getTime()
        })
        if (index > -1) {

            data.push(collection.at(index)?.vehicleReading?.conductor1Name || '')//optional Chaining
        } else {
            data.push('')
        }
    }
    return data
}

const getConductor2NamesForTable = (collection: CollectionType[]) => {
    const data: string[] = []

    let oneDay = 1000 * 60 * 60 * 24
    let today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())

    for (let i = 10; i >= 1; i--) {
        const previousDateMs = today.getTime() - (oneDay * i)//current date ms-1day ms)
        const index = collection.findIndex((a) => {
            let arr = a.date.split('-').map((v) => Number(v))
            let ad = new Date(arr[0], arr[1] - 1, arr[2])

            return previousDateMs === ad.getTime()
        })
        if (index > -1) {

            data.push(collection.at(index)?.vehicleReading?.conductor2Name || '')//optional Chaining
        } else {
            data.push('')
        }
    }
    return data
}

const latestDateFromArrayOfObjects = (array: CollectionType[]) => {
    const LatestDate = new Date(Math.max(...array.map(function (e) {
        let d = new Date(e.date)
        return d.getTime();
    }))).toDateString();

    return LatestDate;
}

const AdminDashboard = () => {
    const authUser = useSelector<StoreState, AuthUserType>(state => state.authUser)
    const token = authUser.token!
    const config = getAxiosRequestConfig(token)

    // const collection = useSelector<StoreState, Array<CollectionType & Key>>(state => addKey(state.collection))

    const [ariyalur, setAriyalur] = useState<CollectionType[]>([])
    const [veeramangudi, setVeeramangudi] = useState<CollectionType[]>([])
    const [papanasam, setPapanasam] = useState<CollectionType[]>([])
    const [pattukottai, setPattukottai] = useState<CollectionType[]>([])
    const [kumbakonam, setKumbakonam] = useState<CollectionType[]>([])
    const [collectionData, setCollectionData] = useState<CollectionType[]>([])
    const [label, setLabel] = useState<string[]>([])

    const [ariyalurDriver, setAriyalurDriver] = useState<string[]>([])
    const [veeramangudiDriver, setVeeramangudiDriver] = useState<string[]>([])
    const [papanasamDriver, setPapanasamDriver] = useState<string[]>([])
    const [pattukottaiDriver, setPattukottaiDriver] = useState<string[]>([])
    const [kumbakonamDriver, setKumbakonamDriver] = useState<string[]>([])

    const [ariyalurConductor1, setAriyalurConductor1] = useState<string[]>([])
    const [veeramangudiConductor1, setVeeramangudiConductor1] = useState<string[]>([])
    const [papanasamConductor1, setPapanasamConductor1] = useState<string[]>([])
    const [pattukottaiConductor1, setPattukottaiConductor1] = useState<string[]>([])
    const [kumbakonamConductor1, setKumbakonamConductor1] = useState<string[]>([])

    const [ariyalurConductor2, setAriyalurConductor2] = useState<string[]>([])
    const [veeramangudiConductor2, setVeeramangudiConductor2] = useState<string[]>([])
    const [papanasamConductor2, setPapanasamConductor2] = useState<string[]>([])
    const [pattukottaiConductor2, setPattukottaiConductor2] = useState<string[]>([])
    const [kumbakonamConductor2, setKumbakonamConductor2] = useState<string[]>([])
    const [latestDate, setLatestDate] = useState<string>('')

    // console.log(latestDate);
    // console.log(new Date("2023-02-27").toDateString());

    const groupBy = (xs: any[], key: string) => {
        return xs.reduce((rv, x) => {
            (rv[x[key]] = rv[x[key]] || []).push(x)
            return rv
        }, {})
    }

    const ariyalurCard = useMemo<CollectionType[]>(() => {
        return collectionData.filter((c) => {
            return c.routeId === 100001
        }).filter((a) => {
            return new Date(a.date).toDateString() === latestDate
        })
    }, [collectionData, latestDate])

    const veeramangudiCard = useMemo<CollectionType[]>(() => {
        return collectionData.filter((c) => {
            return c.routeId === 100002
        }).filter((a) => {
            return new Date(a.date).toDateString() === latestDate
        })
    }, [collectionData, latestDate])

    const papanasamCard = useMemo<CollectionType[]>(() => {
        return collectionData.filter((c) => {
            return c.routeId === 100003
        }).filter((a) => {
            return new Date(a.date).toDateString() === latestDate
        })
    }, [collectionData, latestDate])

    const pattukottaiCard = useMemo<CollectionType[]>(() => {
        return collectionData.filter((c) => {
            return c.routeId === 100004
        }).filter((a) => {
            return new Date(a.date).toDateString() === latestDate
        })
    }, [collectionData, latestDate])

    const kumbakonamCard = useMemo<CollectionType[]>(() => {
        return collectionData.filter((c) => {
            return c.routeId === 100005
        }).filter((a) => {
            return new Date(a.date).toDateString() === latestDate
        })
    }, [collectionData, latestDate])

    useEffect(() => {
        setLatestDate(latestDateFromArrayOfObjects(collectionData))
    }, [collectionData])

    useEffect(() => {
        setLabel(chartLabelForXaxis())
    }, [])

    useEffect(() => {
        setAriyalurDriver(getDriverNamesForTable(ariyalur))
    }, [ariyalur])
    useEffect(() => {
        setVeeramangudiDriver(getDriverNamesForTable(veeramangudi))
    }, [veeramangudi])
    useEffect(() => {
        setPattukottaiDriver(getDriverNamesForTable(pattukottai))
    }, [pattukottai])
    useEffect(() => {
        setPapanasamDriver(getDriverNamesForTable(papanasam))
    }, [papanasam])
    useEffect(() => {
        setKumbakonamDriver(getDriverNamesForTable(kumbakonam))
    }, [kumbakonam])

    //conductor 1
    useEffect(() => {
        setAriyalurConductor1(getConductorNamesForTable(ariyalur))
    }, [ariyalur])
    useEffect(() => {
        setVeeramangudiConductor1(getConductorNamesForTable(veeramangudi))
    }, [veeramangudi])
    useEffect(() => {
        setPattukottaiConductor1(getConductorNamesForTable(pattukottai))
    }, [pattukottai])
    useEffect(() => {
        setPapanasamConductor1(getConductorNamesForTable(papanasam))
    }, [papanasam])
    useEffect(() => {
        setKumbakonamConductor1(getConductorNamesForTable(kumbakonam))
    }, [kumbakonam])

    //conductor 2
    useEffect(() => {
        setAriyalurConductor2(getConductor2NamesForTable(ariyalur))
    }, [ariyalur])
    useEffect(() => {
        setVeeramangudiConductor2(getConductor2NamesForTable(veeramangudi))
    }, [veeramangudi])
    useEffect(() => {
        setPattukottaiConductor2(getConductor2NamesForTable(pattukottai))
    }, [pattukottai])
    useEffect(() => {
        setPapanasamConductor2(getConductor2NamesForTable(papanasam))
    }, [papanasam])
    useEffect(() => {
        setKumbakonamConductor2(getConductor2NamesForTable(kumbakonam))
    }, [kumbakonam])

    useEffect(() => {
        api.get<APIResponse<CollectionType[]>>(`collection/dashboard/`, config).then(response => {

            if (response.status === 200 && response.data.data) {
                const result = response.data.data

                setCollectionData(result)
            }
        }).catch(error => {

        })
    }, [])

    useEffect(() => {
        if (collectionData.length > 0) {
            Object.values(groupBy(collectionData, 'routeId') as CollectionType[][]).forEach((collection, i) => {
                // console.log(collection);
                if (collection[0].routeId === 100001) {
                    setAriyalur(collection)
                } else if (collection[0].routeId === 100002) {
                    setVeeramangudi(collection)
                } else if (collection[0].routeId === 100003) {
                    setPapanasam(collection)
                } else if (collection[0].routeId === 100004) {
                    setPattukottai(collection)
                } else if (collection[0].routeId === 100005) {
                    setKumbakonam(collection)
                }

            })
        }
    }, [collectionData])

    // console.log(ariyalur);
    // console.log(veeramangudi);
    // console.log(papanasam);
    // console.log(pattukottai);
    // console.log(kumbakonam);

    return <>
        <h4 >Collection</h4>
        <div className='card mt-2'>
            <div className='card-body p-0'>
                <div className='container-fluid'>
                    <div className="row m-0">

                        <div className="col-sm-6 col-md-2 col-lg-2 p-1 me-3 ms-4">
                            <div className='rounded  p-2 m-2 veeramangudi row'>
                                <div className="vstack m-1" >
                                    <div className='d-flex justify-content-between align-items-center mb-0 pb-0'>
                                        <h4 className='text-white'>Veeramangudi</h4>
                                    </div>
                                    <label className='text-white mb-1'>{veeramangudiCard[0] ? LocalFormatDate(veeramangudiCard[0].date) : LocalFormatDate(latestDate)}</label>
                                    <h4 className='text-white mt-0 pt-0'><Feather.Pocket
                                        color='white'
                                        size={22}
                                    /> {currencyFormat(veeramangudiCard[0] ? veeramangudiCard[0].balance : 0)}</h4>
                                    <label className='text-white'><Feather.PlusSquare
                                        color='white'
                                        size={20}
                                    /> {currencyFormat(veeramangudiCard[0] ? veeramangudiCard[0].totalIncome : 0)}</label>
                                    <label className='text-white'><Feather.MinusSquare
                                        color='white'
                                        size={20}
                                    /> {currencyFormat(veeramangudiCard[0] ? veeramangudiCard[0].totalExpense : 0)}</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6 col-md-2 col-lg-2 p-1 me-3">
                            <div className='rounded  p-2 m-2 pattukottai row'>
                                <div className="vstack m-1" >
                                    <div className='d-flex justify-content-between align-items-center mb-0 pb-0'>
                                        <h4 className='text-white'>Pattukottai</h4>
                                    </div>
                                    <label className='text-white mb-1'>{pattukottaiCard[0] ? LocalFormatDate(pattukottaiCard[0].date) : LocalFormatDate(latestDate)}</label>
                                    <h4 className='text-white mt-0 pt-0'><Feather.Pocket
                                        color='white'
                                        size={22}
                                    /> {currencyFormat(pattukottaiCard[0] ? pattukottaiCard[0].balance : 0)}</h4>
                                    <label className='text-white'><Feather.PlusSquare
                                        color='white'
                                        size={20}
                                    /> {currencyFormat(pattukottaiCard[0] ? pattukottaiCard[0].totalIncome : 0)}</label>
                                    <label className='text-white'><Feather.MinusSquare
                                        color='white'
                                        size={20}
                                    /> {currencyFormat(pattukottaiCard[0] ? pattukottaiCard[0].totalExpense : 0)}</label>
                                </div>
                            </div>

                        </div>
                        {/* <div className="col-sm-6 col-md-2 col-lg-2">
                                <h4> Excess / Shortage: {currencyFormat(totalExcess)}/{currencyFormat(totalShortage)}</h4>
                            </div> */}
                        <div className="col-sm-6 col-md-2 col-lg-2 p-1 me-3">
                            <div className='rounded  p-2 m-2 papanasam row'>
                                <div className="vstack m-1" >
                                    <div className='d-flex justify-content-between align-items-center mb-0 pb-0'>
                                        <h4 className='text-white'>Papanasam</h4>
                                    </div>
                                    <label className='text-white mb-1'>{papanasamCard[0] ? LocalFormatDate(papanasamCard[0].date) : LocalFormatDate(latestDate)}</label>
                                    <h4 className='text-white mt-0 pt-0'><Feather.Pocket
                                        color='white'
                                        size={22}
                                    /> {currencyFormat(papanasamCard[0] ? papanasamCard[0].balance : 0)}</h4>
                                    <label className='text-white'><Feather.PlusSquare
                                        color='white'
                                        size={20}
                                    /> {currencyFormat(papanasamCard[0] ? papanasamCard[0].totalIncome : 0)}</label>
                                    <label className='text-white'><Feather.MinusSquare
                                        color='white'
                                        size={20}
                                    /> {currencyFormat(papanasamCard[0] ? papanasamCard[0].totalExpense : 0)}</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-2 col-lg-2 p-1 me-3">
                            <div className='rounded  p-2 m-2 ariyalur row'>
                                <div className="vstack m-1" >
                                    <div className='d-flex justify-content-between align-items-center mb-0 pb-0'>
                                        <h4 className='text-white'>Ariyalur</h4>
                                    </div>
                                    <label className='text-white mb-1'>{ariyalurCard[0] ? LocalFormatDate(ariyalurCard[0].date) : LocalFormatDate(latestDate)}</label>
                                    <h4 className='text-white mt-0 pt-0'><Feather.Pocket
                                        color='white'
                                        size={22}
                                    /> {currencyFormat(ariyalurCard[0] ? ariyalurCard[0].balance : 0)}</h4>
                                    <label className='text-white'><Feather.PlusSquare
                                        color='white'
                                        size={20}
                                    /> {currencyFormat(ariyalurCard[0] ? ariyalurCard[0].totalIncome : 0)}</label>
                                    <label className='text-white'><Feather.MinusSquare
                                        color='white'
                                        size={20}
                                    /> {currencyFormat(ariyalurCard[0] ? ariyalurCard[0].totalExpense : 0)}</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-2 col-lg-2 p-1 me-1">
                            <div className='rounded  p-2 m-2 kumbakonam row'>
                                <div className="vstack m-1" >
                                    <div className='d-flex justify-content-between align-items-center mb-0 pb-0'>
                                        <h4 className='text-white'>Kumbakonam</h4>
                                    </div>
                                    <label className='text-white mb-1'>{kumbakonamCard[0] ? LocalFormatDate(kumbakonamCard[0].date) : LocalFormatDate(latestDate)}</label>
                                    <h4 className='text-white mt-0 pt-0'><Feather.Pocket
                                        color='white'
                                        size={22}
                                    /> {currencyFormat(kumbakonamCard[0] ? kumbakonamCard[0].balance : 0)}</h4>
                                    <label className='text-white'><Feather.PlusSquare
                                        color='white'
                                        size={20}
                                    /> {currencyFormat(kumbakonamCard[0] ? kumbakonamCard[0].totalIncome : 0)}</label>
                                    <label className='text-white'><Feather.MinusSquare
                                        color='white'
                                        size={20}
                                    /> {currencyFormat(kumbakonamCard[0] ? kumbakonamCard[0].totalExpense : 0)}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className='row'>
            <div className='col'>
                <div className='card'>
                    <div className='card-body' >
                        <div className='w-100' style={{ height: '350px' }}>
                            <BarChart
                                ariyalur={ariyalur}
                                veeramangudi={veeramangudi}
                                papanasam={papanasam}
                                pattukottai={pattukottai}
                                kumbakonam={kumbakonam} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='row'>
            <div className='col'>
                <h4 >Mileage</h4>
                <div className='card'>
                    <div className='card-body'>
                        <div className='w-100' style={{ height: '350px' }}>
                            <LineChart
                                ariyalur={ariyalur}
                                veeramangudi={veeramangudi}
                                papanasam={papanasam}
                                pattukottai={pattukottai}
                                kumbakonam={kumbakonam} />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className='row'>
            <div className='col'>
                <h4 >Driver and Conductor</h4>
                <div className="card m-1">
                    <div className="card-body ">
                        <div className='table-wrapper '>
                            <table className='table colored pe-2 table-bordered'>
                                <thead className='border-dark'>
                                    <tr>
                                        <th></th>
                                        {label.map(l => {
                                            return <th colSpan={2} className='text-truncate text-center'>{l}</th>
                                        })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* n.forEach((num1, index) => {
                                 const num2 = m[index];
                                console.log(num1, num2);
                                }) */}
                                    <tr>
                                        <td className='fw-bold'>ARIYALUR</td>
                                        {ariyalurDriver.map((ad, i) => {
                                            const ac = ariyalurConductor1[i];
                                            const ac2 = ariyalurConductor2[i];
                                            return <>
                                                < td > {ad}</td>
                                                <td className='text-truncate'>{ac}{ac2 !== '' ? ' , ' + ac2 : ''}</td>
                                            </>
                                        })
                                        }
                                    </tr>
                                    <tr>
                                        <td className='fw-bold'>VEERAMANGUDI</td>
                                        {veeramangudiDriver.map((vd, i) => {
                                            const vc = veeramangudiConductor1[i];
                                            const vc2 = veeramangudiConductor2[i];
                                            return <>
                                                < td > {vd}</td>
                                                <td className='text-truncate'>{vc}{vc2 !== '' ? ' , ' + vc2 : ''}</td>
                                            </>
                                        })
                                        }
                                    </tr>
                                    <tr>
                                        <td className='fw-bold'>PATTUKOTTAI</td>
                                        {pattukottaiDriver.map((ptd, i) => {
                                            const ptc = pattukottaiConductor1[i];
                                            const ptc2 = pattukottaiConductor2[i];
                                            return <>
                                                < td > {ptd}</td>
                                                <td className='text-truncate'>{ptc}{ptc2 !== '' ? ' , ' + ptc2 : ''}</td>
                                            </>
                                        })
                                        }
                                    </tr>
                                    <tr>
                                        <td className='fw-bold'>PAPANASAM</td>
                                        {papanasamDriver.map((pd, i) => {
                                            const pc = papanasamConductor1[i];
                                            const pc2 = papanasamConductor2[i];
                                            return <>
                                                < td > {pd}</td>
                                                <td className='text-truncate'>{pc}{pc2 !== '' ? ' , ' + pc2 : ''}</td>
                                            </>
                                        })
                                        }
                                    </tr>
                                    <tr>
                                        <td className='fw-bold'>KUMBAKONAM</td>
                                        {kumbakonamDriver.map((kd, i) => {
                                            const kc = kumbakonamConductor1[i];
                                            const kc2 = kumbakonamConductor2[i];
                                            return <>
                                                < td > {kd}</td>
                                                <td className='text-truncate'>{kc}{kc2 !== '' ? ' , ' + kc2 : ''}</td>
                                            </>
                                        })
                                        }
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}
export default AdminDashboard