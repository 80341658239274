/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../reducers"

export enum StopMasterActionList {
    ADD_STOP_MASTER = 'ADD_STOP_MASTER',
    FETCH_STOP_MASTER = 'FETCH_STOP_MASTER',
    UPDATE_STOP_MASTER = 'UPDATE_STOP_MASTER',
    DELETE_STOP_MASTER = 'DELETE_STOP_MASTER',
}

export interface StopMasterType {
    id?: number
    routeId: number
    routeName: string
    number: string
    name: string
    createdBy?: string
    createTimestamp?: string
}

export interface AddStopMasterAction {
    type: StopMasterActionList.ADD_STOP_MASTER
    data: StopMasterType
}

export interface FetchStopMasterAction {
    type: StopMasterActionList.FETCH_STOP_MASTER
    data: Array<StopMasterType>
}

export interface UpdateStopMasterAction {
    type: StopMasterActionList.UPDATE_STOP_MASTER
    data: StopMasterType
}

export interface DeleteStopMasterAction {
    type: StopMasterActionList.DELETE_STOP_MASTER
    data: number
}

export type StopMasterActions = AddStopMasterAction | FetchStopMasterAction | UpdateStopMasterAction | DeleteStopMasterAction

export const addStopMaster = (data: StopMasterType) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Stop Master Added")
        return api.post<APIResponse<StopMasterType>>('stop_master/', data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<AddStopMasterAction>({
                    type: StopMasterActionList.ADD_STOP_MASTER,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Stop Master Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const updateStopMaster = (data: StopMasterType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Stop Master Updated")
        return api.put<APIResponse<StopMasterType>>(`stop_master/?id=${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<UpdateStopMasterAction>({
                    type: StopMasterActionList.UPDATE_STOP_MASTER,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Stop Master Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchStopMaster = () => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<StopMasterType[]>>('stop_master/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchStopMasterAction>({
                    type: StopMasterActionList.FETCH_STOP_MASTER,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteStopMaster = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Stop Master Deleted")
        return api.delete<APIResponse<StopMasterType>>(`stop_master/?id=${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteStopMasterAction>({
                    type: StopMasterActionList.DELETE_STOP_MASTER,
                    data: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Stop Master Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}

