
/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../reducers"

export enum VehicleAssetMappingActionList {
    ADD_VEHICLE_ASSET_MAPPING = 'ADD_VEHICLE_ASSET_MAPPING',
    FETCH_VEHICLE_ASSET_MAPPING = 'FETCH_VEHICLE_ASSET_MAPPING',
    UPDATE_VEHICLE_ASSET_MAPPING = 'UPDATE_VEHICLE_ASSET_MAPPING',
    DELETE_VEHICLE_ASSET_MAPPING = 'DELETE_VEHICLE_ASSET_MAPPING',
}

export enum AssetLocationList {
    FL = 'Front Left',
    FR = 'Front Right',
    RLI = 'Rear Left In',
    RLO = 'Rear Left Out',
    RRI = 'Rear Right In',
    RRO = 'Rear Right Out',
    SW = 'Spare Wheel',
}

export type AssetLocation = AssetLocationList.FL | AssetLocationList.FR | AssetLocationList.RLI | AssetLocationList.RLO | AssetLocationList.RRI | AssetLocationList.RRO | AssetLocationList.SW

export interface VehicleAssetMappingType {
    // id:PK,
    // vehicleId:FK,
    // assetId:FK,
    // createdBy,
    // createTimestamp,
    id?: number
    vehicleId: number
    vehicleName: string
    assetId: number
    assetName: string
    location: AssetLocation
    createdBy?: string
    createTimestamp?: string
}

export interface AddVehicleAssetMappingAction {
    type: VehicleAssetMappingActionList.ADD_VEHICLE_ASSET_MAPPING
    data: VehicleAssetMappingType
}

export interface FetchVehicleAssetMappingAction {
    type: VehicleAssetMappingActionList.FETCH_VEHICLE_ASSET_MAPPING
    data: Array<VehicleAssetMappingType>
}

export interface UpdateVehicleAssetMappingAction {
    type: VehicleAssetMappingActionList.UPDATE_VEHICLE_ASSET_MAPPING
    data: VehicleAssetMappingType
}

export interface DeleteVehicleAssetMappingAction {
    type: VehicleAssetMappingActionList.DELETE_VEHICLE_ASSET_MAPPING
    data: number
}

export type VehicleAssetMappingActions = AddVehicleAssetMappingAction | FetchVehicleAssetMappingAction | UpdateVehicleAssetMappingAction | DeleteVehicleAssetMappingAction

export const addVehicleAssetMapping = (data: VehicleAssetMappingType) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Vehicle Asset Mapping Added")
        return api.post<APIResponse<VehicleAssetMappingType>>('vehicle_asset_mapping/', data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<AddVehicleAssetMappingAction>({
                    type: VehicleAssetMappingActionList.ADD_VEHICLE_ASSET_MAPPING,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Vehicle Asset Mapping Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const updateVehicleAssetMapping = (data: VehicleAssetMappingType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Vehicle Asset Mapping Updated")
        return api.put<APIResponse<VehicleAssetMappingType>>(`vehicle_asset_mapping/?id=${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<UpdateVehicleAssetMappingAction>({
                    type: VehicleAssetMappingActionList.UPDATE_VEHICLE_ASSET_MAPPING,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Vehicle Asset Mapping Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchVehicleAssetMapping = () => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<VehicleAssetMappingType[]>>('vehicle_asset_mapping/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchVehicleAssetMappingAction>({
                    type: VehicleAssetMappingActionList.FETCH_VEHICLE_ASSET_MAPPING,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteVehicleAssetMapping = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Vehicle Asset Mapping Deleted")
        return api.delete<APIResponse<VehicleAssetMappingType>>(`vehicle_asset_mapping/?id=${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteVehicleAssetMappingAction>({
                    type: VehicleAssetMappingActionList.DELETE_VEHICLE_ASSET_MAPPING,
                    data: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Vehicle Asset Mapping Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}

