import { Fragment, MouseEventHandler, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PageTitle from '../components/custom/components/PageTitle'
import VerticalLayout from '../components/custom/layouts/Vertical'
import Modal from '../components/Modal'
import { ToastTypes, useToast } from '../components/toast/ToastProvider'
import { FormType, FormTypeList } from '../components/types'
import VehicleMasterForm from '../components/VehicleMasterForm'
import { addKey, isInToday } from '../config/functions'
import { AuthUserType } from '../redux/actions/authUserActions'
import { Key } from '../redux/actions/types'
import { deleteVehicleMaster, VehicleCategory, VehicleMasterType, VehicleType } from '../redux/actions/vehicleMasterActions'
import { StoreState } from '../redux/reducers'
import { AppDispatch } from '../redux/store'

interface TableBody {
    sno: number
    type: VehicleType// to be changed after api implemented 
    code: string
    name: string
    regNo: string
    brand: string
    category: VehicleCategory// to be changed after api implemented
    yearMFG: string
    chassisNo: string
    engineNo: string
    mileage: string
    rtoId?: number //to be implemented from RTO master as dropdown
    purchaseDate?: string //to be implemented from RTO master as dropdown
    rtoName: string
    rtoArea: string
    onEdit: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
    timeStamp: string
    authUser: AuthUserType
}

type HandleEdit = (value: VehicleMasterType) => void
type HandleDelete = (value: VehicleMasterType) => void

const VehicleMaster = () => {

    const toast = useToast()

    const dispatch = useDispatch<AppDispatch>()
    const authUser = useSelector<StoreState, AuthUserType>(state => state.authUser)

    const vehicleMaster = useSelector<StoreState, Array<VehicleMasterType & Key>>(state => addKey(state.vehicleMaster))
    const [showForm, setShowForm] = useState<boolean>(false)
    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    const [editData, setEditData] = useState<VehicleMasterType>()

    const handleAdd = () => {
        setShowForm(true)
    }

    const handleEdit: HandleEdit = (value) => {
        setFormType(FormTypeList.UPDATE)
        setEditData(value)
        setShowForm(true)
    }

    const handleDelete: HandleDelete = (value) => {
        if (value.id !== undefined) {
            dispatch(deleteVehicleMaster(value.id)).then(text => {
                toast(text, ToastTypes.SUCCESS)
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
        }
    }

    const handleFormClose = () => {
        setShowForm(false)
        setFormType(FormTypeList.ADD)
        setEditData(undefined)
    }


    return <Fragment>
        <Modal
            headerText={formType === FormTypeList.ADD ? 'Add Vehicle Master' : 'Edit Vehicle Master'}
            visible={showForm}
            onClose={handleFormClose}
            centered
            size='lg'
        >
            <VehicleMasterForm
                formType={formType}
                editData={editData}
                onSave={handleFormClose}
            />

        </Modal>
        <VerticalLayout>
            <PageTitle
                title='Vehicle Master'
                buttonText='ADD'
                onButtonClick={handleAdd}
            />
            <div className="card">
                <div className="card-body">
                    <div className='table-wrapper'>
                        <table className='table colored'>
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    <th className='text-truncate align-middle'>Type</th>
                                    <th className='text-truncate align-middle'>Code</th>
                                    <th className='text-truncate align-middle'>Route</th>
                                    <th className='text-truncate align-middle'>Reg.No</th>
                                    <th className='text-truncate align-middle'>Brand</th>
                                    <th className='text-truncate align-middle'>Year MFG</th>
                                    <th className='text-truncate align-middle'>Purchase Date</th>
                                    <th className='text-truncate align-middle'>Category</th>
                                    <th className='text-truncate align-middle'>Chassis No</th>
                                    <th className='text-truncate align-middle'>Engine No</th>
                                    <th className='text-truncate align-middle'>Mileage</th>
                                    {/* <th className='text-truncate align-middle'>RTO</th> */}
                                    <th className='text-truncate align-middle text-center'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {vehicleMaster.map((vm, i) => {
                                    return <VehicleMasterBody
                                        brand={vm.brand}
                                        code={vm.code}
                                        category={vm.category}
                                        rtoId={vm.rtoId}
                                        rtoName={vm.rtoName !== undefined ? vm.rtoName : ''}
                                        rtoArea={vm.rtoArea !== undefined ? vm.rtoArea : ''}
                                        chassisNo={vm.chassisNumber}
                                        engineNo={vm.engineNumber}
                                        mileage={vm.mileage}
                                        purchaseDate={vm.purchaseDate}
                                        name={vm.name}
                                        regNo={vm.registrationNumber}
                                        sno={i + 1}
                                        type={vm.type}
                                        yearMFG={vm.yearMFG}
                                        key={vm.key}
                                        authUser={authUser}
                                        timeStamp={vm.createTimestamp !== undefined ? vm.createTimestamp : ''}
                                        onEdit={() => handleEdit(vm)}
                                        onDelete={() => handleDelete(vm)}
                                    />
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </VerticalLayout>
    </Fragment>
}

const VehicleMasterBody = ({ sno, brand, code, category, chassisNo, engineNo, mileage, name, regNo, type, yearMFG, rtoName, rtoArea, purchaseDate, onEdit, onDelete, timeStamp, authUser }: TableBody) => {
    const ShowEditForManager = isInToday(timeStamp)
    return <tr>
        <td>{sno}</td>
        <td>{type}</td>
        <td>{code}</td>
        <td className='text-capitalize'>{name}</td>
        <td className='text-capitalize'>{regNo}</td>
        <td className='text-capitalize'>{brand}</td>
        <td>{yearMFG}</td>
        <td>{purchaseDate}</td>
        <td>{category}</td>
        <td>{chassisNo}</td>
        <td>{engineNo}</td>
        <td>{mileage}</td>
        {/* <td className='text-capitalize'>{rtoName},{rtoArea}</td> */}
        <td>
            <div className='hstack gap-1'>
                {(authUser.role === 'admin' || authUser.role === 'super' || authUser.role === 'Admin' || authUser.role === 'Super') && <button className='btn btn-xs btn-primary align-middle' onClick={onEdit}>Edit</button>}
                {(authUser.role === 'Manager' || authUser.role === 'manager') && ShowEditForManager && <button className='btn btn-xs btn-primary align-middle' onClick={onEdit}>Edit</button>}
                <button className='btn btn-xs btn-danger align-middle' onClick={onDelete}>Delete</button>
            </div>
        </td>
    </tr>

}

export default VehicleMaster
