import { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { CollectionType } from '../redux/actions/collectionActions'
import { chartLabelForXaxis } from './BarChart'

// defaults.global.tooltips.enabled = false
// defaults.global.legend.position = 'bottom'

interface LineChartProps {
    ariyalur: CollectionType[]
    veeramangudi: CollectionType[]
    papanasam: CollectionType[]
    pattukottai: CollectionType[]
    kumbakonam: CollectionType[]
}

const getChartDataset = (collection: CollectionType[]) => {
    const data: number[] = []

    let oneDay = 1000 * 60 * 60 * 24
    let today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())

    for (let i = 10; i >= 1; i--) {
        const previousDateMs = today.getTime() - (oneDay * i)//current date ms-1day ms)
        // console.log(previousDateMs);
        const index = collection.findIndex((a) => {
            let arr = a.date.split('-').map((v) => Number(v))
            let ad = new Date(arr[0], arr[1] - 1, arr[2])
            // console.log(ad);
            return previousDateMs === ad.getTime()
        })
        if (index > -1) {
            // data.push(collection[index].mileage !== undefined ? collection[index].mileage : 0)

            // data.push(collection[index] !== undefined
            //     ? (collection[index].vehicleReading !== undefined
            //         ? (collection[index].vehicleReading[0] !== undefined
            //             ? collection[index].vehicleReading[0].averageMilage
            //             : 0)
            //         : 0)
            //     : 0)

            // console.log(collection.at(index));

            data.push(collection.at(index)?.vehicleReading?.averageMilage || 0)//optional Chaining
        } else {
            data.push(0)
        }
    }
    return data
}

const LineChart = ({ ariyalur, veeramangudi, papanasam, pattukottai, kumbakonam }: LineChartProps) => {

    const [ariyalurMileage, setAriyalurMileage] = useState<number[]>([])
    const [veeramangudiMileage, setVeeramangudiMileage] = useState<number[]>([])
    const [pattukottaiMileage, setPattukottairMileage] = useState<number[]>([])
    const [papanasamMileage, setPapanasamMileage] = useState<number[]>([])
    const [kumbakonamMileage, setKumbakonamMileage] = useState<number[]>([])
    const [label, setLabel] = useState<string[]>([])

    useEffect(() => {
        setLabel(chartLabelForXaxis())
    }, [])
    useEffect(() => {
        setAriyalurMileage(getChartDataset(ariyalur))
    }, [ariyalur])
    useEffect(() => {
        setVeeramangudiMileage(getChartDataset(veeramangudi))
    }, [veeramangudi])
    useEffect(() => {
        setPattukottairMileage(getChartDataset(pattukottai))
    }, [pattukottai])
    useEffect(() => {
        setPapanasamMileage(getChartDataset(papanasam))
    }, [papanasam])
    useEffect(() => {
        setKumbakonamMileage(getChartDataset(kumbakonam))
    }, [kumbakonam])

    return (<>
        <div>
            <Line
                data={{
                    labels: label,
                    datasets: [
                        {
                            label: 'Veeramangudi',
                            data: veeramangudiMileage,
                            fill: false,
                            borderColor: '#348BFB',
                        },
                        {
                            label: 'Pattukottai',
                            data: pattukottaiMileage,
                            fill: false,
                            borderColor: '#45E393',
                        },
                        {
                            label: 'Papanasam',
                            data: papanasamMileage,
                            fill: false,
                            borderColor: '#FCAF3B',
                        },
                        {
                            label: 'Ariyalur',
                            data: ariyalurMileage,
                            fill: false,
                            borderColor: '#FE4A65',
                        },
                        {
                            label: 'Kumbakonam',
                            data: kumbakonamMileage,
                            fill: false,
                            backgroundColor: 'white', // #5B717B
                            borderColor: '#765ACB',
                        },
                    ],
                }}
                height={350}
                width={200}
                options={{
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                },
                            },
                        ],
                    },
                    // title: {
                    //     display: true,
                    //     text: 'Collection',
                    //     fontSize: 15
                    // },
                    legend: {
                        labels: {
                            fontSize: 20,
                        },
                        position: 'bottom'
                    },
                }}
            />
        </div>

    </>
    )
}

export default LineChart
