import { MouseEventHandler, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PageTitle from '../components/custom/components/PageTitle'
import VerticalLayout from '../components/custom/layouts/Vertical'
import EditAttachmentsForm from '../components/EditAttachmentsForm'
import EmployeeForm from '../components/EmployeeForm'
import Modal from '../components/Modal'
import { ToastTypes, useToast } from '../components/toast/ToastProvider'
import { FormType, FormTypeList } from '../components/types'
import { addKey, isInToday, LocalFormatDate } from '../config/functions'
import { AuthUserType } from '../redux/actions/authUserActions'
import { deleteEmployee, EmployeeSalaryType, EmployeesType, EmployeeType } from '../redux/actions/employeeActions'
import { Key } from '../redux/actions/types'
import { StoreState } from '../redux/reducers'
import { AppDispatch } from '../redux/store'

interface TableBody {
    sno: number
    code: string
    name: string
    type: EmployeesType
    designation: string
    dateOfJoin: string
    dateOfLeave?: string
    salaryType: EmployeeSalaryType
    mobileNumber: string
    address: string
    city: string
    state: string
    pincode: string
    rname: string
    rrelation: string
    rcontactNumber: string
    drivingLicenceNumber?: string
    drivingLicenceExpiryDate?: string
    aadharNumber?: string
    attachments?: string
    onEdit: MouseEventHandler<HTMLButtonElement>
    onEditAttachments: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
    timeStamp: string
    authUser: AuthUserType
}

type HandleEdit = (value: EmployeeType) => void
type HandleEditAttachments = (value: EmployeeType) => void
type HandleDelete = (value: EmployeeType) => void


const Employee = () => {
    const toast = useToast()
    const dispatch = useDispatch<AppDispatch>()

    const employee = useSelector<StoreState, Array<EmployeeType & Key>>(state => addKey(state.employee))
    const authUser = useSelector<StoreState, AuthUserType>(state => state.authUser)

    const [showForm, setShowForm] = useState<boolean>(false)
    const [showAttachmentForm, setShowAttachmentForm] = useState<boolean>(false)
    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    const [editData, setEditData] = useState<EmployeeType>()
    const handleAdd = () => {
        setShowForm(true)
    }

    const handleEdit: HandleEdit = (value) => {
        setFormType(FormTypeList.UPDATE)
        setEditData(value)
        setShowForm(true)
    }

    const handleEditAttachments: HandleEditAttachments = (value) => {
        // setFormType(FormTypeList.UPDATE)
        setEditData(value)
        setShowAttachmentForm(true)
    }

    const handleDelete: HandleDelete = (value) => {
        if (value.id !== undefined) {
            dispatch(deleteEmployee(value.id)).then(text => {
                toast(text)
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
        }
    }

    const handleFormClose = () => {
        setShowForm(false)
        setShowAttachmentForm(false)
        setFormType(FormTypeList.ADD)
        setEditData(undefined)
    }

    return <>
        <Modal
            headerText='Edit Employee Attachment'
            visible={showAttachmentForm}
            onClose={handleFormClose}
            size='lg'
            centered
        >
            <EditAttachmentsForm
                editData={editData}
                onSave={handleFormClose}
            />
        </Modal>
        <Modal
            headerText={formType === FormTypeList.ADD ? 'Add Employee' : 'Edit Employee'}
            visible={showForm}
            onClose={handleFormClose}
            size='xl'
            centered
        >
            <EmployeeForm
                formType={formType}
                editData={editData}
                onSave={handleFormClose}
            />
        </Modal>
        <VerticalLayout>
            <PageTitle
                title='Employee'
                buttonText='ADD'
                onButtonClick={handleAdd}
            />
            <div className="card">
                <div className="card-body ">
                    <div className='table-wrapper '>
                        <table className='table colored pe-2'>
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    <th className='text-truncate align-middle'>Code</th>
                                    <th className='text-truncate align-middle'>Name</th>
                                    <th className='text-truncate align-middle'>Type</th>
                                    <th className='text-truncate align-middle'>Designation</th>
                                    <th className='text-truncate align-middle'>Date Of Join</th>
                                    <th className='text-truncate align-middle'>Date of Leave</th>
                                    <th className='text-truncate align-middle'>Salary Type</th>
                                    <th className='text-truncate align-middle'>Mobile</th>
                                    <th className='text-truncate align-middle'>Address</th>
                                    <th className='text-truncate align-middle'>City</th>
                                    <th className='text-truncate align-middle'>State</th>
                                    <th className='text-truncate align-middle'>Pincode</th>
                                    <th className='text-truncate align-middle'>Ref.Person.Name</th>
                                    <th className='text-truncate align-middle'>Ref.Person.Relation</th>
                                    <th className='text-truncate align-middle'>Ref.Person.Mobile</th>
                                    <th className='text-truncate align-middle'>Driving Licence Number</th>
                                    <th className='text-truncate align-middle'>Driving Licence ExpiryDate</th>
                                    <th className='text-truncate align-middle'>Aadhar</th>
                                    <th className='text-center align-middle'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {employee.map((em, i) => {
                                    return <EmployeeBody
                                        sno={i + 1}
                                        code={em.code !== undefined ? em.code : ''}
                                        name={em.name}
                                        type={em.type}
                                        designation={em.designation}
                                        dateOfJoin={LocalFormatDate(em.dateOfJoin)}
                                        dateOfLeave={(em.dateOfLeave !== undefined && em.dateOfLeave !== '0000-00-00') ? LocalFormatDate(em.dateOfLeave) : ''}
                                        salaryType={em.salaryType}
                                        mobileNumber={em.mobileNumber}
                                        address={em.address}
                                        city={em.city}
                                        state={em.state}
                                        pincode={em.pincode}
                                        rname={em.reference.name}
                                        rrelation={em.reference.relationship}
                                        rcontactNumber={em.reference.contactNumber}
                                        drivingLicenceNumber={em.drivingLicenseNumber}
                                        drivingLicenceExpiryDate={(em.drivingLicenseExpiryDate !== undefined && em.drivingLicenseExpiryDate !== '0000-00-00') ? LocalFormatDate(em.drivingLicenseExpiryDate) : ''}
                                        aadharNumber={em.aadharNumber}
                                        // attachments='Attachments'
                                        key={em.key}
                                        authUser={authUser}
                                        timeStamp={em.createTimestamp !== undefined ? em.createTimestamp : ''}
                                        onEdit={() => handleEdit(em)}
                                        onEditAttachments={() => handleEditAttachments(em)}
                                        onDelete={() => handleDelete(em)}
                                    />
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </VerticalLayout>
    </>
}

const EmployeeBody = ({ sno, code, name, type, dateOfLeave, designation, dateOfJoin, salaryType, mobileNumber, address, city, state, pincode, rname, rrelation, drivingLicenceNumber, drivingLicenceExpiryDate, attachments, aadharNumber, rcontactNumber, onEdit, onDelete, onEditAttachments , timeStamp, authUser}: TableBody) => {

    const ShowEditForManager = isInToday(timeStamp)
    
    return <tr>
        <td>{sno}</td>
        <td>{code}</td>
        <td>{name}</td>
        <td>{type}</td>
        <td>{designation}</td>
        <td>{dateOfJoin}</td>
        <td>{dateOfLeave}</td>
        <td>{salaryType}</td>
        <td>{mobileNumber}</td>
        <td>{address}</td>
        <td>{city}</td>
        <td>{state}</td>
        <td>{pincode}</td>
        <td>{rname}</td>
        <td>{rrelation}</td>
        <td>{rcontactNumber}</td>
        <td>{drivingLicenceNumber}</td>
        <td>{drivingLicenceExpiryDate}</td>
        <td>{aadharNumber}</td>
        <td>
            <div className='hstack gap-1'>
            {(authUser.role === 'admin' || authUser.role === 'super' || authUser.role === 'Admin' || authUser.role === 'Super') && <button className='btn btn-xs btn-primary' onClick={onEdit}>Edit</button>}
            {(authUser.role === 'Manager' || authUser.role === 'manager') && ShowEditForManager && <button className='btn btn-xs btn-primary' onClick={onEdit}>Edit</button>}
                <button className='btn btn-xs btn-primary text-truncate' onClick={onEditAttachments}>View Attachments</button>
                <button className='btn btn-xs btn-danger' onClick={onDelete}>Delete</button>
            </div>
        </td>
    </tr>
}

export default Employee
