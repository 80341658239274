/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../reducers"

export enum OpeningBalanceActionList {
    ADD_OPENING_BALANCE = 'ADD_OPENING_BALANCE',
    FETCH_OPENING_BALANCE = 'FETCH_OPENING_BALANCE',
    UPDATE_OPENING_BALANCE = 'UPDATE_OPENING_BALANCE',
    DELETE_OPENING_BALANCE = 'DELETE_OPENING_BALANCE',
}

export interface OpeningBalanceType {

    id?: number
    date: string
    amount: string

}

export interface AddOpeningBalanceAction {
    type: OpeningBalanceActionList.ADD_OPENING_BALANCE
    data: OpeningBalanceType
}

export interface FetchOpeningBalanceAction {
    type: OpeningBalanceActionList.FETCH_OPENING_BALANCE
    data: Array<OpeningBalanceType>
}

export interface UpdateOpeningBalanceAction {
    type: OpeningBalanceActionList.UPDATE_OPENING_BALANCE
    data: OpeningBalanceType
}

export interface DeleteOpeningBalanceAction {
    type: OpeningBalanceActionList.DELETE_OPENING_BALANCE
    data: number
}

export type OpeningBalanceActions = AddOpeningBalanceAction | FetchOpeningBalanceAction | UpdateOpeningBalanceAction | DeleteOpeningBalanceAction

export const addOpeningBalance = (data: OpeningBalanceType) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("OpeningBalance Added")
        return api.post<APIResponse<OpeningBalanceType>>('opening_balance/', data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<AddOpeningBalanceAction>({
                    type: OpeningBalanceActionList.ADD_OPENING_BALANCE,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Opening Balance Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const updateOpeningBalance = (data: OpeningBalanceType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)
        // return Promise.resolve("OpeningBalance Updated")

        return api.put<APIResponse<OpeningBalanceType>>(`opening_balance/?id=${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<UpdateOpeningBalanceAction>({
                    type: OpeningBalanceActionList.UPDATE_OPENING_BALANCE,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Opening Balance Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchOpeningBalance = () => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)


        return api.get<APIResponse<OpeningBalanceType[]>>('opening_balance/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchOpeningBalanceAction>({
                    type: OpeningBalanceActionList.FETCH_OPENING_BALANCE,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteOpeningBalance = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("OpeningBalance Deleted")
        return api.delete<APIResponse<OpeningBalanceType>>(`opening_balance/?id=${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteOpeningBalanceAction>({
                    type: OpeningBalanceActionList.DELETE_OPENING_BALANCE,
                    data: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Opening Balance Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}

