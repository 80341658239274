import { ChangeEventHandler, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { addKey } from "../config/functions"
import { RouteMasterType } from "../redux/actions/routeMasterActions"
import { Key } from "../redux/actions/types"
import { VehicleMasterType } from "../redux/actions/vehicleMasterActions"
import { addVehicleRouteMappingMaster, updateVehicleRouteMappingMaster, VehicleRouteMappingMasterType } from "../redux/actions/vehicleRouteMappingMasterActions"
import { StoreState } from "../redux/reducers"
import { AppDispatch } from "../redux/store"
import { FormInput } from "./custom/components"
import Option from "./Option"
import { ToastTypes, useToast } from "./toast/ToastProvider"
import { FormType, FormTypeList } from "./types"

interface Props {
    formType: FormType
    editData?: VehicleRouteMappingMasterType
    onSave: () => void
}

const VehicleRouteMappingMasterForm = ({ formType, onSave, editData }: Props) => {
    const toast = useToast()

    const dispatch = useDispatch<AppDispatch>()
    const routeMaster = useSelector<StoreState, Array<RouteMasterType & Key>>(state => addKey(state.routeMaster))
    const vehicleMaster = useSelector<StoreState, Array<VehicleMasterType & Key>>(state => addKey(state.vehicleMaster))

    const [vehicleId, setVehicleId] = useState<string>('')
    const [vehicleIdError, setVehicleIdError] = useState<string>('')
    const [vehicleName, setVehicleName] = useState<string>('')
    const [routeName, setRouteName] = useState<string>('')
    const [routeId, setRouteId] = useState<string>('')
    const [routeIdError, setRouteIdError] = useState<string>('')

    const resetStates = () => {
        setVehicleId('')
        setVehicleIdError('')
        setVehicleName('')
        setRouteName('')
        setRouteId('')
        setRouteIdError('')
    }

    const handleVehicleId: ChangeEventHandler<HTMLInputElement> = (e) => {
        setVehicleId(e.target.value)
        const index = vehicleMaster.findIndex(vm => vm.id !== undefined && vm.id.toString() === e.target.value)

        if (index > -1) {
            setVehicleName(vehicleMaster[index].name)
        }
        setVehicleIdError('')
    }

    const handleRouteId: ChangeEventHandler<HTMLInputElement> = (e) => {
        setRouteId(e.target.value)
        const index = routeMaster.findIndex(rm => rm.id !== undefined && rm.id.toString() === e.target.value)

        if (index > -1) {
            setRouteName(routeMaster[index].name)
        }

        setRouteIdError('')
    }

    const handleClear = () => {
        resetStates()
    }

    const handleSave = () => {
        let error = false
        const data: VehicleRouteMappingMasterType = {
            routeId: parseInt(routeId),
            routeName: routeName,
            vehicleId: parseInt(vehicleId),
            vehicleName: vehicleName
        }

        if (isNaN(data.vehicleId)) {
            setVehicleIdError('Vehicle required')
            error = true
        } else if (data.vehicleId <= 0) {
            setVehicleIdError('Invalid Vehicle')
            error = true
        }

        if (isNaN(data.routeId)) {
            setRouteIdError('Route required')
            error = true
        } else if (data.routeId <= 0) {
            setRouteIdError('Invalid Route')
            error = true
        }

        if (!error) {
            if (formType === FormTypeList.ADD) {

                dispatch(addVehicleRouteMappingMaster(data)).then(text => {
                    toast(text)
                    onSave()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            } else if (formType === FormTypeList.UPDATE && editData !== undefined && editData.id !== undefined) {
                dispatch(updateVehicleRouteMappingMaster(data, editData.id)).then(text => {
                    toast(text)
                    onSave()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
        }
    }

    useEffect(() => {
        if (formType === FormTypeList.UPDATE && editData !== undefined) {
            setVehicleId(editData.vehicleId !== undefined ? editData.vehicleId.toString() : '')
            setVehicleName(editData.vehicleName)
            setRouteId(editData.routeId !== undefined ? editData.routeId.toString() : '')
            setRouteName(editData.routeName)
            // setRouteName(editData.routeName !== undefined ? editData.routeName : '')
        } else {
            resetStates()
        }
    }, [formType, editData])


    return <div className='container'>
        <div className="row">

            <div className='col-sm-6 '>
                {/* <FormInput
                    value={routeName}
                    onChange={handleRouteName}
                    type='select'
                    label='Route'
                    errorText={routeNameError}
                >
                    <option value="">Select</option>
                    {routeMaster.map(rm => {
                        return <Option value={rm.name} key={rm.key}>{rm.name}</Option>
                    })}
                </FormInput> */}
                <FormInput
                    label='Route'
                    labelClassName="required"
                    value={routeId}
                    onChange={handleRouteId}
                    type='select'
                    errorText={routeIdError}
                >
                    <option value="">Select</option>
                    {routeMaster.filter(rm => {
                        return rm.code !== "HIRE"
                    }).map(rm => {
                        return <Option value={rm.id} key={rm.key}>{rm.name}</Option>
                    })}
                </FormInput>

            </div>

            <div className='col-sm-6 '>
                <FormInput
                    label='Vehicle'
                    labelClassName="required"
                    value={vehicleId}
                    onChange={handleVehicleId}
                    type='select'
                    errorText={vehicleIdError}
                >
                    <option value="">Select</option>
                    {vehicleMaster.map(vm => {
                        return <Option value={vm.id} key={vm.key}>{vm.name}</Option>
                    })}
                </FormInput>
            </div>

        </div>
        <div className="d-flex justify-content-end hstack gap-1 mt-2">
            <button className="btn btn-primary" onClick={handleClear}>Clear</button>
            <button className="btn btn-success" onClick={handleSave}>Save</button>
        </div>

    </div>
}
export default VehicleRouteMappingMasterForm