import { Action } from "../actions/types"
import { ServiceMasterType, ServiceMasterActionList } from "../actions/serviceMasterActions"

const initialValue: Array<ServiceMasterType> = []

const serviceMasterReducer = (state: Array<ServiceMasterType> = initialValue, action: Action) => {
    switch (action.type) {

        case ServiceMasterActionList.ADD_SERVICE_MASTER:
            return [
                ...state,
                action.data,
            ]

        case ServiceMasterActionList.UPDATE_SERVICE_MASTER:
            const updated = [...state].slice()
            const index = updated.findIndex(u => u.id === action.data.id)

            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }

            return updated

        case ServiceMasterActionList.FETCH_SERVICE_MASTER:
            return action.data

        case ServiceMasterActionList.DELETE_SERVICE_MASTER:
            return state.filter(s => s.id !== action.data)

        default:
            return state
    }
}

export default serviceMasterReducer