import React, { ChangeEventHandler, MouseEventHandler, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { addKey } from "../config/functions"
import { addRole, RoleType, updateRole } from "../redux/actions/roleActions"
import { Key } from "../redux/actions/types"
import { StoreState } from "../redux/reducers"
import { AppDispatch } from "../redux/store"
import { FormInput } from "./custom/components"
import { ToastTypes, useToast } from "./toast/ToastProvider"
import { FormType, FormTypeList } from "./types"

interface Props {
    formType: FormType
    editData?: RoleType
    onSave: () => void
}
// id:PK,
// title:FT/UK,
// module:FT,
// permission:FT

const RoleForm = ({ formType, onSave, editData }: Props) => {
    const toast = useToast()

    const dispatch = useDispatch<AppDispatch>()
    const role = useSelector<StoreState, Array<RoleType & Key>>(state => addKey(state.role))

    const [title, setTitle] = useState<string>('')
    const [titleError, setTitleError] = useState<string>('')
    const [module, setModule] = useState<string>('')
    const [moduleError, setModuleError] = useState<string>('')
    const [permission, setPermission] = useState<string>('')
    const [permissionError, setPermissionError] = useState<string>('')

    const resetStates = () => {
        setTitle('')
        setTitleError('')
        setModule('')
        setModuleError('')
        setPermission('')
        setPermissionError('')

    }

    const handleTitle: ChangeEventHandler<HTMLInputElement> = (e) => {
        setTitle(e.target.value)
        setTitleError('')
    }

    const handleModule: ChangeEventHandler<HTMLInputElement> = (e) => {
        setModule(e.target.value)
        setModuleError('')
    }

    const handlePermission: ChangeEventHandler<HTMLInputElement> = (e) => {
        setPermission(e.target.value)
        setPermissionError('')
    }

    const handleClear: MouseEventHandler<HTMLButtonElement> = (e) => {
        resetStates()
    }

    const handleSave = () => {
        let error = false
        const data: RoleType = {
            role: title,
            module: module,
            access: permission
        }
        if (formType === FormTypeList.ADD) {
            if (data.role === "") {
                setTitleError('Title required')
                error = true
            } else if ((data.role).length > 15) {
                setTitleError('Title must be within 15 characters')
                error = true
            } else if (data.role !== "") {
                const uniqueIndex = role.findIndex(r => {
                    return r.role.toLowerCase() === data.role.toLowerCase()
                })
                if (uniqueIndex > -1) {
                    setTitleError('Title already exists')
                    error = true
                }
            }
        }

        if (formType === FormTypeList.UPDATE) {
            if (data.role === "") {
                setTitleError('Title required')
                error = true
            } else if ((data.role).length > 15) {
                setTitleError('Title must be within 15 characters')
                error = true
            }
        }


        if (data.module === "") {
            setModuleError('Module required')
            error = true
        } else if ((data.module).length > 15) {
            setModuleError('Module must be within 15 characters')
            error = true
        }

        if (data.access === "") {
            setPermissionError('Access required')
            error = true
        } else if ((data.access).length > 15) {
            setPermissionError('Access must be within 15 characters')
            error = true
        }

        if (!error) {
            if (formType === FormTypeList.ADD) {

                dispatch(addRole(data)).then(text => {
                    toast(text)
                    onSave()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            } else if (formType === FormTypeList.UPDATE && editData !== undefined && editData.id !== undefined) {
                dispatch(updateRole(data, editData.id)).then(text => {
                    toast(text)
                    onSave()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
        }
    }

    useEffect(() => {
        if (formType === FormTypeList.UPDATE && editData !== undefined) {

            setTitle(editData.role)
            setModule(editData.module)
            setPermission(editData.access)

        } else {
            resetStates()
        }
    }, [formType, editData])


    return <div className='container'>
        <div className="row">

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Title'
                    labelClassName="required"
                    value={title}
                    onChange={handleTitle}
                    placeholder='Title'
                    errorText={titleError}
                    containerClass="mb-2"
                />
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Module'
                    labelClassName="required"
                    value={module}
                    onChange={handleModule}
                    placeholder='Module'
                    errorText={moduleError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Access'
                    labelClassName="required"
                    value={permission}
                    onChange={handlePermission}
                    placeholder='Access'
                    errorText={permissionError}
                    containerClass="mb-2"
                />
            </div>

        </div>
        <div className="d-flex justify-content-end hstack gap-1">
            <button className="btn btn-primary" onClick={handleClear}>Clear</button>
            <button className="btn btn-success" onClick={handleSave}>Save</button>
        </div>

    </div>
}
export default RoleForm