import { MouseEventHandler, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AccountsMasterForm from '../components/AccountsMasterForm'
import PageTitle from '../components/custom/components/PageTitle'
import VerticalLayout from '../components/custom/layouts/Vertical'
import Modal from '../components/Modal'
import { ToastTypes, useToast } from '../components/toast/ToastProvider'
import { FormType, FormTypeList } from '../components/types'
import { addKey, isInToday, LocalFormatDate } from '../config/functions'
import { AccountsMasterType, deleteAccountsMaster } from '../redux/actions/accountsMasterActions'
import { AuthUserType } from '../redux/actions/authUserActions'
import { Key } from '../redux/actions/types'
import { StoreState } from '../redux/reducers'
import { AppDispatch } from '../redux/store'

interface TableBody {

    // "id": 1,
    // "code": "sup1",
    // "name": "Mahindra",
    // "contactPerson": "Ash",
    // "mobileNumber": "8604858905",
    // "GSTNumber": "3485HGJ348937",
    // "aadharNumber": "6756 4088 7883"
    sno: number
    // code: string
    name: string

    bank?: string
    branch?: string
    address?: string
    ifscCode?: string
    contactNo?: string
    oldBalance?: string
    oldBalanceDate?: string
    authUser: AuthUserType
    onEdit: MouseEventHandler<HTMLButtonElement>
    onDelete: MouseEventHandler<HTMLButtonElement>
    timeStamp: string
}

type HandleEdit = (value: AccountsMasterType) => void
type HandleDelete = (value: AccountsMasterType) => void

const AccountsMaster = () => {
    const toast = useToast()

    const dispatch = useDispatch<AppDispatch>()
    const authUser = useSelector<StoreState, AuthUserType>(state => state.authUser)
    const accountsMaster = useSelector<StoreState, Array<AccountsMasterType & Key>>(state => addKey(state.accountsMaster))
    const [showForm, setShowForm] = useState<boolean>(false)
    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    const [editData, setEditData] = useState<AccountsMasterType>()

    const handleAdd = () => {
        setShowForm(true)
    }

    const handleEdit: HandleEdit = (value) => {
        setFormType(FormTypeList.UPDATE)
        setEditData(value)
        setShowForm(true)
    }

    const handleDelete: HandleDelete = (value) => {
        if (value.id !== undefined) {
            dispatch(deleteAccountsMaster(value.id)).then(text => {
                toast(text, ToastTypes.SUCCESS)
            }).catch(text => {
                toast(text, ToastTypes.ERROR)
            })
        }
    }

    const handleFormClose = () => {
        setShowForm(false)
        setFormType(FormTypeList.ADD)
        setEditData(undefined)
    }

    return <>
        <Modal
            headerText={formType === FormTypeList.ADD ? 'Add Accounts Master' : 'Edit Accounts Master'}
            visible={showForm}
            onClose={handleFormClose}
            size='xl'
            centered
        >
            <AccountsMasterForm
                formType={formType}
                editData={editData}
                onSave={handleFormClose}
            />

        </Modal>
        <VerticalLayout>
            <PageTitle
                title='Accounts Master'
                buttonText='ADD'
                onButtonClick={handleAdd}
            />
            <div className="card">
                <div className="card-body">
                    <div className='table-wrapper'>
                        <table className='table colored'>
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    {/* <th className='text-truncate align-middle'>Code</th> */}
                                    <th className='text-truncate align-middle'>Name</th>
                                    <th className='text-truncate align-middle'>Bank</th>
                                    <th className='text-truncate align-middle'>Branch</th>
                                    <th className='text-truncate align-middle'>Address</th>
                                    <th className='text-truncate align-middle'>IFSC Code</th>
                                    <th className='text-truncate align-middle'>Contact No</th>
                                    <th className='text-truncate align-middle'>Old Balance</th>
                                    <th className='text-truncate align-middle'>Old Balance Date</th>
                                    <th className='text-truncate align-middle text-center'>Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                {accountsMaster.map((s, i) => {
                                    return <AccountsMasterBody
                                        sno={i + 1}
                                        // code={s.code !== undefined ? s.code : ''}
                                        name={s.name}
                                        bank={s.bank}
                                        branch={s.branch}
                                        address={s.address !== undefined ? s.address : ''}
                                        ifscCode={s.ifscCode}
                                        contactNo={s.contactNo}
                                        oldBalance={s.oldBalance}
                                        oldBalanceDate={(s.oldBalanceDate !== undefined && s.oldBalanceDate !== '') ? LocalFormatDate(s.oldBalanceDate) : ''}
                                        key={s.key}
                                        authUser={authUser}
                                        timeStamp={s.createTimestamp !== undefined ? s.createTimestamp : ''}
                                        onEdit={() => handleEdit(s)}
                                        onDelete={() => handleDelete(s)}
                                    />
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </VerticalLayout>
    </>
}

const AccountsMasterBody = ({ sno, name, address, bank, branch, ifscCode, contactNo, oldBalance, oldBalanceDate, onEdit, onDelete, authUser,timeStamp }: TableBody) => {
    const ShowEditForManager = isInToday(timeStamp)

    return <tr>
        <td className='align-middle'>{sno}</td>
        {/* <td className=''>{code}</td> */}
        <td className='text-capitalize align-middle'>{name}</td>
        <td className='text-capitalize align-middle'>{bank}</td>
        <td className='text-capitalize align-middle'>{branch}</td>
        <td className='text-capitalize align-middle'>{address}</td>
        <td className='text-capitalize align-middle text-truncate'>{ifscCode}</td>
        <td className='text-capitalize align-middle text-truncate'>{contactNo}</td>
        <td className='text-capitalize align-middle text-truncate'>{oldBalance}</td>
        <td className='text-capitalize align-middle text-truncate'>{oldBalanceDate}</td>
        <td>
            <div className='hstack gap-1'>
            {(authUser.role === 'admin' || authUser.role === 'super' || authUser.role === 'Admin' || authUser.role === 'Super') && <button className='btn btn-xs btn-primary' onClick={onEdit}>Edit</button>}
            {(authUser.role === 'Manager' || authUser.role === 'manager') && ShowEditForManager && <button className='btn btn-xs btn-primary' onClick={onEdit}>Edit</button>}
                <button className='btn btn-xs btn-danger' onClick={onDelete}>Delete</button>
            </div>
        </td>
    </tr>
}

export default AccountsMaster
