import { Action } from "../actions/types"
import { VehicleReadingType, VehicleReadingActionList } from "../actions/vehicleReadingActions"

const initialValue: Array<VehicleReadingType> = []

const vehicleReadingReducer = (state: Array<VehicleReadingType> = initialValue, action: Action) => {
    switch (action.type) {

        case VehicleReadingActionList.ADD_VEHICLE_READING:
            return [
                ...state,
                action.data,
            ]

        case VehicleReadingActionList.UPDATE_VEHICLE_READING:
            const updated = [...state].slice()
            const index = updated.findIndex(u => u.id === action.data.id)

            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }

            return updated

        case VehicleReadingActionList.FETCH_VEHICLE_READING:
            return action.data

        default:
            return state
    }
}

export default vehicleReadingReducer