import { ChangeEventHandler, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import FormInput from '../components/custom/components/FormInput'
import { addKey } from "../config/functions"
import { addAsset, AssetStatus, AssetStatusList, AssetsType, AssetType, AssetTypeList, updateAsset } from "../redux/actions/assetActions"
import { AppDispatch } from "../redux/store"
import Option from "./Option"
import { ToastTypes, useToast } from "./toast/ToastProvider"
import { FormType, FormTypeList } from "./types"

interface Props {
    formType: FormType
    editData?: AssetType
    onSave: () => void

}

const ASSET_TYPES = addKey([
    {
        text: 'Tyre',
        value: 'Tyre'
    },
    {
        text: 'Others',
        value: 'Others'
    }
])

const ASSET_STATUS = addKey([
    {
        text: 'Available',
        value: 'Available'
    },
    {
        text: 'Assigned',
        value: 'Assigned'
    },
    {
        text: 'Retreaded',
        value: 'Retreaded'
    },
    {
        text: 'Service in Progress',
        value: 'Service In Progress'
    },
    {
        text: 'Expired',
        value: 'Expired'
    }
])

// id:PK,
// type: tyre/others
// brand:FT,
// name:FT,
// number:FT,
// status: Available | Assigned | Retreaded | Service in Progress | Expired
// retreadeCount: number,
// nextservice: FT,
// createdBy,
// createTimestamp 

const AssetForm = ({ formType, editData, onSave }: Props) => {
    const toast = useToast()

    const dispatch: AppDispatch = useDispatch()

    const [type, setType] = useState<AssetsType>(AssetTypeList.TYRE)
    const [typeError, setTypeError] = useState<string>('')
    const [brand, setBrand] = useState<string>('')
    const [brandError, setBrandError] = useState<string>('')
    const [name, setName] = useState<string>('')
    const [nameError, setNameError] = useState<string>('')
    const [number, setNumber] = useState<string>('')
    const [numberError, setNumberError] = useState<string>('')
    const [status, setStatus] = useState<AssetStatus>(AssetStatusList.ASSIGNED)
    const [statusError, setStatusError] = useState<string>('')
    const [retreadeCount, setRetreadeCount] = useState<string>('')
    const [retreadeCountError, setRetreadeCountError] = useState<string>('')
    const [nextService, setNextService] = useState<string>('')
    const [nextServiceError, setNextServiceError] = useState<string>('')


    const resetStates = () => {
        setType(AssetTypeList.TYRE)
        setTypeError('')
        setBrand('')
        setBrandError('')
        setName('')
        setNameError('')
        setNumber('')
        setNumberError('')
        setStatus(AssetStatusList.ASSIGNED)
        setStatusError('')
        setRetreadeCount('')
        setRetreadeCountError('')
        setNextService('')
        setNextServiceError('')

    }

    const handleType: ChangeEventHandler<HTMLInputElement> = (e) => {
        setType(e.target.value as AssetsType)
        setTypeError('')
    }

    const handleBrand: ChangeEventHandler<HTMLInputElement> = (e) => {
        setBrand(e.target.value)
        setBrandError('')
    }

    const handleName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setName(e.target.value)
        setNameError('')
    }

    const handleNumber: ChangeEventHandler<HTMLInputElement> = (e) => {
        setNumber(e.target.value)
        setNumberError('')
    }


    const handleStatus: ChangeEventHandler<HTMLInputElement> = (e) => {
        setStatus(e.target.value as AssetStatus)
        setStatusError('')
    }

    const handleRetreadeCount: ChangeEventHandler<HTMLInputElement> = (e) => {
        setRetreadeCount(e.target.value)
        setRetreadeCountError('')
    }

    const handleNextService: ChangeEventHandler<HTMLInputElement> = (e) => {
        setNextService(e.target.value)
        setNextServiceError('')
    }

    const handleClear = () => {
        resetStates()
    }

    const handleSave = () => {
        let error = false
        const data: AssetType = {
            type: type,
            brand: brand,
            name: name,
            number: number,
            status: status,
            retreadCount: parseInt(retreadeCount),
            nextService: nextService,

        }
        // if (data.type === "") {
        //     setTypeError('Asset Type required')
        //     error = true
        // } else
        if ([AssetTypeList.TYRE, AssetTypeList.OTHERS].indexOf(data.type as AssetsType) === -1) {
            setTypeError('Asset type must be Tyre/Others')
            error = true
        }

        if (data.brand === "") {
            setBrandError('Brand required')
            error = true
        } else if ((data.brand).length > 30) {
            setBrandError('Brand must be within 30 characters')
            error = true
        }

        if (data.name === "") {
            setNameError('Asset Name required')
            error = true
        } else if ((data.name).length > 30) {
            setNameError('Asset Name must be within 30 characters')
            error = true
        }

        if (data.number === "") {
            setNumberError('No.Of.Assets required')
            error = true
        } else if (parseInt(data.number) < 0) {
            setNumberError('No.Of.Assets invalid')
            error = true
        }

        // if (data.status === "") {
        //     setStatusError('Status required')
        //     error = true
        // } else 
        if ([AssetStatusList.ASSIGNED, AssetStatusList.AVAILABLE, AssetStatusList.EXPIRED, AssetStatusList.RETREADED, AssetStatusList.SERVICE_IN_PROGRESS].indexOf(data.status as AssetStatus) === -1) {
            setStatusError('Asset status must be Assigned/Available/Expired/Retreaded/Service in Progress')
            error = true
        }

        if (data.nextService !== undefined) {
            if ((data.nextService).length > 10) {
                setNextServiceError('Next service must be within 10 characters')
                error = true
            }
        }
        //need to be cleared as required or not

        if (data.retreadCount !== undefined) {
            if (data.retreadCount < 0) {
                setRetreadeCountError('Retread Count must be a valid one')
                error = true
            }
        }
        //need to be cleared as required or not

        if (!error) {
            if (formType === FormTypeList.ADD) {

                dispatch(addAsset(data)).then(text => {
                    toast(text)
                    onSave()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            } else if (formType === FormTypeList.UPDATE && editData !== undefined && editData.id !== undefined) {
                dispatch(updateAsset(data, editData.id)).then(text => {
                    toast(text)
                    onSave()
                }).catch(text => {
                    toast(text, ToastTypes.ERROR)
                })
            }
        }
    }

    useEffect(() => {
        if (formType === FormTypeList.UPDATE && editData !== undefined) {

            setType(editData.type)
            setBrand(editData.brand)
            setName(editData.name)
            setNumber(editData.number)
            setStatus(editData.status)
            setRetreadeCount(editData.retreadCount ? editData.retreadCount.toString() : '')
            setNextService(editData.nextService)

        } else {
            resetStates()
        }
    }, [formType, editData])

    return <div className='container'>
        <div className='row'>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Type'
                    labelClassName="required"
                    type='select'
                    value={type}
                    onChange={handleType}
                    errorText={typeError}
                    containerClass="mb-2"
                >
                    <Option value=''>Select</Option>
                    {ASSET_TYPES.map(at => {
                        return <Option value={at.value} key={at.key}>{at.text}</Option>
                    })}
                </FormInput>
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Brand'
                    labelClassName="required"
                    value={brand}
                    onChange={handleBrand}
                    placeholder='Brand'
                    errorText={brandError}
                    containerClass="mb-2"
                />
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Name'
                    labelClassName="required"
                    value={name}
                    onChange={handleName}
                    placeholder='Name'
                    errorText={nameError}
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Number'
                    labelClassName="required"
                    value={number}
                    onChange={handleNumber}
                    placeholder='Number'
                    errorText={numberError}
                    containerClass="mb-2"
                    type="text"
                />
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    type='select'
                    label='Status'
                    labelClassName="required"
                    value={status}
                    onChange={handleStatus}
                    errorText={statusError}
                    containerClass="mb-2"
                >
                    <Option value=''>Select</Option>
                    {ASSET_STATUS.map(as => {
                        return <Option value={as.value} key={as.key}>{as.text}</Option>
                    })}
                </FormInput>
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Retreade Count'
                    value={retreadeCount}
                    onChange={handleRetreadeCount}
                    placeholder='Retreade Count'
                    errorText={retreadeCountError}
                    type='number'
                    containerClass="mb-2"
                />
            </div>
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    label='Next Service'
                    value={nextService}
                    onChange={handleNextService}
                    placeholder='Next Service'
                    errorText={nextServiceError}
                    containerClass="mb-2"
                />
            </div>

        </div>
        <div className="d-flex justify-content-end hstack gap-1">
            <button className="btn btn-primary" onClick={handleClear}>Clear</button>
            <button className="btn btn-success" onClick={handleSave}>Save</button>
        </div>

    </div>
}

export default AssetForm