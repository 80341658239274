import { Action } from "../actions/types"
import { TransactionType, TransactionActionList } from "../actions/transactionActions"

const initialValue: Array<TransactionType> = []

const transactionReducer = (state: Array<TransactionType> = initialValue, action: Action) => {
    switch (action.type) {

        case TransactionActionList.ADD_TRANSACTION:
            return [
                ...state,
                action.data,
            ]

        case TransactionActionList.UPDATE_TRANSACTION:
            const updated = [...state].slice()
            const index = updated.findIndex(u => u.id === action.data.id)

            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }

            return updated

        case TransactionActionList.FETCH_TRANSACTION:
            return action.data

        case TransactionActionList.DELETE_TRANSACTION:
            return state.filter(s => s.id !== action.data)

        default:
            return state
    }
}

export default transactionReducer