/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import { default as ticketDetailsData } from '../dataSet/ticketDetails.json'
import { GetState } from "../reducers"

export enum TicketDetailsActionList {
    ADD_TICKET_DETAILS = 'ADD_TICKET_DETAILS',
    FETCH_TICKET_DETAILS = 'FETCH_TICKET_DETAILS',
    UPDATE_TICKET_DETAILS = 'UPDATE_TICKET_DETAILS',
}

export interface TicketDetailsType {
    // "id": 1,
    // "collectionId": 1,
    // "ticketPrice": "10",
    // "startingNumber": "100",
    // "EndingNumber": "150"

    id?: number
    collectionId?: number
    conductorId?: number
    ticketPrice: string
    startingNumber: string
    endingNumber: string
}

export interface AddTicketDetailsAction {
    type: TicketDetailsActionList.ADD_TICKET_DETAILS
    data: TicketDetailsType
}

export interface FetchTicketDetailsAction {
    type: TicketDetailsActionList.FETCH_TICKET_DETAILS
    data: Array<TicketDetailsType>
}

export interface UpdateTicketDetailsAction {
    type: TicketDetailsActionList.UPDATE_TICKET_DETAILS
    data: TicketDetailsType
}

export type TicketDetailsActions = AddTicketDetailsAction | FetchTicketDetailsAction | UpdateTicketDetailsAction

export const addTicketDetails = (data: TicketDetailsType) => {
    return async (dispatch: Dispatch, getState: GetState) => {
        const { ticketDetails } = getState()
        dispatch<AddTicketDetailsAction>({
            type: TicketDetailsActionList.ADD_TICKET_DETAILS,
            data: {
                ...data,
                id: ticketDetails.length + 1
            }
        })
        return Promise.resolve("Ticket details Added")
        // return api.post<APIResponse<TicketDetailsType>>('TicketDetails/', data).then(response => {
        //    if (response.status === 200 && response.data.data) {

        //         return Promise.resolve(response.data.message
        //             ? response.data.message
        //             : 'Successfully logged in')
        //     } else {
        //         throw { response }
        //     }
        // }).catch(error => {
        //     return Promise.reject(error.response
        //         ? error.response.data.message
        //             ? error.response.data.message
        //             : 'Unable to login'
        //         : 'Unable to login')
        // })
    }
}

export const updateTicketDetails = (data: TicketDetailsType, id: number) => {
    return async (dispatch: Dispatch) => {
        dispatch<UpdateTicketDetailsAction>({
            type: TicketDetailsActionList.UPDATE_TICKET_DETAILS,
            data: {
                ...data,
                id: id
            }
        })
        return Promise.resolve("Ticket details Updated")
        // return api.post<APIResponse<TicketDetailsType>>('TicketDetails/', data).then(response => {
        //    if (response.status === 200 && response.data.data) {

        //         return Promise.resolve(response.data.message
        //             ? response.data.message
        //             : 'Successfully logged in')
        //     } else {
        //         throw { response }
        //     }
        // }).catch(error => {
        //     return Promise.reject(error.response
        //         ? error.response.data.message
        //             ? error.response.data.message
        //             : 'Unable to login'
        //         : 'Unable to login')
        // })
    }
}

export const fetchTicketDetails = () => {
    return async (dispatch: Dispatch) => {
        dispatch<FetchTicketDetailsAction>({
            type: TicketDetailsActionList.FETCH_TICKET_DETAILS,
            data: ticketDetailsData
        })
        // return api.post<APIResponse<TicketDetailsType>>('TicketDetails/', data).then(response => {
        //    if (response.status === 200 && response.data.data) {

        //         return Promise.resolve(response.data.message
        //             ? response.data.message
        //             : 'Successfully logged in')
        //     } else {
        //         throw { response }
        //     }
        // }).catch(error => {
        //     return Promise.reject(error.response
        //         ? error.response.data.message
        //             ? error.response.data.message
        //             : 'Unable to login'
        //         : 'Unable to login')
        // })
    }
}



