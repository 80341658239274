import { Action } from "../actions/types"
import { AuthUserType, AuthUserActionList } from "../actions/authUserActions"

const initialValue: AuthUserType = {
    email: '',
    mobileNumber: '',
    name: '',
    role: '',
    username: '',
    id: undefined,
    token: undefined,
    status: undefined
}

const authUserReducer = (state: AuthUserType = initialValue, action: Action): AuthUserType => {
    switch (action.type) {

        case AuthUserActionList.SIGNUP_USER:
            return action.data

        case AuthUserActionList.LOGIN_USER:
            return action.data

        case AuthUserActionList.LOGOUT_USER:
            return initialValue

        default:
            return state
    }
}

export default authUserReducer