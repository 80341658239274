import { MouseEventHandler, useState } from 'react'
import { useSelector } from 'react-redux'
import PageTitle from '../components/custom/components/PageTitle'
import VerticalLayout from '../components/custom/layouts/Vertical'
import ExpenseMasterForm from '../components/ExpenseMasterForm'
import Modal from '../components/Modal'
import { FormType, FormTypeList } from '../components/types'
import { addKey, isInToday } from '../config/functions'
import { AuthUserType } from '../redux/actions/authUserActions'
import { ExpenseMasterType } from '../redux/actions/expenseMasterActions'
import { Key } from '../redux/actions/types'
import { StoreState } from '../redux/reducers'

interface TableBody {
    sno: number
    title: string
    onEdit: MouseEventHandler<HTMLButtonElement>
    timeStamp: string
    authUser: AuthUserType
    // onDelete: MouseEventHandler<HTMLButtonElement>
}

type HandleEdit = (value: ExpenseMasterType) => void
// type HandleDelete = (value: ExpenseMasterType) => void

const ExpenseMaster = () => {
    // const toast = useToast()

    // const dispatch = useDispatch<AppDispatch>()
    const authUser = useSelector<StoreState, AuthUserType>(state => state.authUser)

    const expenseMaster = useSelector<StoreState, Array<ExpenseMasterType & Key>>(state => addKey(state.expenseMaster))
    const [showForm, setShowForm] = useState<boolean>(false)
    const [formType, setFormType] = useState<FormType>(FormTypeList.ADD)
    const [editData, setEditData] = useState<ExpenseMasterType>()

    const handleAdd = () => {
        setShowForm(true)
    }

    const handleEdit: HandleEdit = (value) => {
        setFormType(FormTypeList.UPDATE)
        setEditData(value)
        setShowForm(true)
    }

    // const handleDelete: HandleDelete = (value) => {
    //     if (value.id !== undefined) {
    //         dispatch(delete(value.id)).then(text => {
    //             toast(text)
    //         }).catch(text => {
    //             toast(text, ToastTypes.ERROR)
    //         })
    //     }
    // }

    const handleFormClose = () => {
        setShowForm(false)
        setFormType(FormTypeList.ADD)
        setEditData(undefined)
    }

    return <>
        <Modal
            headerText={formType === FormTypeList.ADD ? 'Add Expense Master' : 'Edit Expense Master'}
            visible={showForm}
            onClose={handleFormClose}
            size='lg'
            centered
        >
            <ExpenseMasterForm
                formType={formType}
                editData={editData}
                onSave={handleFormClose}
            />

        </Modal>
        <VerticalLayout>
            <PageTitle
                title='Expense Master'
                buttonText='ADD'
                onButtonClick={handleAdd}
            />
            <div className="card">
                <div className="card-body">
                    <div className='table-wrapper'>
                        <table className='table colored'>
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    <th className='text-truncate align-middle'>Title</th>
                                    <th className='text-truncate align-middle'>Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                {expenseMaster.map((em, i) => {
                                    return <RoleBody
                                        sno={i + 1}
                                        title={em.title}
                                        key={em.key}
                                        authUser={authUser}
                                        timeStamp={em.createTimestamp !== undefined ? em.createTimestamp : ''}
                                        onEdit={() => handleEdit(em)}
                                    // onDelete={() => handleDelete(em)}
                                    />
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </VerticalLayout>
    </>
}

const RoleBody = ({ sno, title, onEdit, timeStamp, authUser }: TableBody) => {
    const ShowEditForManager = isInToday(timeStamp)

    return <tr>
        <td className='align-middle'>{sno}</td>
        <td className='text-capitalize align-middle'>{title}</td>
        <td>
            <div className='hstack gap-1'>
                {(authUser.role === 'admin' || authUser.role === 'super' || authUser.role === 'Admin' || authUser.role === 'Super') && <button className='btn btn-xs btn-primary' onClick={onEdit}>Edit</button>}
                {(authUser.role === 'Manager' || authUser.role === 'manager') && ShowEditForManager && <button className='btn btn-xs btn-primary' onClick={onEdit}>Edit</button>}
                {/* <button className='btn btn-danger btn-xs' onClick={onDelete}>Delete</button> */}
            </div>
        </td>
    </tr>
}

export default ExpenseMaster
