import { Action } from "../actions/types"
import { MachineMasterType, MachineMasterActionList } from "../actions/machineMasterActions"

const initialValue: Array<MachineMasterType> = []

const machineMasterReducer = (state: Array<MachineMasterType> = initialValue, action: Action) => {
    switch (action.type) {

        case MachineMasterActionList.ADD_MACHINE_MASTER:
            return [
                ...state,
                action.data,
            ]

        case MachineMasterActionList.UPDATE_MACHINE_MASTER:
            const updated = [...state].slice()
            const index = updated.findIndex(u => u.id === action.data.id)

            if (index > -1) {
                updated[index] = {
                    ...action.data
                }
            }

            return updated

        case MachineMasterActionList.FETCH_MACHINE_MASTER:
            return action.data

        case MachineMasterActionList.DELETE_MACHINE_MASTER:
            return state.filter(s => s.id !== action.data)

        default:
            return state
    }
}

export default machineMasterReducer