/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { default as sparePartsData } from '../dataSet/spareParts.json'
import { GetState } from "../reducers"

export enum SparePartsActionList {
    ADD_SPARE_PARTS = 'ADD_SPARE_PARTS',
    FETCH_SPARE_PARTS = 'FETCH_SPARE_PARTS',
    UPDATE_SPARE_PARTS = 'UPDATE_SPARE_PARTS',
    DELETE_SPARE_PARTS = 'DELETE_SPARE_PARTS',
}

export interface SparePartsType {
    id?: number
    code: string
    name: string
    number?: string
    brand: string
    usedFor: string
    createdBy?: string
    createTimestamp?: string
}

export interface AddSparePartsAction {
    type: SparePartsActionList.ADD_SPARE_PARTS
    data: SparePartsType
}

export interface FetchSparePartsAction {
    type: SparePartsActionList.FETCH_SPARE_PARTS
    data: Array<SparePartsType>
}

export interface UpdateSparePartsAction {
    type: SparePartsActionList.UPDATE_SPARE_PARTS
    data: SparePartsType
}

export interface DeleteSparePartsAction {
    type: SparePartsActionList.DELETE_SPARE_PARTS
    data: number
}

export type SparePartsActions = AddSparePartsAction | FetchSparePartsAction | UpdateSparePartsAction | DeleteSparePartsAction

export const addSpareParts = (data: SparePartsType) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Spare Parts Added")
        return api.post<APIResponse<SparePartsType>>('spare_parts/', data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<AddSparePartsAction>({
                    type: SparePartsActionList.ADD_SPARE_PARTS,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Spare Parts Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const updateSpareParts = (data: SparePartsType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Spare Parts Updated")
        return api.put<APIResponse<SparePartsType>>(`spare_parts/?id=${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<UpdateSparePartsAction>({
                    type: SparePartsActionList.UPDATE_SPARE_PARTS,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Spare Parts Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchSpareParts = () => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<SparePartsType[]>>('spare_parts/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchSparePartsAction>({
                    type: SparePartsActionList.FETCH_SPARE_PARTS,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteSpareParts = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("Spare Parts Deleted")
        return api.delete<APIResponse<SparePartsType>>(`spare_parts/?id=${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteSparePartsAction>({
                    type: SparePartsActionList.DELETE_SPARE_PARTS,
                    data: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Spare Parts Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}

