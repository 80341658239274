/* eslint-disable no-throw-literal */
import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../reducers"

export enum ExpenseMasterActionList {
    ADD_EXPENSE_MASTER = 'ADD_EXPENSE_MASTER',
    FETCH_EXPENSE_MASTER = 'FETCH_EXPENSE_MASTER',
    UPDATE_EXPENSE_MASTER = 'UPDATE_EXPENSE_MASTER',
    DELETE_EXPENSE_MASTER = 'DELETE_EXPENSE_MASTER',
}

export interface ExpenseMasterType {

    id?: number
    title: string
    createTimestamp?: string

}

export interface AddExpenseMasterAction {
    type: ExpenseMasterActionList.ADD_EXPENSE_MASTER
    data: ExpenseMasterType
}

export interface FetchExpenseMasterAction {
    type: ExpenseMasterActionList.FETCH_EXPENSE_MASTER
    data: Array<ExpenseMasterType>
}

export interface UpdateExpenseMasterAction {
    type: ExpenseMasterActionList.UPDATE_EXPENSE_MASTER
    data: ExpenseMasterType
}

export interface DeleteExpenseMasterAction {
    type: ExpenseMasterActionList.DELETE_EXPENSE_MASTER
    data: number
}

export type ExpenseMasterActions = AddExpenseMasterAction | FetchExpenseMasterAction | UpdateExpenseMasterAction | DeleteExpenseMasterAction

export const addExpenseMaster = (data: ExpenseMasterType) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("ExpenseMaster Added")
        return api.post<APIResponse<ExpenseMasterType>>('expense_master/', data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<AddExpenseMasterAction>({
                    type: ExpenseMasterActionList.ADD_EXPENSE_MASTER,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Expense Master Added')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add')
        })
    }
}

export const updateExpenseMaster = (data: ExpenseMasterType, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)
        // return Promise.resolve("ExpenseMaster Updated")

        return api.put<APIResponse<ExpenseMasterType>>(`expense_master/?id=${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<UpdateExpenseMasterAction>({
                    type: ExpenseMasterActionList.UPDATE_EXPENSE_MASTER,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Expense Master Updated')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update')
        })
    }
}

export const fetchExpenseMaster = () => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        return api.get<APIResponse<ExpenseMasterType[]>>('expense_master/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchExpenseMasterAction>({
                    type: ExpenseMasterActionList.FETCH_EXPENSE_MASTER,
                    data: response.data.data
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successfull')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch')
        })
    }
}

export const deleteExpenseMaster = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!
        const config = getAxiosRequestConfig(token)

        // return Promise.resolve("ExpenseMaster Deleted")
        return api.delete<APIResponse<ExpenseMasterType>>(`expense_master/?id=${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteExpenseMasterAction>({
                    type: ExpenseMasterActionList.DELETE_EXPENSE_MASTER,
                    data: id
                })
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Expense Master Deleted')
            } else {
                throw { response }
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete')
        })
    }
}

